import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterEl from "../Footer/FooterEl";

import Header from "../header/Header";
import "./Terms.scss";
const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div className="headLine">
        <p className="textHeadline">Terms & Conditions</p>
        <div className="content">
          <p className="text">
            <strong>Last Updated: 12 January, 2025</strong>
            <br></br>
            <br></br>
            <strong>INTRODUCTION - WHO WE ARE AND WHAT WE DO?</strong>
            <br></br>
            <br></br>

            {/*         
          We are the flagship brand of Sporta Technologies Private
          Limited, offering Our Platform to You and an opportunity for You to
          participate in Contests spanning across a broad range of officially
          sanctioned live sporting events (“Services”). An illustrative list
          of such sporting events is mentioned below as maybe modified from
          time to time (“Sports”): Cricket Football Basketball Baseball Hockey
          Handball Volleyball Kabaddi Any person using, accessing and/or
          participating in any fantasy sports-related free-to-play online
          gaming contests (“Practice Contest”) and/or pay-to-play online
          gaming contests (“Paid Contest”) on Our Platform is a user (“User”).
          All references to “You/Your” relate to the User. All references to
          “We/Us/Our” relate to “Dream11” which denotes a collective reference
          to the Dream11 mobile application and the Dream11 website
          (hereinafter collectively referred to as “Platform”). Practice
          Contest and Paid Contest are hereinafter collectively referred to as
          “Contests”. ACCEPTANCE OF OUR TERMS AND CONDITIONS Your continued
          usage of Our Platform constitutes Your acceptance of the terms and
          conditions, including any additional terms that We may make
          available to You (“Terms") and are contractually binding on You. You
          accept that in the event of any variance between these Terms and any
          additional terms relating to the usage of Our Platform or any
          Contest, the additional terms will prevail. By accepting these
          Terms, You agree and consent to receive communications from Us
          and/or Our partners, licensors or associates for any purpose through
          the following modes: Announcements; Administrative messages/direct
          messages ; Advertisements; direct notification to your account; and
          by any other means that We may consider fit for this purpose. You
          agree that You shall mark Us as a safe sender on all Your platforms
          where You receive any such communications from Us (including via
          email and SMS) to ensure such communications are not transferred to
          the spam/junk folder. USER ACCOUNT You are required to register on
          Our Platform to create an account to access Our Services
          (“Account”). At the time of creating Your Account and/or at any time
          during the utilisation of Our Services, You will be required to
          provide any and/or all of the following information and/or
          documents: Your full name; Your team name; Your mobile number; Your
          full address and state of residence; Your gender; and Your date of
          birth. Aadhar number Any other valid identification Bank Account
          information UPI ID Permanent Account Number (PAN) We reserve the
          right to conduct KYC as may be required from time to time in the
          manner prescribed under such law. ELIGIBILITY To participate in any
          Paid Contest, you must meet the following eligibility criteria: You
          must be above the age of 18 years. You must be residing in India.
          You must have a valid Indian mobile number to create an Account.
          Please note that Paid Contests are not permitted in the following
          states (“Restricted States”): Andhra Pradesh; Assam; Nagaland;
          Sikkim; and Telangana You agree that You are not from any of these
          Restricted States and You shall refrain from making any false
          disclosures, declarations and/or representations to Us to circumvent
          the applicable laws and/or regulations of the territory of India
          (“Applicable Law”) of the Restricted States to participate in any
          Paid Contests. CONTEST RULES AND FORMAT CONTEST RULES You agree and
          acknowledge that: To participate in a Contest(s), You are required
          to create a fantasy team (“Team”), by selecting real-life players
          who are participating in the specific Sports. You must finalise Your
          Team for the relevant Contest before the start time and/or any other
          adjusted time (as specified below) of the Sports (“Contest
          Deadline”). We may notify any extensions to the Contest Deadline in
          advance. Each Contest will require a minimum number of Users to
          participate in the Contest for it to become operational
          (“Pre-specified Number of Users”) In the case of a multiple-entry
          contest, a User may create multiple Teams to participate in such a
          Contest (“Multiple Entry Contest”). We reserve Our right in Our sole
          discretion to restrict the maximum number of Teams You may submit
          for each Contest format. You may participate in the Paid Contests by
          paying a pre-designated amount as specified on the relevant Contest
          page (“Pre-Designated Amount”). For each Contest, We will create a
          prize pool consisting of contributions from all participating Users
          (“Prize Money Pool”). For clarity, we have no right or interest in
          this Prize Money Pool. We reserve a right to deduct a fee from the
          Pre-Designated Amount in lieu of the Service being provided by Us
          (“Platform Fees”) and the balance amount shall constitute Your
          contribution towards the Prize Money Pool. From time to time, we may
          conduct/organize promotions/offers on the Platform. Your
          participation in such promotions/ offers shall be in accordance with
          the terms and conditions applicable to the Promotion. You can
          participate in up to 2000 Contests per match in cricket and up to
          500 Contests per match in any other sports. You can only create a
          maximum of 20 (twenty) Teams for any Contest You are participating
          in. Our toss and starting lineup feature in relation to the Contests
          is only to guide and assist the User in selecting their Team(s). In
          indicating a player’s inclusion in a starting lineup, we rely on
          information/data received from third-party data feed service
          providers (“Data Feed Providers”) and/or publicly available
          information. Prior to creating your Team, We strongly recommend You
          conduct Your own independent research of player statistics, pitch
          conditions, weather conditions, player performances, track record of
          competing teams, winning record of captains and vice captains.
          Flexible and Non-Flexible Contest (a) A Contest will be operational
          even if a minimum of (2)two Users join such a Contest (“Flexible
          Contest”) (b) A Contest will be operational only if the number of
          Users participating in such Contest is equal to the limit set by us
          in case of a Public Contest and by You in case of a Private Contest
          (“Non-Flexible Contest”) (c) The Prize Money Pool shall be directly
          proportional to the number of Users participating in a Flexible
          Contest. For clarity, the Prize Money Pool specified on the Contest
          page will decrease if the number of Users joining the Contest is
          less than the limit set by You for a Private Contest and by Us for a
          Public Contest. In participating in Contests, You agree to strictly
          comply with the Terms, including additional rules published by Us,
          such as: (a) “Fantasy Rules” ( available at How to Play - Fantasy
          Cricket or How to Play - Fantasy Football or How to Play - Fantasy
          Kabaddi or How to Play - Fantasy Volleyball or How to Play - Fantasy
          Hockey or How to Play - Fantasy Basketball or How to Play - Fantasy
          Baseball or How to Play - Fantasy Handball or How to Play - Fantasy
          American Football; and (b) any other rules and regulations
          (including without limitation in relation to payments made to
          participate in any Contest) CONTEST FORMATS Public Contest A Public
          Contest is a Contest where You can participate with other Users
          without any restrictions. Features of Public Contests: Public
          Contest can either be a Flexible Contest or a Non-Flexible Contest.
          We reserve the right to cancel the Public Contest if the number of
          participating Users is less than the Pre Specified Number of Users.
          If cancelled We will return Your Pre Designated Amount without any
          deduction. If a Contest is labelled as a "Guaranteed Contest" it
          will become operational when the Pre-Specified Number of Users join
          the Contest. The Prize Money Pool for each Guaranteed Contest is
          predetermined. We will cover the shortfall if there are not enough
          Users in a Guaranteed Contest to meet the guaranteed Prize Money
          Pool. We will declare the winners as per the Contest page,
          regardless of the number of Users joining the Contest Private
          Contest A Private Contest is where You can create a Contest and
          invite other Users (“Invited Users”) to join your Contest. Features
          of Private Contest: To create a Private Contest, You need to ensure
          You provide a name to the Private Contest; You set a limit on the
          number of Users joining the Private Contest, which can range from
          2-20,000 users; You will have the right to select whether Your
          Contest will be a Flexible Contest or a Non - Flexible Contest. Pay
          the Pre-Designated Amount to join the Private Contest. After You
          create a Private Contest, We will provide a unique identification
          code for the Private Contest that You can share with other Users for
          participating in the Private Contest (“Contest Code”). You will not
          be able to make any changes to the terms of the Private Contest once
          created Invited User(s) must enter the Contest Code and pay the
          Pre-Designated Amount to join the Private Contest. We will refund
          the Pre-Designated Amount if one hour before the Contest Deadline,
          the users participating in the Private Contest are less than either
          the Pre-Specified Number of Users as applicable for a Flexible
          Contest; or the limit set by You for a Non-Flexible Contest. We will
          process the refund after the expiry of the Contest Deadline. We will
          not be liable to the Invited User(s) for their inability to join the
          Private Contest for any reason not attributable to Us WINNING WINNER
          DECLARATION Teams are awarded points based on the performance of
          Your Team at the end of the Contest. The User’s Team with the
          highest score(s) in the Contest will be declared as the winner
          (“Winners”). In certain pre-specified Contests having more than one
          Winner, the distribution of prizes to such Winners will be in
          increasing order of their Team’s aggregate score at the end of the
          relevant Sports relating to such Contest. The Winner(s) will be
          selected based on the highest aggregate scoring User Team in a
          particular Contest (where the duration of the Sports can range from
          1 day to 5 days). The number of eligible winners and prizes for the
          Contest(s) will be announced on the Contest page before the Contest
          begins. GENERAL RULES FOR DISTRIBUTION OF WINNINGS The User
          accumulating the highest points at the end of a match shall be
          eligible to win a pre-designated winning disbursed out of the Prize
          Money Pool as stated on the relevant Contest page. If there is a
          tie, the winnings will be distributed evenly among the declared
          winners. We reserve the right to vary or modify the winnings at Our
          discretion before the Contest Deadline. You agree that any winnings
          in the nature of prizes or rewards awarded by Us shall be governed
          by the terms and conditions of such specific promotional events. We
          reserve the right to disqualify any Winner and prevent them from
          withdrawing their winnings or receiving any prize if: any of the
          document or information submitted by You are incorrect, misleading,
          false, fabricated, incomplete or illegible; or You created teams on
          behalf of other Users. You do not abide by these Terms. You have
          falsely declared your State and entered the contest from prohibited
          states as per clause IV above. TABULATION AND SCORING We use Data
          Feed Providers and/ or the official website of the Sports Event
          organiser to obtain scores and relevant information required for
          calculating the fantasy points. If there is an error in calculating
          the fantasy points that are brought to Our attention due to
          inaccuracies or incompleteness of the information provided by the
          Data Feed Provider, We shall do Our best to rectify said errors
          before distributing the prizes. VERIFICATION OF WINNERS We or our
          third-party service providers working on behalf of us may contact
          the Winners on the email address provided at the time of Account
          creation. At this stage, Winner will be explained the details of the
          verification process and the documents required for collecting the
          prize. If You have been declared as a Winner on Our Platform but
          have not received Your Winnings, You may contact Our Help and
          Support within forty-eight (48) hours of the Winner declaration to
          help us resolve your concern at the earliest. You agree and
          acknowledge that Our decision with respect to awarding the Winnings
          as per the Terms will be final and binding on You. PAYMENT TERMS The
          below terms govern all payment-related transactions made by You, and
          You agree to abide by them. PAYMENT ACCOUNTS By accessing and using
          Our Platform, You are provided with the following categories of
          accounts for processing and reconciling Your payments: Unutilized
          Account - This account holds any amount remitted by You through a
          designated payment gateway for availing Our Services; and Winning
          Account - This account holds your winnings in any Paid Contests.
          DISCOUNT OFFERED In order to conduct promotional activities and
          provide incentives, We may issue discounts to You which may be
          utilised for joining any paid Public Contest(s) (“Discount(s)”). You
          agree to the following You shall not be permitted to transfer or
          withdraw any amount of Discounts offered to any other account
          including Your bank account. The utilisation of any Discount shall
          be subject to certain terms which will be notified to You at the
          time of issuance of any Discount. Discount shall be issued at our
          sole discretion and will not be demanded as a matter of right.
          Discount will be applied towards the Pre-designated Amount for
          joining any Contest. Upon termination of Your Account, all Discounts
          shall be forfeited and You shall not have any right or interest on
          such Discount. Any Discount issued to you may take up to 24 hours to
          reflect in their respective Discount account as defined below. You
          agree not to hold Us responsible or liable for any delay, including
          any loss of opportunity to join any Contest(s) due to delay in
          crediting the Discount. In the event of any conflict between these
          Terms and the terms specific to the Discount, if any, the Discount
          terms shall prevail. We offer the following Discounts Discount Point
          We may award discount points at the time of a successful deposit in
          your Unutilised Account (“Discount Point”). Your utilisation of the
          Discount Points shall be subject to the following terms and
          conditions: Discount Points shall be valid for a period of three
          (03) months from the date of issuance Discount Points shall be
          credited to your Discount Point account (“Discount Point Account”).
          While participating in any Paid Contest, a certain percentage of
          available Discount Points in Your Discount Point Account shall be
          applied to the Pre Designated Amount. Deduction of Discount Points
          from the Pre-Designated Amount is in addition to any other Discounts
          available to You. You agree that in the event of any request for
          refund of the amount deposited in the Unutilised Account, any
          Discount Points awarded to you in connection with such amount
          deposited shall stand forfeited and you will not be able to utilise
          such Discount Points. If a match is abandoned or cancelled, the
          applicable Discount Points deducted from the Pre Designated Amount
          shall be returned to Your Discount Point Account. Discount Bonus We
          may at our discretion as part of our Promotion issue a Discount
          bonus to You in accordance with the terms and conditions applicable
          to such Promotions. (“Discount Bonus”). In addition to the terms
          relating to the Promotion, You agree to the following: Discount
          Bonus shall be credited to your Discount Bonus account upon
          fulfilling the applicable conditions under the respective Promotions
          (“Discount Bonus Account”). Discount Bonus will be automatically
          deducted from the Pre- Designated Amount payable by You. Discount
          Bonuses shall be valid for a period of 14 days from the date of the
          credit Discount Coupons. We may grant Discount coupons at Our sole
          discretion and each Discount coupon shall be governed by the
          specific terms and conditions as notified by Us at the time of grant
          of such Discount coupons. You acknowledge that Discount coupons
          shall have their own validity and specific terms and conditions
          available on the Platform. In case of any conflict between these
          Terms and the terms specific to the Discount coupon, the terms
          specific to the Discount coupon shall prevail. PRE - DESIGNATED
          AMOUNT PAYMENT When You join any Contest, subject to the deduction
          of Discount Bonus and Discount Points (if any), the Pre-Designated
          Amount gets debited first from the Unutilised Account, and then from
          Your Winnings Account. For the Unutilized Account, debits will be
          made in order of the date when the funds were first credited. If You
          do not have enough balance in either of your Accounts to participate
          in any Paid Contest, You will need to remit the Pre-Designated
          Amount required to join the Contest(s). You agree that any amount
          deposited shall be subject to deduction of applicable GST in
          accordance with these Terms. WITHDRAWALS PROCESSING You will be
          prompted to provide certain documents for processing your payments
          and verification purposes at the time of your first withdrawal. The
          name mentioned on the identification document as provided by You at
          the time of withdrawal should correspond with the name provided by
          You at the time of Account creation. We will process your withdrawal
          after verifying your bank account details and/ or UPI ID (as
          applicable). Upon verification, we will debit the amount from your
          Winning Account and transfer it online to your bank account at the
          earliest. We may charge any processing fee for the online transfer
          of funds from your Winning Account to Your bank account We depend on
          banks and third parties to process transactions on Our Platform.
          Thus, we may take up to 24 hours to process any payments to Your
          bank account. You agree not to hold Us responsible or liable for any
          delay in processing any payments. A transaction, once confirmed, is
          final, and no cancellation is permissible. TAXES PAYABLE From 01
          October 2023, 28% GST is applicable on the amount deposited by You
          in Your Unutilised Account. You can view the GST paid on each
          deposit made by You in the Unutilised Account on the ‘My
          Transaction’ page on the Platform. The GST value listed on the
          invoice shall be the final GST amount charged to You All winnings
          shall be subject to deduction of tax (“TDS”) as per the Income Tax
          Act 1961. With effect from 1st April 2023, TDS of 30% shall be
          deducted from Net Winnings (“NW”) on each withdrawal request placed
          by You. For the purpose of calculating NW: calculation of NW for the
          first withdrawal during the Financial Year (“FY”): NW = A - (B+C) A
          = Aggregate amount withdrawn from the Winning Account; B = Aggregate
          amount of non-taxable deposit made in the Unutilised Account till
          the time of withdrawal; C = balance amount in the Unutilised Account
          and the Winning Account at the beginning of the FY (“Opening
          Balance”); NW shall be Zero if the sum of B & C is equal to or
          greater than A calculation of NW during the FY in each subsequent
          withdrawal from the Winning Account NW =A-(B+C+E) A = Aggregate
          amount withdrawn from the Winning Account till the time of such
          withdrawal including the amount of such subsequent withdrawal; B =
          Aggregate amount of non-taxable deposit made in the Unutilised
          Account till the time of such subsequent withdrawal; C = balance
          amount in the Unutilised Account and the Winning Account at the
          beginning of the FY (“Opening Balance”); E = NW comprised in earlier
          withdrawal or withdrawals during the FY until this subsequent
          withdrawal if tax has been deduced on winnings comprised in such
          withdrawal or withdrawals NW shall be Zero if the sum of B, C & E is
          equal to or greater than A calculation of NW at the end of the FY NW
          =(A+D)-(B+C+E) A = Aggregate amount withdrawn from the Winning
          Account during the FY; B = Aggregate amount of non-taxable deposit
          made in the Unutilised Account during the FY; C = balance amount in
          the Unutilised Account and the Winning Account at the beginning of
          the FY (“Opening Balance”); D = balance amount in the Unutilised
          Account and Winning Account at the end of the FY (“Closing Balance”)
          and E = Net Winnings comprised in earlier withdrawal or withdrawals
          during the FY until this subsequent withdrawal if tax has been
          deduced on winnings comprised in such withdrawal or withdrawals; NW
          shall be Zero if the sum of B, C & E is equal to or greater than sum
          of A & D In the event, You do not withdraw any of Your winnings
          during a financial year and You have Net Winning balance as of 31
          March of each year, then TDS of 30% shall be deducted on such Net
          Winnings balance from Your Winning Account as on 31 March as
          calculated above. In case of any revisions by the Government of
          India to the aforementioned rate or the definition of Net Winnings
          in the future, TDS will be deducted by Us in accordance with the
          then-current prescribed TDS rate and the revised definition of Net
          Winnings. Winners will be provided TDS certificates in respect of
          such tax deductions. The Winners shall be responsible for payment of
          any other applicable taxes, including but not limited to, income
          tax, gift tax, etc. in respect of the Net Winnings. We shall not in
          any manner be responsible for users' individual tax matters. REFUNDS
          We will refund any amount left in your Unutilised Account if your
          Account gets suspended or removed (a) due to Our failure to provide
          Services, (b) any instruction received from any government or
          regulatory authority We will refund the Pre-Designated Amount paid
          by You in case any Contest is abandoned in accordance with these
          Terms. We shall deactivate Your Account pursuant to any direction
          issued by an appropriate government agency and/or competent
          authority. If We receive requisite instructions from such authority,
          We shall refund the deposited amount from Your Unutilised Account to
          the source account, subject to applicable processing fees. In the
          event Our Services are not available due to reasons outside Our
          control, including but not limited to any failure to perform due to
          unforeseen circumstances or cause beyond Our control, such as acts
          of god, war, terrorism, riots, embargoes, acts of civil or military
          authorities, fire, floods, accidents, pandemic, epidemic, network
          infrastructure failures, strikes, or shortages of transportation
          facilities, fuel, energy, labor or materials or any cancellation of
          services available on Our Platform (each a “Force Majeure Event”),
          then We reserve the right to cancel any Contest and process refund
          of the Pre - Designated Amount. In the event of a Paid Contest
          involving up to 4 Users, where all the Users have entered the same
          teams (including the same Captain, Vice-captain and Back Up
          replacement), the Pre-Designated Amount shall be refunded to all the
          users after the match starts. The refund shall be credited to the
          account from which the Pre-designated Amount was utilized. For a
          Paid Contest involving 5 or more Users, where all the Users have
          entered the same teams (including the same Captain, Vice-captain and
          Back Up replacement), the Prize Money Pool shall be equally divided
          between the Users. If there is any request for withdrawal of amount
          deposited in Unutilised Account, such refund request shall be
          processed net of GST i.e. applicable 28% GST paid on the amount
          deposited in Unutilised Account. GST amount shall not be refunded as
          per applicable government laws. USER FUNDS You hereby authorise Us
          to appoint an independent third-party/ trustee/ escrow agent to act
          on your behalf in a fiduciary capacity (“Third Party”) with respect
          to holding any amount belonging to You and undertaking actions,
          consents, approvals and any other requisite instructions necessary
          for such amount. You acknowledge that We do not hold any right,
          title and/or interest in the amount deposited by You. the amount
          deposited by You will be used only for participation in the Paid
          Contest. any amount deposited by You, shall not be forfeited by Us
          except in case where We receive an instruction from any government
          agency or competent authority. You further authorize Us to instruct
          the Third Party to open a non-interest earning bank account(s) which
          will be used for the following payouts: withdrawal of Your winnings;
          payment of Platform Fee; payment of taxes as applicable towards TDS
          on Net Winnings; and Government (toward GST payable) YOUR CONDUCT In
          using and accessing Our Platform, You agree to observe the following
          code of conduct: ACCOUNT INFORMATION You are permitted to create
          only one Account on Our Platform. When creating Your Account with Us
          (or updating Your Account information), You agree that You will
          provide true, accurate and updated information and documentation.
          You will not provide any false and/or incorrect information and
          documentation nor impersonate or attempt to impersonate and/or
          otherwise assume the identity of another person without due
          authority. You will not commit any such fraudulent act which amounts
          to forgery and/or involves the fabrication of documentation. You
          agree and acknowledge that the address-related details provided by
          You shall be treated as Your “Address on record” for the purposes of
          the Goods and Service Tax Act. You agree that You shall promptly
          update your Account related details on Our Platform in the event of
          any change in such details and/or write to Our Help and Support in
          the event of any; a) change in Your Account related details and/or;
          b) any unauthorised use of Your Account to enable us to take
          remedial action. MAINTAIN CONFIDENTIALITY You will maintain the
          confidentiality of all information relating to Your Account, and You
          will not share Your One Time Password (“OTP”) with any other person;
          You will not engage in any fraudulent conduct in logging into
          another user’s account by asking for their account-related
          information or taking their OTP. COMPLIANCE WITH APPLICABLE LAW You
          agree to comply with Applicable Laws. You agree that You shall not
          commit any illegal act that disrupts Our systems. VIOLATION OF
          PLATFORM TERMS You shall not engage in any Fair Play Violation. You
          shall not violate any of the Terms in using and accessing Our
          Platform and Services; You shall not misuse any Discount and/or any
          other offers or promotions given by Us. You agree that you shall use
          the Platform and Our Services solely in Your personal capacity, and
          You will not engage in malpractices or collude with other Users in
          deriving any benefit and/or running any business in connection with
          the use of Our Platform and/or Services. You shall not be engaged in
          any form of insider trading, i.e. illegally sharing and seeking
          non-public information, knowledge of participating teams in any
          given contests/match, strategies, organizing boards, leagues etc.
          which may give You an unfair competitive advantage. (“Insider
          Trading”) You shall not tamper, modify, or otherwise deal with our
          data, content, software, technology and/or Intellectual Property by
          any means. RESPONSIBLE PLAY We're committed to fostering responsible
          gaming for all our users. You agree to participate in the Contests
          responsibly by making informed decisions and playing within Your
          means. You are encouraged to seek help if needed. ADDITIONAL
          FEATURES We offer Our Users additional features on our platform Chat
          Feature: is a tool that allows You to communicate with each other in
          real-time on the Platform either in a public or private setting
          (“Chat Feature”). You agree to observe the following code of
          conduct: You shall not engage in illegal, obscene, abusive,
          offensive, racially insensitive, communal, objectionable,
          defamatory, or otherwise inappropriate and immoral conduct You shall
          not harass, bully, stalk, threaten, or otherwise violate any rights
          of other Users; You shall be responsible for all content published,
          contributed, and made available by You on Our Platform. This
          includes any content You share with Us (“User Content/Your
          Content”). Your Content shall not be immoral, profane, defamatory,
          or offensive; You shall not use Our Platform for advertising,
          offering or selling any goods or services for commercial gain except
          with Our prior written consent; You shall not restrict or inhibit
          any other User from using Our Platform; You shall not publish any
          content on Our Platform which is patently false, with the intent to
          mislead or harass any person or third party, whether for financial
          gain or to cause any injury to any person or otherwise; You shall
          not collude with any other User(s) or engage in syndicate play; You
          agree that such behaviour qualifies as User misconduct. You shall
          not spam other User(s) by sending any unsolicited communications;
          You will not collect, store, or obtain any information about other
          Users without their prior written consent; You shall not engage in
          any action that threatens the unity, integrity, defence, security or
          sovereignty of India, friendly relations with foreign States, or
          public order, or causes incitement to the commission of any
          cognisable offence or prevents investigation of any offence or is
          insulting other nations. You agree to adhere to the Community
          Guidelines. DreamCoin - By participating on our Platform and
          engaging in specific actions such as participating in a Contest, You
          will be awarded DreamCoins for Your participation subject to Your
          compliance with the terms and conditions provided here. OUR RIGHTS
          AND OBLIGATION YOUR CONTENT We reserve our rights to remove (without
          notice) any offending/illegal/objectionable/ infringing content
          posted by You and take such necessary action as We may consider
          appropriate concerning Your Content; CONTEST We may choose to:
          Revise the roster of players available for selection in relation to
          any Contest/Sports offered on Our Platform based on the official
          information We may receive from the sports organiser or any
          third-party sources. Withdraw and/or cancel any Contest without
          prior notice to You should the carrying on of such Contest be
          prohibited and/or require any governmental, statutory or regulatory
          compliances or approvals; Adjust the Contest Deadline or Abandon any
          Contest. We reserve the right to abandon a Contest upon the
          occurrence of circumstances beyond Our reasonable control, including
          circumstances specified below: If the Sports (other than Cricket
          matches) is rescheduled to take place before the Contest Deadline
          and whose start time is more than 10 mins of the Contest Deadline.
          if the Contest Deadline for cricket matches is extended by more than
          10 minutes, or 3(three) overs have been bowled (whichever is less),
          before the official start time of the cricket event; If the Sports
          is rescheduled to take place after the Contest Deadline for any
          reason, including interruptions and delays caused due to weather,
          toss delays, non-availability and non-appearance of Teams, technical
          glitches etc and the start time of the relevant Sports cannot be
          verified by Us through publicly available sources. We reserve Our
          Right to adjust the Contest Deadline upon the occurrence of
          circumstances beyond Our reasonable control, including the following
          circumstances: For Sports other than cricket - If the Sport is
          rescheduled to take place before the Contest Deadline, We reserve
          Our Right to adjust the Contest Deadline to a maximum of 10 minutes
          post actual start time of the relevant Sports; Suppose the cricket
          event is rescheduled to take place before the Contest Deadline. In
          that case, We reserve Our Right to adjust the Contest Deadline by a
          maximum of 10 minutes, or 3(three) overs bowled (whichever is less),
          before the official start time of the cricket event; If the cricket
          event is rescheduled to take place before the Contest Deadline and
          the official start time of the cricket event cannot be determined
          and verified by Us through publicly available sources, We may adjust
          the Contest Deadline to such a time by which a maximum of 3 overs in
          a given match are bowled; or If the Sport is rescheduled to take
          place after the Contest Deadline for any reason including,
          interruptions and delays caused due to weather, toss delays,
          technical glitches etc., based on reliable and/or publicly available
          sources, We may extend the Contest Deadline to a time which is
          synonymous with the actual start time of the Sport. OUR SERVICES We
          may choose to Change, discontinue or suspend all or any part of Our
          Service; Suspend or terminate Your Account as directed by an
          appropriate government agency and/or competent authority. BREACH OF
          TERMS In case of breach of these Terms or if We reasonably believe
          that You have breached these Terms, We may choose to: Restrict,
          suspend, deactivate or permanently delete Your account with Us
          (including all related information that You might have provided to
          Us) Disqualify You from participating in a Contest; refrain from
          awarding any prizes to You or forfeit Your winnings initiate
          appropriate civil/criminal proceedings as We may consider
          appropriate in addition to forfeiture and/or recovery of prize
          money. INTELLECTUAL PROPERTY RIGHTS You agree that We (including Our
          partners, licensors and/or associates) own all intellectual property
          rights in and to the software underlying the creation of Our
          Platform, including all material and content published by Us on Our
          Platform, which includes (but is not limited) to the following: Our
          Contests Our software/technology Our advertisements Our
          written/published content Our images, graphics, photographs,
          illustrations Our marks, logos, trade name Audio and visual
          clippings used and published by Us Flash animation (“Our
          Intellectual Property Rights/Our Content”) You agree that in
          accessing Our Intellectual Property Rights, You shall not do the
          following without Our prior written consent: Modify, distribute,
          publish, transmit, reproduce, or otherwise deal with Our
          Intellectual Property Rights; or Create derivative works of Our
          Intellectual Property Rights or in any way exploit Our Intellectual
          Property Rights. You agree that: You own all the intellectual
          property rights in Your content; Your content will not result in any
          infringement claims from any third party; and We are not liable for
          any of Your content shared and/or contributed by You on Our
          Platform. You grant us a worldwide, non-exclusive, royalty-free,
          sublicensable, irrevocable, and transferable license to use Your
          content for the following: use, reproduce, distribute, publish, and
          transmit Your content; and otherwise, create any derivative works of
          the User Content for the following purposes: display of User Content
          on Our Platform; distribution and/or storage of User Content (either
          electronically or via other media) for any purpose; and storing
          Users' Content in a remote database accessible by Users, for a
          charge PRIVACY POLICY All Your personal information collected
          (including financial data) is subject to Our Privacy Policy which is
          available at Privacy Policy. You are encouraged to review this
          Privacy Policy to understand the data we collect and the purpose for
          which such data is collected. GRIEVANCE REDRESSAL MECHANISM In case
          you have any complaints or grievance pertaining to (i) any User
          Content that You believe violates these Terms including any
          infringement of Intellectual Property Rights, (ii) Your access to
          the Platform, please share the same with Us by writing to:
          grievanceofficer@dream11.com. On receiving such a complaint,
          grievance or notice, the grievance officer may reach out to You to
          seek further clarification, or to verify Your complaint. The name
          and title of the Grievance Redressal Officer are as follows: Name:
          Gaurav Bajaj Email: grievanceofficer@dream11.com Address: Sporta
          Technologies Private Limited, Unit 1201 - 1202, OneBKC, Tower A,
          Bandra Kurla Complex, Bandra East, Mumbai - 400051, Maharashtra,
          India The Grievance Officer identified above is pursuant to the
          provisions of Applicable Laws including but not limited to the
          Information Technology Act, 2000 and the Consumer Protection Act,
          2019, and the Rules enacted under those laws. The Company reserves
          the right to replace the Grievance Redressal Officer at its
          discretion through the publication of the name and title of such
          replacement on the website, which replacement shall come into effect
          immediately upon publication. You agree that regardless of any
          statute or law to the contrary, any complaints or grievances arising
          out of or related to the use of Our Platform or these Terms should
          be filed within thirty (30) days of such claim to enable us to
          resolve Your complaint at the earliest. LEGALITY Fantasy Sports
          offered by Us on our platform is a game of skill and a legally and
          constitutionally protected business not amounting to betting,
          gambling and wagering held by the Supreme Court of India and
          different High Courts of India in multiple judgments. These Contests
          require You to use Your knowledge, judgement and expertise to
          primarily determine Your success in such Contests rather than
          relying on chance. PUBLICITY By accepting a prize, You grant Us and
          Our affiliates an irrevocable, royalty-free, worldwide, perpetual
          license to use Your name, likeness, image, voice and comments/
          testimonials for any purpose, including but not limited to
          advertising and promotional purposes in any media. We may organize
          promotional activities for which We may require Your presence. We
          will communicate the details of these activities to You. These
          promotional activities may include press events, internal meetings
          and ceremonies/functions. LIMITATION OF LIABILITY AND
          INDEMNIFICATION By accessing Our Platform, You hereby agree to
          indemnify Us and/ or any of Our directors, employees, partners,
          affiliates, associates and licensors (“Representatives”) against all
          liability, cost, loss, claims or expense arising out of Your access
          to Our Platform, arising due to any negligence on Your part and/ or
          any illegal act on Our Platform and/ or non-compliance with these
          Terms. Neither We nor Our partners, affiliates, licensors or
          associates shall be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of Your use of or
          inability to use Our Services, even if We have been advised of the
          possibility of such damages. You, with this, waive each and every
          right You may have against Us and/ or Our Representatives for any
          injuries, accidents, or other events that may occur as a result of
          You accessing Our Platform, participating in Contests, or receiving
          Contest prizes. This waiver includes any known or unknown,
          anticipated or unanticipated events. GOVERNING LAW AND DISPUTE
          RESOLUTION The courts of Mumbai shall have exclusive jurisdiction to
          determine any and all disputes related to Our Services [(including
          the Contest(s)] (“Dispute”) and grant interim relief in any Dispute
          referred to arbitration as given below. Any Dispute will be governed
          by the laws of the Republic of India. If there is a legal Dispute,
          the party raising the Dispute must send a written notification
          (“Notification”) to the other party. The parties will then try to
          resolve the Dispute through amicable discussions. Suppose the
          dispute remains unresolved within fifteen (15) days of receiving the
          Notification, in that case, the dispute will be settled through
          arbitration. We will appoint a sole arbitrator to conduct the
          arbitration proceedings in English as per the provisions of the
          Arbitration and Conciliation Act, 1996, as amended from time to
          time. The seat and venue of arbitration shall be Mumbai. The
          arbitration award will be final and binding on the parties. Each
          party will pay for the costs of arbitration and equally share the
          arbitrator's fees unless the arbitral tribunal decides otherwise.
          The arbitrator can pass interim orders and awards, including orders
          for specific performance, and such orders would be enforceable in
          competent courts. The arbitrator shall give a reasoned award.
          Nothing contained in these Terms shall restrict Us from seeking and
          obtaining interim or permanent equitable or injunctive relief or any
          other relief available to safeguard Our interest prior to, during or
          following the filing of arbitration proceedings or pending the
          execution of a decision or award in connection with any arbitration
          proceedings from any court having jurisdiction to grant the same.
          The pursuit of equitable or injunctive relief shall not constitute a
          waiver on Our part to pursue any remedy for monetary damages through
          the arbitration described herein. OTHER TERMS These Terms govern
          Your use of Our Platform and Services and supersede any prior
          agreements that You may have with Us. Our failure to exercise or
          enforce any right or provision of these Terms shall not constitute a
          waiver of such right or provision. In the event any of the
          provisions of these Terms are held to be invalid, illegal or
          unenforceable by any competent authority for any reason, the
          remaining portions of these Terms, if capable of substantial
          performance, will remain in full force and effect. Our employees are
          not eligible to participate in any paid Public Contest, which is a
          Public Contest. You acknowledge that You may be subject to
          additional terms that govern your transactions on Our Platform. You
          agree that if there is any conflict between these terms and the
          additional terms, the additional terms will prevail. We may assign
          our rights and liabilities in relation to the Services offered by Us
          to any entity as we may deem fit in course of our business activity.
          DISCLAIMERS We will not be liable for any delay or failure to render
          the Services resulting from any Force Majeure Event. You may access
          Our Platform at Your own risk. We are not responsible or liable for
          any loss or damage (including injuries, death, or loss of property)
          that You or anyone else may suffer while accessing Our Platform. You
          agree that Our Platform may contain third-party advertisements and
          links to websites/services owned and operated by Our affiliates and/
          or third parties (“Third Party Content”). We do not endorse the
          Third Party Content or services of any third parties included on Our
          platform. We cannot guarantee the accuracy, reliability or quality
          of such Third Party Content. Your reliance on Third Party Content is
          at Your own risk. Any claims that You have regarding Third-Party
          Content should be directed to the third party in question. We will
          not be held responsible for any issues with the storage, deletion,
          delivery, or accessibility of information or materials to the extent
          allowed by law. We do not provide any warranty for Our Content,
          which is provided on an "as is, as available basis”. We are not
          responsible for any errors or inaccuracies in the team starting
          line-up data provided on Our Platform, as it is obtained from
          external sources or publicly available information. We are not
          responsible for any inaccuracies in calculating fantasy points or
          the selection of winners due to inaccurate or incomplete information
          received from any third-party service provider. We make no
          representations or warranties regarding the quality, suitability or
          merchantability of any prizes and are not liable with respect to the
          same. We will not be liable for your inability to access Our
          Platform for any reason beyond our control such as network outage,
          telecommunication failure, internet suspension,virus, malware etc.
          We are not responsible for any errors in communication, or omissions
          in communication, including any errors made by You, which may
          prevent the results from being communicated to You. We are not
          liable for any consequences arising from You sharing any
          confidential information including but not limited to OTP with any
          other person.
          {/* 







Standard Terms and Conditions of Promotions These standard terms and
          conditions of promotions (“Standard Terms”) supplement the terms of
          promotions undertaken on the Dream11 website and which reference
          these Standard Terms (each a “Promotion”): Participation in any
          Promotion will be subject to a user complying with the Promotion
          Terms implemented by Dream11 in respect of such Promotion
          (“Promotion Terms”) and these Standard Terms. By participating in
          any Promotion, the participant further consents to and agrees to
          adhere with the terms and conditions of the Dream11 game and
          Dream11’s privacy policy. The Promotions are only open to users in
          India. Participation in the Promotions by proxy is not permitted.
          Participation in the Promotions is voluntary. Participation in one
          Promotion does not guarantee that such user will be eligible to
          participate in another Promotion. A user may participate in a
          Promotion and avail of each Promotion only through one account. An
          existing user of Dream11 shall not register a new account or operate
          more than one user account with Dream11 or participate in a
          Promotion by registering a new account. Users intending to
          participate in a Promotion may be required to verify their mobile
          number and other account details in accordance with the Promotion
          Terms for such Promotion. Persons intending to participate in a
          Promotion, who have listed their phone numbers on the National Do
          Not Call Registry (“NDNC Registry”), shall de-register themselves
          from the NDNC Registry till the completion of such Promotion
          (including the delivery of Bonus Amount (if any) or the free-entry
          (if any) under such Promotion). Such persons agree not to make any
          claim or raise any complaint whatsoever against Dream11 in this
          respect. Please note that persons intending to participate in a
          Promotion who have not de-registered themselves from the NDNC
          Registry shall also have no right to make any claim or raise any
          complaints against Dream11 if they do or do not receive any call or
          SMS with respect to their participation and all other matters
          pertaining to a Promotion. The verification process may require you
          to submit personal information about yourself. You agree to receive
          communication from Dream11. Any information collected in respect of
          your identity and contact details as part of a Promotion or
          otherwise in the course of your use of the Dream11 Website shall be
          subject to Dream11’s Privacy Policy Dream11 may, at its sole and
          absolute discretion, disqualify any user from a Promotion if such
          user engages in or it is found that such user has engaged in any
          illegal, unlawful or improper conduct (with regard to any of the
          Promotions or otherwise). The Bonus Amount (if any) deposited into
          the user’s account can be used to join cash contests and contests on
          Dream11. However, the Bonus Amount (if any) cannot be: (i) used to
          join 2-member contests; or (ii) withdrawn or transferred to any
          other cash balance account held by you with Dream11 or to any third
          party account or to any bank/payment instrument account. THE BONUS
          AMOUNT (IF ANY) SHALL EXPIRE AND BE WITHOUT EFFECT AT THE END OF
          FOURTEEN DAYS FROM THE DATE OF CREDIT OF THE BONUS AMOUNT (IF ANY).
          The deposit of the Bonus Amount (if applicable) or the grant of the
          free-entry (if any) shall be at the sole discretion of Dream11 and
          shall be subject to the user’s compliance with these Standard Terms
          and the applicable Promotion Terms. Dream11 may substitute or change
          the Bonus Amount(if any) or free-entry (if any) offered under a
          Promotion at any time without notice. Users may not substitute Bonus
          Amount (if any) or free-entry (if any) for other items or exchange
          for cash. Dream11 reserves the right to withhold or forfeit the
          benefits of a Promotion (including a free-entry or Bonus Amount due
          to a participant or any prizes/winnings earned by the participant by
          using such benefits) in the event that it determines or reasonably
          believes that the participating user has violated these Standard
          Terms, the applicable Promotion Terms or the terms and conditions of
          the Dream11 fantasy game(s). Mere participation in a Promotion does
          not entitle the participant to receive any free-entry or Bonus
          Amount(s) indicated as a prize under such Promotion The decision of
          Dream11 will be final and binding with respect to the Promotions,
          and the prizes therein and no correspondence, objection, complaints,
          etc. will be entertained in this regard. Each Promotion cannot be
          clubbed with any of other contest/offer/promotion that are running
          simultaneously and organised or conducted by Dream11. Dream11
          reserves the right to change/modify/or withdraw any of the
          Promotions and/or change these Standard Terms and/or the Promotion
          Terms without any prior notice of the same at its sole discretion.
          Dream11 does not make any commitment, express or implied, to respond
          to any feedback, suggestion and, or, queries of the participants of
          the Promotions. Notwithstanding anything contained herein, the
          aggregate liability of Dream11 to a participating user in relation
          to any Promotion for any reason whatsoever shall not exceed Rs. 50.
          The Promotions shall be governed by the laws of the Republic of
          India, and any disputes or disagreements in respect of this
          Promotion shall be subject to the exclusive jurisdiction of the
          courts of Mumbai. Standard Terms and Conditions of "Invite Friends"
          program. The Dream11 Invite Friends Program lets you invite friends
          (“Inviter”) to the Dream11 app. In the event that you and your
          invited friend (“Invitee”) meet the criteria and complete all the
          steps specified in these terms, you will earn benefits as
          communicated to you on the app via our new 'Refer & Earn' program
          from 05:00:00 hours on 1 March, 2024 till IST 23:59:59 hours on 31
          December, 2024, and your friend will receive 90% discount ( “Invitee
          Discount”). Please note that you will not get any rewards for your
          friend's actions that were invited before our new rewards program
          implemented from 1 March, 2024. To participate in the Program,
          please note our terms and conditions ("Terms") in this respect, as
          they govern your participation. Eligibility – All users who: (i)
          have an account registered on the "Platform"; and (ii) are eligible
          to participate in the pay-to play game (as per the Dream11's terms
          and conditions, accessible here ). Participation in the program is
          voluntary. A user shall not register or operate more than one user
          account with Dream11 and shall not participate in the Program with
          more than one user account with Dream11. The Program will be open
          for participation from Indian Standard Time 05:00:00 hours on 1
          March, 2024 till IST 23:59:59 hours on 31 December, 2024 By
          participating in the Program, you agree to and accept these Terms
          Rewards will be received by the Inviter only when the Invitee has
          joined a paid public contest. Simple registration or depositing of
          cash on Dream11 by the Invitee will not result in any rewards for
          the Inviter For the purpose of participation in the Program, you
          will be provided with a unique link or code, which can be shared by
          you with friends for the purpose of inviting such friends to and
          create an account on the Dream11 Application. On receiving the link
          or code from the Inviter, the Invitee may either: (i) Click on the
          link, consequent to which such Invitee will be directed to a
          registration page and will be provided the option to download and
          install the Dream11 Application on his/her device; and , register
          for a Dream11 account via which he/she can enter the unique code
          shared by the Inviter where prompted in the Dream11 Application The
          Invitee has to join a paid public contest before 31 December, 2024
          for the Inviter to receive the benefits of the Invite Friends
          program. The Inviter and the Invitee will be eligible to earn the
          benefits (as communicated on the app) subject to (amongst other
          terms specified in these Terms): (i) the Invitee not being an
          existing user of Dream11; and (ii) the Inviter and Invitee being
          eligible to participate in the pay-to play contests on Dream11; and
          (iii) the Invitee successfully registering for an account with
          Dream11 through the unique link or by using the unique code shared
          by the Inviter; and (iv) the Inviter and Invitee downloading and
          installing the Dream11 Application as available for the Inviter’s
          and Invitee’s respective mobile devices. For the purposes of these
          Terms, an 'existing user of Dream11' shall mean a user who presently
          operates an account with Dream11 or operated an account with Dream11
          at any point of time. An Invitee who is an existing user of Dream11
          is not permitted to register a new account for the purpose of
          availing of the benefit as communicated on the app. Dream11 will
          determine in its sole discretion whether an Invitee is an existing
          user of Dream11 or not and take appropriate action. The invited
          friend has to be at least 18 years old and not from one of the
          following states: Andhra Pradesh, Telangana, Sikkim, Odisha,
          Nagaland and Assam. The verification process may require an
          Inviter/Invitee to submit personal information and documents
          identifying the Inviter/Invitee. The Inviter agrees to receive
          communication from Dream11 and to allow Dream11 to communicate with
          friends invited by you about the Inviter's participation in the
          Program. Any information collected in respect of the Inviter/Invitee
          as part of the Program or otherwise in the course of such person's
          use of the Platform shall be subject to Dream11's Privacy Policy
          (available here for Indian users) The verification of an
          Inviter/Invitee shall be completed at the time of first paid/cash
          contest join by the Inviter/Invitee or at the first time of adding
          cash to the Unutilised Account on the platform. The Invitee will be
          eligible to receive a one-time 90% discount up to a maximum of Rs.
          55 (“Invitee Discount”). This Invitee Discount of 90% shall be valid
          for 21 days from the date of registration and will be valid on paid
          public contests above 1000 members before IST 23:59:59 hours on 31
          December, 2024. Inviter can receive DreamCoins which shall be
          communicated to the Inviter via the in-app communications:
          DreamCoins credited for the Inviter will expire after 180 days In
          addition to the above, terms and conditions (if any) as applicable
          on each of the rewards shall be communicated to the Inviter via the
          in-app communications. Inviter Reward a) The credit of the Inviter
          rewards is contingent on the Invitee joining a paid public contest
          before IST 23:59:59 hours on 31 December, 2024. Subject to the
          provisions of above, the applicable Inviter rewards to the Inviter
          shall be credited to the Inviter's account within fifteen (15) days
          of the completion paid public contest joined by the Invitee. The
          deposit of the rewards shall be subject to the Inviter’s/Invitee’s
          compliance with these Terms. Dream11 reserves the right to: withhold
          the deposit of the rewards; and/or forfeit any deposited rewards to
          an Inviter/Invitee or any prizes/winnings earned by the participant
          by using such rewards; and/or deactivate the accounts of the
          Inviter/Invitee, in the event that it determines or reasonably
          believes that such Inviter/Invitee has violated these Terms or the
          terms and conditions of the Dream11 fantasy games. Dream11 may, at
          its sole and absolute discretion, disqualify any Inviter/Invitee if
          such Inviter/Invitee engages in or it is found that such
          Inviter/Invitee has engaged in any illegal, unlawful or improper
          conduct (with regard to the Program or otherwise). The decision of
          Dream11 will be final and binding with regard to the Program, and
          the deposit of the Rewards and the Invitee Discount and no
          correspondence, objection, complaints, etc. will be entertained in
          this regard. This Program cannot be clubbed with any other
          promotions/programs that are running simultaneously by Dream11. The
          Terms and Conditions, as applicable to the Dream11's fantasy games
          and services, will apply to and govern the Program. Dream11 does not
          make any commitment, express or implied, to respond to any feedback,
          suggestion and, or, queries of the participants (Inviter/Invitee) of
          the Program. Notwithstanding anything contained herein, the
          aggregate liability of Dream11 to a participating Inviter/Invitee in
          relation to the Program for any reason whatsoever shall not exceed
          ₹100. Dream11 January Deposit Bonus Offer Terms and Conditions This
          "Dream11 January Deposit Bonus Offer" ("Promotion") is open to
          select users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          ("Bonus Amount"), the value of which will be dependent on the value
          of the Qualifying Deposit credited into their Dream11 Deposit
          Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 10:00:00 hours on
          January 1st, 2023 till IST 12:00:00 hours on January 1st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amounts specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          account during the Promotion Period to be eligible to receive the
          Bonus Amount corresponding to the Qualifying Deposit, in accordance
          with the table below: Qualifying Deposit (INR) Bonus Amount (INR)
          149 60 Please note that only the Qualifying Deposit made by a user
          during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          January 2nd, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 60.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit of Rs.149
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          deposit amount. Dream11 January Deposit Bonus Offer Terms and
          Conditions This "Dream11 January Deposit Bonus Offer" ("Promotion")
          is open to select users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          ("Bonus Amount"), the value of which will be dependent on the value
          of the Qualifying Deposit credited into their Dream11 Deposit
          Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          January 1st, 2023 till IST 13:00:00 hours on January 1st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amounts specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          account during the Promotion Period to be eligible to receive the
          Bonus Amount corresponding to the Qualifying Deposit, in accordance
          with the table below: Qualifying Deposit (INR) Bonus Amount (INR)
          5555 2023 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          January 2nd, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 2023.
          Please note that this Promotion is only available to the first
          20,000 (Twenty Thousand) users who make the Qualifying Deposit of
          Rs.5555 during the Promotion Period. For these Terms, the first
          20,000 (Twenty Thousand) users shall be calculated based on the time
          of Qualifying Deposit during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the deposit amount. Team Clone Campaign The Team Clone
          Campaign (“Promotion”) is open to select users of Dream11 who
          receive the banner and push notification communication on the
          Dream11 mobile application. Under this Promotion, users who share
          their team using the team share feature on the Dream11 app, will
          receive 10 DreamCoins and shall receive 20 DreamCoins every time a
          team shared by the user is used in a paid public contest. Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Participation is only
          available to select registered users of Dream11 who receive the
          banner and push communication on the Dream11 mobile application and
          are eligible to participate in the pay to play contests as per
          Dream11's terms and conditions (accessible here) By participating in
          this Promotion, users agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here) DreamCoins Details: The Promotion will be
          open for participation from IST 12:00:00 hours on January 10th, 2023
          till IST 23:59:00 hours on January 17th, 2023 ("Promotion Period").
          Users receive 10 DreamCoins for sharing a team using the share team
          feature. Users shall receive a maximum of 30 DreamCoins per day for
          just sharing a team. Users receive 20 DreamCoins for every time
          their shared team is used by another user to join a paid public
          contest. Users can earn a maximum of 60 DreamCoins per day when a
          user uses their shared team. Users shall receive 10 DreamCoins only
          for new teams created and shared. Users will not receive DreamCoins
          for sharing the same team multiple times. Users will not receive
          DreamCoins for abandoned matches. Users will receive DreamCoins on
          the basis of the day the team was shared, irrespective of the start
          time of the match. DreamCoins would be credited to the users account
          on January 19th, 2023, 17:00:00 hours. DreamCoins granted will be
          governed by the terms and conditions applicable for the usage of
          DreamCoins. Dream11 January Deposit Bonus Offer Terms and Conditions
          This "Dream11 January Deposit Bonus Offer" ("Promotion") is open to
          select users of Dream11 app who see the banner and App-Inbox via
          in-app communication. Under this Promotion, users who make at least
          one Qualifying Deposit (as defined below) during the Promotion
          Period (defined below) will be eligible to receive a cash bonus
          amount ("Bonus Amount"), the value of which will be dependent on the
          value of the Qualifying Deposit credited into their Dream11 Deposit
          Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          January 14th, 2022 till IST 13:00:00 hours on January 15th, 2022
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amounts specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          account during the Promotion Period to be eligible to receive the
          Bonus Amount corresponding to the Qualifying Deposit, in accordance
          with the table below: Qualifying Deposit (INR) Bonus Amount (INR) 99
          49 Please note that only the Qualifying Deposit made by a user
          during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          January 16th, 2022. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 49
          Please note that this Promotion is only available to the first
          3,00,000 (One Lakh) users who make the Qualifying Deposit of Rs.99
          during the Promotion Period. For these Terms, the first 3,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          deposit amount. Money Back Offer The "Money Back Offer"
          ("Promotion") is open to users of Dream11 app who see the banner via
          in-app communication. Under this Promotion users can join the Mega
          Contest of a specific match, and the net losers of that game would
          get Cash Bonus equivalent to the Entry Fee for that game. This
          Promotion is valid once per user. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:01:00 hours on
          Jan 27th, 2023 till IST 23:59:59 hours on Feb 5th, 2023 ("Promotion
          Period"). Users will be given a discount of 90% up to Rs. 50 to join
          the contest. For E.g.: if the user joins a Mega Contest with Entry
          Fee of Rs.49 , the discounted entry will be Rs.4. In case the user
          wins an amount less than Rs.4 he will get Rs.4 as CB. The offer is
          only valid for the Mega Contests of all Cricket games during the
          Promotion Period The Cash Bonus will be credited the next day at 1
          PM. The offer is valid only once per user and for the first Mega
          Contest joined by a user during the Promotion Period. The offer will
          not be applicable in case the match is abandoned. Dream11 January
          Deposit Bonus Offer Terms and Conditions This "Dream11 January
          Deposit Bonus Offer" ("Promotion") is open to select users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) during the Promotion Period (defined below) will be
          eligible to receive a cash bonus amount ("Bonus Amount"), the value
          of which will be dependent on the value of the Qualifying Deposit
          credited into their Dream11 Deposit Account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 10:00:00 hours on January 26th, 2023 till IST 12:00:00
          hours on January 26th, 2023 ("Promotion Period"). To participate in
          the Promotion, user must make at least one cash deposit of exactly
          the amounts specified in the table below ("Qualifying Deposit") into
          the users’ Dream11 account during the Promotion Period to be
          eligible to receive the Bonus Amount corresponding to the Qualifying
          Deposit, in accordance with the table below: Qualifying Deposit
          (INR) Bonus Amount (INR) 261 105 Please note that only the
          Qualifying Deposit made by a user during the Promotion Period shall
          be considered for determining whether a user is eligible to receive
          the Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period. There
          is no maximum limit on the number of Qualifying Deposits made by a
          user during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          IST 23:59:59 hours on January 27th, 2023. The maximum Bonus Amount
          that can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 105. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit of Rs.261 during the Promotion Period. For these
          Terms, the first 1,00,000 (One Lakh) users shall be calculated based
          on the time of Qualifying Deposit during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the deposit amount. Dream11 January
          Deposit Bonus Offer Terms and Conditions This "Dream11 January
          Deposit Bonus Offer" ("Promotion") is open to select users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) during the Promotion Period (defined below) will be
          eligible to receive a cash bonus amount ("Bonus Amount"), the value
          of which will be dependent on the value of the Qualifying Deposit
          credited into their Dream11 Deposit Account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 12:00:00 hours on January 26th, 2023 till IST 13:00:00
          hours on January 26th, 2023 ("Promotion Period"). To participate in
          the Promotion, user must make at least one cash deposit of exactly
          the amounts specified in the table below ("Qualifying Deposit") into
          the users’ Dream11 account during the Promotion Period to be
          eligible to receive the Bonus Amount corresponding to the Qualifying
          Deposit, in accordance with the table below: Qualifying Deposit
          (INR) Bonus Amount (INR) 1950 730 Please note that only the
          Qualifying Deposit made by a user during the Promotion Period shall
          be considered for determining whether a user is eligible to receive
          the Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period. There
          is no maximum limit on the number of Qualifying Deposits made by a
          user during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          IST 23:59:59 hours on January 27th, 2023. The maximum Bonus Amount
          that can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 730. Please note that this Promotion is only
          available to the first 40,000 (Forty Thousand) users who make the
          Qualifying Deposit of Rs.1950 during the Promotion Period. For these
          Terms, the first 40,000 (Forty Thousand) users shall be calculated
          based on the time of Qualifying Deposit during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the deposit amount. Dream11 Deposit
          Bonus Offer Terms and Conditions(Happy Hours) This "Dream11 Deposit
          Bonus Offer" ("Promotion") is open to select users of the Dream11
          app who receive the communication via banners on the Dream11 app.
          Under this Promotion, users who deposit INR 49 during the Promotion
          Period (defined below) will be eligible to receive a cash bonus
          amount of INR 49 ("Bonus Amount") , in accordance with, and subject
          to the users’ full compliance with these Terms (defined below).
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Eligibility –
          Participation in the Promotion is only available to registered users
          of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) and have received in-app banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from January 27th, 2023 to
          February 01st, 2023 during the time period, Indian Standard Time
          ("IST") 14:30:00 hours till IST 18:30:00 hours ("Promotion Period").
          To participate in the Promotion, users must make a cash deposit of
          INR 49 (“Qualifying Deposit”) to receive Bonus Amount. Please note
          that only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 49 and
          not any other amount Subject to users’ compliance with these terms
          and conditions, Bonus Amount will be disbursed after forty eight
          (48) hours of successful qualified transaction. Offer can be availed
          only once per user per day post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Happy Hours) This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to select users
          of the Dream11 app who receive the communication via banners on the
          Dream11 app. Under this Promotion, users who deposit INR 49 during
          the Promotion Period (defined below) will be eligible to receive a
          cash bonus amount of INR 49 ("Bonus Amount") , in accordance with,
          and subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from January 27th, 2023
          to February 01st, 2023 during the time period, Indian Standard Time
          ("IST") 19:00:00 hours till IST 23:59:00 hours ("Promotion Period").
          To participate in the Promotion, users must make a cash deposit of
          INR 49 (“Qualifying Deposit”) to receive Bonus Amount. Please note
          that only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 49 and
          not any other amount Subject to users’ compliance with these terms
          and conditions, Bonus Amount will be disbursed after forty eight
          (48) hours of successful qualified transaction. Offer can be availed
          only once per user per day post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Happy Hours) This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to select users
          of the Dream11 app who receive the communication via banners on the
          Dream11 app. Under this Promotion, users who deposit INR 29 during
          the Promotion Period (defined below) will be eligible to receive a
          cash bonus amount of INR 29 ("Bonus Amount") , in accordance with,
          and subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from January 27th, 2023
          to February 01st, 2023 during the time period, Indian Standard Time
          ("IST") 14:30:00 hours till IST 18:30:00 hours ("Promotion Period").
          To participate in the Promotion, users must make a cash deposit of
          INR 29 (“Qualifying Deposit”) to receive Bonus Amount. Please note
          that only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 29 and
          not any other amount Subject to users’ compliance with these terms
          and conditions, Bonus Amount will be disbursed after forty eight
          (48) hours of successful qualified transaction. Offer can be availed
          only once per user per day post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Happy Hours) This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to select users
          of the Dream11 app who receive the communication via banners on the
          Dream11 app. Under this Promotion, users who deposit INR 29 during
          the Promotion Period (defined below) will be eligible to receive a
          cash bonus amount of INR 29 ("Bonus Amount") , in accordance with,
          and subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from January 27th, 2023
          to February 01st, 2023 during the time period, Indian Standard Time
          ("IST") 19:00:00 hours till IST 23:59:00 hours ("Promotion Period").
          To participate in the Promotion, users must make a cash deposit of
          INR 29 (“Qualifying Deposit”) to receive Bonus Amount. Please note
          that only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 29 and
          not any other amount Subject to users’ compliance with these terms
          and conditions, Bonus Amount will be disbursed after forty eight
          (48) hours of successful qualified transaction. Offer can be availed
          only once per user per day post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Coupon Code Discount (Flipkart) - Feb 3- Jul 31st 2023 This Flipkart
          Coupon Offer is open to new users of Dream11 who receive a coupon
          pertaining to this offer through the Flipkart mobile application.
          Under this Promotion, users who have this coupon shall be eligible
          to receive a 50% Discount on paid public contests having a contest
          size of minimum 10000 players. This offer is subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who
          receive the coupon on the Flipkart mobile application and have never
          deposited and joined a pay-to-play contest on the Dream11 mobile
          application. Users must also be eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 00:00:00 hours on Feb 3rd, 2023 till
          IST 23:59:00 hours on July 31st, 2023 ("Promotion Period"). Users
          must redeem the coupon code on the Dream11 mobile application within
          the Promotion Period. The discount coupon shall be valid for a
          period of 14 days from the date of 1st redemption. To participate in
          the Promotion, users must participate in public paid contests on the
          Dream11 app with a contest size of minimum 10000 players across any
          sports with a minimum entry fee of Rs 25 (Eligible Contest). The
          discount coupon will give the users a 50% discount on the entry fee.
          The total discount that a user received under this promotion is Rs.
          300. The discount coupon cannot be clubbed with any other offers,
          cash bonus available with the user. The discount coupon shall be
          available for redemption till Jul 31st 2023. The discount coupon can
          be used on only eight (8) Eligible Contests.In the event that a
          contest in which a user has used this discount coupon gets canceled
          or the match itself gets abandoned, the discount amount used in that
          contest or match will be refunded to that user as Cash Bonus.
          Dream11 reserves the right to cancel this Promotion in its sole
          discretion at any time without any prior notice. All discount
          coupons given prior to the date of cancellation shall be valid for
          utilization as per these terms. Answer & Win: The Answer & Win
          Promotion (“Promotion”) is open to select users of Dream11 who
          receive the In App communication on the Dream11 mobile application.
          Under this Promotion, users who receive the communication on the
          Dream11 app, will receive a question to be answered. If the user
          answers correctly, they would receive a discount coupon of 50% off
          up to Rs.25 (“Discount Coupon”). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive the In App
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here). Coupon Details: The Promotion will be open for
          participation from IST 17:00:00 hours on Feb 1st, 2023 till IST
          19:00:00 hours on Feb 1st, 2023 ("Promotion Period"). Users would
          receive a DiscountCoupon code of 50% OFF up to Rs.25 upon answering
          the question correctly. Discount coupons will be given to the user
          within 24 hours of the end time of participation. Discount coupons
          are valid only paid public contests of IL T20 till 8th Feb 2023,
          11:59:59 PM. Dream11 Beat The Score Terms and Conditions This "Beat
          The Score" ("Contest") is open to select users of Dream11 app who
          receive in-app communication via a banner. Under this Contest, users
          who view the contest on their My Contest page will be eligible to
          participate in the Contest, the construct of which is mentioned
          below, in accordance with, and subject to the users’ full compliance
          with these Terms (defined below). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation and eligibility in the Tournament: Eligibility –
          Participation in the Tournament is only available to registered
          users of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) and have received in-app banner communication from Dream11. By
          participating in this Tournament, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Contests and the terms
          and conditions of the Dream11 game (accessible here) Please note
          that the first 2,00,000 (Two Lakh) eligible Participants who join
          the Contest shall be considered as Participants for this Contest.
          Contest Details Each Participant must participate in the free to
          enter contest titled as Beat The Score. Participants who participate
          in the Contest and achieve a higher score than the target score
          shall be eligible to receive 50% discount worth upto Rs.25 on all SA
          T20 League matches henceforth. The Contests are only applicable for
          the SA T20 League in 5 matches starting from 3rd February, 2023 to
          5th February, 2023. Subject to Participants compliance with these
          Terms, Participants who are eligible to receive 50% discount worth
          upto Rs.25 will receive the discount coupon in their “My Rewards”
          tab by IST 23:59:59 hours on February 6th, 2023. The discount coupon
          can be applied for any public paid contest having an entry fee less
          than Rs.100 in the SA T20 League. The discount coupon shall be valid
          till Indian Standard Time ("IST") 20:00:00 hours on February 11th,
          2023. Please note that if any Participant has the same points as the
          target score, that user is not eligible for the 50% discount worth
          upto Rs.25 promotion. If, for any reason the match is abandoned, the
          Contests will be considered null and void. Dream11 reserves the
          right to amend the terms and conditions applicable to the Contest at
          any time in its sole discretion, without any notice to the
          Participants. Private Contest Passport Promotion for Group Stages of
          ICC Women's T20 World Cup, 2023 This “Dream11 Private Contest
          Passport Promotion” starting from group stages of ICC Women’s T20
          World Cup, 2023 matches (“Promotion”) is only available for users
          who receive communication of the Promotion in their Dream11 app via
          a banner. Through this Promotion, users who receive the
          communication and successfully challenge another Dream11 user to a
          paid private contest, with a minimum entry fee of INR 25, in any
          match of the group stages of ICC Women’s T20 World Cup, 2023 within
          the Promotion Period will be eligible to receive a discount as per
          the number of stamps they are able to collect. Additionally, 1 lucky
          winner out of all those who have collected all the 10 stamps would
          stand a chance to win one brand new Apple iPhone 14. Number of
          stamps Discount Upto amount (Max) 4 50% INR 25 8 75% INR 50 Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Promotion Period - The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 12:00:00 hours from February 10th, 2023 till IST 23:59:59
          hours on February 21st, 2023 ("Promotion Period") Eligibility -
          Participation in this Promotion is available to only those users who
          receive the relevant communication of the Promotion from Dream11 on
          their Dream11 app. The users who successfully challenge another user
          to a paid private contest on Dream11 will be eligible to receive the
          respective discount rewards based on the number of stamps collected.
          Entry Fee for such private contest must be greater than or equal to
          INR 25. Please note that only the private contest created and played
          to completion by the user during the Promotion Period will be
          considered. By participating in this Promotion, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          Users should play paid private contests of all the nations in the
          group stages of the ICC Women’s T20 World Cup, 2023 in order to
          collect all the 10 stamps A maximum of 1 stamp per nation can be
          collected by the user Discount coupons earned by the user will be
          one-time use only and will be auto-applied for the next paid private
          contest Public contests, Special contests and Flexible Private
          contests will not be eligible for this Promotion. If a contest or a
          match is abandoned, that contest or match will not be considered
          complete and will not be eligible under this promotion Dream11’s
          decision in selecting, declaring the winners of the Promotion, shall
          be final and binding on all the users participating in the Promotion
          Subject to your compliance with these Terms, the discount will be
          visible in the eligible users’ My Rewards section in the Rewards
          shop, accessible via the bottom navigation bar Final delivery of the
          iPhone may take upto 14-28 days post declaring the winner, depending
          on the user’s geographical location. Dream11 is only responsible for
          the delivery of the iPhone to the user in India and only in the
          states where delivery of the prize is allowed by Dream11. Delivery
          will be made in the name of the winner only and not in the name of
          any other person. The decision of Dream11 in selecting the winner is
          final and binding and no user shall dispute the decision of Dream11
          iPhone cannot be exchanged for cash or any other benefit iPhone is
          non-transferable and cannot be returned Dream11 shall not be liable
          for any failure to deliver the iPhone to the address given by the
          user, in the event of any restrictions imposed by any regulatory
          authority In case of a match getting abandoned before the match
          deadline, the disbursement of stamps would be at the discretion of
          Dream11. Private Contest Passport Promotion for Group Stages of ICC
          Women's T20 World Cup, 2023 This “Dream11 Private Contest Passport
          Promotion” starting from group stages of ICC Women’s T20 World Cup,
          2023 matches (“Promotion”) is only available for users who receive
          communication of the Promotion in their Dream11 app via a banner.
          Through this Promotion, users who receive the communication and
          successfully challenge another Dream11 user to a paid private
          contest, with a minimum entry fee of INR 10, in any match of the
          group stages of ICC Women’s T20 World Cup, 2023 within the offer
          period will be eligible to receive a discount as per the number of
          stamps they are able to collect. Additionally, 1 lucky winner out of
          all those who have collected all the 10 stamps would stand a chance
          to win one brand new Apple iPhone 14. Number of stamps Discount Upto
          amount (Max) 4 50% INR 25 8 75% INR 50 Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open for participation from Indian Standard Time ("IST")
          12:00:00 hours from February 10th, 2023 till IST 23:59:59 hours on
          February 21st, 2023 ("Promotion Period") Eligibility - Participation
          in this Promotion is available to only those users who receive the
          relevant communication of the Promotion from Dream11 on their
          Dream11 app. The users who successfully challenge another user to a
          paid private contest on Dream11 will be eligible to receive the
          respective discount rewards based on the number of stamps collected.
          Entry Fee for such private contest must be greater than or equal to
          INR 10. Please note that only the private contest created and played
          to completion by the user during the Promotion Period will be
          considered Stamps can be collected by playing paid private contests
          on Dream11 Users should play paid private contests of all the
          nations in the group stages of the ICC Women’s T20 World Cup, 2023
          in order to collect all the 10 stamps A maximum of 1 stamp per
          nation can be collected by the user Discount coupons earned by the
          user will be one-time use only and will be auto-applied for the next
          paid private contest Public contests, Special contests and Flexible
          Private contests will not be eligible for this promotion. If a
          contest or a match is abandoned, that contest or match will not be
          considered complete and will not be eligible under this promotion By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) Dream11’s
          decision in selecting, declaring the winners of the Promotion, shall
          be final and binding on all the users participating in the Promotion
          Subject to your compliance with these Terms, the discount will be
          visible in the eligible users’ My Rewards section in the Rewards
          shop, accessible via the bottom navigation bar Dream11 reserves the
          right to discontinue this promotion in its sole discretion at any
          time without any prior notice to the users Final delivery of the
          iPhone may take upto 14-28 days post declaring the winner, depending
          on the user’s geographical location. Dream11 is only responsible for
          the delivery of the iPhone to the user in India and only in the
          states where delivery of the prize is allowed by Dream11. Delivery
          will be made in the name of the winner only and not in the name of
          any other person. The decision of Dream11 in selecting the winner is
          final and binding and no user shall dispute the decision of Dream11
          iPhone cannot be exchanged for cash or any other benefit iPhone is
          non-transferable and cannot be returned Dream11 shall not be liable
          for any failure to deliver the iPhone to the address given by the
          user, in the event of any restrictions imposed by any regulatory
          authority In case of a match getting abandoned before the match
          deadline, the disbursement of stamps would be at the discretion of
          Dream11 Women's World Cup Public Contest Passport Promotion for
          Group Stages of ICC Women's T20 World Cup, 2023 This “Dream11
          Women’s World Cup Public Contest Passport Promotion” starting from
          group stages of ICC Women’s T20 World Cup, 2023 matches
          (“Promotion”) is only available for users who receive communication
          of the Promotion in their Dream11 app via a banner or a
          notification. Through this Promotion, users who receive the
          communication and successfully play a paid public contest, with a
          minimum entry fee of INR 20, in any match of the group stages of ICC
          Women’s T20 World Cup, 2023 within the Promotion Period will be
          eligible to receive a discount as per the number of stamps they are
          able to collect. Number of stamps Discount Upto amount (Max) 2 25%
          INR 25 6 50% INR 50 10 75% INR 75 Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open for participation from Indian Standard Time ("IST")
          12:00:00 hours from February 10th, 2023 till IST 23:59:59 hours on
          February 21st, 2023 ("Promotion Period") Eligibility - Participation
          in this Promotion is available to only those users who receive the
          relevant communication of the Promotion from Dream11 on their
          Dream11 app. The users who successfully play a paid public contest
          on Dream11 will be eligible to receive the respective discount
          rewards based on the number of stamps collected. Entry Fee for such
          public contests must be greater than or equal to INR 20. Please note
          that only the public contest joined and played to completion by the
          user during the Promotion Period will be considered. By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) Users
          should play paid public contests for matches of all the nations in
          the group stages of the ICC Women’s T20 World Cup, 2023 in order to
          collect all the 10 stamps A maximum of 1 stamp per nation can be
          collected by the user Discount coupons earned by the user will be
          one-time use only and will be auto-applied for the next paid public
          contest Private contests, Special contests and Flexible Private
          contests will not be eligible for this Promotion. If a contest or a
          match is abandoned, that contest or match will not be considered
          complete and will not be eligible under this promotion Dream11’s
          decision in selecting, declaring the winners of the Promotion, shall
          be final and binding on all the users participating in the Promotion
          Subject to your compliance with these Terms, the discount will be
          visible in the eligible users’ My Rewards section in the Rewards
          shop, accessible via the bottom navigation bar In case of a match
          getting abandoned before the match deadline, the disbursement of
          stamps would be at the discretion of Dream11 The Women’s World Cup
          Public Contest Passport will be accessible to you via a banner on
          your homepage on the Dream11 app during the duration of the
          promotion The Women’s World Cup Public Contest Passport page will be
          updated latest by 11:59 PM every day during the duration of the
          promotion and will correctly show the number of stamps collected by
          you Jupiter Discount Coupon - Feb 10 to Apr 30th 2023 This Jupiter
          Discount Coupon (“Promotion”) is open to all users of Dream11 who
          receive a coupon pertaining to this offer through the Jupiter mobile
          application. Under this Promotion, users who have received this
          coupon shall be eligible to receive a 50% discount (“Discount
          Coupon”) on paid public contests having a contest size of minimum
          10000 players. This offer is subject to the users’ full compliance
          with these Terms (defined below). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who
          receive the coupon on the Jupiter mobile application. Users must
          also be eligible to participate in the pay-to-play Dream11 contests
          as per Dream11’s terms and conditions (accessible here) By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 00:00:00 hours on Feb 10th, 2023 till IST 23:59:00 hours on
          Apr 30th, 2023 ("Promotion Period"). Users must redeem the coupon
          code on the Dream11 mobile application within the Promotion Period.
          To participate in the Promotion, users must participate in public
          paid contests on the Dream11 app with a contest size of minimum
          10000 players across any sports with a minimum entry fee of Rs 25
          (Eligible Contest) The Discount Coupon shall be valid for a period
          of 14 days from the date of 1st redemption. The Discount Coupon will
          give the users a 50% discount on the entry fee of any Eligible
          public paid Contest. The total discount that a user receives under
          this Promotion is Rs. 100. The Discount Coupon cannot be clubbed
          with any other offers, cash bonus available with the user. The
          Discount Coupon shall be available for redemption till Apr 30th,
          2023. The Discount Coupon can be used on only four (4) Eligible
          Contests. In the event that an Eligible Contest in which a user has
          used the Discount Coupon gets canceled or the match itself gets
          abandoned, the discount amount used in that contest will be refunded
          to that user as Cash Bonus. Dream11 Deposit Bonus Offer Terms and
          Conditions(Deposit 50 Get 25) This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to select users of the Dream11 app who receive
          the communication via banners on the Dream11 app. Under this
          Promotion, users who deposit INR 50 during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount of
          INR 25 ("Bonus Amount") , in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from February 10th, 2023, IST 23:00:00
          hours to February 16th, 2023 IST 23:59:00 hours ("Promotion
          Period"). To participate in the Promotion, users must make a cash
          deposit of INR 50 (“Qualifying Deposit”) to receive Bonus Amount.
          Please note that only the Qualifying Deposit made by a user during
          the Promotion Period shall be considered for determining whether a
          user is eligible to receive the Bonus Amount and not any deposits
          that the user makes into such user’s Dream11 account before or after
          the Promotion Period. All payment instruments are applicable for
          this Promotion. The bonus amount will be given on exact deposit of
          50 and not any other amount Subject to users’ compliance with these
          terms and conditions, Bonus Amount will be disbursed after forty
          eight (48) hours of successful qualified transaction. Offer can be
          availed only once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Deposit 150 Get 50)
          This "Dream11 Deposit Bonus Offer" ("Promotion") is open to select
          users of the Dream11 app who receive the communication via banners
          on the Dream11 app. Under this Promotion, users who deposit INR 150
          during the Promotion Period (defined below) will be eligible to
          receive a cash bonus amount of INR 50 ("Bonus Amount") , in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from February 10th, 2023, IST 23:00:00 hours to
          February 16th, 2023 IST 23:59:00 hours ("Promotion Period"). To
          participate in the Promotion, users must make a cash deposit of INR
          150 (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 150
          and not any other amount Subject to users’ compliance with these
          terms and conditions, Bonus Amount will be disbursed after forty
          eight (48) hours of successful qualified transaction. Offer can be
          availed only once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Deposit 100 Get 50)
          This "Dream11 Deposit Bonus Offer" ("Promotion") is open to select
          users of the Dream11 app who receive the communication via banners
          on the Dream11 app. Under this Promotion, users who deposit INR 100
          during the Promotion Period (defined below) will be eligible to
          receive a cash bonus amount of INR 50 ("Bonus Amount") , in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from February 10th, 2023, IST 23:00:00 hours to
          February 16th, 2023 IST 23:59:00 hours ("Promotion Period"). To
          participate in the Promotion, users must make a cash deposit of INR
          100 (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 100
          and not any other amount Subject to users’ compliance with these
          terms and conditions, Bonus Amount will be disbursed after forty
          eight (48) hours of successful qualified transaction. Offer can be
          availed only once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions(Deposit 333 Get
          111) This "Dream11 Deposit Bonus Offer" ("Promotion") is open to
          select users of the Dream11 app who receive the communication via
          banners on the Dream11 app. Under this Promotion, users who deposit
          INR 333 during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 111 ("Bonus Amount") , in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from February 10th, 2023, IST 23:00:00 hours to
          February 16th, 2023 IST 23:59:00 hours ("Promotion Period"). To
          participate in the Promotion, users must make a cash deposit of INR
          333 (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. The bonus amount will be given on exact deposit of 333
          and not any other amount Subject to users’ compliance with these
          terms and conditions, Bonus Amount will be disbursed after forty
          eight (48) hours of successful qualified transaction. Offer can be
          availed only once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer Terms and Conditions This "Dream11
          Deposit Bonus Offer" ("Promotion") is open to select users of the
          Dream11 app who receive the communication via banners on the Dream11
          app. Under this Promotion, users who deposit INR 10 during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 10 ("Bonus Amount") , in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from February 23rd,
          2023, IST 18:00:00 hours to February 23rd, 2023 IST 18:30:00 hours
          ("Promotion Period"). To participate in the Promotion, users must
          make a cash deposit of INR 10 (“Qualifying Deposit”) to receive
          Bonus Amount. Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. All payment instruments are
          applicable for this Promotion. The bonus amount will be given on
          exact deposit of INR 10 and not any other amount Subject to users’
          compliance with these Terms, the Bonus Amount will be credited into
          users’ Cash Bonus Account by IST 23:59:59 hours on February 24th,
          2023. Offer can be availed 10 times per user post a successful
          transaction during the Promotion Period. Unutilized Bonus Amount
          will expire in 14 days from the date that it has been credited. The
          Bonus Amount cannot be clubbed with any other coupon or offers
          available with the user and/ or the Dream11 platform. Once the Bonus
          Amount is utilized in part or whole, the user cannot claim any
          refund of the deposit amount. Dream11 Next Mega Free This “Dream11
          Next mega Free” (“Promotion”) is only available for users who
          receive communication of the Promotion in their Dream11 app via a
          banner or push notification. Through this Promotion, users who
          receive the communication and successfully deposit any of the below
          mentioned amount within the Promotion Period (defined below) will be
          eligible to receive a discount as per below construct: Amount
          deposited Discount Offer Add exactly INR 149 1 mega contest free
          (100% off upto INR 49) Add exactly INR 249 2 mega contest free (100%
          off upto INR 98) Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Promotion Period - The Promotion will be open from Indian Standard
          Time ("IST") 12:00:00 hours from March 01st, 2023 till IST 23:59:59
          hours on March 28th, 2023 ("Promotion Period") Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app and successfully deposited the
          specified amount as mentioned above within the Promotional Period.
          By participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) Discount
          offer will be activated within 4 hours after a user has added the
          exact amount mentioned above. A maximum of 1 Discount Offer of each
          type can be earned by users by depositing the above mentioned
          amounts. Discount Offers earned by the user will be one-time use
          only and will be auto-applied for the next available mega contests
          with a max entry fee of INR 49. Discount Offer shall be valid for 7
          days from the date of credit to the users account. Subject to your
          compliance with these Terms, the Discount Offer will be visible in
          the eligible users’ My Rewards section in the Rewards shop,
          accessible via the bottom navigation bar In case match is abandoned,
          the value of the Discount Offer will be added as Cash bonus to
          user’s account Dream11 Deposit via Card Bonus Offer Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to select users of the Dream11 app who receive the communication via
          banners on the Dream11 app. Under this Promotion, users who deposit
          minimum Rs 5 using debit or credit card during the Promotion Period
          (defined below), will be eligible to receive a cash bonus amount of
          Rs 50 ("Bonus Amount") , in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:30:00
          hours on March 02nd, 2023 till IST 23:59:00 hours on April 30th,
          2023 ("Promotion Period"). The Promotion is only valid for
          transactions performed by the users using credit / debit card (min
          Rs 5) on Dream11 during the Promotion Period No other payment
          instruments (UPI, Net Banking,wallet etc.) are applicable for this
          Promotion. Subject to users’ compliance with these terms and
          conditions, Bonus Amount will be disbursed after forty eight (48)
          hours of successful qualified transaction. Offer can be availed only
          once per user post a successful transaction via cards during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers on the Dream11 platform.
          Once the Bonus Amount is utilized in part or whole, the user cannot
          claim any refund of the deposit amount. Dream11 March Deposit Bonus
          Offer Terms and Conditions This "Dream11 March Deposit Bonus Offer"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 60 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 17:00:00 hours on
          March 7th, 2023 till IST 18:00:00 hours on March 7th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 149 60 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          March 8th, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 60.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit of Rs.149
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Dream11 March Deposit Bonus
          Offer Terms and Conditions This "Dream11 March Deposit Bonus Offer"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 25 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 18:00:00 hours on
          March 16th, 2023 till IST 14:30:00 hours on March 19th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 25 25 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          March 19th, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 25.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit of Rs.25
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer Terms and Conditions
          This "Dream11 Deposit Bonus Offer" ("Promotion") is open to the
          users of the Dream11 app who receive the communication via banners
          on the Dream11 app. Under this Promotion, users who deposit INR 10
          during the Promotion Period (defined below) will be eligible to
          receive a cash bonus amount of INR 10 ("Bonus Amount") , in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from March 10th, 2023, IST 19:00:00 hours to March
          10th, 2023, IST 19:30:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of INR 10
          (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by IST
          23:59:59 hours on March 11th, 2023. Offer can be availed 10 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Answer &
          Win: The Answer & Win Promotion (“Promotion”) is open to select
          users of Dream11 who receive the In App communication on the Dream11
          mobile application. Under this Promotion, users who receive the
          communication on the Dream11 app, will receive a question to be
          answered. If the user answers correctly, they would receive a
          discount coupon of 50% off up to Rs.25 (“Discount Coupon”) . Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Participation is only
          available to select registered users of Dream11 who receive the In
          App communication on the Dream11 mobile application and are eligible
          to participate in the pay to play contests as per Dream11's terms
          and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 17:00:00 hours on March 15th, 2023
          till IST 19:00:00 hours on March 15th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.25 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of European Cricket
          League T10 till 16th March 2023, 08:59:59 PM. Dream11 March Deposit
          Bonus Offer Terms and Conditions This "Dream11 March Deposit Bonus
          Offer" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 250
          (“Bonus Amount”)., in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 18:00:00 hours on
          March 15th, 2023 till IST 23:59:00 hours on March 22nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 500 250 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 Deposit
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account within 24
          Hours after a successful transaction. The maximum Bonus Amount that
          can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 250. Please note that this Promotion is only
          available to the first 10,00,000 (Ten Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 10,00,000 (Ten Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 March Deposit Bonus
          Offer Terms and Conditions This "Dream11 March Deposit Bonus Offer"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 25 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 18:00:00 hours on
          March 15th, 2023 till IST 23:59:00 hours on March 22nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 100 25 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 Deposit
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account within 24
          Hours after a successful transaction. The maximum Bonus Amount that
          can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 25. Please note that this Promotion is only
          available to the first 10,00,000 (Ten Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 10,00,000 (Ten Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 March Deposit Bonus
          Offer Terms and Conditions This "Dream11 March Deposit Bonus Offer"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 100 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 18:00:00 hours on
          March 15th, 2023 till IST 23:59:00 hours on March 22nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 250 100 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 Deposit
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account within 24
          Hours after a successful transaction. The maximum Bonus Amount that
          can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 100. Please note that this Promotion is only
          available to the first 10,00,000 (Ten Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 10,00,000 (Ten Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus Offer
          Terms and Conditions This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to the users of the Dream11 app who receive
          the communication via banners on the Dream11 app. Under this
          Promotion, users who deposit INR 10 during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount of
          INR 10 ("Bonus Amount") , in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from March 16th, 2023, IST 19:05:00 hours
          to March 16th, 2023, IST 19:20:00 hours ("Promotion Period"). To
          participate in the Promotion, users must make a cash deposit of INR
          10 (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Deposit Account before or after
          the Promotion Period. All payment instruments are applicable for
          this Promotion. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Cash Bonus Account by IST
          23:59:59 hours on March 17th, 2023. Offer can be availed 10 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer Terms and Conditions This "Dream11 Deposit Bonus
          Offer" ("Promotion") is open to select users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited into their Dream11
          Deposit Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 11:30:00 hours on
          March 22nd, 2023 till IST 13:30:00 hours on March 22nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amounts specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          account during the Promotion Period to be eligible to receive the
          Bonus Amount corresponding to the Qualifying Deposit, in accordance
          with the table below: Qualifying Deposit (INR) Bonus Amount (INR) 99
          49 Please note that only the Qualifying Deposit made by a user
          during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          March 23rd, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 49.
          Please note that this Promotion is only available to the first
          50,000 (Fifty thousand) users who make the Qualifying Deposit of
          Rs.99 during the Promotion Period. For these Terms, the first 50,000
          (Fifty thousand) users shall be calculated based on the time of
          Qualifying Deposit during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the deposit amount. Bonus DreamCoins Campaign The
          Bonus DreamCoins Promotion (“Promotion”) is open to select users of
          Dream11 who receive the communication on the Dream11 mobile
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, and join the Paid Mega Contest for
          the Mumbai Indian - W vs UP Womens - match on 24th March
          2023(“Match”), with at least 2 teams., will receive 200 Bonus
          DreamCoins. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive communication on the Dream11 mobile application
          and are eligible to participate in the pay to play contests as per
          Dream11's terms and conditions (accessible here). By participating
          in this Promotion, you agree to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of Dream11 (accessible here). Coupon Details: The
          Promotion will be open for participation from IST 13:00:00 hours on
          March 24th, 2023 till IST 19:29:59 hours on March 24th, 2023
          ("Promotion Period"). Users who join the paid Mega Contest of the
          Match with at least 2 teams, will receive the Bonus DreamCoins
          within 48 hours of completion of the Match The Bonus DreamCoins will
          be added to the users DreamCoins balance. Bonus Dream Coins will be
          valid for 1 year from the date of receiving them. Bonus DreamCoins
          will not contribute to the Level progress for the user. Bonus
          DreamCoins will be given over and above the DreamCoins earned based
          on joining the contest. DreamCoins terms and conditions apply. Dream
          Sale : Mar 26 - Apr 02 This Dream Sale (“Sale”) is open to all users
          of Dream11 who participate in at least one Mega Contest with a prize
          pool of 30 Crore+ (“Contest”) of the first five matches of TATA IPL.
          Eligibility Criteria: This Sale is only available to registered
          users of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here). By participating in this Sale, you agree to comply with these
          Terms, and the terms and conditions of the Dream11 game (accessible
          here). The Sale will be open from Indian Standard Time ("IST")
          13:00:00 hours on Mar 26th , 2023 till IST 19:30:00 hours on Apr
          2nd, 2023 ("Sale Period"). Users who participate in at least one
          Mega Contest with a prize pool of 30 Crore+ of the first 5 TATA IPL
          matches, shall be considered a participant for the Sale. The user
          who comes first in any one of the Contest of the first 5 TATA IPL
          Matches shall win an Audi A4 Car (“Prize”) Winners shall receive an
          email communication confirming their winnings within 24 hours of the
          match end. Prize will not be given for abandoned matches. The Prize
          is subject to TDS and shall be borne solely by the winners. In the
          event, a winner chooses to receive the cash equivalent of the Prize,
          the user must reach out to the helpdesk. The cash equivalent of the
          Prize shall be subject to a deduction of 30% TDS deductions. The
          value of the Prize shall be of ex-showroom Delhi. The winner shall
          be solely liable for submission of all required documents at the
          time of receiving the Prize. The list of required documents shall be
          communicated to the winner by Dream11. The Prize if taken in the
          form of cash shall be transferred only to the registered bank
          account of the user and not to any other account. In the event of a
          winner not being a verified user on Dream11, the winner will be
          required to verify his/her details within a period of fourteen (14)
          days, from the date of winner declaration. Failure by the winner to
          adhere to the above timelines, Dream11 shall select the immediately
          next qualifying rank as the winner. Dream11 is not responsible for
          any costs of maintenance once the car has been handed over to the
          winner. The delivery timeline of the Prize shall be subject to
          availability and registration of the nearest authorized dealership.
          In the event the winner is unable to produce the required valid
          documents, the Prize shall be forfeited. Upon delivery and
          acceptance of the Prize by the winner, Dream11 is discharged of all
          its liabilities under this Sale. In the event winners do not receive
          an email with a link they can reach out to the helpdesk within 7
          days of winner declaration. In the event two or more users have the
          same team points at the end of each match in the Sale Period, then
          the following tiebreaker will be used. It is clarified that for the
          purpose of this Tiebreaker, the teams used to join the Mega contest
          only shall be considered at the end of match to determine the
          winners and not teams created. The highest scoring team of the user
          at the end of the match shall be considered for this Tiebreaker.
          Tiebreaker Rule - Earlier contest join time, the team that joined
          the mega contest earlier would be considered winner In the event of
          any ties - related dispute, Dream11 reserves the right to decide
          based on any metric of its choice. Dream Sale Amazon Pay UPI Promo
          Offer Terms and Conditions This "Dream Sale Amazon Pay UPI"
          ("Promotion") is open to users of the Dream11 app who receive the
          communication on the Dream11 app. Under this Promotion, users who
          perform a transaction of INR 50 or above using Amazon Pay UPI , will
          be eligible to receive a cashback of flat INR 10 in the users Amazon
          Pay balance during the Promotion Period (“defined below”). Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Eligibility –
          Participation in the Promotion is only available to registered users
          of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) Offer is applicable to all users on transacting with Amazon
          Pay UPI payment option only To register for Amazon Pay UPI: click
          here Users can activate their Amazon Pay UPI by following the below
          steps: Open Amazon app Click on Amazon Pay icon on homepage, which
          will take you to Amazon Pay page Click on the Amazon Pay UPI icon on
          top of the page, which will take you to the registration page Click
          on proceed to link your bank account Setup MPIN and you are ready to
          start using Amazon Pay UPI By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion is only valid for transactions
          performed using Amazon Pay UPI on Dream11 during the Promotion
          Period The Promotion will be open for participation from Indian
          Standard Time ("IST") 00:00:00 hours on 26th Mar, 2023 till IST
          23:59:00 hours on 2nd Apr, 2023 ("Promotion Period"). No other
          payment instruments apart from Amazon Pay UPI is eligible for this
          promotion User will be eligible for flat cashback of INR 10 on
          transactions above INR 50 Subject to users’ compliance with these
          terms and conditions, cashback will be credited to users Amazon Pay
          balance by Amazon Pay. All complaints relating to cashback shall be
          raised with Amazon Pay only. User can reach out to Amazon Pay here
          The Cashback given by Amazon Pay is governed by the terms and
          conditions of Amazon Pay found here Dream11 Deposit Bonus Offer
          Terms and Conditions This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to the users of the Dream11 app who receive
          the communication via banners on the Dream11 app. Under this
          Promotion, users who deposit INR 5 during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount of
          INR 10 ("Bonus Amount") , in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from March 26th, 2023, IST 19:03:00 hours
          to March 26th, 2023, IST 19:23:00 hours ("Promotion Period"). To
          participate in the Promotion, users must make a cash deposit of INR
          5 (“Qualifying Deposit”) to receive Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Deposit Account before or after
          the Promotion Period. All payment instruments are applicable for
          this Promotion. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Cash Bonus Account by IST
          23:59:59 hours on March 27th, 2023. Offer can be availed 10 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Answer & Win
          SA vs WI Pre-Roundlock: The Answer & Win Promotion (“Promotion”) is
          open to select users of Dream11 who receive the In App communication
          on the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will receive a
          question to be answered. If the user answers correctly, they would
          receive a discount coupon of 50% off up to Rs.25 (“Discount Coupon”)
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who receive the
          In App communication on the Dream11 mobile application and are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 19:00:00 hours on March 28th, 2023
          till IST 21:00:00 hours on March 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.25 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of Kuwait Ramadan T10
          League till 29th March 2023, 11:59:59 PM. Answer & Win SA vs WI
          Post-Roundlock: The Answer & Win Promotion (“Promotion”) is open to
          select users of Dream11 who receive the In App communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, will receive a question to be
          answered. If the user answers correctly, they would receive a
          discount coupon of 50% off up to Rs.25 (“Discount Coupon”). Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Participation is only
          available to select registered users of Dream11 who receive the In
          App communication on the Dream11 mobile application and are eligible
          to participate in the pay to play contests as per Dream11's terms
          and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 21:30:00 hours on March 28th, 2023
          till IST 23:30:00 hours on March 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.25 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of Kuwait Ramadan T10
          League till 29th March 2023, 11:59:59 PM. Dream11 RBL Bank Card
          Promo Offer Terms and Conditions This "Dream11 RBL Bank Card Offer"
          ("Promotion") is open to users of the Dream11 app who receive the
          communication on the Dream11 app. Under this Promotion, users who
          perform a transaction of INR 249 or above using RBL Bank Credit or
          Debit Card, during the Promotion Period (“defined below”) will be
          eligible to receive a cash bonus of INR 50 (“Bonus Amount”) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Eligibility –
          Participation in the Promotion is only available to registered users
          of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) and have received in-app banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here). The
          Promotion is only valid for transactions performed by the users
          using RBL Bank card on Dream11 during the Promotion Period. The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 00:00:00 hours on 30th March, 2023 till IST 23:30:00 hours
          on 28th May, 2023 ("Promotion Period"). No other payment instruments
          (UPI, Wallet, Gift Card, etc.) are applicable on this Promotion In
          case the user does a transaction of less than INR 249, the user will
          not be eligible for the Bonus Amount. Subject to users’ compliance
          with these terms and conditions, Bonus Amount will be disbursed
          after forty eight (48) hours of successful RBL Bank card
          transaction. Offer can be availed only once per card during the
          offer period. Unutilized Bonus Amount will expire in 14 days from
          the date that it has been credited The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Swiggy Money Vouchers Terms & Conditions Eligibility Criteria: The
          Swiggy Matchday Mania (Contest) is only available to registered
          users of Dream11 who are eligible to participate in the Dream11
          contests as per Dream11’s terms and conditions. By participating in
          this Contest, you agree to comply with these Terms, and the terms
          and conditions of the Dream11 game (accessible here) Prize will not
          be given for abandoned matches. In case the winner has not verified
          their PAN, winner will be required to verify their PAN in order to
          receive the Swiggy Money voucher within 3 days of winner declaration
          of this contest In the event two or more users have the same team
          points at the end of each match, then the following tiebreaker will
          be used. It is clarified that for the purpose of this Tiebreaker,
          the teams used to join the Swiggy Matchday Mania contest only shall
          be considered at the end of match to determine the winners and not
          teams created. The highest scoring team of the user at the end of
          the match shall be considered for this Tiebreaker. Tiebreaker Rule -
          Earlier contest join time, the team that joined the contest earlier
          would be considered winner In the event of any ties - related
          dispute, Dream11 reserves the right to decide based on any metric of
          its choice. Swiggy Money - How to activate & redeem voucher This
          Voucher Code is issued by ICICI to add money to Swiggy wallet
          powered by ICICI. Use of money in Swiggy Wallet powered by ICICI
          will be subject to terms and conditions available at
          https://www.icicibank.com/terms-condition/tnc-for-swiggy-money.page
          Once the amount is loaded to Swiggy Wallet powered by ICICI, the
          amount cannot expire The amount in Swiggy Wallet powered by ICICI
          can only be used on food orders on the Platform. The amount cannot
          be taken out/transferred from Swiggy Wallet powered by ICICI. The
          amount can be used on multiple transactions. The amount covers
          taxes, packing charges, delivery fee. For users having the Swiggy
          Wallet powered by ICICI account already activated the amount will be
          uploaded in the wallet once these Voucher Codes are redeemed by the
          end user in the Swiggy app. Users not having the Swiggy Wallet
          powered by ICICI account need to login to the Swiggy app and create
          the Swiggy account to use these Voucher Codes. Users will need to
          perform a one time verification before utilizing the Swiggy Wallet
          powered by ICICI by filling the KYC (Know Your Customer details), if
          not already done. Dream11 March Deposit Bonus Offer Terms and
          Conditions This "Dream11 March Deposit Bonus Offer" ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 10 (“Bonus Amount”)., in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here). The Promotion will be open for
          participation from Indian Standard Time ("IST") 14:00:00 hours on
          March 30th, 2023 till IST 18:00:00 hours on March 31st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 100 10 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 1st April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 10.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 March Deposit Bonus Offer Terms and Conditions This
          "Dream11 March Deposit Bonus Offer" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 25 (“Bonus Amount”)., in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on March 30th, 2023 till
          IST 18:00:00 hours on March 31st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 250 25 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 1st April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 25. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 March Deposit Bonus Offer Terms and Conditions This
          "Dream11 March Deposit Bonus Offer" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 50 (“Bonus Amount”)., in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on March 30th, 2023 till
          IST 18:00:00 hours on March 31st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 500 50 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 1st April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 50. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 March Deposit Bonus Offer Terms and Conditions This
          "Dream11 March Deposit Bonus Offer" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 100 (“Bonus Amount”)., in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on March 30th, 2023 till
          IST 18:00:00 hours on March 31st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 1000 100 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 1st April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 100. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 March Deposit Bonus Offer Terms and Conditions This
          "Dream11 March Deposit Bonus Offer" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 250 (“Bonus Amount”)., in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on March 30th, 2023 till
          IST 18:00:00 hours on March 31st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 2500 250 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 1st April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 250. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Terms and Conditions This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to all users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 100 (“Bonus Amount”), in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:30:00 hours on April 01st, 2023 till
          IST 15:00:00 hours on April 01st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 250 100 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 2nd April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 100. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Dream11 Deposit Bonus Offer (Dep 250 get 25) Terms
          and Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 25 (“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:30:00 hours on
          April 03rd, 2023 till IST 19:00:00 hours on April 03rd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 250 25 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 4th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 25.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Private Party Pass - Terms and Conditions This Private
          Contest Pass (“Discount Pass”) is available to select users of
          Dream11 who receive communication via in app banner and push
          notification from Dream11. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Discount Pass will be valid only
          for private contests played for IPL tournament and joined from
          Indian Standard Time ("IST") 00:00:00 hours from May 12th, 2023 till
          IST 23:59:59 hours on May 12th, 2023 ("Promotion Period").
          Eligibility - Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) Coupon Details - The Discount Pass will allow the
          user to avail a 50% discount in GT vs MI IPL tournament private
          contest having a minimum contest entry amount of INR 5, during the
          Promotion Period, with an overall maximum avail amount of INR 50.
          Expiry - The Discount Pass will expire on IST 23:59:59 hours on May
          12th, 2023. The Discount Pass cannot be clubbed together with cash
          bonus or any other promotions that are running simultaneously on the
          Dream11 platform. Dream11 reserves the right to discontinue this
          promotion in its sole discretion at any time without any prior
          notice to the users. Private Party Pass - Terms and Conditions This
          Private Contest Pass (“Discount Pass”) is available to select users
          of Dream11 who receive communication via in app banner and push
          notification from Dream11. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Discount Pass will be valid only
          for private contests joined from Indian Standard Time ("IST")
          00:00:00 hours from Apr 7th, 2023 till IST 23:59:59 hours on April
          21st, 2023 ("Promotion Period"). Eligibility - Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) Coupon Details - The Discount
          Pass will allow the user to avail a 50% discount in any private
          contest having a minimum contest entry amount of INR 5, during the
          Promotion Period, with an overall maximum avail amount of INR 100
          Expiry - The Discount Pass will expire on IST 23:59:59 hours on
          April 21st, 2023. The Discount Pass cannot be clubbed together with
          cash bonus or any other promotions that are running simultaneously
          on the Dream11 platform. Dream11 reserves the right to discontinue
          this promotion in its sole discretion at any time without any prior
          notice to the users. Private Party Pass - Terms and Conditions This
          Private Contest Pass (“Discount Pass”) is available to select users
          of Dream11 who receive communication via in app banner and push
          notification from Dream11. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Discount Pass will be valid only
          for private contests joined from Indian Standard Time ("IST")
          00:00:00 hours from Apr 7th, 2023 till IST 23:59:59 hours on April
          21st, 2023 ("Promotion Period"). Eligibility - Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) Coupon Details - The Discount
          Pass will allow the user to avail a 75% discount in any private
          contest having a minimum contest entry amount of INR 5, during the
          Promotion Period, with an overall maximum avail amount of INR 50.
          Expiry - The Discount Pass will expire on IST 23:59:59 hours on
          April 21st, 2023. The Discount Pass cannot be clubbed together with
          cash bonus or any other promotions that are running simultaneously
          on the Dream11 platform. Dream11 reserves the right to discontinue
          this promotion in its sole discretion at any time without any prior
          notice to the users. Private Party Pass - Terms and Conditions This
          Private Contest Pass (“Discount Pass”) is available to select users
          of Dream11 who receive communication via in app banner and push
          notification from Dream11. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Discount Pass will be valid only
          for private contests joined from Indian Standard Time ("IST")
          00:00:00 hours from Apr 7th, 2023 till IST 23:59:59 hours on April
          21st, 2023 ("Promotion Period"). Eligibility - Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) Coupon Details - The Discount
          Pass will allow the user to avail a 75% discount in any private
          contest having a minimum contest entry amount of INR 5, during the
          Promotion Period, with an overall maximum avail amount of INR 100.
          Expiry - The Discount Pass will expire on IST 23:59:59 hours on
          April 21st, 2023. The Discount Pass cannot be clubbed together with
          cash bonus or any other promotions that are running simultaneously
          on the Dream11 platform. Dream11 reserves the right to discontinue
          this promotion in its sole discretion at any time without any prior
          notice to the users. Dream11 Deposit Bonus Offer(Dep 5555 get 2222)
          Terms and Conditions This "Dream11 Deposit Bonus Offer(Dep 5555 get
          2222)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 2222
          (“Bonus Amount”)., in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 09th, 2023 till IST 18:00:00 hours on April 09st,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2222 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          10th April 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 2222. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer (Dep1111 get 333)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep1111 get
          333)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 333
          (“Bonus Amount”)., in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 09th, 2023 till IST 18:00:00 hours on April 09st,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 333 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          10th April 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 333. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Happy hours Offers Terms and Conditions This
          "Happy hours Offers" ("Promotion") is open to all users of Dream11
          app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount corresponding to the Qualifying Deposit and as
          mentioned in table below(“Bonus Amount”), in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 16:00:00 hours on April 09th, 2023 till
          IST 18:00:00 hours on April 09st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 100 10 250 40 500 100 Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          respective Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 10th April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is as mentioned above.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Happy hours Offers Terms and Conditions This "Happy hours
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount corresponding
          to the Qualifying Deposit and as mentioned in table below(“Bonus
          Amount”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 09th, 2023 till IST 18:00:00 hours on April 09st,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the corresponding Bonus Amount: Qualifying Deposit (INR)
          Bonus Amount (INR) 250 25 500 75 1000 250 Please note that only the
          Qualifying Deposit made by a user during the Promotion Period shall
          be considered for determining whether a user is eligible to receive
          the Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          respective Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 10th April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is as mentioned above.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Happy hours deposit Offers Terms and Conditions This "Happy
          hours Offers" ("Promotion") is open to all users of Dream11 app who
          see the banner via in-app communication. Under this Promotion, users
          who make at least one Qualifying Deposit (as defined below) into
          their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          corresponding to the Qualifying Deposit and as mentioned in table
          below(“Bonus Amount”), in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 09th, 2023 till IST 18:00:00 hours on April 09st,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the corresponding Bonus Amount: Qualifying Deposit (INR)
          Bonus Amount (INR) 500 50 1000 150 2500 500 Please note that only
          the Qualifying Deposit made by a user during the Promotion Period
          shall be considered for determining whether a user is eligible to
          receive the Bonus Amount and not any deposits that the user makes
          into such user’s Dream11 unutilised account before or after the
          Promotion Period. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the respective Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by 10th April 2023
          23:59:00 Hours. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is as mentioned
          above. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 5555 get 2222)
          Terms and Conditions This "Dream11 Deposit Bonus Offer(Dep 5555 get
          2222)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 2222
          (“Bonus Amount”)., in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 18:00:00
          hours on April 10th, 2023 till IST 18:00:00 hours on April 11th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2222 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          12th April 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 2222. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer (Dep 1111 get 333)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 1111 get
          333)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 333
          (“Bonus Amount”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 18:00:00
          hours on April 10th, 2023 till IST 18:00:00 hours on April 11th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 333 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          12th April 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 333. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Next Mega Free This “Dream11 Next mega
          Free” (“Promotion”) is only available for users who receive
          communication of the Promotion in their Dream11 app via a banner or
          push notification. Through this Promotion, users who receive the
          communication and successfully deposit any of the below mentioned
          amount within the Promotion Period (defined below) will be eligible
          to receive a discount as per below construct: Amount deposited
          Discount Offer Add exactly INR 149 1 mega contest free (100% off
          upto INR 49) Add exactly INR 249 2 mega contest free (100% off upto
          INR 98) Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Promotion Period - The Promotion will be open from Indian Standard
          Time ("IST") 12:00:00 hours from April 13th, 2023 till IST 23:59:59
          hours on May 28th, 2023 ("Promotion Period") Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app and successfully deposited the
          specified amount as mentioned above within the Promotional Period.
          By participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) Discount
          offer will be activated within 4 hours after a user has added the
          exact amount mentioned above. A maximum of 1 Discount Offer of each
          type can be earned by users by depositing the above mentioned
          amounts. Discount Offers earned by the user will be one-time use
          only and will be auto-applied for the next available mega contests
          with a max entry fee of INR 49. Discount Offer shall be valid for 7
          days from the date of credit to the users account. Subject to your
          compliance with these Terms, the Discount Offer will be visible in
          the eligible users’ My Rewards section in the Rewards shop,
          accessible via the bottom navigation bar In case match is abandoned,
          the value of the Discount Offer will be added as Cash bonus to
          user’s account Dream11 Deposit Bonus Offer (Dep 149 get 69) Terms
          and Conditions This "Dream11 Deposit Bonus Offer (Dep 149 get 69)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 69 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 15th, 2023 till IST 18:30:00 hours on April 15th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 149 69 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 16th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 69.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer (Dep 5555 get 2222) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 5555 get 2222)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 2222 (“Bonus
          Amount”)., in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 15th, 2023 till IST 18:30:00 hours on April 15th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2222 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          16th April 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 2222. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer (Dep 499 get 199)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 499 get
          199)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 199
          (“Bonus Amount”)., in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 15th, 2023 till IST 18:30:00 hours on April 15th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 499 199 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 16th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 199.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 500 get 500 CB) Terms and
          Conditions - 20th April This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to select users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited into their Dream11
          Deposit Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          April 20th, 2023 till IST 11:59:59 hours on April 27th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amounts specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          account during the Promotion Period to be eligible to receive the
          Bonus Amount corresponding to the Qualifying Deposit, in accordance
          with the table below: Qualifying Deposit (INR) Bonus Amount (INR)
          500 500 Please note that only the Qualifying Deposit made by a user
          during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 account before
          or after the Promotion Period. There is no maximum limit on the
          number of Qualifying Deposits made by a user during the Promotion
          Period. However, the Bonus Amount shall be credited only once for
          the First Qualifying Deposit amount of the above-listed values.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by IST 23:59:59 hours on
          April 28, 2023. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 500.
          Please note that this Promotion is only available to the first 6,000
          (Six thousand) users who make the Qualifying Deposit of INR 500
          during the Promotion Period. For these Terms, the first 6,000 (Six
          thousand) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          deposit amount. IPL Bonus DreamCoins Promotion The IPL Bonus
          DreamCoins Promotion (“Promotion”) is open to select users of
          Dream11 who receive the communication on the Dream11 mobile
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, and join the paid Mega Contest for
          the following ten matches: DC vs KOL - 20th April 2023 - 7:30 pm
          (Match 1) CHE v SRH - 21st April 2023 - 7: 30 pm (Match 2) LKN v GT
          - 22nd April 2023 - 3:30 pm (Match 3) MI v PBKS - 22nd April 2023 -
          7:30 pm (Match 4) RCB v RR - 23rd April 2023 - 3:30 pm (Match 5) KOL
          v CHE - 23rd April 2023 - 7:30 pm (Match 6) SRH v DC - 24th April
          2023 - 7:30 pm (Match 7) GT v MI - 25th April 2023 - 7:30 pm (Match
          8) RCB v KOL - 26th April 2023 - 7:30 pm (Match 9) RR V CHE - 27th
          April 2023 - 7:30 pm (Match 10) with at least 2 teams will receive
          100 Bonus DreamCoins for each mega contest of the above match that
          they have joined with at least 2 teams. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive communication on the
          Dream11 mobile application and are eligible to participate in the
          pay to play contests as per Dream11's terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible
          here). The Promotion will be open for participation from IST
          15:30:00 hours on April 20th, 2023 till IST 19:29:59 hours on April
          27th, 2023 ("Promotion Period"). The Promotion is only applicable
          for the first Paid Mega Contest of each ‘Match’ listed above. Users
          who join the paid Mega Contest of the any Match listed above only,
          with at least 2 teams, will receive the Bonus DreamCoins within 48
          hours of completion of the Match The Bonus DreamCoins will be added
          to the users DreamCoins balance. Bonus Dream Coins will be valid for
          1 year from the date of receiving them. Bonus DreamCoins will not
          contribute to the Level progress for the user. Bonus DreamCoins will
          be given over and above the DreamCoins earned based on joining the
          contest. Bonus DreamCoins terms and conditions apply ‘Dream11 Mega
          Contest Mania’ This “Dream11 Mega Contest Mania” (“Promotion”) is
          only available for users who receive communication of the Promotion
          in their Dream11 app via in app popup. Through this Promotion, users
          who receive the communication will receive successive discounts for
          joining the IPL mega contest with their second to fifth team joins.
          They must join the same mega contest for the same match with
          multiple teams to receive the promotion. First join with team number
          1 will be without any discounted rate, but for the second to fifth
          team that joins the same mega contest, the users will receive
          discounts as follows - Promotion Type Discount Offer Ascending 50%
          off up to Rs 10 on 2nd team to join the mega contest, 50% off up to
          Rs 15 on 3rd team to join the mega contest, 50% off up to Rs 20 on
          4th team to join the mega contest,,50% off up to Rs 25 on 5th team
          to join the mega contest Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Promotion will be open from Indian
          Standard Time ("IST") 19:00:00 hours from April 30th, 2023 till IST
          23:59:59 hours on May 31st, 2023 ("Promotion Period") The promotion
          is not necessarily applicable to every game within this period,
          eligibility will be communicated via in-app to selected users if
          they are going to receive the discount for a particular match
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on their Dream11 app By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) In case match is abandoned, the value
          of the Discount Offer will be added as Cash bonus to user’s account
          The Discount will only be applied to the contest joins in a specific
          mega contest as notified on the app to the users and in the order
          mentioned above only. ‘Dream11 Mega Contest Mania’ This “Dream11
          Mega Contest Mania” (“Promotion”) is only available for users who
          receive communication of the Promotion in their Dream11 app via in
          app popup. Through this Promotion, users who receive the
          communication will receive successive discounts for joining the IPL
          mega contest with their second to fifth team joins. They must join
          the same mega contest for the same match with multiple teams to
          receive the promotion. First join with team number 1 will be without
          any discounted rate,, but for the second to fifth team that joins
          the same mega contest, the users will receive discounts as follows -
          Promotion Type Discount Offer Descending 50% off up to Rs 25 on 2nd
          team to join the mega contest, 50% off up to Rs 20 on 3rd team to
          join the mega contest, 50% off up to Rs 15 on 4th team to join the
          mega contest,,50% off up to Rs 10 on 5th team to join the mega
          contest Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Promotion Period - The Promotion will be open from Indian Standard
          Time ("IST") 19:00:00 hours from April 30th, 2023 till IST 23:59:59
          hours on May 31st, 2023 ("Promotion Period") The promotion is not
          necessarily applicable to every game within this period, eligibility
          will be communicated via in-app to selected users if they are going
          to receive the discount for a particular match Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) In case match is abandoned, the value of the
          Discount Offer will be added as Cash bonus to user’s account. The
          Discount will only be applied to the contest joins in a specific
          mega contest as notified on the app to the users and in the order
          mentioned above only. Dream11 VISA Card Promo Offer Terms and
          Conditions This "Dream11 VISA Card Offer" ("Promotion") is open to
          users of the Dream11 app who receive the communication on the
          Dream11 app. Under this Promotion, users who perform a transaction
          of INR 249 or above using VISA Credit or Debit Card, during the
          Promotion Period (“defined below”) will be eligible to receive a
          cash bonus of INR 50 (“Bonus Amount”) Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion is only valid for
          transactions performed by the users using VISA card on Dream11
          during the Promotion Period. The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:00:00 hours on
          20th April, 2023 till IST 23:30:00 hours on 28th May, 2023
          ("Promotion Period"). No other payment instruments (UPI, Wallet,
          Gift Card, etc.) are applicable on this Promotion In case the user
          does a transaction of less than INR 249, the user will not be
          eligible for the Bonus Amount. Subject to users’ compliance with
          these terms and conditions, Bonus Amount will be disbursed after
          forty eight (48) hours of successful VISA card transaction. Offer
          can be availed only once per VISA card during the offer period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the deposit amount. 20-20 Deposit
          Offers Terms and Conditions This "20-20 Deposit Offers"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount corresponding to the
          Qualifying Deposit and as mentioned in table below (“Bonus Amount”),
          in accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 15:00:00 hours on
          April 26th, 2023 till IST 23:59:00 hours on May 02nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of the amount specified in the table
          below ("Qualifying Deposit") into the users’ Dream11 unutilised
          account during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Min Deposit (INR) Max Deposit(INR) Bonus
          Amount 50 250 20% of the deposit amount 80 300 20% of the deposit
          amount 150 500 20% of the deposit amount 250 750 20% of the deposit
          amount 500 1000 20% of the deposit amount 750 2000 20% of the
          deposit amount 1500 5000 20% of the deposit amount 10000 20000 5% of
          the deposit amount Please note that only the Qualifying Deposit made
          by a user as per the communication banner received by the user
          during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before, during or after the Promotion Period. Please note
          that the Bonus Amount to be given to the user, will depend on the
          value of the Qualifying Deposit made by the user during the
          Promotion Period. The Bonus Amount shall be a percentage of the
          deposit amount as per the table above. There is no maximum limit on
          the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the respective Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account
          within 48 hours of deposit. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          as mentioned above. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Deposit Offer This "Deposit
          Offer " ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount corresponding
          to the Qualifying Deposit and as mentioned in table below ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on April 28th, 2023 till IST 18:30:00 hours on April 28st,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make cash deposit of exactly the amount specified in the table
          below ("Qualifying Deposit") into the users’ Dream11 unutilised
          account during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 25000 10000 Please note that only the Qualifying Deposit made
          by a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 29th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR
          10000. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Deposit Offer This "Deposit Offer "
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount corresponding to the
          Qualifying Deposit and as mentioned in table below ("Bonus Amount"),
          in accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          April 28th, 2023 till IST 18:30:00 hours on April 28st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 3333 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 29th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 3333.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          April 28th, 2023 till IST 18:30:00 hours on April 28st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 666 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 29th April
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 666 .
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          April 28th, 2023 till IST 18:30:00 hours on April 28st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 499 199 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 29th April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 199 . Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer" ("Promotion") is open to
          all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          April 28th, 2023 till IST 18:30:00 hours on April 28st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 149 69 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 29th April 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 69. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Answer & Win PBKS vs LKN: 50% up to Rs.25 The Answer & Win
          Promotion (“Promotion”) is open to select users of Dream11 who
          receive the In App communication on the Dream11 mobile application.
          Under this Promotion, users who receive the communication on the
          Dream11 app, will receive a question to be answered. If the user
          answers correctly, they would receive a discount coupon of 50% off
          up to Rs.25 (“Discount Coupon”) . Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive the In App
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here). Coupon Details: The Promotion will be open for
          participation from IST 19:30:00 hours on April 28th, 2023 till IST
          21:30:00 hours on April 28th, 2023 ("Promotion Period"). Users would
          receive a Discount Coupon of 50% OFF up to Rs.25 upon answering the
          question correctly. Discount coupons will be given to the user
          within 24 hours of the end time of participation. Discount coupons
          are valid only on paid public contests of FanCode ECS Milan T10
          League till 30th April 2023, 11:59:59 PM. Answer & Win PBKS vs LKN:
          50% up to Rs.20 The Answer & Win Promotion (“Promotion”) is open to
          select users of Dream11 who receive the In App communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, will receive a question to be
          answered. If the user answers correctly, they would receive a
          discount coupon of 50% off up to Rs.20 (“Discount Coupon”) . Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Participation is only
          available to select registered users of Dream11 who receive the In
          App communication on the Dream11 mobile application and are eligible
          to participate in the pay to play contests as per Dream11's terms
          and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 19:30:00 hours on April 28th, 2023
          till IST 21:30:00 hours on April 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.20 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of FanCode ECS Milan
          T10 League till 30th April 2023, 11:59:59 PM. Answer & Win PBKS vs
          LKN: 50% up to Rs.15 The Answer & Win Promotion (“Promotion”) is
          open to select users of Dream11 who receive the In App communication
          on the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will receive a
          question to be answered. If the user answers correctly, they would
          receive a discount coupon of 50% off up to Rs.15 (“Discount Coupon”)
          . Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who receive the
          In App communication on the Dream11 mobile application and are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 19:30:00 hours on April 28th, 2023
          till IST 21:30:00 hours on April 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.15 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of FanCode ECS Milan
          T10 League till 30th April 2023, 11:59:59 PM. Answer & Win PBKS vs
          LKN: 50% up to Rs.10 The Answer & Win Promotion (“Promotion”) is
          open to select users of Dream11 who receive the In App communication
          on the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will receive a
          question to be answered. If the user answers correctly, they would
          receive a discount coupon of 50% off up to Rs.10 (“Discount Coupon”)
          . Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who receive the
          In App communication on the Dream11 mobile application and are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 19:30:00 hours on April 28th, 2023
          till IST 21:30:00 hours on April 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.10 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of FanCode ECS Milan
          T10 League till 30th April 2023, 11:59:59 PM. Answer & Win PBKS vs
          LKN: 50% up to Rs.5 The Answer & Win Promotion (“Promotion”) is open
          to select users of Dream11 who receive the In App communication on
          the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will receive a
          question to be answered. If the user answers correctly, they would
          receive a discount coupon of 50% off up to Rs.5 (“Discount Coupon”)
          . Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who receive the
          In App communication on the Dream11 mobile application and are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Coupon Details: The Promotion will be
          open for participation from IST 19:30:00 hours on April 28th, 2023
          till IST 21:30:00 hours on April 28th, 2023 ("Promotion Period").
          Users would receive a Discount Coupon of 50% OFF up to Rs.5 upon
          answering the question correctly. Discount coupons will be given to
          the user within 24 hours of the end time of participation. Discount
          coupons are valid only on paid public contests of FanCode ECS Milan
          T10 League till 30th April 2023, 11:59:59 PM. Deposit Offer This
          "Deposit Offer " ("Promotion") is open to all users of Dream11 app
          who see the banner via in-app communication. Under this Promotion,
          users who make at least one Qualifying Deposit (as defined below)
          into their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          corresponding to the Qualifying Deposit and as mentioned in table
          below(“Bonus Amount”), in accordance with, and subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 9999 5555 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 04th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 5555.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 7777 4444 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 04th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 4444.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 3333 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 04th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 3333.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 666 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 04th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 666.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 499 199 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 04th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 199. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 16:00:00 hours on
          May 03rd, 2023 till IST 19:00:00 hours on May 03rd, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 149 69 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 04th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 69. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 149 69 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 07th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 69. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 329 149 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the corresponding Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 07th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 149. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 555 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 07th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 555.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 07th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 2777.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 7777 3777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 07th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 3777.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 18:00:00 hours on May 06th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 9999 4999 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the First Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 07th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 4999.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer This "Deposit Offer " ("Promotion") is open
          to all uss of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount corresponding to the Qualifying
          Deposit and as mentioned in table below(“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 23:59:59 hours on May 12th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 2499 1249 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 13th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 1249.
          Please note that this Promotion is only available to the first
          10,000 (Ten Thousand) users who make the Qualifying Deposit during
          the Promotion Period. For these Terms, the first 10,000 (Ten
          Thousand) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Deposit Offer This "Deposit Offer "
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount corresponding to the
          Qualifying Deposit and as mentioned in table below(“Bonus Amount”),
          in accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 23:59:59 hours on May 12th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1999 999 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 13th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 999.
          Please note that this Promotion is only available to the first
          10,000 (Ten Thousand) users who make the Qualifying Deposit during
          the Promotion Period. For these Terms, the first 10,000 (Ten
          Thousand) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Deposit Offer This "Deposit Offer "
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount corresponding to the
          Qualifying Deposit and as mentioned in table below(“Bonus Amount”),
          in accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 06th, 2023 till IST 23:59:59 hours on May 12th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1199 599 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the corresponding
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 13th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 599.
          Please note that this Promotion is only available to the first
          10,000 (Ten Thousand) users who make the Qualifying Deposit during
          the Promotion Period. For these Terms, the first 10,000 (Ten
          Thousand) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 75 get 75 CB)
          Terms and Conditions - 7th May This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to select users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited into their Dream11
          Deposit Account, in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 07th, 2023 till IST 18:00:00 hours on May 07th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amounts specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 account during the
          Promotion Period to be eligible to receive the Bonus Amount
          corresponding to the Qualifying Deposit, in accordance with the
          table below: Qualifying Deposit (INR) Bonus Amount (INR) 75 75
          Please note that only the Qualifying Deposit made by a user during
          the Promotion Period shall be considered for determining whether a
          user is eligible to receive the Bonus Amount and not any deposits
          that the user makes into such user’s Dream11 account before or after
          the Promotion Period. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by IST 23:59:59 hours on May
          08th, 2023. The maximum Bonus Amount that can be credited to users’
          Cash Bonus Account pursuant to this Promotion is INR 75. Please note
          that this Promotion is only available to the first 1,00,000 (one
          lac) users who make the Qualifying Deposit of INR 75 during the
          Promotion Period. For these Terms, the first 1,00,000 (one lac)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the deposit
          amount. May Deposit Offers Terms and Conditions This "May Deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount corresponding
          to the Qualifying Deposit and as mentioned in table below (“Bonus
          Amount”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 07th, 2023 till IST 21:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of the amount specified in the table
          below ("Qualifying Deposit") into the users’ Dream11 unutilised
          account during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount: Min Deposit (INR) Max Deposit(INR) Bonus
          Amount 75 250 30% of the deposit amount 150 500 30% of the deposit
          amount 250 750 25% of the deposit amount 500 1000 20% of the deposit
          amount 1000 2500 15% of the deposit amount 2500 7500 15% of the
          deposit amount 10000 25000 5% of the deposit amount Please note that
          only the Qualifying Deposit made by a user as per the communication
          banner received by the user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before, during or after the
          Promotion Period. Please note that the Bonus Amount to be given to
          the user, will depend on the value of the Qualifying Deposit made by
          the user during the Promotion Period. The Bonus Amount shall be a
          percentage of the deposit amount as per the table above. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the respective Bonus Amount
          shall be credited only once for the first Qualifying Deposit amount
          of the above-listed values. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Cash Bonus
          Account within 48 hours of deposit. The maximum Bonus Amount that
          can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is as mentioned above. Please note that this Promotion is
          only available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. May Deposit Offers 2 Terms and
          Conditions This "May Deposit Offers 2" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount corresponding to the Qualifying Deposit and as
          mentioned in table below (“Bonus Amount”), in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 12:00:00 hours on May 07th, 2023 till
          IST 21:00:00 hours on May 13th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of the amount specified in the table below ("Qualifying
          Deposit") into the users’ Dream11 unutilised account during the
          Promotion Period to be eligible to receive the corresponding Bonus
          Amount: Min Deposit (INR) Max Deposit(INR) Bonus Amount 75 250 25%
          of the deposit amount 150 500 25% of the deposit amount 250 750 20%
          of the deposit amount 500 1000 15% of the deposit amount 750 2500
          10% of the deposit amount 2500 7500 10% of the deposit amount 10000
          25000 5% of the deposit amount Please note that only the Qualifying
          Deposit made by a user as per the communication banner received by
          the user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before, during or after the Promotion Period.
          Please note that the Bonus Amount to be given to the user, will
          depend on the value of the Qualifying Deposit made by the user
          during the Promotion Period. The Bonus Amount shall be a percentage
          of the deposit amount as per the table above. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the respective Bonus Amount shall be
          credited only once for the first Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account
          within 48 hours of deposit. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          as mentioned above. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Phone Giveaway - for
          Multi-Match Fantasy Round ‘Wednesday Triple Treat’ This offer is
          open to all users who have access to the Multi-Match Fantasy product
          only. All users who join the Mega Contest (contest with a prize pool
          of INR 560,000) for the Wednesday Triple Treat Multi-Match Fantasy
          round which starts on Wednesday 10th May at 7:30 PM IST will be
          eligible to participate in this offer. The user who comes first
          place in the Mega Contest (prize pool worth 560,000) of this
          Wednesday Triple Treat Round will be given an Imagine (Apple
          Reseller) Voucher worth Rs.80,000. They will receive the voucher
          details on their email within 2 working days from the date of the
          completion of the round. This offer is only eligible for the 1st
          rank winner of the Mega Contest for the Wednesday Triple Treat
          (contest with prize pool worth 560,000) and not any other contest
          within that round. It is not applicable to any private contests
          within the round. The Mega Contest shall have a total of 20,000
          spots available only. Therefore only the first 20,000 users who join
          this Mega Contest will be eligible for this offer. If the spots in
          this Mega Contest fill prior to the start of the round, no other
          contests that are available for that round will be eligible for this
          offer. Winners shall receive an email communication confirming their
          winnings and the details of the Imagine Voucher within 2 working
          days after the end of this round, latest by Tuesday 15th May 2023
          23:59:59 PM. In the event that the round itself is abandoned, this
          offer will be null and void. The Prize is subject to TDS which shall
          be borne by Dream11. In the event two or more users have the same
          team points at the end of the Wednesday Triple Treat round Mega
          Contest, then the following tiebreaker will be used. It is clarified
          that for the purpose of this Tiebreaker, the teams used to join the
          Mega contest (prize pool worth 560,000) only shall be considered at
          the end of round to determine the winners and not any other teams
          created. Tiebreaker Rule - The team that joined the contest first
          shall be considered the winner Dream11 Deposit Bonus Offer(Dep 25
          get 50 CB) Terms and Conditions - 9th May This "Dream11 Deposit
          Bonus Offer" ("Promotion") is open to select users of Dream11 app
          who see the banner via in-app communication. Under this Promotion,
          users who make at least one Qualifying Deposit (as defined below)
          during the Promotion Period (defined below) will be eligible to
          receive a cash bonus amount ("Bonus Amount"), the value of which
          will be dependent on the value of the Qualifying Deposit credited
          into their Dream11 Deposit Account, in accordance with, and subject
          to the users’ full compliance with these Terms (defined below).
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Eligibility –
          Participation in the Promotion is only available to registered users
          of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) and have received banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 12:00:00 hours on May 09th, 2023 till IST 19:30:00 hours on
          May 10th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit of exactly the
          amounts specified in the table below ("Qualifying Deposit") into the
          users’ Dream11 account during the Promotion Period to be eligible to
          receive the Bonus Amount corresponding to the Qualifying Deposit, in
          accordance with the table below: Qualifying Deposit (INR) Bonus
          Amount (INR) 25 50 Please note that only the Qualifying Deposit made
          by a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by IST
          23:59:59 hours on May 11th, 2023. The maximum Bonus Amount that can
          be credited to users’ Cash Bonus Account pursuant to this Promotion
          is INR 50. Unutilized Bonus Amount will expire in 14 days from the
          date that it has been credited. The Bonus Amount cannot be clubbed
          with any other coupon or offers available with the user and/ or the
          Dream11 platform. Once the Bonus Amount is utilized in part or
          whole, the user cannot claim any refund of the deposit amount.
          Dream11 Deposit Bonus Offer(Dep 25 get 50) Terms and Conditions This
          "Dream11 Deposit Bonus Offer(Dep 25 get 50)" ("Promotion") is open
          to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 50 (“Bonus Amount”) in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 10th, 2023 till IST 19:30:00 hours on May 13th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 25 50 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 14th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 50. Please note that
          this Promotion is only available to the first 26,000 (Twenty Six
          Thousand) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 26,000 (Twenty Six Thousand)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Next Mega Free This “Dream11 Next mega Free”
          (“Promotion”) is only available for users who receive communication
          of the Promotion in their Dream11 app via a banner or push
          notification. Through this Promotion, users who receive the
          communication and successfully deposit any of the below mentioned
          amount within the Promotion Period (defined below) will be eligible
          to receive a discount as per below construct: Amount deposited
          Discount Offer Add exactly INR 49 1 mega contest free (100% off upto
          INR 49) Add exactly INR 89 2 mega contest free (100% off upto INR
          98) Please note our terms and conditions ("Terms") in this respect,
          as they govern your participation in the Promotion: Promotion Period
          - The Promotion will be open from Indian Standard Time ("IST")
          15:00:00 hours from May 10th, 2023 till IST 19:30:00 hours on May
          12th, 2023 ("Promotion Period") Eligibility - Participation in this
          Promotion is available to only those users who have received the
          relevant communication of the Promotion from Dream11 on their
          Dream11 app and successfully deposited the specified amount as
          mentioned above within the Promotional Period. By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) Discount offer will be activated
          within 4 hours after a user has added the exact amount mentioned
          above. A maximum of 1 Discount Offer of each type can be earned by
          users by depositing the above mentioned amounts. Discount Offers
          earned by the user will be one-time use only and will be
          auto-applied for the next available mega contests with a max entry
          fee of INR 49. Discount Offer shall be valid for 7 days from the
          date of credit to the users account. Subject to your compliance with
          these Terms, the Discount Offer will be visible in the eligible
          users’ My Rewards section in the Rewards shop, accessible via the
          bottom navigation bar In case match is abandoned, the value of the
          Discount Offer will be added as Cash bonus to user’s account Once
          the Coupon Amount is utilized in part or whole, the user cannot
          claim any refund of the Qualifying Deposit. Bonus DreamCoins -
          FanCode ECS Austria (“Promotion”) The Bonus DreamCoins FanCode ECS
          Austria Promotion (“Promotion”) is open to select users of Dream11
          who receive the communication on the Dream11 mobile application.
          Under this Promotion, users who receive the communication on the
          Dream11 app, and join a paid public contest for the FanCode ECS
          Austria tour for the matches listed below, shall receive 300 Bonus
          DreamCoins. 11th May, 2023 at 17:00 IST - SNA v DST (Match 1) 12th
          May, 2023 at 17:00 IST - VEA v VID (Match 2) 13th May, 2023 at 17:00
          IST - GCA v ACT (Match 3) 14th May, 2023 at 17:00 IST - ACT v VID
          (Match 4) 15th May, 2023 at 17:00 IST - SNA v GCA (Match 5) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Participation is only
          available to select registered users of Dream11 who receive
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here). The Promotion will be open for participation from
          IST 14:00:00 hours on May 11th, 2023 till IST 16:59:59 hours on May
          15th, 2023 ("Promotion Period"). Users who join atleast one paid
          public contest of the Match, will receive the Bonus DreamCoins
          within 72 hours of completion of the Match Users will only receive
          the Bonus DreamCoins only once per match upon joining a paid public
          contest The promotion is only applicable on the matches listed above
          and no other matches Paid public contest of the matches listed above
          can be of any entry fee amount.The Bonus DreamCoins will be added to
          the users DreamCoins balance. Bonus Dream Coins will be valid for 1
          year from the date of receiving them. Bonus DreamCoins will not
          contribute to the Level progress for the user. Bonus DreamCoins will
          be given over and above the DreamCoins earned based on joining the
          contest. DreamCoins terms and conditions apply. Referral Discount
          Hours This "Referral Discount Hours" ("Promotion") is open to users
          of Dream11 app who receive the banner via in-app communication.
          Under this campaign users can refer their friends during the
          campaign duration to receive additional discounts. Only those
          friends that register and join at least one public cash contest
          would qualify as a referral under this campaign. Based on the number
          of referrals done by a user during the Promotion Period (defined
          below) , the user shall receive Discount Coupons subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 14:00:00
          hours from May 11th, 2023 till IST 18:00:00 hours on May 16th, 2023
          ("Promotion Period") Eligibility – Participation in the Promotion is
          only available to registered users of Dream11 who receive the banner
          in app communication and are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) Users who participate in the Promotion during the
          Promotion Period and refer new users to Dream11, shall be eligible
          to receive Discount Coupons, the value of which will be 50% OFF up
          to Rs.50 per friend that joins a paid public contest during the
          Promotion Period. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by 10 pm on a
          daily basis during the Promotion Period depending on the number of
          referrals completed. The Discount Coupon shall be valid for 7 Days
          from the date of credit. The Discount Coupon given under this
          Promotion is in addition to the existing referral rewards given to
          the users. Dream11 reserves the right to cancel this Promotion at
          any time in its sole discretion without assigning any reason
          thereto. The discount pass will only be applicable for Public
          Contests IPL 2023 Bonus DreamCoins Promotion The IPL Bonus
          DreamCoins Promotion ( “Promotion”) is open to select users of
          Dream11 who receive the communication on the Dream11 mobile
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, and start a paid Automatic Contest
          for the Tata IPL 2023 in their group will receive 500 Bonus
          DreamCoins upon successfully creating the Automatic Contest for Tata
          IPL 2023. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive communication on the Dream11 mobile application
          and are group admins for their respective group on Dream11 in order
          to start an automatic contest By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here). The Promotion will be open for participation from
          IST 21:30:00 hours on May 11th, 2023 till IST 23:59:00 hours on May
          12th, 2023 ("Promotion Period"). The Promotion is only applicable
          for the first one time set-up of an automatic contest set up during
          the Promotion Period The eligible users will receive the bonus
          DreamCoins only once during the Promotion Period Users who set-up
          the automatic contest for Tata IPL 2023 will receive the Bonus
          DreamCoins within 48-72 hours of completion of the Match Contest
          size of the automatic contest set should be 2 or more spots
          Automatic contest should not have been set-up and deleted before the
          end of Promotion Period The Bonus DreamCoins will be added to the
          users DreamCoins balance. Bonus Dream Coins will be valid for 1 year
          from the date of receiving them. Bonus DreamCoins will not
          contribute to the Champions Club Level progress for the user. Bonus
          DreamCoins will be given over and above the DreamCoins earned based
          on joining the contest. Bonus DreamCoins terms and conditions apply
          (accessible here). iPhone Giveaway - for Multi-Match Fantasy Rounds
          This offer is open to all users who have access to the Multi-Match
          Fantasy product only. All users who join the Mega Contest for any
          Multi-Match Fantasy round starting from Sunday 14th May until Sunday
          28th May (‘Promotion Period’) will be eligible to participate in
          this offer. The user who comes first place in the Mega Contest of
          any Multi-Match Fantasy Round during the promotion period will each
          be given an Imagine (Apple Reseller) Voucher worth Rs.80,000. They
          will receive the voucher details on their email within 3 working
          days from the date of the completion of the respective round. This
          offer is only eligible for the 1st rank winner of the Mega Contest
          for any Multi-Match Fantasy Round (where the user would see a banner
          below the Mega Contest card saying ‘Rank 1 wins iPhone’) from 14th
          May until 28th May and not any other contest type within the round.
          It is not applicable to any private contests within the rounds. Only
          contests with the banner under the contest card saying ‘Rank 1 wins
          iPhone’ would be eligible for this offer, and not any other contests
          without this banner present under the contest card. Only users who
          join the Mega Contest of all Multi-Match fantasy rounds from 14th
          May until 28th May will be eligible for this offer. If the spots in
          the Mega Contest for any round fills prior to the start of the
          round, no other contests that are available for that round will be
          eligible for this offer. Winners shall receive an email
          communication confirming their winnings and the details of the
          Imagine Voucher within 3 working days after the end of the
          respective round where they have been declared a winner. In the
          event that any round itself is abandoned, this offer will be null
          and void for that round. The Prize is subject to TDS which shall be
          borne by Dream11. In the event two or more users have the same team
          points at the end of any Multi-Match fantasy round’s Mega Contest,
          then the following tiebreaker will be used. It is clarified that for
          the purpose of this Tiebreaker, the teams used to join the Mega
          contest only shall be considered at the end of round to determine
          the winners and not any other teams created. Tiebreaker Rule - The
          team that joined the contest first shall be considered the winner
          Dream11 Deposit Bonus Offer(Dep 149 get 69) Terms and Conditions
          This "Dream11 Deposit Bonus Offer (Dep 149 get 69) ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 69 (“Bonus Amount”) in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 149 69 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the First
          Qualifying Deposit amount of the above-listed values. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 14th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 69. Please note that
          this Promotion is only available to the first 1,00,000 (One Lakh)
          users who make the Qualifying Deposit during the Promotion Period.
          For these Terms, the first 1,00,000 (One Lakh) users shall be
          calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 329 get 149) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 329 get 149)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 149 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 329 149 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the First Qualifying Deposit amount of the above-listed
          values. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 14th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is INR 149.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 1111 get 555) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 1111 get 555)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 555 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 555 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          14th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 555. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 5555 get 2777)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 5555 get
          2777) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 2777
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          14th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 2777. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 7777 get 3777)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 7777 get
          3777) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 3777
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 7777 3777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          14th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 3777. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 9999 get 4999)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 9999 get
          4999) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 4999
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 13th, 2023 till IST 18:00:00 hours on May 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 9999 4999 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the First Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          14th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 4999. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. IPL 2023 Bonus DreamCoins Promotion for Paid
          Private Contests IPL 2023 Bonus DreamCoins Promotion for Paid
          Private Contests ( “Promotion”) is open to select users of Dream11
          who receive the communication on the Dream11 mobile application.
          Under this Promotion, users who receive the communication on the
          Dream11 app, and send a paid private contest, for the Tata IPL 2023
          match between Mumbai Indians and Gujarat Titans to be held on 12th
          May 2023, to their opponent will receive 500 Bonus DreamCoins upon
          successful completion of the said paid private contest. Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Participation is only available
          to select registered users of Dream11 who receive communication on
          the Dream11 mobile application By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here) The Promotion will be open for participation from
          IST 15:00:00 hours on May 12th, 2023 till IST 23:59:00 hours on May
          12th, 2023 ("Promotion Period") The Promotion is only applicable for
          creating and sharing a successful paid private contest to the
          opponent recommended to the user The eligible users will receive the
          bonus DreamCoins only once during the Promotion Period Qualified
          users who have played a successful paid private contest will receive
          the Bonus DreamCoins within 48-72 hours of completion of the Match
          Contest size of the paid private contest should be 2 or more spots
          Paid private contest should not have been abandoned, in other words,
          the opponent should have joined the contest shared by the user The
          Bonus DreamCoins will be added to the users DreamCoins balance Bonus
          Dream Coins will be valid for 1 year from the date of receiving them
          Bonus DreamCoins will not contribute to the Champions Club Level
          progress for the user Bonus DreamCoins will be given over and above
          the DreamCoins earned based on joining the contest Bonus DreamCoins
          terms and conditions apply (accessible here) Dream11 Next Mega Free
          This “Dream11 Next mega Free” (“Promotion”) is only available for
          users who receive communication of the Promotion in their Dream11
          app via a banner or push notification. Through this Promotion, users
          who receive the communication and successfully deposit any of the
          below mentioned amount within the Promotion Period (defined below)
          will be eligible to receive a discount as per below construct:
          Amount deposited Discount Offer Add exactly INR 29 75% Off on next
          Mega Add exactly INR 49 1 mega contest free (100% off upto INR 49)
          Add exactly INR 89 2 mega contest free (100% off upto INR 98) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 10:00:00
          hours from May 13th, 2023 till IST 19:30:00 hours on May 14th, 2023
          ("Promotion Period") Eligibility - Participation in this Promotion
          is available to only those users who have received the relevant
          communication of the Promotion from Dream11 on their Dream11 app and
          successfully deposited the specified amount as mentioned above
          within the Promotional Period. By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Discount offer will be activated
          within 4 hours after a user has added the exact amount mentioned
          above. A maximum of 1 Discount Offer of each type can be earned by
          users by depositing the above mentioned amounts. Discount Offers
          earned by the user will be one-time use only and will be
          auto-applied for the next available mega contests with a max entry
          fee of INR 49. Discount Offer shall be valid for 7 days from the
          date of credit to the users account. Subject to your compliance with
          these Terms, the Discount Offer will be visible in the eligible
          users’ My Rewards section in the Rewards shop, accessible via the
          bottom navigation bar In case match is abandoned, the value of the
          Discount Offer will be added as Cash bonus to user’s account Once
          the Coupon Amount is utilized in part or whole, the user cannot
          claim any refund of the Qualifying Deposit. iPhone Giveaway - for
          TATA IPL 2023 This giveaway is open to all users of Dream11 who
          participate in the public Mega Contest with a prize of Rs. 50 crores
          and above for the TATA IPL match: LKN vs MI (16th May, 2023) The
          users who are on rank 4 to rank 53 in the Mega Contest of the above
          mentioned TATA IPL match will each receive an Imagine (Apple
          Reseller) Voucher worth Rs.80,000. The details of the voucher shall
          be sent on the registered email id within 5 working days from the
          date of the completion of the match. Only the 4th rank to the 53rd
          rank winner of the above mentioned Mega Contest for the above
          mentioned TATA IPL match with a prize pool of Rs50 Crore and above
          (“Contest”) and not any other contest within the match. If the spots
          in the Mega Contest for the above match fills prior to the start of
          the match, no other contests that are available for that match will
          be eligible for this giveaway. The Imagine voucher shall be valid
          till April 30th, 2024. In the event that the match itself is
          abandoned, this offer will be null and void for that match. The
          giveaway is subject to TDS which shall be borne by Dream11. In the
          event two or more users have the same team points at the end of any
          TATA IPL match’s Mega Contest, then the following tiebreaker will be
          used. It is clarified that for the purpose of this Tiebreaker, the
          teams used to join the Mega contest only shall be considered at the
          end of match to determine the winners and not any other teams
          created. Tiebreaker Rule - The team that joined the contest first
          shall be considered the winner In the event of more than one team
          have the same rank, the above tie-breaker rule shall apply and only
          50 teams that have participated in the Contest and have the ranks as
          mentioned above shall receive the Imagine Voucher Play Big, Win Big
          Terms and Conditions The “Play Big, Win Big” ( “Promotion”) is open
          to select users of Dream11 who receive the In App communication on
          the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will be required to
          achieve total day Entry Fee target(excluding Entry fee from private
          contests) till 11.00 PM on the same day. It being amply clarified
          that the entry fee used to join private contests shall not be
          considered for this Promotion. The user will receive a flat discount
          coupon of either 10% or 25%(not applicable on private contests) upto
          Rs 50, Rs. 100, Rs. 150 or Rs. 200 if they achieve the target. The
          amount of the discount coupon will be communicated to the user
          through in app communication . If the user achieves the target till
          11.00 PM , they would receive the promised discount coupon within 24
          hrs of target completion. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          promotion: Participation is only available to select registered
          users of Dream11 who receive the In App communication on the Dream11
          mobile application and are eligible to participate in the pay to
          play contests as per Dream11's terms and conditions (accessible
          here). By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions and
          the terms and conditions of Dream11 (accessible here). These
          promotions are valid from 16th May 12:00 to 30th May 12:00. In the
          event that a contest in which a user has used this discount coupon
          gets canceled or the match itself gets abandoned, the discount
          amount used in that contest or match will be refunded to that user
          as Cash Bonus. Coupon Details: Discount coupons will be given to the
          user within 24 hours of Entry fee target completion. Discount
          coupons are valid only on paid public contests and will be valid
          only for 24 hours from the time they are given. Range Deposit Offers
          1 Terms and Conditions This "Range Deposit Offers 1" ("Promotion")
          is open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          cash deposit within the Qualifying Range (as defined below) into
          their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          depending on the amount deposited by the User and as mentioned in
          table below (“Bonus Amount”), in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests and
          have received in-app banner communication from Dream11. By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here). The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 20:00:00 hours on May 16th, 2023 till IST 19:30:00 hours on
          May 23rd, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit which is more
          than equal to Min. Deposit and less than equal to the Max Deposit as
          specified in the table below (each a "Qualifying Range") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the corresponding Bonus Amount as per the table:
          Min Deposit (INR) Max Deposit(INR) Bonus Amount 50 250 40% of the
          deposit amount 100 500 40% of the deposit amount 200 750 25% of the
          deposit amount 300 1000 15% of the deposit amount 750 2500 10% of
          the deposit amount 2500 7500 10% of the deposit amount 10000 25000
          5% of the deposit amount Please note that only the amount deposited
          within the each of the Qualifying Range by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before,
          during or after the Promotion Period. Please note that the Bonus
          Amount to be given to the user, will depend on the pre specied
          percentage mentioned in the table above and the value of the amount
          deposited by the user during the Promotion Period. Please note that
          the above table provides for 7 (seven) Qualifying Range and the user
          may choose to deposit any amount within each of the Qualifying Range
          to be eligile to receive the Bonus Amount. There is no maximum limit
          on the number of times a user can make a deposit within each of the
          Qualifying Range and during the Promotion Period. However, the Bonus
          Amount corresponding to each Qualifying Range shall be credited only
          once for each Qualifying Range. Subject to users’ compliance with
          these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account within 48 hours of deposit. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who deposit an amount within the Qualifying Range during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the amount
          deposited within each of the Qualifying Range. Range Deposit Offers
          1 Terms and Conditions This "Range Deposit Offers 1" ("Promotion")
          is open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          cash deposit within the Qualifying Range (as defined below) into
          their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          depending on the amount deposited by the User and as mentioned in
          table below (“Bonus Amount”), in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests and
          have received in-app banner communication from Dream11. By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here). The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 20:00:00 hours on May 16th, 2023 till IST 19:30:00 hours on
          May 23rd, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit which is more
          than equal to Min. Deposit and less than equal to the Max Deposit as
          specified in the table below (each a "Qualifying Range") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the corresponding Bonus Amount as per the table:
          Min Deposit (INR) Max Deposit(INR) Bonus Amount 50 250 25% of the
          deposit amount 100 500 25% of the deposit amount 200 750 20% of the
          deposit amount 300 1000 15% of the deposit amount 750 2500 10% of
          the deposit amount 2500 7500 10% of the deposit amount 10000 25000
          5% of the deposit amount Please note that only the amount deposited
          within the each of the Qualifying Range by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before,
          during or after the Promotion Period. Please note that the Bonus
          Amount to be given to the user, will depend on the pre specied
          percentage mentioned in the table above and the value of the amount
          deposited by the user during the Promotion Period. Please note that
          the above table provides for 7 (seven) Qualifying Range and the user
          may choose to deposit any amount within each of the Qualifying Range
          to be eligile to receive the Bonus Amount. There is no maximum limit
          on the number of times a user can make a deposit within each of the
          Qualifying Range and during the Promotion Period. However, the Bonus
          Amount corresponding to each Qualifying Range shall be credited only
          once for each Qualifying Range. Subject to users’ compliance with
          these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account within 48 hours of deposit. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who deposit an amount within the Qualifying Range during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the amount
          deposited within each of the Qualifying Range. iPhone Giveaway - for
          TATA IPL 2023 This iPhone giveaway is open to all users of Dream11
          who participate in the public Mega Contest with a prize pool of Rs.
          50 crores and above for the TATA IPL matches: SRH vs RCB (18th May,
          2023) PBKS vs RR (19th May, 2023) The users who are on rank 4 to
          rank 53 in the Mega Contest of the above mentioned TATA IPL matches
          will each receive an Imagine (Apple Reseller) Voucher worth
          Rs.80,000. The details of the voucher shall be sent on the
          registered email id of the winner within 5 working days from the
          date of the completion of the match. Only the 4th rank to the 53rd
          rank winners of the above mentioned Mega Contest for the above
          mentioned TATA IPL matches with a prize pool of Rs.50 Crore and
          above (“Contest”) will receive the Prize and not any other contest
          within the match. If the spots in the Mega Contest for the above
          matches fill prior to the start of the match, no other contests that
          are available for that match will be eligible for this giveaway. The
          Imagine voucher shall be valid till April 30th, 2024. In the event
          that the match itself is abandoned, this offer will be null and void
          for that match. The giveaway is subject to TDS which shall be borne
          by Dream11. In the event two or more users have the same team points
          at the end of any TATA IPL match’s Mega Contest, then the following
          tiebreaker will be used. It is clarified that for the purpose of
          this Tiebreaker, the teams used to join the Mega contest only shall
          be considered at the end of match to determine the winners and not
          any other teams created. Tiebreaker Rule - The team that joined the
          contest first shall be considered the winner In the event of more
          than one team have the same rank, the above tie-breaker rule shall
          apply and only 50 teams that have participated in the Contest and
          have the ranks as mentioned above shall receive the Imagine Voucher
          Dream11 Next Mega Free This “Dream11 Next mega Free” (“Promotion”)
          is only available for users who receive communication of the
          Promotion in their Dream11 app via a banner or push notification.
          Through this Promotion, users who receive the communication and
          successfully deposit any of the below mentioned amount within the
          Promotion Period (defined below) will be eligible to receive a
          discount as per below construct: Amount deposited Discount Offer Add
          exactly INR 51 1 mega contest free (100% off upto INR 49) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 12:00:00
          hours from May 18th, 2023 till IST 23:59:59 hours on May 24th, 2023
          ("Promotion Period") Eligibility - Participation in this Promotion
          is available to only those users who have received the relevant
          communication of the Promotion from Dream11 on their Dream11 app and
          successfully deposited the specified amount as mentioned above
          within the Promotional Period. By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Discount offer will be activated
          within 4 hours after a user has added the exact amount mentioned
          above. A maximum of 1 Discount Offer can be earned by users by
          depositing the above mentioned amounts. Discount Offers earned by
          the user will be one-time use only and will be auto-applied for the
          next available mega contests with a max entry fee of INR 49.
          Discount Offer shall be valid for 7 days from the date of credit to
          the users account. Subject to your compliance with these Terms, the
          Discount Offer will be visible in the eligible users’ My Rewards
          section in the Rewards shop, accessible via the bottom navigation
          bar In case match is abandoned, the value of the Discount Offer will
          be added as Cash bonus to user’s account Once the Discount Coupon is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 25 get 25) Terms
          and Conditions This "Dream11 Deposit Bonus Offer (Dep 25 get 25)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 25 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on May 18th, 2023 till IST 20:00:00 hours on May 21st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 25 25 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 21st May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 25. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 25 get 50) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 25 get 50) ("Promotion") is open to all users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 50 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 16:00:00 hours on May 18th, 2023 till
          IST 20:00:00 hours on May 21st, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 25 50 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          21st May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 50. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 25 get 75) Terms
          and Conditions This "Dream11 Deposit Bonus Offer (Dep 25 get 75)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 75 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 16:00:00
          hours on May 18th, 2023 till IST 20:00:00 hours on May 21st, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 25 75 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 21st May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 75. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Referral Discount Hours This
          "Referral Discount Hours" ("Promotion") is open to users of Dream11
          app who receive the banner via in-app communication. Under this
          campaign users can refer their friends during the campaign duration
          to receive additional discounts & aspirational rewards. Only those
          friends that register and join at least one public cash contest
          would qualify as a referral under this campaign. Based on the number
          of referrals done by a user during the Promotion Period (defined
          below), the user shall receive Discount Coupons subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 15:00:00
          hours to 20:00:00 hours everyday from May 19th, 2023 to May 21st,
          2023 ("Promotion Period") Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who
          receive the banner in app communication and are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Users who participate in the
          Promotion and refer new users to Dream11 during the Promotion
          Period, shall be eligible to receive Discount Coupons, the value of
          which will be 50% OFF up to Rs.25 per friend that joins a paid
          public contest during the Promotion Period. Additionally, 5 users
          will be eligible to win an iPhone at the end of the campaign. The
          iPhone reward winners will be declared on May 23rd, 2023. The iPhone
          reward winners will be sent an Imagine (Apple Reseller) Voucher
          worth Rs.80,000 redeemable towards the purchase of an iPhone. The
          iPhone reward Winners shall receive an email communication
          confirming their winnings and the details of the Imagine Voucher
          within 2 working days post declaration of winners. The Voucher is
          non-transferrable and cannot be exchanged for cash or any other
          products and/or services. The Voucher is subject to the terms and
          conditions set forth by the Imagine Store. The Voucher is subject to
          TDS which shall be borne by Dream11. The Voucher is subject to 30%
          as TDS deduction. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by 11 pm on a
          daily basis during the Promotion Period depending on the number of
          referrals completed. The Discount Coupon shall be valid for 5 Days
          from the date of credit. The Discount Coupon given under this
          Promotion is in addition to the existing referral rewards given to
          the users. Dream11 reserves the right to cancel this Promotion at
          any time in its sole discretion without assigning any reason
          thereto. The discount Coupon will only be applicable for one Public
          Contest. Dream11 SafetyShield Feature Terms and Conditions This
          "Dream11 Safety Shield is available to all existing users of the
          Dream11 app, for whichever matches it has been enabled. This feature
          is only available and enabled on some matches and contests on the
          platform, and not all. The feature is only applicable to the largest
          prize pool ‘Mega Contest’ for a match, and not subsequent ones that
          open up once the first one fills A user may choose to ‘Shield’ their
          team Captain and/or Vice Captain for a contest by paying Rs 3 for
          each of these shields. If they do this, they will receive Rs 20 in
          the form of Discount Bonus if both the following conditions are met
          - The user’s selected Captain or Vice Captain for that shielded team
          used to join the Mega Contest scores less than 22 (11 base FPS x2)
          and 16.5 (11 base FPS x1.5) points respectively and ; User is not in
          the winning zone for the match. Eligibility – Participation is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) The entry amount to purchase
          the Shield can come from the users deposit balance and winning
          balance and not Discount Bonus balance. If the user has insufficient
          deposit/winning balance, then they will have to add more in order to
          opt for the Safety Shield. The Discount Bonus will be credited into
          the user's Discount Bonus Account within two hours from the end time
          of the match when the contest winnings are disbursed (only if the
          above conditions are satisfied). The amount of Discount Bonus
          received per team is not capped, so if a user has opted for both C
          and VC shield and both the players score less than the required
          points as mentioned above, and the user does not come in the winning
          zone, then Discount Bonus of Rs 40 (20x2) will be given the user In
          case the user switches their team that was shielded, or edits the
          team (including changing C/VC) - the shield is inherited by the new
          team / new player i.e it is not lost If the user has utilized
          back-ups and their Shielded captain or vice captain changes at the
          time of round lock - the newly selected Captain/vice captain will
          now be shielded Discount Bonus expires fourteen (14) days from the
          date of credit. In case the match is abandoned, we will return the
          Rupee amount that was deducted to opt for this feature Answer & Win:
          The Answer & Win Promotion ( “Promotion”) is open to select users of
          Dream11 who receive the whatsapp communication from Dream11. Under
          this Promotion, users who receive the communication on whatsapp from
          Dream11, will receive a question to be answered. If the user answers
          correctly, the user will receive a Rs.50 Cash Bonus in their Dream11
          account the next day. Please note our terms and conditions ("Terms")
          in this respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the aforementioned whatsapp communication and
          are eligible to participate in the pay to play contests as per
          Dream11's terms and conditions (accessible here). By participating
          in this Promotion, you agree to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of Dream11 (accessible here). To be eligible to receive
          the Cash Bonus, the user must provide the right answer to the
          question directly on whatsapp before 7:30 PM IST on the day of
          receiving the whatsapp communication. The Promotion will be open for
          participation from IST 10:00:00 hours on May 19th, 2023 till IST
          19:30:00 hours on May 21st, 2023 ("Promotion Period"). Users will
          receive a Cash Bonus of Rs.50 upon answering the question correctly.
          The cash bonus to all the eligible user’s will be credited by
          23:59:59 hours on 22nd May (Offer end date). Unutilized Cash Bonus
          amount will expire in 14 days from the date that it has been
          credited. The Cash Bonus amount cannot be clubbed with any other
          coupon or offers available with the user on the Dream11 platform.
          Deposit Offers Terms and Conditions This "Deposit Offers"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount depending on the amount
          deposited by the User and as mentioned in table below (“Bonus
          Amount”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 08:00:00 hours on
          May 19th, 2023 till IST 23:59:00 hours on May 25th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount 75 15 100 20 200 40
          400 80 750 150 1,000 200 2,500 500 15,000 750 Please note that only
          the Qualifying Deposit made by a user as per the communication
          banner received by the user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Cash Bonus Account by 26th May
          2023 23:59:00 Hours. The maximum Bonus Amount that can be credited
          to users’ Cash Bonus Account pursuant to this Promotion is mentioned
          above. Please note that this Promotion is only available to the
          first 2,00,000 (Two Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 2,00,000
          (Two Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Deposit Offers Terms and Conditions This
          "Deposit Offers" ("Promotion") is open to all users of Dream11 app
          who see the banner via in-app communication. Under this Promotion,
          users who make at least one Qualifying Deposit (as defined below)
          into their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive DreamCoins depending on
          the amount deposited by the User and as mentioned in table below
          (“DreamCoins”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 08:00:00 hours on
          May 19th, 2023 till IST 23:59:00 hours on May 25th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) DreamCoins 75 360 100 480 200 950
          400 1,900 750 3,600 1,000 4,750 2,500 12,000 15,000 18,000 Please
          note that only the Qualifying Deposit made by a user as per the
          communication banner received by the user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the DreamCoins and not any deposits that the
          user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, DreamCoins shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          DreamCoins will be credited into users’ account by 26th May 2023
          23:59:00 Hours. The maximum DreamCoins that can be credited to
          users’ account pursuant to this Promotion is mentioned above. Please
          note that this Promotion is only available to the first 2,00,000
          (Two Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 2,00,000 (Two Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. DreamCoins expire in one year from the
          date of credit. For detailed Terms and conditions -here is the link:
          Bonus DreamCoins will not contribute to the Level progress for the
          user. Once the DreamCoins are credited into a user’s account in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offers Terms and Conditions This "Deposit Offers"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive DreamCoins depending on the amount deposited
          by the User and as mentioned in table below (“DreamCoins”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 08:00:00 hours on
          May 19th, 2023 till IST 23:59:00 hours on May 25th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding DreamCoins: Qualifying Deposit (INR) DreamCoins 75 300
          100 400 200 800 400 1,600 750 3,000 1,000 4,000 2,500 10,000 15,000
          15,000 Please note that only the Qualifying Deposit made by a user
          as per the communication banner received by the user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the DreamCoins and not any deposits that the
          user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, DreamCoins shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          DreamCoins will be credited into users’ account by 26th May 2023
          23:59:00 Hours. The maximum DreamCoins that can be credited to
          users’ account pursuant to this Promotion is mentioned above. Please
          note that this Promotion is only available to the first 2,00,000
          (Two Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 2,00,000 (Two Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. DreamCoins expire in one year from the
          date of credit. For detailed Terms and conditions -here is the link:
          Bonus DreamCoins will not contribute to the Level progress for the
          user. Once the DreamCoins are credited into a user’s account in part
          or whole, the user, the user cannot claim any refund of the
          Qualifying Deposit. iPhone Giveaway - for TATA IPL 2023 This iPhone
          giveaway is open to all users of Dream11 who participate in the
          public Mega Contest with a prize pool of Rs.35 crores and above for
          the TATA IPL matches: DC vs CHE (20th May, 2023) KOL vs LKN (20th
          May, 2023) MI vs SRH (21st May, 2023) RCB vs GT (21st May, 2023) The
          users who are on rank 3 to rank 52 in the Mega Contest of the above
          mentioned TATA IPL matches will each receive an Imagine (Apple
          Reseller) Voucher worth Rs.80,000. The details of the voucher shall
          be sent on the registered email id of the winner within 5 working
          days from the date of the completion of the match. Only the 3rd rank
          to the 52nd rank winners of the above mentioned Mega Contest for the
          above mentioned TATA IPL matches with a prize pool of Rs.35 Crore
          and above (“Contest”) will receive the Prize and not any other
          contest within the match. If the spots in the Mega Contest for the
          above matches fill prior to the start of the match, no other
          contests that are available for that match will be eligible for this
          giveaway. The Imagine voucher shall be valid till April 30th, 2024.
          In the event that the match itself is abandoned, this offer will be
          null and void for that match. The giveaway is subject to TDS which
          shall be borne by Dream11. In the event two or more users have the
          same team points at the end of any TATA IPL match’s Mega Contest,
          then the following tiebreaker will be used. It is clarified that for
          the purpose of this Tiebreaker, the teams used to join the Mega
          contest only shall be considered at the end of match to determine
          the winners and not any other teams created. Tiebreaker Rule - The
          team that joined the contest first shall be considered the winner In
          the event of more than one team have the same rank, the above
          tie-breaker rule shall apply and only 50 teams that have
          participated in the Contest and have the ranks as mentioned above
          shall receive the Imagine Voucher iPhone Giveaway - for Multi-Match
          Fantasy Playoffs Rounds This offer is open to all users who have
          access to the Multi-Match Fantasy product only. For Multi-Match
          fantasy Playoffs rounds, starting Monday 22nd May until Friday 26th
          May (‘Promotion Period’), along with the Rank 1 winner of the Mega
          Contest who would be eligible to win an iPhone along with their
          contest winnings as per the Terms and Conditions outlined here, Rank
          2 and 3 of the Mega Contest would also be eligible to win iPhones
          for the two Playoffs rounds only. All users who join the Mega
          Contest for any Multi-Match Fantasy Playoffs round starting from
          Monday 22nd May until Friday 26th May (‘Promotion Period’) will be
          eligible to participate in this offer. The users who come second and
          third place in the Mega Contest of any Multi-Match Fantasy Playoffs
          Rounds during the promotion period will each be given an Imagine
          (Apple Reseller) Voucher worth Rs.80,000. They will receive the
          voucher details on their email within 3 working days from the date
          of the completion of the respective round. This offer is only
          eligible for the 2nd and 3rd rank winner of the Mega Contest for the
          Playoffs Multi-Match Fantasy Rounds (where the user would see a
          banner below the Mega Contest card saying ‘Top 3 get iPhone +
          Winnings’) from 22nd May until 26th May and not any other contest
          type within the round. It is not applicable to any private contests
          within the rounds. Only contests with the banner under the contest
          card saying ‘Top 3 get iPhone + Winnings’ would be eligible for this
          offer, and not any other contests without this banner present under
          the contest card. Only users who join the Mega Contest of the
          Playoffs Multi-Match fantasy rounds from 22nd May until 26th May
          will be eligible for this offer. If the spots in the Mega Contest
          for any round fills prior to the start of the round, no other
          contests that are available for that round will be eligible for this
          offer. Winners shall receive an email communication confirming their
          winnings and the details of the Imagine Voucher within 3 working
          days after the end of the respective round where they have been
          declared a winner. In the event that any round itself is abandoned,
          this offer will be null and void for that round. The Prize is
          subject to TDS which shall be borne by Dream11. In the event two or
          more users have the same team points at the end of the Multi-Match
          fantasy Playoffs rounds’ Mega Contest, then the following tiebreaker
          will be used. It is clarified that for the purpose of this
          Tiebreaker, the teams used to join the Mega contest only shall be
          considered at the end of round to determine the winners and not any
          other teams created. Tiebreaker Rule - The team that joined the
          contest first shall be considered the winner In the event two or
          more users have the same team points for Rank 1, amongst the two,
          the user who joined the contest first will be Rank 1 and the other
          user will be considered as Rank 2 and the user with the next highest
          team points will be considered Rank 3. Similarly if there are 2
          users who have the same team points in Rank 2 then, amongst the two,
          the user who joined the contest first shall be considered as Rank 2
          and the other user will be considered as Rank 3. Only a total of 3
          users will be given the Imagine (Apple Reseller) Voucher for any
          single Mega Contest for the Multi Match fantasy rounds during the
          promotion period, in order of highest team points for that contest
          and contest join timing as per the tiebreaker rule detailed here.
          Dream11 Deposit Bonus Offer(Dep 149 get 69) Terms and Conditions
          This "Dream11 Deposit Bonus Offer (Dep 149 get 69) ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 69 (“Bonus Amount”) in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          May 20th, 2023 till IST 18:00:00 hours on May 20th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 149 69 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          21st May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 69. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 329 get 149)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 329 get
          149) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 149
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 20th, 2023 till IST 18:00:00 hours on May 20th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 329 149 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 21st May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 149. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 1111 get 555) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 1111 get 555) ("Promotion") is open to all users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 555 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 12:00:00 hours on May 20th, 2023 till
          IST 18:00:00 hours on May 20th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 1111 555 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          21st May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 555. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 5555 get 2777)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 5555 get
          2777) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 2777
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 20th, 2023 till IST 18:00:00 hours on May 20th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 5555 2777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 21st May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 2777. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 7777 get 3777) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 7777 get 3777)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 3777 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 20th, 2023 till IST 18:00:00 hours on May 20th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 7777 3777 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 21st May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 3777. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 9999 get 4999) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 9999 get 4999)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 4999 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on May 20th, 2023 till IST 18:00:00 hours on May 20th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 9999 4999 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 21st May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 4999. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. iPhone Giveaway - for TATA IPL 2023 This iPhone giveaway is
          open to all users of Dream11 who participate in the public Mega
          Contest with a prize pool of Rs.50 crores and above for the TATA IPL
          matches: GT vs CHE (23rd May, 2023) LKN vs TBC (24th May, 2023) TBC
          vs TBC (26th May, 2023) The users who are on rank 4 to rank 53 in
          the Mega Contest of the above mentioned TATA IPL matches will each
          receive an Imagine (Apple Reseller) Voucher worth Rs.80,000. The
          details of the voucher shall be sent on the registered email id of
          the winner within 5 working days from the date of the completion of
          the match. Only the 4th rank to the 53rd rank winners of the above
          mentioned Mega Contest for the above mentioned TATA IPL matches with
          a prize pool of Rs.50 Crore and above (“Contest”) will receive the
          Prize and not any other contest within the match. If the spots in
          the Mega Contest for the above matches fill prior to the start of
          the match, no other contests that are available for that match will
          be eligible for this giveaway. The Imagine voucher shall be valid
          till April 30th, 2024. In the event that the match itself is
          abandoned, this offer will be null and void for that match. The
          giveaway is subject to TDS which shall be borne by Dream11. In the
          event two or more users have the same team points at the end of any
          TATA IPL match’s Mega Contest, then the following tiebreaker will be
          used. It is clarified that for the purpose of this Tiebreaker, the
          teams used to join the Mega contest only shall be considered at the
          end of match to determine the winners and not any other teams
          created. Tiebreaker Rule - The team that joined the contest first
          shall be considered the winner In the event more than one team have
          the same rank, the above tie-breaker rule shall apply and only 50
          teams that have participated in the Contest and have the ranks as
          mentioned above shall receive the Imagine Voucher Dream11 Deposit
          Bonus Offer(Dep 499 get 249) Terms and Conditions This "Dream11
          Deposit Bonus Offer (Dep 499 get 249)" ("Promotion") is open to all
          users of Dream11 app who see the banner via in-app communication.
          Under this Promotion, users who make at least one Qualifying Deposit
          (as defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 249 (“Bonus Amount”)., in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on May 23rd, 2023 till
          IST 23:59:00 hours on May 28th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 499 249 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          29th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 249. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 799 get 399)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 799 get
          399)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 399
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 14:00:00
          hours on May 23rd, 2023 till IST 23:59:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 799 399 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 29th May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 399. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 1199 get 599) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 1199 get 599)" ("Promotion") is open to all users
          of Dream11 app who see the banner via in-app communication. Under
          this Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 599 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 14:00:00 hours on May 23rd, 2023 till
          IST 23:59:00 hours on May 28th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 1199 599 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          29th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 599. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 1999 get 999)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 1999 get
          999)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 999
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 14:00:00
          hours on May 23rd, 2023 till IST 23:59:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1999 999 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 29th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 999. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 2499 get 1249) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 2499 get 1249)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 1249 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 14:00:00
          hours on May 23rd, 2023 till IST 23:59:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 2499 1249 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 29th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 1249. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer(Dep 4999 get 2499) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 4999 get 2499)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 2499 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 14:00:00
          hours on May 23rd, 2023 till IST 23:59:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 4999 2499 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 29th May 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is INR 2499. Please note
          that this Promotion is only available to the first 1,00,000 (One
          Lakh) users who make the Qualifying Deposit during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Playoffs Tata IPL Bonus DreamCoins and Discount Coupon
          Promotion The IPL Bonus DreamCoins Promotion ( “Promotion”) is open
          to select users of Dream11 who receive the communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, and join a paid Mega Contest
          for the following three matches: LKN vs MI - 24th May 2023 - 7:30 pm
          (Match 1) TBC v TBC - 26th May 2023 - 7: 30 pm (Match 2) TBC v TBC -
          28th May 2023 - 7:30 pm (Match 3) with at least 2 teams will receive
          500 Bonus DreamCoins or a discount coupon of 50% off up to Rs 25
          (“Discount Coupon”) (as intimated in the communication received by
          the user on the Dream11 app) for each mega contest of the above set
          of match(es) that they have joined with at least 2 teams. In case of
          Discount Coupon, the User can use the same for any other matches,
          including but not limited to IPL matches, as made available to the
          user on the Dream11 app. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Participation is only available to select registered
          users of Dream11 who receive communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here) The Promotion will
          be open for participation from IST 13:00:00 hours on May 24th, 2023
          till IST 19:29:59 hours on May 28th, 2023 ("Promotion Period"). The
          Promotion is only applicable for Paid Mega Contest(s) with a prize
          pool of more than Rs. 50Cr for each ‘Match’ listed above. Users who
          join a paid Mega Contest as detailed above, of any of the
          aforementioned Match(es), with at least 2 teams, will be eligible to
          receive the Bonus DreamCoins or Discount Coupon (as applicable)
          within 48 hours of completion of the relevant Match. The Bonus
          DreamCoins will be added to the users DreamCoins balance. Subject to
          the foregoing, the Discount Coupon will be automatically applied to
          any match the users wants to join thereafter. Bonus Dream Coins will
          be valid for 3 months from the date of credit. Bonus DreamCoins will
          not contribute to the Level progress for the user. Bonus DreamCoins
          will be given over and above the DreamCoins earned based on joining
          the contest. Bonus DreamCoins terms and conditions apply. In the
          event that the match is abandoned, this offer will be null and void
          for that particular Match. Answer & Win LKN vs MI: 50% up to Rs.25
          The Answer & Win Promotion ( “Promotion”) is open to select users of
          Dream11 who receive the In App communication on the Dream11 mobile
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, will receive a question to be
          answered. If the user answers the question correctly, the user will
          receive a discount coupon of 50% off up to Rs.25 ( “Discount
          Coupon”). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the In App communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here) Coupon Details:
          The Promotion will be open for participation from IST 19:30:00 hours
          on May 24th, 2023 till IST 20:30:00 hours on May 24th, 2023
          ("Promotion Period"). Users will receive a Discount Coupon of 50%
          OFF up to Rs.25 upon answering the question correctly. Discount
          coupons will be given to the user by IST 11:00:00 hours on May 25th,
          2023. Discount coupons are valid only on paid public contests taking
          place on 25th May 2023 till 11:59:59 PM on the same day. Range
          Deposit Offers 2 Terms and Conditions This "Range Deposit Offers "
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one cash deposit within the Qualifying Range (as defined
          below) into their Dream11 unutilised account during the Promotion
          Period (defined below) will be eligible to receive a cash bonus
          amount depending on the amount deposited by the User and as
          mentioned in table below (“Bonus Amount”), in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here). The Promotion will be open for participation from
          Indian Standard Time ("IST") 18:00:00 hours on May 25th, 2023 till
          IST 19:30:00 hours on May 28th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit which is more than equal to Min. Deposit and less than equal
          to the Max Deposit as specified in the table below (each a
          "Qualifying Range") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the
          corresponding Bonus Amount as per the table: Min Deposit (INR) Max
          Deposit(INR) Bonus Amount 50 250 25% of the deposit amount 100 500
          25% of the deposit amount 200 750 20% of the deposit amount 300 1000
          15% of the deposit amount 750 2500 10% of the deposit amount 2500
          7500 10% of the deposit amount 10000 25000 5% of the deposit amount
          Please note that only the amount deposited within the each of the
          Qualifying Range by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before, during or after the
          Promotion Period. Please note that the Bonus Amount to be given to
          the user, will depend on the pre specied percentage mentioned in the
          table above and the value of the amount deposited by the user during
          the Promotion Period. Please note that the above table provides for
          7 (seven) Qualifying Range and the user may choose to deposit any
          amount within each of the Qualifying Range to be eligile to receive
          the Bonus Amount. There is no maximum limit on the number of times a
          user can make a deposit within each of the Qualifying Range and
          during the Promotion Period. However, the Bonus Amount corresponding
          to each Qualifying Range shall be credited only once for each
          Qualifying Range. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Cash Bonus Account within
          48 hours of deposit. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who deposit an
          amount within the Qualifying Range during the Promotion Period. For
          these Terms, the first 1,00,000 (One Lakh) users shall be calculated
          based on the time of deposit within each of the Qualifying Range
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the amount
          deposited within each of the Qualifying Range. Range Deposit Offers
          Terms and Conditions This "Range Deposit Offers 2" ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          cash deposit within the Qualifying Range (as defined below) into
          their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          depending on the amount deposited by the User and as mentioned in
          table below (“Bonus Amount”), in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests and
          have received in-app banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here). The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 18:00:00 hours on May 25th, 2023 till IST 19:30:00 hours on
          May 28th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit which is more
          than equal to Min. Deposit and less than equal to the Max Deposit as
          specified in the table below (each a "Qualifying Range") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the corresponding Bonus Amount as per the table:
          Min Deposit (INR) Max Deposit(INR) Bonus Amount 50 250 40% of the
          deposit amount 100 500 40% of the deposit amount 200 750 25% of the
          deposit amount 300 1000 15% of the deposit amount 750 2500 10% of
          the deposit amount 2500 7500 10% of the deposit amount 10000 25000
          5% of the deposit amount Please note that only the amount deposited
          within the each of the Qualifying Range by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before,
          during or after the Promotion Period. Please note that the Bonus
          Amount to be given to the user, will depend on the pre specied
          percentage mentioned in the table above and the value of the amount
          deposited by the user during the Promotion Period. Please note that
          the above table provides for 7 (seven) Qualifying Range and the user
          may choose to deposit any amount within each of the Qualifying Range
          to be eligile to receive the Bonus Amount. There is no maximum limit
          on the number of times a user can make a deposit within each of the
          Qualifying Range and during the Promotion Period. However, the Bonus
          Amount corresponding to each Qualifying Range shall be credited only
          once for each Qualifying Range. Subject to users’ compliance with
          these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account within 48 hours of deposit. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who deposit an amount within the Qualifying Range during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of deposit within each
          of the Qualifying Range during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the amount deposited within each of the Qualifying
          Range. Dream11 Deposit Bonus Offer(Dep 25 get 50) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 25 get 50)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 50 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 18:00:00
          hours on May 25th, 2023 till IST 19:30:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 25 50 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 29th May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 50. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. OUT NOT OUT The Out Not Out
          Promotion ( “Promotion”) is open to select users of Dream11 who
          receive the communication on the Dream11 mobile application. Under
          this Promotion, users who receive the communication on the Dream11
          app, will receive a total of 3 questions to be answered on a static
          page. If the user: 1. Answers one question of the 3 correctly, the
          user will receive a discount coupon 25% off up to Rs 50; 2. Answers
          two questions out of three correctly, the user will receive a
          discount coupon 50% off up to Rs. 50; 3. Answers all three questions
          correctly, the user will receive a discount coupon 75% up to Rs 50
          4. Answers no question correctly, the user will receive no discount
          coupon. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the in App communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here) The Promotion will
          be applicable only once per user during the Promotion Period. For
          avoidance of doubt, if a user is found to have participated in the
          Promotion through both the APK and Play Store versions, the system
          will identify the user as a single entity and only consider their
          response once Coupon Details: The Promotion will be open for
          participation from IST 13:00:00 hours on May 27th, 2023 till IST
          19:00:00 hours on May 29th, 2023 ("Promotion Period"). Users will
          receive a Discount Coupon depending on the number of questions the
          user answers correctly as stated above. Discount coupons will be
          given to the user within 24 hours of the end time of participation.
          Discount coupons are valid only on paid public contests of FanCode
          ECS Bulgaria T10 League till 2nd June 2023, 17:59:59 PM. Referral
          Discount Hours This "Referral Discount Hours" ("Promotion") is open
          to users of Dream11 app who receive the banner via in-app
          communication. Under this campaign users can refer their friends
          during the campaign duration to receive additional discounts &
          aspirational rewards. Only those friends who was referred by the
          user and who register and join at least one public cash contest
          would qualify as a referral under this campaign. Based on the number
          of referrals done by a user during the Promotion Period (defined
          below), the user shall receive Discount Coupons subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 15:00:00
          hours to 20:00:00 hours everyday from May 26th, 2023 to May 28th,
          2023 ("Promotion Period") Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who
          receive the banner in app communication and are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Users who participate in the
          Promotion and refer new users to Dream11 during the Promotion
          Period, shall be eligible to receive Discount Coupons, the value of
          which will be 50% OFF up to Rs.25 per friend that joins a paid
          public contest during the Promotion Period. Additionally, 1 user
          will be eligible to win an iPhone at the end of the campaign. The
          iPhone reward winners will be declared on May 30th, 2023. The iPhone
          reward winners will be sent an Imagine (Apple Reseller) Voucher
          worth Rs.80,000 redeemable towards the purchase of an iPhone. The
          iPhone reward Winners shall receive an email communication
          confirming their winnings and the details of the Imagine Voucher
          within 2 working days post declaration of winners. The Voucher is
          non-transferrable and cannot be exchanged for cash or any other
          products and/or services. The Voucher is subject to the terms and
          conditions set forth by the Imagine Store. The Voucher is subject to
          TDS which shall be borne by Dream11. The Voucher is subject to 30%
          as TDS deduction. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by IST 23:00:00
          on a daily basis during the Promotion Period depending on the number
          of successful referrals completed as stated herein. The Discount
          Coupon shall be valid for 5 Days from the date of credit. The
          Discount Coupon given under this Promotion is in addition to the
          existing referral rewards given to the users. Dream11 reserves the
          right to cancel this Promotion at any time in its sole discretion
          without assigning any reason thereto. The discount Coupon will only
          be applicable for one Public Contest. Dream11 Next Mega Free This
          “Dream11 Next mega Free” (“Promotion”) is only available for users
          who receive communication of the Promotion in their Dream11 app via
          a banner or push notification. Through this Promotion, users who
          receive the communication and successfully deposit any of the below
          mentioned amount within the Promotion Period (defined below) will be
          eligible to receive a discount as per below construct: Amount
          deposited Discount Offer Add exactly INR 51 1 mega contest free
          (100% off upto INR 49) Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Promotion Period - The Promotion will be open from Indian
          Standard Time ("IST") 12:00:00 hours from May 26th, 2023 till IST
          23:59:59 hours on May 28th, 2023 ("Promotion Period") Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app and successfully deposited the
          specified amount as mentioned above within the Promotional Period.
          By participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) Discount
          offer will be activated within 4 hours after a user has added the
          exact amount mentioned above. A maximum of 1 Discount Offer can be
          earned by users by depositing the above mentioned amounts. Discount
          Offers earned by the user will be one-time use only and will be
          auto-applied for the next available mega contests with a max entry
          fee of INR 49. Discount Offer shall be valid for 7 days from the
          date of credit to the users account. Subject to your compliance with
          these Terms, the Discount Offer will be visible in the eligible
          users’ My Rewards section in the Rewards shop, accessible via the
          bottom navigation bar In case match is abandoned, the value of the
          Discount Offer will be added as Cash bonus to user’s account Once
          the Coupons utilized in part or whole, the user cannot claim any
          refund of the Qualifying Deposit. iPhone Giveaway - for TATA IPL
          2023 This iPhone giveaway is open to all users of Dream11 who
          participate in the public Mega Contest with a prize pool of Rs.70
          crores and above for the following TATA IPL match: CHE vs TBC (28th
          May, 2023) The users who are on rank 4 to rank 53 in the public Mega
          Contest of the above mentioned TATA IPL match will each receive an
          Imagine (Apple Reseller) Voucher worth Rs.80,000. The details of the
          voucher shall be sent on the registered email id(s) of the winner(s)
          within 5 working days from the date of the completion of the match.
          Only the 4th rank to the 53rd rank winners of the above mentioned
          public Mega Contest for the above mentioned TATA IPL match with a
          prize pool of Rs.70 Crore and above (“Contest”) will receive the
          Prize and not any other contest within the match. If the spots in
          the public Mega Contest for the above match fill prior to the start
          of the match, no other contests that are available for that match
          will be eligible for this giveaway. The Imagine voucher shall be
          valid till April 30th, 2024. In the event that the match itself is
          abandoned, this offer will be null and void for that match. The
          giveaway is subject to TDS which shall be borne by Dream11. In the
          event two or more users have the same team points at the end of any
          TATA IPL match’s Mega Contest, then the following tiebreaker will be
          used. It is clarified that for the purpose of this Tiebreaker, the
          teams used to join the public Mega contest only shall be considered
          at the end of the match to determine the winners and not any other
          teams created. Tiebreaker Rule - The team that joined the contest
          first shall be considered the winner In the event more than one team
          have the same rank, the above tie-breaker rule shall apply and only
          50 teams that have participated in the Contest and have the ranks as
          mentioned above shall receive the Imagine Voucher Multi-Tour Pass
          The Multi-Tour Pass promotion (”Promotion”) is only open to select
          users of the Dream11 app who are able to view the specific reward of
          50% OFF up to Rs 100 (“Discount Coupon") on their Rewards page on
          the Dream11 app. The users who can view the Discount Coupon on the
          Dream11 app can only avail this reward by burning the requisite
          amount of Dream Coins as displayed on the Rewards Page for this
          particular Discount Coupon. The Discount Coupon will allow the user
          to avail a 50% OFF up to Rs.100 in any four matches for any of the
          following tours, with an overall maximum avail amount limit of Rs.
          25 per match. The Promotion is only applicable for the following
          tours - Vitality Blast, FanCode ECS Bulgaria, Pondicherry T10, and
          Nature Isle T10 (“Tours”). For sake of clarity, the user will not be
          able to utilise the entire Discount Coupon amount of Rs. 100 all at
          once for a single match in any of the Tours detailed above. The
          Promotion will be open for participation from IST 21:00 on May 26th,
          2023 till IST 23:59:00 hours on June 16th, 2023 (“Promotion
          Period”). The Promotion will be applicable only once per user during
          the Promotion Period. The Discount Coupon availed as part of the
          Promotion cannot be clubbed together with any other promotions that
          are running simultaneously on the Dream11 platform. Also, this
          Discount Coupon cannot be clubbed with a Cash Bonus available to any
          user. The Discount Coupon will not be applicable on Private
          Contests. The Discount Coupon are valid only on paid public contests
          of the Tours. In the event that a contest in which a user has used
          this Discount Coupon gets cancelled or the match itself gets
          abandoned, the Discount Coupon amount used in that contest or match
          by the user will be refunded to that user as Cash Bonus in their
          Dream11 wallet. Dream11 Deposit Bonus Offer(Dep 39 get 19) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 39 get 19)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 19 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 17:00:00
          hours on May 28th, 2023 till IST 19:00:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 39 19 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 29th May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 19. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 5555 get 1999) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 5555 get 1999)" ("Promotion") is open to all users
          of Dream11 app who see the banner via in-app communication. Under
          this Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 1999 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 15:00:00 hours on May 28th, 2023 till
          IST 17:00:00 hours on May 28th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 5555 1999 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          29th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 1999. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 777 get 333)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 777 get
          333)" ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 333
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 15:00:00
          hours on May 28th, 2023 till IST 17:00:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 777 333 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 29th May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 333. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 249 get 111) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 249 get 111)" ("Promotion") is open to all users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 111 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 15:00:00 hours on May 28th, 2023 till
          IST 17:00:00 hours on May 28th, 2023 ("Promotion Period"). To
          participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 249 111 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          29th May 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 111. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 99 get 39) Terms
          and Conditions This "Dream11 Deposit Bonus Offer (Dep 99 get 39)"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 39 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 17:00:00
          hours on May 28th, 2023 till IST 19:00:00 hours on May 28th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 99 39 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 29th May 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 39. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Answer & Win CHE vs GT (29th
          May): 50% up to Rs.25 The Answer & Win Promotion ( “Promotion”) is
          open to select users of Dream11 who receive the In App communication
          on the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, will receive a
          question to be answered. If the user answers the question correctly,
          the user will receive a discount coupon of 50% off up to Rs.25 (
          “Discount Coupon”). Please note our terms and conditions ("Terms")
          in this respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the In App communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). Coupon Details:
          The Promotion will be open for participation for the eligible users
          (as detailed above), one hour post the start of the CHE vs GT match,
          scheduled to take place on 29th May, 2023. Users will receive a
          Discount Coupon of 50% OFF up to Rs.25 upon answering the question
          correctly. Discount coupons will be given to the user by IST
          11:00:00 hours on May 30th, 2023. Discount coupons are valid only on
          paid public contests taking place from IST 11:00:00 hours on 30th
          May 2023, till IST 23:59:59 hours on 1st June, 2023. For avoidance
          of doubt, if a user is found to have participated in the Promotion
          through both the APK and Play Store versions, the system will
          identify the user as a single entity and only consider their
          response once. In the event that the CHE vs GT match is abandoned,
          this promotion will be null and void. Tata IPL Final Discount Coupon
          Promotion The IPL Discount Coupon Promotion ( “Promotion”) is open
          to select users of Dream11 who receive the communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, and join the 25 Cr Paid Mega
          Contest for the CHE vs GT (“Match”) with at least 2 teams will
          receive discount coupon of 50% off up to Rs 25 (“Discount Coupon”)
          (as intimated in the communication received by the user on the
          Dream11 app) applicable on any of the following tours: Vitality
          Blast FanCode ECS Bulgaria Pondicherry T10 Nature Isle T10 The User
          can use the Discount Coupon for any matches for any of the above
          tours, as made available to the user on the Dream11 app. Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Participation is only available
          to select registered users of Dream11 who receive communication on
          the Dream11 mobile application and are eligible to participate in
          the pay to play contests as per Dream11's terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible
          here). The Promotion will be open for participation from IST
          14:00:00 hours on May 29th, 2023 till IST 17:00:00 hours on May
          29th, 2023 ("Promotion Period"). Users who join the 25 Cr Paid Mega
          Contest for the Match, with at least 2 teams, will be eligible to
          receive the Discount Coupon (as applicable) within 48 hours of
          completion of the relevant Match. Subject to the foregoing, the
          Discount Coupon will be automatically applied to any match the users
          wants to join thereafter. In the event that the match is abandoned,
          this offer will be null and void. iPhone Giveaway - for Tamil Nadu
          Premier League T20 This iPhone giveaway is open to all users of
          Dream11 who participate in the public Mega Contest of Tamil Nadu
          Premier League T20 which mentions iPhone’s as a giveaway. The
          following Tamil Nadu Premier League T20 matches are covered under
          this promotion: CSG vs DD (21st June, 2023) BT vs LKK (21st June,
          2023) NRK vs SS (22nd June, 2023) NRK vs CSG (24th June, 2023) SS vs
          SMP (24th June, 2023) DD vs LKK (25th June, 2023) ITT vs BT (25th
          June, 2023) CSG vs SMP (26th June, 2023) SS vs LKK (27th June, 2023)
          ITT vs DD (28th June, 2023) The users who are on rank 2 to rank 6 in
          the public Mega Contest of the above mentioned Tamil Nadu Premier
          League T20 matches will each receive an Imagine (Apple Reseller)
          Voucher worth Rs.80,000. The details of the voucher shall be sent on
          the registered email id of the winner(s) within 5 working days from
          the date of the completion of the match. Only the 2nd rank to the
          6th rank winners of the above mentioned public Mega Contest for the
          above mentioned Tamil Nadu Premier League T20 matches where it is
          mentioned that iPhone’s as a giveaway are applicable (“Contest”)
          will receive the Prize and not any other contest within the match.
          If the spots in the public Mega Contest for the above matches fill
          prior to the start of the match, no other contests that are
          available for that match will be eligible for this giveaway. The
          Imagine voucher shall be valid till May 30th, 2024. In the event
          that the match itself is abandoned, this offer will be null and void
          for that match. The giveaway is subject to TDS which shall be borne
          by Dream11. In the event two or more users have the same team points
          at the end of any of the above mentioned Tamil Nadu Premier League
          T20 matches Mega Contest, then the following tiebreaker will be
          used. It is clarified that for the purpose of this Tiebreaker, the
          teams used to join the public Mega contest only shall be considered
          at the end of the match to determine the winners and not any other
          teams created. Tiebreaker Rule - The team that joined the contest
          first shall be considered the winner In the event more than one team
          have the same rank, the above tie-breaker rule shall apply and only
          5 teams that have participated in the Contest and have the ranks as
          mentioned above shall receive the Imagine Voucher Triple Treat
          Campaign: The Triple Treat Campaign (“Promotion”) is open to select
          users of Dream11 who receive the banner and push notification
          communication on the Dream11 mobile application. Under this
          Promotion, users who receive the communication can participate by
          playing any public paid contest above Rs.25 of the tours mentioned
          below (“Tour”)and receive discounts to the extent mentioned below
          for each Tour played during the Promotion Period (defined below).
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who receive the
          banner and push communication on the Dream11 mobile application and
          are eligible to participate in the public pay-to-play contests above
          Rs. 25 as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). The Promotion
          will be open for participation from IST 12:00:00 hours on June 23rd,
          2023 till IST 23:59:59 hours on June 29th, 2023 ("Promotion
          Period"). Users must participate in any public paid contest with an
          entry fee above Rs.25 in the tours mentioned below to receive their
          discounts. ELIGIBILITY REWARDS Play a paid public contest in any one
          Tour Get 25% OFF up to Rs.25 Play a public paid contest in any two
          Tours Get 50% OFF up to Rs.25 Play a public paid contest in any
          three Tours Get 75% OFF up to Rs.25 During the Promotion Period, the
          user must participate in the following Tour(s) to be eligible to
          receive the aforementioned discounts: FanCode ECS Romania T10 KCC
          T10 Challengers League Tamil Nadu Premier League T20 Users will
          receive the discounts for every Tour they play during the Promotion
          Period of the Promotion up to a maximum of 3 discounts. The discount
          will be credited to the qualifying users’ Dream11 account every day
          by 13:00:00 IST during the Promotion Period. The discount will
          expire in seven (7) days from the date it's credited to the user’s
          Dream11 account. If the user plays a Tour(s) on the last day of the
          Promotion Period ie. June 29th, 2023, the discount will be credited
          to the user’s Dream11 account on June 30th, 2023 and will be valid
          until July 6th, 2023 23:59:59 IST. The discount will be valid on
          FanCode ECS Romania T10, KCC T10 Challengers League & Tamil Nadu
          Premier League T20 tours only. In an event the Match is abandoned
          and the entry fee for such a contest is returned to the user,, this
          offer will be null and void for that particular contest/match and
          the user will not be eligible to receive the discount. OUT NOT OUT -
          TNPL The Out Not Out Promotion ( “Promotion”) is open to select
          users of Dream11 who receive the communication on the Dream11 mobile
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, will receive a total of 3
          questions to be answered on a static page. If the user: Answers one
          question of the 3 correctly, the user will receive a discount coupon
          25% off up to Rs 25; Answers two questions out of three correctly,
          the user will receive a discount coupon 50% off up to Rs. 25;
          Answers all three questions correctly, the user will receive a
          discount coupon 75% up to Rs 25 Answers no question correctly, the
          user will receive no discount coupon. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive the in App
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here). The Promotion will be available from IST 20:00:00
          hours on June 30th, 2023 till IST 23:59:59 hours on July 3rd 2023
          Each user will be allowed to participate in the Promotion only once.
          Coupon Details: The Promotion will be open for participation from
          IST 20:00:00 hours on June 30th, 2023 till IST 23:59:59 hours on
          July 3rd 2023 ("Promotion Period"). Users will receive a Discount
          Coupon depending on the number of questions the user answers
          correctly as stated above. Discount coupons will be given to the
          user within 24 hours of the end time of Promotion participation.
          Discount coupons are valid only on paid public contests of Tamil
          Nadu Premier League T20 till 12th July 2023, 19:14:59. Quiz on
          Leaderboard (Master Stroke) TnCs The Quiz on Leaderboard Promotion
          (‘Master Stroke’’) is open to select users of Dream11 who receive
          the communication on the Dream11 mobile application. Under this
          campaign, users who see a ‘Quiz’ Banner on Live match Leaderboard
          along with Push and App Inbox Notifications for selected contests
          will be eligible for participating in the campaign. Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive communication on the
          Dream11 mobile application regarding the promotion and see the entry
          point on the Live Match Mega Contest Leaderboard of the matches
          mentioned below Quiz will be applicable for certain matches of
          India’s tour of West Indies The Quiz will be open for participation
          from the match start time of the above mentioned matches till the
          match ends If a user chooses different options using different
          devices or apps, the answer selected first will only be considered
          The eligible users will get 20 Bonus Dream Coins for every right
          answer and shall will be credited within 48 hours after the end of
          the match The Bonus DreamCoins will be added to the users DreamCoins
          balance. Bonus Dream Coins will be valid for 1 year from the date of
          receiving them. Bonus DreamCoins will not contribute to the
          Champions Club Level progress for the user. Bonus DreamCoins will be
          given over and above the DreamCoins earned based on joining the
          contest. Bonus DreamCoins terms and conditions apply (accessible
          here). Amazon Pay Gift Card Giveaway - for Women’s Football World
          Cup This Amazon Pay Gift Card giveaway is open to all users of
          Dream11 who participate in the public Mega Contest with a prize pool
          of Rs.8 lakh and above for the following Women’s Football World Cup
          matches: NZ-W vs NOR-W (20th July, 2023) AUS-W vs IRE-W (20th July,
          2023) NIG-W vs CAN-W (21st July, 2023) PHI-W vs SUI-W (21st July,
          2023) SPA-W vs CRC-W (21st July, 2023) USA-W vs VIE-W (22nd July,
          2023) ZAM-W vs JPN-W (22nd July, 2023) ENG-W vs HAI-W (22nd July,
          2023) The users who are on rank 2 to rank 6 in the public Mega
          Contest of the above mentioned Women’s Football World Cup matches
          will each receive an Amazon Pay Gift Card (“Gift Card”) in
          accordance with the table below. The details of the Gift Card shall
          be sent on the registered email id(s) of the winner(s) within 5
          working days from the date of the completion of the matches. Rank
          Amazon Pay Gift Card Value (INR) 2 10,000(*2) 3 10,000 4 10,000 5
          5,000 6 5,000 Only the 2nd rank to the 6th rank winners of the above
          mentioned public Mega Contest for the above mentioned Women’s
          Football World Cup matches with a prize pool of Rs.8 lakh and above
          (“Contest”) will receive the Prize and not any other contest within
          the match. If the spots in the public Mega Contest for the above
          matches fill prior to the start of the match, no other contests that
          are available for that match will be eligible for this giveaway. The
          Gift Card shall be valid till July 19th, 2024. In the event that the
          match itself is abandoned, this offer will be null and void for that
          match. The giveaway is subject to TDS which shall be borne by
          Dream11. In the event two or more users have the same team points at
          the end of any Women’s Football World Cup match’s Mega Contest, then
          the following tiebreaker will be used. It is clarified that for the
          purpose of this Tiebreaker, the teams used to join the public Mega
          contest only shall be considered at the end of the match to
          determine the winners and not any other teams created. Tiebreaker
          Rule - The team that joined the contest first shall be considered
          the winner In the event more than one team have the same rank, the
          above tie-breaker rule shall apply and only 5 teams that have
          participated in the Contest and have the ranks as mentioned above
          shall receive the Gift Card. The redemption of the Gift Cards and
          resolution of all complaints in relation to the redemption of the
          Gift Card shall be governed by the terms and conditions of Amazon as
          outlined here. Dream11 Deposit Bonus Offer(Dep 99 get 49 CB) Terms
          and Conditions - 20th July This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to select users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited by the user into
          their Dream11 unutilised deposit account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 12:00:00 hours on July 20th, 2023 till IST 21:00:00
          hours on July 20th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit of exactly the
          amount specified in the table below ("Qualifying Deposit") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the Bonus Amount corresponding to the Qualifying
          Deposit, in accordance with the table below: Qualifying Deposit
          (INR) Bonus Amount (INR) 99 49 Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit amount of the above-listed values. Subject to users’
          compliance with these Terms, the Bonus Amount will be credited into
          users’ Cash Bonus Account by IST 23:59:59 hours on July 21st, 2023.
          The maximum Bonus Amount that can be credited to users’ Cash Bonus
          Account pursuant to this Promotion is INR 49. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who make the Qualifying Deposit of INR 49 during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Free Entry Contest This “Dream11 Free Entry” (“Promotion”) is only
          available for users who receive communication of the Promotion in
          their Dream11 app via a banner or push notification. Through this
          Promotion, users who receive the communication will receive a 100%
          Discount Coupon upto INR 49 (Free Entry) to the Mega contest OR a
          special paid contest for the following matches: 27/07/23 - IND vs WI
          (1st ODI) 29/07/23 - IND vs WI (2nd ODI) 03/08/23 - IND vs WI (1st
          T20I) 06/08/23 - IND vs WI (2nd T20I) Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open from Indian Standard Time ("IST") 19:00:00 hours from
          July 26th, 2023 Eligibility - Participation in this Promotion is
          available to only those users who have received the relevant
          communication of the Promotion from Dream11 on their Dream11 app and
          successfully deposited the specified amount as mentioned above
          within the Promotional Period. By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Discount Offers will be applicable
          only for the above mentioned matches and just once per user. Subject
          to your compliance with these Terms, the Discount Offer will be
          visible in the eligible users’ My Rewards section in the Rewards
          shop, accessible via the bottom navigation bar In case match is
          abandoned, the value of the Discount Offer will be added as Cash
          bonus to user’s account iPhone Giveaway - for Multi-Match Fantasy
          Rounds This offer is open to all users who have access to the
          Multi-Match Fantasy product only. All users who join the Mega
          Contest, which have the contest band saying ‘Rank 1 wins iPhone +
          Winnings’, for select Multi-Match Fantasy rounds only (‘Eligible
          Rounds’) starting from Tuesday, 1st August until Sunday 27th August
          (‘Promotion Period’) will be eligible to participate in this offer.
          The user who comes first place in the Mega Contest of the Eligible
          Rounds during the Promotion Period will be given an Imagine (Apple
          Reseller) Voucher worth Rs.80,000. They will receive the voucher
          details on their email within 3 working days from the date of the
          completion of the respective round. This offer is only eligible for
          the 1st rank winner of the Mega Contest for the Eligible Round
          (where the user would see a banner below the Mega Contest card
          saying ‘Rank 1 wins iPhone+ Winnings’) from 1st August until 27th
          August and not any other contest type within the round. It is not
          applicable to any private contests within the rounds. Only contests
          with the banner under the contest card saying ‘Rank 1 wins iPhone +
          Winnings’ would be eligible for this offer, and not any other
          contests without this banner present under the contest card. If the
          spots in the Mega Contest for any round fills prior to the start of
          the round, no other contests that are available for that round will
          be eligible for this offer. Winners shall receive an email
          communication confirming their winnings and the details of the
          Imagine Voucher within 3 working days after the end of the
          respective round where they have been declared a winner. The Gift
          Card shall be valid till December 31st, 2023. In the event that any
          round itself is abandoned, this offer will be null and void for that
          round. The Prize is subject to TDS which shall be borne by Dream11.
          In the event two or more users have the same team points at the end
          of any Multi-Match fantasy round’s Mega Contest, then the following
          tiebreaker will be used. It is clarified that for the purpose of
          this Tiebreaker, the teams used to join the Mega contest only shall
          be considered at the end of round to determine the winners and not
          any other teams created. Tiebreaker Rule - The team that joined the
          contest first shall be considered the winner Dream Coins Contest
          This “Dream Coins Contest” (“Promotion”) is only available for users
          who receive communication of the Promotion in their Dream11 app via
          a banner or push notification. Through this offer, users who receive
          the communication will be eligible to join a Practice Contest of any
          of the Eligible Games detailed below where users receive DC on
          winnings in the following amount: DC on Free Contest Rank Start Rank
          End No. of people DC Given Total DC (No. of people * DC Given) 1 1 1
          2000 2000 2 10 9 1000 9000 11 50 40 500 20000 51 2000 1950 200
          390000 Eligible Games: 29/07/23 - IND vs WI (2nd ODI) 01/08/23 - IND
          vs WI (3rd ODI) 08/08/23 - IND vs WI (3rd T20I) 12/08/23 - IND vs WI
          (4th T20I) Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Promotion Period - The Promotion will be open from Indian Standard
          Time ("IST") 19:00:00 hours from July 28th, 2023 Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app.By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) This offer is valid only once per user & the Dream
          Coins will be credited within 48 hours of winner declaration. In
          case any of the Eligible Match(es) are abandoned, no Dream Coins
          will be disbursed to the users. The Dream Coins once credited will
          have a validity of 7 Days and can be redeemed on the Reward Shop.
          Bonus DreamCoins received in furtherance of this Promotion will not
          contribute to the Champions Club Level progress for the user. In the
          event of a tie: the total Dream Coins earmarked for the tied teams
          will be combined, and will be equally divided among the tied
          teams/users. The formula for calculating each user's share in such a
          scenario shall be as follows: Amount per user/winner = Total Dream
          Coins ÷ Number of Tied Teams Dream11 Deposit Bonus Offer(Dep 99 get
          49 CB) Terms and Conditions - 29th July This "Dream11 Deposit Bonus
          Offer" ("Promotion") is open to select users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited by the user into
          their Dream11 unutilised deposit account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 15:00:00 hours on July 29th, 2023 till IST 21:00:00
          hours on July 29th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit of exactly the
          amount specified in the table below ("Qualifying Deposit") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the Bonus Amount corresponding to the Qualifying
          Deposit, in accordance with the table below: Qualifying Deposit
          (INR) Bonus Amount (INR) 99 49 Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit amount of the above-listed values. Subject to users’
          compliance with these Terms, the Bonus Amount will be credited into
          users’ Cash Bonus Account by IST 23:59:59 hours on July 30th, 2023.
          The maximum Bonus Amount that can be credited to users’ Cash Bonus
          Account pursuant to this Promotion is INR 49. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who make the Qualifying Deposit of INR 49 during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          Football Fever - Women's Football World Cup The Football Fever -
          Campaign for Women's World Cup (“Promotion”) is open to select users
          of Dream11 who receive the banner and push notification
          communication on the Dream11 mobile application. Under this
          Promotion, users who receive the communication can participate by
          playing any public paid contest of any match of the Women’s Football
          World Cup Tour (“Tour”) and receive discounts to the extent
          mentioned below for each match played during the Promotion Period
          (defined below). Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the banner and push communication on the Dream11
          mobile application and are eligible to participate in the public
          pay-to-play as per Dream11's terms and conditions (accessible here).
          By participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). The Promotion
          will be open for participation from IST 13:00:00 hours on August
          3rd, 2023 till IST 23:59:59 hours on August 7th, 2023 ("Promotion
          Period"). Users must participate in any public paid contest with an
          entry fee above Rs.25 in the matches of the Women’s Football World
          Cup to receive their discounts. The discounts given out will be as
          follows: ELIGIBILITY REWARDS Play 1 paid public contest Get 50% OFF
          up to Rs.15 Play 3 paid public contest Get 50% OFF up to Rs.25 Play
          5 paid public contest Get 50% OFF up to Rs.50 It is clarified that
          to receive the above mentioned discounts, the user is required to
          join paid public contests of different matches of Women’s Football
          World Cup paid public contests and not multiple paid public contests
          in the same match. During the Promotion Period, the user must
          participate in the Women’s Football World Cup paid public contest to
          be eligible to receive the aforementioned discounts Users will
          receive the discounts for matches they play during the Promotion
          Period of the Promotion as per the table shown above. The discount
          will be credited to the qualifying users’ Dream11 account every day
          within 24 hours of the match completion. The discount will only be
          applicable on theWomen’s Football World Cup tour. The discount can
          only be used for paid public contests above INR 25 for the Women's
          Football World Cup. In an event the Match is abandoned and the entry
          fee for such a contest is returned to the user, this offer will be
          null and void for that particular contest/match and the user will
          not be eligible to receive the discount. Dhamakedar discounts terms
          and Conditions The “Dhamakedar discounts” ( “Promotion”) is open to
          select users of Dream11 who receive communication from the Dream11
          application. Under this Promotion, users who receive the
          communication on the Dream11 app, will be required to achieve total
          Entry Fee target(excluding Entry fee from private contests) given to
          them till the time communicated on the app. It is being amply
          clarified that the entry fee used to join private contests shall not
          be considered for this Promotion. The user will receive a flat
          discount coupon communicated on the app upon achieving the target
          mentioned before. The amount and % of the discount coupon will be
          communicated to the user through in app communication. They can
          receive a 10%,25% or a 50% promotion as communicated in the app.If
          the user achieves the target in the given time, they would receive
          the promised discount coupon within 24 hrs of target completion.
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the promotion: Participation is
          only available to select registered users of Dream11 who receive the
          In App communication on the Dream11 mobile application and are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). These promotions will be
          valid from 11th January 2024 to 31st December 2024 By participating
          in this Promotion, you agree to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of Dream11 (accessible here). In the event that a contest
          in which a user has used this discount coupon gets canceled or the
          match itself gets abandoned, the discount amount used in that
          contest or match will be refunded to that user as Cash Bonus. Coupon
          Details: Discount coupons will be given to the user within 24 hours
          of Entry fee target completion. Discount coupons are valid only on
          paid public contests and will be valid only for 7 days from the time
          they are given. Dream11 Deposit Bonus Offer(Dep 49 get 29 CB) Terms
          and Conditions - 12th & 13th Aug This "Dream11 Deposit Bonus Offer"
          ("Promotion") is open to select users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited by the user into
          their Dream11 unutilised deposit account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible here)
          and have received banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 15:00:00
          hours on Aug 12th, 2023 till IST 21:00:00 hours on Aug 13th, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount corresponding to the Qualifying Deposit, in
          accordance with the table below: Qualifying Deposit (INR) Bonus
          Amount (INR) 49 29 Please note that only the Qualifying Deposit made
          by a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit amount of the
          above-listed values. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          IST 23:59:59 hours on Aug 14th, 2023. The maximum Bonus Amount that
          can be credited to users’ Cash Bonus Account pursuant to this
          Promotion is INR 29. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the deposit amount. Dream11 Deposit Bonus Offer(Dep 76
          get 47) Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep
          76 get 47) ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 47
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 12:00:00
          hours on August 15th, 2023 till IST 16:00:00 hours on August 15th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 76 47 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 16th Aug 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 47. Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus
          Offer(Dep 194 get 76) Terms and Conditions This "Dream11 Deposit
          Bonus Offer (Dep 194 get 76) ("Promotion") is open to all users of
          Dream11 app who see the banner via in-app communication. Under this
          Promotion, users who make at least one Qualifying Deposit (as
          defined below) into their Dream11 unutilised account during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount of INR 76 (“Bonus Amount”) in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 16:00:00 hours on August 15th, 2023
          till IST 18:00:00 hours on August 15th, 2023 ("Promotion Period").
          To participate in the Promotion, user must make at least one cash
          deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount (INR) 194 76 Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 unutilised account before or
          after the Promotion Period. There is no maximum limit on the number
          of Qualifying Deposits made by a user during the Promotion Period.
          However, the Bonus Amount shall be credited only once for the first
          Qualifying Deposit. Subject to users’ compliance with these Terms,
          the Bonus Amount will be credited into users’ Cash Bonus Account by
          16th Aug 2023 23:59:00 Hours. The maximum Bonus Amount that can be
          credited to users’ Cash Bonus Account pursuant to this Promotion is
          INR 76. Please note that this Promotion is only available to the
          first 1,00,000 (One Lakh) users who make the Qualifying Deposit
          during the Promotion Period. For these Terms, the first 1,00,000
          (One Lakh) users shall be calculated based on the time of Qualifying
          Deposit during the Promotion Period. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          Qualifying Deposit. Dream11 Deposit Bonus Offer(Dep 470 get 194)
          Terms and Conditions This "Dream11 Deposit Bonus Offer (Dep 470 get
          194) ("Promotion") is open to all users of Dream11 app who see the
          banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive a cash bonus amount of INR 194
          (“Bonus Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from Indian Standard Time ("IST") 21:00:00
          hours on August 15th, 2023 till IST 22:00:00 hours on August 15th,
          2023 ("Promotion Period"). To participate in the Promotion, user
          must make at least one cash deposit of exactly the amount specified
          in the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 470 194 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 16th Aug 2023 23:59:00 Hours. The maximum Bonus
          Amount that can be credited to users’ Cash Bonus Account pursuant to
          this Promotion is INR 194 Please note that this Promotion is only
          available to the first 1,00,000 (One Lakh) users who make the
          Qualifying Deposit during the Promotion Period. For these Terms, the
          first 1,00,000 (One Lakh) users shall be calculated based on the
          time of Qualifying Deposit during the Promotion Period. Unutilized
          Bonus Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Referral Discount Hours This
          "Referral Discount Hours" ("Promotion") is open to users of Dream11
          app who receive the banner via in-app communication. Under this
          campaign users can refer their friends during the campaign duration
          to receive additional discounts. Only those friends that register
          and join at least one public cash contest would qualify as a
          referral under this campaign. Based on the number of referrals done
          by a user during the Promotion Period (defined below) , the user
          shall receive Discount Coupons subject to the users’ full compliance
          with these Terms (defined below). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open from Indian Standard Time ("IST") 15:00:00 hours on Aug
          21st, 2023 till IST 20:00:00 hours on Aug 25th, 2023 ("Promotion
          Period") Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who receive the banner in
          app communication and are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions and
          the terms and conditions of the Dream11 game (accessible here) Users
          who participate in the Promotion during the Promotion Period and
          refer new users to Dream11, shall be eligible to receive Discount
          Coupons, the value of which will be 50% OFF up to Rs.25 per friend
          that joins a paid public contest during the Promotion Period.
          Additionally, 1 user will be eligible to win an iPhone at the end of
          the Promotion. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by IST 22:00:00
          hours the next day during the Promotion Period depending on the
          number of referrals completed as stated herein. The Discount Coupon
          shall be valid for 7 Days from the date of credit. The Discount
          Coupon given under this Promotion is in addition to the existing
          referral rewards given to the users. The Discount Coupons will only
          be applicable for Public Contests. The iPhone reward winner will be
          declared on Aug 26th, 2023. The iPhone reward winners will be sent
          an Imagine (Apple Reseller) Voucher worth Rs.80,000 redeemable
          towards the purchase of an iPhone (“Voucher”). The iPhone reward
          winners shall receive an email communication confirming their
          winnings and the details of the Voucher within 2 working days post
          declaration of winners. The Voucher is non-transferrable and cannot
          be exchanged for cash or any other products and/or services. The
          Voucher is subject to the terms and conditions set forth by the
          Imagine Store. The Voucher is subject to TDS which shall be borne by
          Dream11. The Voucher is subject to 30% as TDS deduction. Dream11
          reserves the right to cancel this Promotion at any time in its sole
          discretion without assigning any reason thereto. Deposit Offer (CB)
          Terms and conditions This "Range Deposit Offer" ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          cash deposit within the Qualifying Range (as defined below) into
          their Dream11 unutilised account during the Promotion Period
          (defined below) will be eligible to receive a cash bonus amount
          depending on the amount deposited by the User and as mentioned in
          table below (“Bonus Amount”), in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests and
          have received in-app banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 13:00:00 hours on Aug 24rd, 2023 till IST 23:59:00 hours on
          Aug 28th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit which is more
          than or equal to Min. Deposit and less than or equal to the Max
          Deposit as specified in the table below (each a "Qualifying Range")
          into the users’ Dream11 unutilised account during the Promotion
          Period to be eligible to receive the corresponding Bonus Amount as
          per the table: Min Deposit (INR) Max Deposit(INR) Bonus Amount 40
          100 20% of the deposit amount 60 150 20% of the deposit amount 80
          200 20% of the deposit amount 150 250 20% of the deposit amount 200
          500 20% of the deposit amount 400 750 20% of the deposit amount
          1,500 5,000 10% of the deposit amount Please note that only the
          amount deposited within the each of the Qualifying Range (as
          communicated to the user via banner) by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 unutilised account before,
          during or after the Promotion Period. Please note that the Bonus
          Amount to be given to the user, will depend on the pre specified
          percentage mentioned in the table above and the value of the amount
          deposited by the user during the Promotion Period. Please note that
          in order to be eligible for receiving the Bonus Amount, the user
          must make a deposit of only the amount between the Qualifying Range
          as communicated to the user. There is no maximum limit on the number
          of times a user can make a deposit within each of the Qualifying
          Range and during the Promotion Period. However, the Bonus Amount
          corresponding to each Qualifying Range shall be credited only once.
          Subject to users’ compliance with these Terms, the Bonus Amount will
          be credited into users’ Cash Bonus Account by 30th August 2023
          23:59:00 Hours. Please note that this Promotion is only available to
          the first 2,00,000 (Two Lakh) users who deposit an amount within the
          Qualifying Range during the Promotion Period. For these Terms, the
          first 2,00,000 (Two Lakh) users shall be calculated based on the
          time of deposit within each of the Qualifying Range during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the amount deposited.
          Deposit Offer (DC) Terms and conditions This "Range Deposit Offer"
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one cash deposit within the Qualifying Range (as defined
          below) into their Dream11 unutilised account during the Promotion
          Period (defined below) will be eligible to receive DreamCoins
          depending on the amount deposited by the User and as mentioned in
          table below (“Bonus Amount”), in accordance with, and subject to the
          users’ full compliance with these Terms (defined below). Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests and
          have received in-app banner communication from Dream11 By
          participating in this Promotion, user agrees to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 13:00:00 hours on Aug 24rd, 2023 till IST 23:59:00 hours on
          Aug 28th, 2023 ("Promotion Period"). To participate in the
          Promotion, users must make at least one cash deposit which is more
          than equal to Min. Deposit and less than equal to the Max Deposit as
          specified in the table below (each a "Qualifying Range") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive DreamCoins more than or equal to the Min
          DreamCoins and less than or equal to the Max DreamCoins depending on
          the corresponding Bonus Amount as per the table: Min Deposit (INR)
          Max Deposit(INR) Bonus Amount Min DreamCoins Max DreamCoins 40 100
          20% of the deposit amount 160 400 60 150 20% of the deposit amount
          240 600 80 200 20% of the deposit amount 320 800 150 250 20% of the
          deposit amount 600 1,000 200 500 20% of the deposit amount 800 2,000
          400 750 20% of the deposit amount 1,600 3,000 1,500 5,000 10% of the
          deposit amount 3,000 10,000 Please note that only the amount
          deposited within the each of the Qualifying Range (as communicated
          to the user) by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before, during or after the
          Promotion Period. Please note that the Bonus Amount to be given to
          the user, will depend on the pre specified percentage mentioned in
          the table above and the value of the amount deposited by the user
          during the Promotion Period. Please note that in order to be
          eligible to receive the Bonus Amount t the user must make a deposit
          of any amount within the Qualifying Range as communicated to user.
          Subject to users’ compliance with these Terms, DreamCoins will be
          credited into users’ account by 30th August 2023 23:59:00 Hours. The
          maximum DreamCoins that can be credited to users’ account pursuant
          to this Promotion is mentioned above. Please note that this
          Promotion is only available to the first 2,00,000 (Two Lakh) users
          who make the Qualifying Deposit during the Promotion Period. For
          these Terms, the first 2,00,000 (Two Lakh) users shall be calculated
          based on the time of Qualifying Deposit during the Promotion Period.
          DreamCoins expire in three months from the date of credit. For
          detailed Terms and conditions -here is the link: Bonus DreamCoins
          will not contribute to the Level progress for the user. Once the
          DreamCoins are credited into a user’s account in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Deposit
          Offer (CB) Terms and conditions This "Deposit Offers" ("Promotion")
          is open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount depending on the amount deposited by
          the User and as mentioned in table below (“Bonus Amount”), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests and have received
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 13:00:00 hours on
          Aug 24th, 2023 till IST 23:59:00 hours on Aug 28th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) Bonus Amount 50 15 150 45 250 75
          500 150 1,500 150 5,000 500 Please note that only the Qualifying
          Deposit made by a user as per the communication banner received by
          the user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 30th August 2023 23:59:00
          Hours. The maximum Bonus Amount that can be credited to users’ Cash
          Bonus Account pursuant to this Promotion is mentioned above. Please
          note that this Promotion is only available to the first 2,00,000
          (Two Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 2,00,000 (Two Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Deposit Offer (DC) Terms and conditions This "Deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) into their
          Dream11 unutilised account during the Promotion Period (defined
          below) will be eligible to receive DreamCoins depending on the
          amount deposited by the User and as mentioned in table below
          (“DreamCoins”), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below) Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests and have
          received banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 13:00:00 hours on
          Aug 24th, 2023 till IST 23:59:00 hours on Aug 28th, 2023 ("Promotion
          Period"). To participate in the Promotion, user must make at least
          one cash deposit of exactly the amount specified in the table below
          ("Qualifying Deposit") into the users’ Dream11 unutilised account
          during the Promotion Period to be eligible to receive the Bonus
          Amount: Qualifying Deposit (INR) DreamCoins 50 300 150 900 250 1,500
          500 3,000 1,500 3,000 5,000 10,000 Please note that only the
          Qualifying Deposit made by a user as per the communication banner
          received by the user during the Promotion Period shall be considered
          for determining whether a user is eligible to receive the DreamCoins
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, DreamCoins shall be credited
          only once for the first exact Qualifying Deposit. Subject to users’
          compliance with these Terms, DreamCoins will be credited into users’
          account by 30th August 2023 23:59:00 Hours. Please note that this
          Promotion is only available to the first 2,00,000 (Two Lakh) users
          who make the Qualifying Deposit during the Promotion Period. For
          these Terms, the first 2,00,000 (Two Lakh) users shall be calculated
          based on the time of Qualifying Deposit during the Promotion Period.
          DreamCoins expire in three months from the date of credit. For
          detailed Terms and conditions -here is the link: Bonus DreamCoins
          will not contribute to the Level progress for the user. Once the
          DreamCoins are credited into a user’s account in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. iPhone
          Giveaway - for Multi-Match Fantasy Rounds - for Asia Cup 2023 This
          offer is open to all users who have access to the Multi-Match
          Fantasy product only. All users who join the Mega Contest, which
          have the contest band saying ‘Rank 1 wins iPhone + Winnings’, for
          select Multi-Match Fantasy rounds only (‘Eligible Rounds’) starting
          from Wednesday, 30th August until Sunday, 17th September (‘Promotion
          Period’) will be eligible to participate in this offer. The user who
          comes first place in the Mega Contest of the Eligible Rounds during
          the Promotion Period will be given an Imagine (Apple Reseller)
          Voucher worth Rs.80,000. They will receive the voucher details on
          their email within 3 working days from the date of the completion of
          the respective round. This offer is only eligible for the 1st rank
          winner of the Mega Contest for the Eligible Round (where the user
          would see a banner below the Mega Contest card saying ‘Rank 1 wins
          iPhone+ Winnings’) from 30th August until 17th September and not any
          other contest type within the round. It is not applicable to any
          private contests within the rounds. Only contests with the banner
          under the contest card saying ‘Rank 1 wins iPhone + Winnings’ would
          be eligible for this offer, and not any other contests without this
          banner present under the contest card. If the spots in the Mega
          Contest for any round fills prior to the start of the round, no
          other contests that are available for that round will be eligible
          for this offer. Winners shall receive an email communication
          confirming their winnings and the details of the Imagine Voucher
          within 3 working days after the end of the respective round where
          they have been declared a winner. The Gift Card shall be valid till
          December 31st, 2023. In the event that any round itself is
          abandoned, this offer will be null and void for that round. The
          Prize is subject to TDS which shall be borne by Dream11. In the
          event two or more users have the same team points at the end of any
          Multi-Match fantasy round’s Mega Contest, then the following
          tiebreaker will be used. It is clarified that for the purpose of
          this Tiebreaker, the teams used to join the Mega contest only shall
          be considered at the end of round to determine the winners and not
          any other teams created. Tiebreaker Rule - The team that joined the
          contest first shall be considered the winner Asia ODI Cup Tour Pass
          The Asia ODI Cup Tour Pass promotion (”Promotion”) is only open to
          select users of the Dream11 app who are able to view the specific
          reward of 50% OFF up to Rs 100 (“Discount Coupon") on their Rewards
          page on the Dream11 app. The users who can view the Discount Coupon
          on the Dream11 app can only avail this reward by burning the
          requisite amount of Dream Coins as displayed on the Rewards Page for
          this particular Discount Coupon. The Discount Coupon will allow the
          user to avail a 50% OFF up to Rs.100 in any 4 Asia ODI Cup matches,
          with an overall maximum avail amount limit of Rs. 25 per match.
          Please note, the user will not be able to utilise the entire
          Discount Coupon amount of Rs. 100 all at once for a single match of
          Asia ODI Cup. The Promotion will be open for participation from
          Indian Standard Time (”IST”) 9:00:00 on August 30th, 2023 till IST
          15:00:00 on September 17th, 2023 (“Promotion Period”). The Discount
          Coupon will be available for purchase only once per user during the
          Promotion Period. The Discount Coupon availed as part of the
          Promotion cannot be clubbed together with any other promotions that
          are running simultaneously on the Dream11 platform. Also, this
          Discount Coupon cannot be clubbed with a Cash Bonus available to any
          user. The Discount Coupon will not be applicable on Private
          Contests. The Discount Coupon are valid only on paid public contests
          of the Asia ODI Cup matches. In the event that a contest in which a
          user has used this Discount Coupon gets cancelled or the match
          itself gets abandoned, the Discount Coupon amount used in that
          contest or match by the user will be refunded to that user as Cash
          Bonus. ENG vs NZ T20Is Tour Pass The ENG vs NZ T20Is Tour Pass
          promotion (”Promotion”) is only open to select users of the Dream11
          app who are able to view the specific reward of 50% OFF up to Rs 50
          (“Discount Coupon") on their Rewards page on the Dream11 app. The
          users who can view the Discount Coupon on the Dream11 app can only
          avail this reward by burning the requisite amount of Dream Coins as
          displayed on the Rewards Page for this particular Discount Coupon.
          The Discount Coupon will allow the user to avail a 50% OFF up to
          Rs.50 in any two ENG vs NZ T20I matches, with an overall maximum
          avail amount limit of Rs. 25 per match. Please note, the user will
          not be able to utilise the entire Discount Coupon amount of Rs. 50
          all at once for a single match of the ENG vs NZ T20Is. The Promotion
          will be open for participation from Indian Standard Time (”IST”)
          12:00:00 on August 30th, 2023 till IST 17:00:00 on September 15th,
          2023 (“Promotion Period”). The Discount Coupon will be available for
          purchase only once per user during the Promotion Period. The
          Discount Coupon availed as part of the Promotion cannot be clubbed
          together with any other promotions that are running simultaneously
          on the Dream11 platform. Also, this Discount Coupon cannot be
          clubbed with a Cash Bonus available to any user. The Discount Coupon
          will not be applicable on Private Contests. The Discount Coupon are
          valid only on paid public contests of the ENG vs NZ T20I matches. In
          the event that a contest in which a user has used this Discount
          Coupon gets cancelled or the match itself gets abandoned, the
          Discount Coupon amount used in that contest or match by the user
          will be refunded to that user as Cash Bonus . Republic Bank CPL T20
          Tour Pass The Republic Bank CPL T20 Tour Pass promotion
          (”Promotion”) is only open to select users of the Dream11 app who
          are able to view the specific reward of 50% OFF up to Rs 100
          (“Discount Coupon") on their Rewards page on the Dream11 app. The
          users who can view the Discount Coupon on the Dream11 app can only
          avail this reward by burning the requisite amount of Dream Coins as
          displayed on the Rewards Page for this particular Discount Coupon.
          The Discount Coupon will allow the user to avail a 50% OFF up to
          Rs.100 in any 4 Republic Bank CPL T20 matches, with an overall
          maximum avail amount limit of Rs. 25 per match. Please note, the
          user will not be able to utilise the entire Discount Coupon amount
          of Rs. 100 all at once for a single match of Republic Bank CPL T20.
          The Promotion will be open for participation from Indian Standard
          Time (”IST”) 9:00:00 on September 1st, 2023 till IST 16:30:00 on
          September 25th, 2023 (“Promotion Period”). The Discount Coupon will
          be available for purchase only once per user during the Promotion
          Period. The Discount Coupon availed as part of the Promotion cannot
          be clubbed together with any other promotions that are running
          simultaneously on the Dream11 platform. Also, this Discount Coupon
          cannot be clubbed with a Cash Bonus available to any user. The
          Discount Coupon will not be applicable on Private Contests. The
          Discount Coupon are valid only on paid public contests of the
          Republic Bank CPL T20 matches. In the event that a contest in which
          a user has used this Discount Coupon gets cancelled or the match
          itself gets abandoned, the Discount Coupon amount used in that
          contest or match by the user will be refunded to that user as Cash
          Bonus. Dream11 Deposit Bonus Offer(Dep 399 get 129) Terms and
          Conditions This "Dream11 Deposit Bonus Offer (Dep 399 get 129)
          ("Promotion") is open to all users of Dream11 app who see the banner
          via in-app communication. Under this Promotion, users who make at
          least one Qualifying Deposit (as defined below) into their Dream11
          unutilised account during the Promotion Period (defined below) will
          be eligible to receive a cash bonus amount of INR 129 (“Bonus
          Amount”) in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 10:00:00 hours on
          September 02nd, 2023 till IST 12:00:00 hours on September 02nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 399 129 Please note that only the Qualifying Deposit made by a
          user during the Promotion Period shall be considered for determining
          whether a user is eligible to receive the Bonus Amount and not any
          deposits that the user makes into such user’s Dream11 unutilised
          account before or after the Promotion Period. There is no maximum
          limit on the number of Qualifying Deposits made by a user during the
          Promotion Period. However, the Bonus Amount shall be credited only
          once for the first Qualifying Deposit. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’ Cash
          Bonus Account by 03rd September 2023, IST 23:59:00 hours. The
          maximum Bonus Amount that can be credited to users’ Cash Bonus
          Account pursuant to this Promotion is INR 129. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who make the Qualifying Deposit during the Promotion Period. For
          these Terms, the first 1,00,000 (One Lakh) users shall be calculated
          based on the time of Qualifying Deposit during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer(Dep 1111 get 279) Terms and Conditions This
          "Dream11 Deposit Bonus Offer (Dep 1111 get 279) ("Promotion") is
          open to all users of Dream11 app who see the banner via in-app
          communication. Under this Promotion, users who make at least one
          Qualifying Deposit (as defined below) into their Dream11 unutilised
          account during the Promotion Period (defined below) will be eligible
          to receive a cash bonus amount of INR 279 (“Bonus Amount”) in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 12:00:00 hours on
          September 02nd, 2023 till IST 14:00:00 hours on September 02nd, 2023
          ("Promotion Period"). To participate in the Promotion, user must
          make at least one cash deposit of exactly the amount specified in
          the table below ("Qualifying Deposit") into the users’ Dream11
          unutilised account during the Promotion Period to be eligible to
          receive the Bonus Amount: Qualifying Deposit (INR) Bonus Amount
          (INR) 1111 279 Please note that only the Qualifying Deposit made by
          a user during the Promotion Period shall be considered for
          determining whether a user is eligible to receive the Bonus Amount
          and not any deposits that the user makes into such user’s Dream11
          unutilised account before or after the Promotion Period. There is no
          maximum limit on the number of Qualifying Deposits made by a user
          during the Promotion Period. However, the Bonus Amount shall be
          credited only once for the first Qualifying Deposit. Subject to
          users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Cash Bonus Account by 03rd September 2023, IST
          23:59:00 hours. The maximum Bonus Amount that can be credited to
          users’ Cash Bonus Account pursuant to this Promotion is INR 279.
          Please note that this Promotion is only available to the first
          1,00,000 (One Lakh) users who make the Qualifying Deposit during the
          Promotion Period. For these Terms, the first 1,00,000 (One Lakh)
          users shall be calculated based on the time of Qualifying Deposit
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 RuPay Credit Card Promo Offer Terms and Conditions
          This "Dream11 RuPay Credit Card Offer" ("Promotion") is open to
          users of the Dream11 app who receive the communication on the
          Dream11 app. Under this Promotion, users who perform a transaction
          of INR 299 or above using RuPay Credit Card, will be eligible to
          receive a cash bonus of INR 100 (“Bonus Amount”) during the
          Promotion Period (“defined below”). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here)) and have received
          in-app banner communication from Dream11. By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here). The Promotion is only valid for
          transactions performed by the users using RuPay Credit card on
          Dream11 during the Promotion Period The Promotion will be open for
          participation from Indian Standard Time ("IST") 18:00:00 hours on
          6th September, 2023 till IST 23:59:00 hours on 17th September, 2023
          ("Promotion Period"). No other payment instruments (UPI, Wallet,
          Gift Card, etc.) are applicable on this Promotion In case the user
          does a transaction of less than INR 299, the user will not be
          eligible for the Bonus Amount. Subject to users’ compliance with
          these terms and conditions, Bonus Amount will be disbursed after
          forty eight (48) hours of successful RuPay Credit card transaction.
          Offer can be availed only once per card during the Promotion Period.
          The maximum Bonus Amount that can be credited to users’ Account
          pursuant to this Promotion is INR 100. Unutilized Bonus Amount will
          expire in 14 days from the date that it has been credited. The Bonus
          Amount cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Once the Bonus Amount is
          utilized in part or whole, the user cannot claim any refund of the
          deposit amount. Dream11 Deposit Bonus Offer(Dep 99 get 49 CB) Terms
          and Conditions (7 Sept - 8th Sept) This "Dream11 Deposit Bonus
          Offer" ("Promotion") is open to select users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make at least one Qualifying Deposit (as defined below) during the
          Promotion Period (defined below) will be eligible to receive a cash
          bonus amount ("Bonus Amount"), the value of which will be dependent
          on the value of the Qualifying Deposit credited by the user into
          their Dream11 unutilised deposit account, in accordance with, and
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received banner communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 15:00:00 hours on Sept 7th, 2023 till IST 14:59:59
          hours on Sept 8th, 2023 ("Promotion Period"). To participate in the
          Promotion, user must make at least one cash deposit of exactly the
          amount specified in the table below ("Qualifying Deposit") into the
          users’ Dream11 unutilised account during the Promotion Period to be
          eligible to receive the Bonus Amount corresponding to the Qualifying
          Deposit, in accordance with the table below: Qualifying Deposit
          (INR) Bonus Amount (INR) 99 49 Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 unutilised account before or after the Promotion
          Period. There is no maximum limit on the number of Qualifying
          Deposits made by a user during the Promotion Period. However, the
          Bonus Amount shall be credited only once for the first Qualifying
          Deposit amount of the above-listed values. Subject to users’
          compliance with these Terms, the Bonus Amount will be credited into
          users’ Cash Bonus Account by IST 23:59:59 hours on Sept 9th, 2023.
          The maximum Bonus Amount that can be credited to users’ Cash Bonus
          Account pursuant to this Promotion is INR 49. Please note that this
          Promotion is only available to the first 1,00,000 (One Lakh) users
          who make the Qualifying Deposit of INR 99 during the Promotion
          Period. For these Terms, the first 1,00,000 (One Lakh) users shall
          be calculated based on the time of Qualifying Deposit during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the deposit amount.
          India vs Australia 2023 WC Match Experience Giveaway: India vs
          Australia 2023 WC Match Experience giveaway (“Giveaway”) is open to
          select users of Dream11 who can see the banner and receive the
          communication on the Dream11 mobile application. Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Giveaway: To qualify for the Giveaway, the user
          must join at least one paid Mega Contest of the India vs Pakistan
          Super Fours match scheduled to take place on Sunday, 10th September
          2023 or such other date as officially announced by the Asian Cricket
          Council (ACC). Among the users that qualify, a randomizer tool will
          be used to decide 1 lucky winner. The winner will be announced at
          IST 17:00:00 hours on Tuesday, 12th September 2023. The winner will
          receive communications regarding the same via email. The winner is
          required to confirm their acceptance of the Giveaway prize no later
          than 48 hours post announcement of the winner. Failure to do so
          within the specified timeline shall result in the forfeiture of the
          Giveaway prize. The winner will receive a package for 2 people, and
          can choose a companion whom they would like to take with them. The
          Package will include a one night stay at a 5-Star hotel + Category A
          match tickets for the India vs Australia 2023 WC match taking place
          in Chennai on the 8th of October at MA Chidambaram Stadium
          (“Match”). Dream11 will also sponsor the air transport (return
          Economy Class flight tickets for the user and the user’s companion
          from the user’s residence city in India or the nearest airport from
          the user’s residence city in India to Chennai) for the lucky winner
          and the winner’s chosen companion, however the details of the same
          will be communicated with the user after the winner is announced.
          (the accomodation, match tickets and transportation together shall
          collectively be referred to as “Package”). The Giveaway is subject
          to TDS which shall be borne by Dream11. If the winner fails to
          confirm acceptance of the Giveaway prize within the timeline or
          refuses the Giveaway, there will be no refund of any deposit made in
          furtherance of this Giveaway or compensation in cash, kind or any
          other cash bonuses. In the event the user fails to confirm
          acceptance of the Giveaway prize or refuses the Giveaway, a new
          winner will be chosen from the pool of previously qualified users
          using the randomizer tool. Dream11 will not be responsibe for any
          expenses that are not specifically mentioned in these Terms. In the
          event the Match is abandoned, canceled or rescheduled for any reason
          whatsoever, Dream11 shall not be responsible for any expenses
          incurred by the user in furtherance of this Giveaway. In addition to
          Dream11's terms and conditions, the user/winner and the winner’s
          companion shall also adhere to and be bound by the terms and
          conditions of third-party service providers, including but not
          limited to airlines, hotels, and any other relevant travel service
          providers. It is the user/winner’s and the winner’s companion’s
          responsibility to review and comply with the terms and conditions of
          these third-party service providers, as they may govern various
          aspects of the user/winner’s and the winner’s companion’s travel
          arrangements, including reservation, cancellation, refund policies,
          baggage regulations, and more. Dream11 will have no liability with
          respect to the acts, omissions, errors, representations, warranties,
          breaches or negligence on part of any third party service provider.
          In the event the India vs Pakistan Super Fours match is abandoned or
          officially decalred as a no-result, the Giveaway offer will be null
          and void. Coupon Code Discount (Flipkart) - June 8 2023 - Dec 31st
          2023 This Flipkart Coupon Offer is open to new users of Dream11 who
          receive a coupon pertaining to this offer through the Flipkart
          mobile application. Under this Promotion, users who have this coupon
          shall be eligible to receive a 50% Discount on paid public contests
          having a contest size of minimum 10000 players. This offer is
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who receive the coupon on the Flipkart
          mobile application and have never deposited and joined a pay-to-play
          contest on the Dream11 mobile application. Users must also be
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:00:00 hours on
          June 8th, 2023 till IST 23:59:00 hours on Dec 31st, 2023 ("Promotion
          Period"). Users must redeem the coupon code on the Dream11 mobile
          application within the Promotion Period. The discount coupon shall
          be valid for a period of 14 days from the date of 1st redemption. To
          participate in the Promotion, users must participate in public paid
          contests on the Dream11 app with a contest size of minimum 10000
          players across any sports with a minimum entry fee of Rs 25
          (Eligible Contest). The discount coupon will give the users a 50%
          discount on the entry fee. The total discount that a user receives
          under this promotion is Rs. 300. The discount coupon cannot be
          clubbed with any other offers, cash bonus available with the user.
          The discount coupon shall be available for redemption till Dec 31st
          2023. The discount coupon can be used on maximum of eight (8)
          Eligible Contests.In the event that a contest in which a user has
          used this discount coupon gets canceled or the match itself gets
          abandoned, the discount amount used in that contest or match will be
          refunded to that user as Cash Bonus. Dream11 reserves the right to
          cancel this Promotion in its sole discretion at any time without any
          prior notice. All discount coupons given prior to the date of
          cancellation shall be valid for utilization as per these terms.
          iPhone Giveaway - for Multi-Match Fantasy Rounds - for the 2023 ICC
          Men's Cricket World Cup This offer is open to all users who have
          access to the Multi-Match Fantasy product only. All users who join
          the Mega Contest, which have the contest band saying ‘Rank 1 wins
          iPhone + Winnings’, for select Multi-Match Fantasy rounds only
          (‘Eligible Rounds’) starting from Thursday, 5th October 2023 until
          Sunday, 19th November 2023 (‘Promotion Period’) will be eligible to
          participate in this offer. The user who comes first place in the
          Mega Contest of the Eligible Rounds during the Promotion Period will
          be given an Imagine (Apple Reseller) Voucher worth Rs.80,000. They
          will receive the voucher details on their email within 3 working
          days from the date of the completion of the respective round. This
          offer is only eligible for the 1st rank winner of the Mega Contest
          for the Eligible Round (where the user would see a banner below the
          Mega Contest card saying ‘Rank 1 wins iPhone+ Winnings’) from 5th
          October until 19th November 2023 and not any other contest type
          within the round. It is not applicable to any private contests
          within the rounds. Only contests with the banner under the contest
          card saying ‘Rank 1 wins iPhone + Winnings’ would be eligible for
          this offer, and not any other contests without this banner present
          under the contest card. If the spots in the Mega Contest for any
          round fills prior to the start of the round, no other contests that
          are available for that round will be eligible for this offer.
          Winners shall receive an email communication confirming their
          winnings and the details of the Imagine Voucher within 3 working
          days after the end of the respective round where they have been
          declared a winner. The Gift Card shall be valid till June 30th,
          2024. In the event that any round itself is abandoned, this offer
          will be null and void for that round. The Prize is subject to TDS
          which shall be borne by Dream11. In the event two or more users have
          the same team points at the end of any Multi-Match fantasy round’s
          Mega Contest, then the following tiebreaker will be used. It is
          clarified that for the purpose of this Tiebreaker, the teams used to
          join the Mega contest only shall be considered at the end of round
          to determine the winners and not any other teams created. Tiebreaker
          Rule - The team that joined the contest first shall be considered
          the winner World Cup Dhamaka DreamBox Terms and Conditions From
          5-7th October 2023, on joining any Non-India Paid Public contest the
          user will get guaranteed rewards via the World Cup Dhamaka DreamBox
          the next day by 2pm. The DreamBox will expire 2 days from the date
          when a user receives it i.e. the user will have 2 days time to claim
          the reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox fab icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop
          with a level tag. Rewards received through DreamBox Terms and
          Conditions: Bonus DreamCoins: Expiry of Bonus DreamCoins will be 3
          months from the date of credit. Bonus DreamCoins can be used towards
          redeeming rewards on the Rewards Shop only but will not count
          towards a user’s Champions Club Levels Progress. Check other
          DreamCoins terms and conditions here FC Jersey Voucher Users who win
          an FC jersey Voucher via the World Cup Dhamaka DreamBox worth ₹1427
          (subject to deduction of 30% TDS) can use to purchase any items on
          FanCode website. The actual voucher value received by the user post
          TDS deductions will be ₹999. FC Jersey voucher coupon code will be
          available on “My Rewards” page of the user FC jersey voucher cannot
          be exchanged for cash or any other benefit. FC jersey voucher shall
          be valid till 8th january 2024. Terms and conditions of the FC
          jersey voucher shall apply. Should the purchase value exceed the
          value of the voucher, the differential amount of such purchase shall
          be paid by the user using the other modes of payment available on
          the website. All purchases made on the FanCode website will be
          subject to the standard terms and conditions of the FanCode website
          including its cancellation, return and delivery policy. India vs
          England 2023 WC Match Experience Giveaway India vs England 2023 WC
          Match Experience giveaway (“Giveaway”) is open to select users of
          Dream11 who can see the banner and receive the communication on the
          Dream11 mobile application. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Giveaway: To qualify for the Giveaway, the user must join the paid
          Mega Contest of the 4 match Multi-Match Fantasy round for the ICC
          Men’s ODI Cricket World Cup named “India Kick-off”, where the first
          match of the round is India vs Australia scheduled to take place on
          Sunday, 8th October 2023, 2:00pm. The user that comes 1st in the
          contest will be considered the winner of the Giveaway. In the event
          two or more users are ranked 1st, the user that joined the contest
          first shall be considered the winner for the purpose of this
          Giveaway (“Winner”). The Winner will receive communications
          regarding the same via email. The Winner is required to confirm
          their acceptance of the Giveaway prize no later than 48 hours post
          announcement of the winner. Failure to do so within the specified
          timeline shall result in the forfeiture of the Giveaway prize. The
          Winner will receive a package for 2 people, and can choose a
          companion whom they would like to take with them. The Package will
          include a two night stay at a 4-Star hotel + Category B match
          tickets + hotel transfer to stadium and back for the India vs
          England 2023 WC match taking place in Lucknow on the 29th of October
          at Ekana Sports City - Lucknow (“Match”). The Giveaway is subject to
          TDS which shall be borne by Dream11. If the Winner fails to confirm
          acceptance of the Giveaway prize within the timeline or refuses the
          Giveaway, there will be no refund of any deposit made in furtherance
          of this Giveaway or compensation in cash, kind or any other Discount
          Bonuses. In the event the chosen user fails to confirm acceptance of
          the Giveaway prize or refuses the Giveaway, the player on the next
          rank will be offered the giveaway. This process will go on till
          October 21st, after which the giveaway will be considered null and
          void. Dream11 will not be responsible for any expenses that are not
          specifically mentioned in these Terms. In the event the Match is
          abandoned, canceled or rescheduled for any reason whatsoever,
          Dream11 shall not be responsible for any expenses incurred by the
          user in furtherance of this Giveaway. In addition to Dream11's terms
          and conditions, the user/winner and the winner’s companion shall
          also adhere to and be bound by the terms and conditions of
          third-party service providers, including but not limited to hotels,
          and any other relevant travel service providers. It is the
          user/winner’s and the winner’s companion’s responsibility to review
          and comply with the terms and conditions of these third-party
          service providers, as they may govern various aspects of the
          user/winner’s and the winner’s companion’s travel arrangements,
          including reservation, cancellation, refund policies, baggage
          regulations, and more. Dream11 will have no liability with respect
          to the acts, omissions, errors, representations, warranties,
          breaches or negligence on part of any third party service provider.
          In the event that the round itself is abandoned, this offer will be
          null and void for that round. Play with winnings This “Play with
          winnings” (“Promotion”) is only available for users who receive
          communication of the Promotion in their Dream11 app via a banner or
          push notification. Through this offer, users who receive the
          communication will be eligible to join a Public Paid Contest using
          their winning balance and win discounts Join contests using your
          Winning balance as communicated and get a 25% off discount pass upon
          completion of the task. Promotion Period 11th October 2023
          (19:00:00) to 29th October 2023 (23:59:59) Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility - Participation in this
          Promotion is available to only those users who have received the
          relevant communication of the Promotion from Dream11 on their
          Dream11 app. By participating in this Promotion, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The discount pass will be given to the eligible users only after
          they claim the discount from the offer page on or before 10th
          November 2023. In the event a user joins a contest using only their
          Unutilised Amount Account, then such contest join shall not be
          considered for the purposes of this Promotion. The Discount pass
          once credited will have a validity of 30 Days and can be redeemed
          while joining only the public paid contest. India vs England 2023 WC
          Match Experience Giveaway India vs England 2023 WC Match Experience
          giveaway (“Giveaway”) is open to select users of Dream11 who can see
          the banner and receive the communication on the Dream11 mobile
          application. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Giveaway: To
          qualify for the Giveaway, the user must join the paid Mega Contest
          of the 4 match Multi-Match Fantasy round for the ICC Men’s ODI
          Cricket World Cup named “Oct 19-21”, where the first match of the
          round is India vs Bangladesh scheduled to take place on Thursday,
          19th October 2023, 2:00pm. The user that comes 1st in the contest
          will be considered the winner of the Giveaway. In the event two or
          more users are ranked 1st, the user that joined the contest first
          shall be considered the winner for the purpose of this Giveaway
          (“Winner”). The Winner will receive communications regarding the
          same via email. The Winner is required to confirm their acceptance
          of the Giveaway prize no later than 48 hours post announcement of
          the winner. Failure to do so within the specified timeline shall
          result in the forfeiture of the Giveaway prize. The Winner will
          receive a package for 2 people, and can choose a companion whom they
          would like to take with them. The Package will include a two night
          stay at a 4-Star hotel + Category B match tickets + hotel transfer
          to stadium and back for the India vs England 2023 WC match taking
          place in Lucknow on the 29th of October at Ekana Sports City -
          Lucknow (“Match”). The Giveaway is subject to TDS which shall be
          borne by Dream11. If the Winner fails to confirm acceptance of the
          Giveaway prize within the timeline or refuses the Giveaway, there
          will be no refund of any deposit made in furtherance of this
          Giveaway or compensation in cash, kind or any other Discount
          Bonuses. In the event the chosen user fails to confirm acceptance of
          the Giveaway prize or refuses the Giveaway, the player on the next
          rank will be offered the giveaway. This process will go on till
          October 24th, after which the giveaway will be considered null and
          void. Dream11 will not be responsible for any expenses that are not
          specifically mentioned in these Terms. In the event the Match is
          abandoned, canceled or rescheduled for any reason whatsoever,
          Dream11 shall not be responsible for any expenses incurred by the
          user in furtherance of this Giveaway. In addition to Dream11's terms
          and conditions, the user/winner and the winner’s companion shall
          also adhere to and be bound by the terms and conditions of
          third-party service providers, including but not limited to hotels,
          and any other relevant travel service providers. It is the
          user/winner’s and the winner’s companion’s responsibility to review
          and comply with the terms and conditions of these third-party
          service providers, as they may govern various aspects of the
          user/winner’s and the winner’s companion’s travel arrangements,
          including reservation, cancellation, refund policies, baggage
          regulations, and more. Dream11 will have no liability with respect
          to the acts, omissions, errors, representations, warranties,
          breaches or negligence on part of any third party service provider.
          In the event that the round itself is abandoned, this offer will be
          null and void for that round. 20 Crore Strong Campaign: The 20 Crore
          Strong Campaign (“Promotion”) is open to all users of Dream11 who
          receive the banner and push notification communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication can participate by playing any public paid contest
          for the India vs New Zealand match scheduled for 22nd October, 2023
          in the ICC Men’s ODI Cricket World Cup above Rs.25 and stand a
          chance to win the India vs England World Cup Package (mentioned
          below) during the Promotion Period (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is available to all
          registered users of Dream11 who receive the banner and push
          communication on the Dream11 mobile application and are eligible to
          participate in the public pay-to-play contests above Rs. 25 as per
          Dream11's terms and conditions (accessible here). By participating
          in this Promotion, you agree to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of Dream11 (accessible here). The Promotion will be open
          for participation from IST 14:30:00 hours on October 20th, 2023 till
          IST 13:59:59 hours on October 22nd, 2023 ("Promotion Period"). Users
          must participate in the India vs New Zealand match scheduled for the
          22nd October, 2023 in the ICC Men’s ODI Cricket World Cup to be
          eligible to stand a chance to win the world cup package. Winners
          will be reached via a telephone call from the Dream11 team between
          22nd October, 2023 and 23rd October, 2023. Among the users that
          qualify for this Promotion, 10 winners will be selected at random
          using a randomizer tool and declared on the Dream11 application on
          the 24th of October, 2023. In an event the Match is abandoned or
          officially declared as no-result and the entry fee for such a
          contest is returned to the user, this offer will be null and void
          for that particular contest/match and the user will not be eligible
          to win the World Cup Package. The World Cup Package is subject to
          TDS which shall be borne by Dream11. India vs England World Cup
          Package Details: Two Category B match tickets for the 29th October,
          2023 India vs England Match. A 4 star double occupancy hotel room in
          the Ramada Lucknow for 2 nights from Saturday 28th October 2023
          (check in at 3 PM IST) until Monday 30th October 2023 (check out at
          12 PM IST) which shall be subject to the terms and conditions of the
          hotel. Travel to and from the hotel to the stadium. Users shall be
          solely responsible to book and pay for the travel from a user’s home
          city to Lucknow and all other expenses not included in the package.
          This Package will always be subject to the following standard terms
          and conditions outlined here. No cancellation or return would be
          allowed for this Package. All complaints in relation to the
          redemption of the Match Tickets shall be resolved as per these terms
          and conditions. If a match gets canceled or abandoned without a
          single ball being bowled, then Dream11 shall not be held responsible
          in any manner and Dream11 shall not provide any alternate match
          tickets. In addition to Dream11's terms and conditions, the
          users/winners and the winner’s companions shall also adhere to and
          be bound by the terms and conditions of third-party service
          providers, including but not limited to hotels, and any other
          relevant travel service providers. It is the users/winners and the
          winners’ companions’ responsibility to review and comply with the
          terms and conditions of these third-party service providers, as they
          may govern various aspects of the user/winner’s and the winner’s
          companion’s travel arrangements, including reservation,
          cancellation, refund policies, baggage regulations, and more.
          Dream11 will have no liability with respect to the acts, omissions,
          errors, representations, warranties, breaches or negligence on part
          of any third party service provider. Dream11 will not be responsible
          for any expenses that are not specifically mentioned in these Terms.
          In the event the India v England Match is abandoned, canceled or
          rescheduled for any reason whatsoever, Dream11 shall not be
          responsible for any expenses incurred by the user in furtherance of
          this Promotion. India vs England 2023 WC Match Experience Giveaway:
          India vs England 2023 WC Match Experience giveaway (“Giveaway”) is
          open to select users of Dream11 who can see the banner and receive
          the communication on the Dream11 mobile application. Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Giveaway: To qualify for the Giveaway, the user
          must join the paid Mega Contest of the 2 match Multi-Match Fantasy
          round for the ICC Men’s ODI Cricket World Cup named “Oct 22-23”,
          where the first match of the round is India vs New Zealand scheduled
          to take place on Sunday, 22nd October 2023, 2:00pm. The user that
          comes 1st in the contest will be considered the winner of the
          Giveaway. In the event two or more users are ranked 1st, the user
          that joined the contest first shall be considered the winner for the
          purpose of this Giveaway (“Winner”). The Winner will receive
          communications regarding the same via email. The Winner is required
          to confirm their acceptance of the Giveaway prize no later than 48
          hours post announcement of the winner. Failure to do so within the
          specified timeline shall result in the forfeiture of the Giveaway
          prize. The Winner will receive a package for 2 people, and can
          choose a companion whom they would like to take with them. The
          Package will include a two night stay at a 4-Star hotel + Category B
          match tickets + hotel transfer to stadium and back for the India vs
          England 2023 WC match taking place in Lucknow on the 29th of October
          at Ekana Sports City - Lucknow (“Match”). The Giveaway is subject to
          TDS which shall be borne by Dream11. If the Winner fails to confirm
          acceptance of the Giveaway prize within the timeline or refuses the
          Giveaway, there will be no refund of any deposit made in furtherance
          of this Giveaway or compensation in cash, kind or any other Discount
          Bonuses. In the event the chosen user fails to confirm acceptance of
          the Giveaway prize or refuses the Giveaway, the player on the next
          rank will be offered the giveaway. This process will go on till
          October 27th, after which the giveaway will be considered null and
          void. Dream11 will not be responsible for any expenses that are not
          specifically mentioned in these Terms. In the event the Match is
          abandoned, canceled or rescheduled for any reason whatsoever,
          Dream11 shall not be responsible for any expenses incurred by the
          user in furtherance of this Giveaway. In addition to Dream11's terms
          and conditions, the user/winner and the winner’s companion shall
          also adhere to and be bound by the terms and conditions of
          third-party service providers, including but not limited to hotels,
          and any other relevant travel service providers. It is the
          user/winner’s and the winner’s companion’s responsibility to review
          and comply with the terms and conditions of these third-party
          service providers, as they may govern various aspects of the
          user/winner’s and the winner’s companion’s travel arrangements,
          including reservation, cancellation, refund policies, baggage
          regulations, and more. Dream11 will have no liability with respect
          to the acts, omissions, errors, representations, warranties,
          breaches or negligence on part of any third party service provider.
          In the event that the round itself is abandoned, this offer will be
          null and void for that round. Crown your Champion: The Crown your
          Champion Promotion ( "Promotion") is open to select users of Dream11
          who receive the banner and push notification communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, will receive four questions to
          be answered. If the user answers any or all the four questions
          correctly, they would receive Discount Coupon(s) in accordance with
          the table below. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the banner and push communication on the Dream11
          mobile application and are eligible to participate in the
          pay-to-play contests as per Dream11's terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible
          here). The Promotion will be open for participation from IST
          20:00:00 hours on October 26th, 2022 till IST 23:59:59 hours on
          November 3rd, 2023 ("Promotion Period"). Any Discount Coupon availed
          as part of the Promotion cannot be clubbed with any other Discount
          Coupon received under this Promotion or other promotions running
          simultaneously on the Dream11 platform. Also, Discount Coupon(s)
          cannot be clubbed with a Discount Bonus available to any user. In
          the event that a paid public contest in which a user has used this
          Discount Coupon gets cancelled or the match itself gets abandoned,
          the Discount Coupon amount used in that contest or match by the user
          will be refunded to that user as Discount Bonus. Users must select
          the 4 semi-finalists, 2 finalists and the winner of the ODI World
          Cup, 2023. Users who answer any or all question(s) correctly will be
          eligible to receive the following Discount Coupon(s): Questions
          answered correctly Discount Coupon 2 Semi Finalists correct 15% OFF
          up to Rs.25 (whichever is lesser) 4 Semi Finalists correct 25% OFF
          up to Rs.30 (whichever is lesser) Finalists correct 50% OFF up to
          Rs.35 (whichever is lesser) Winner correct 75% OFF up to Rs.40
          (whichever is lesser) Below are details of the promotion that will
          be given out to users who answer correctly. 15% OFF up to Rs.25
          Discount Coupon will be valid on all paid public contests above Rs.
          49 on the Dream11 platform. Discount Coupon is not applicable on
          private contests. Maximum discount a user can avail on a public paid
          contest is Rs. 25 or 15% of the contest entry fee, whichever is
          lesser. Discount can be availed on multiple public paid contests
          till the cap of Rs. 25 is exhausted. Subject to your compliance with
          these Terms, the Discount Coupon will be credited to the qualifying
          users' Dream11 account on 20th November, 2023. The Discount Coupon
          will be valid until 11:59:59 PM on 27th November 2023. 25% OFF up to
          Rs.30 Discount Coupon will be valid on all paid public contests
          above Rs. 49 on the Dream11 platform Discount Coupon is not
          applicable on private contests. Maximum discount a user can avail on
          a contest is Rs. 30 or 25% of the contest entry fee, whichever is
          lesser. Discount can be availed on Multiple public paid contests
          till the cap of Rs. 30 is exhausted. Subject to your compliance with
          these Terms, the Discount will be credited to the qualifying users'
          Dream11 account on the 20th of November 2023. The Discount Coupon
          will be valid until 11:59:59 PM on 27th November 2023. 50% OFF up to
          Rs.35 Discount Coupon will be valid on all paid public contests
          above Rs.49 on the Dream11 platfrom Discount Coupon is not
          applicable on private contests. Maximum discount a user can avail on
          a contest is Rs. 35 or 50% of the contest entry fee, whichever is
          lesser. Discount can be availed on Multiple public paid contests
          till the cap of Rs. 35 is exhausted. Subject to your compliance with
          these Terms, the Discount Coupon will be credited to the qualifying
          users' Dream11 account on the 20th of November2023. The Discount
          Coupon will be valid until 11:59:59 PM on 27th November 2023. 75%
          OFF up to Rs.40 Discount Coupon will be valid on all paid public
          contests above Rs.49 Dream11 platform Discount Coupon is not
          applicable on private contests. Maximum discount a user can avail on
          a contest is Rs. 40 or 75% of the contest entry fee, whichever is
          lesser. Discount can be availed on Multiple public paid contests
          till the cap of Rs. 40 is exhausted. Subject to your compliance with
          these Terms, the Discount Coupon will be credited to the qualifying
          users' Dream11 account on the 20th of November 2023. The Discount
          Coupon will be valid until 11:59:59 PM on 27th November 2023.
          Illustration: Contest Entry Fee Discount Coupon Maximum Discount
          that can be availed in one contest Rs.50 15% OFF up to Rs.25 Rs.8
          Rs.50 25% OFF up to Rs.30 Rs.13 Rs.50 50% OFF up to Rs.35 Rs.25
          Rs.50 75% OFF up to Rs.40 Rs.38 Dream11 Multi-Match Fantasy 2023
          DreamCoins Booster Offer Terms and Conditions This "Dream11
          Multi-Match Fantasy 2023 DreamCoins Booster Offer" ("Promotion") is
          open to only those users of Dream11 who view the specific reward on
          their Rewards page on the Dream11 app and who have redeemed the
          DreamCoin Booster reward from the Reward Shop. Under this Promotion,
          a user shall receive Ten times (up to a maximum of 75 Bonus
          DreamCoins) the Bonus DreamCoins earned when they join the Mega
          contest (entry-fee of Rs. 59) for the Multi-Match Fantasy round
          starting 29th October and ending 1st November 2023. Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions, the terms and conditions of the
          Dream11 game (accessible here) and the standard terms and conditions
          of DreamCoins (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:14:01 hours on
          October 28th, 2023 till IST 13:59:59 hours on October 29th 2023
          ("Promotion Period"). Under this Promotion, the DreamCoins booster
          will apply to the Mega contest (entry-fee of Rs. 59) for the
          Multi-Match Fantasy Round starting 29th October and ending 1st
          November 2023 that a user joins which earns them DreamCoins (defined
          here) under the stipulated Promotion Period. Only performing these
          defined actions will earn the user ten times the Bonus DreamCoins up
          to a maximum of 75 Bonus DreamCoins, actions outside this list will
          not earn the user any DreamCoins. A user can receive a maximum of 75
          Bonus DreamCoins in addition to the usual DreamCoins at the end of
          the Promotion Period. Subject to a user’s compliance with these
          Terms, the bonus DreamCoins will be credited into the user's
          DreamCoins balance by IST 23:59:59 hours on 1st November, 2023. The
          Bonus DreamCoins are valid as per the standard terms of the
          DreamCoins available here The additional bonus DreamCoins given via
          this promotion will not be counted towards the user’s Champions club
          progress. These are valid till 1st February 2024. Diwali Party T&C’s
          The Diwali Party Promotion ( “Promotion”) is open to select users of
          Dream11 who receive the banner and push notification communication
          on the Dream11 mobile application. Under this Promotion, users who
          receive the communication on the Dream11 app, need to complete
          certain gameplay tasks as shown on this Party ticket(defined below)
          to win prizes. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Participation is only available to select registered users of
          Dream11 who receive the banner and push communication on the Dream11
          mobile application and are eligible to participate in Diwali Party
          as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). The Promotion
          will be open for participation from IST 12:00:00 on October 27th,
          2023 till IST 23:59:59 hours on November 19th, 2023 ("Promotion
          Period"). The Diwali Party page will update every morning at 9 AM
          for the duration of the campaign. The updated page will reflect
          progress made upto IST 01:00:00 of the same day. Users must
          successfully complete the below tasks to win the corresponding
          prizes. Below are the tasks and the definitions for the same.
          Winning Criteria Progress Guaranteed Prize Aspirational Prize
          Complete any task 25% off up to ₹15 Complete all tasks of any one
          row of the Party Ticket (as given below) 50% off up to ₹50 4 users
          get an iPhone Complete all tasks 90% off up to ₹100 6 users get an
          iPhone Party Ticket Play paid contests Try new sport(s) Get some
          common players with dream team in a match Pick Guru Teams to join
          paid public contests Play one paid contest each with every ICC Men’s
          CWC team Play mega contest with multiple teams Use Backups in your
          matches to play public paid contests Play new cricket tours Redeem a
          reward from the Reward Shop Tasks Task Definitions & exclusions Play
          paid contests Play paid, non-private contests (number as mentioned
          on your Party ticket) on the Dream11 app during the Promotion
          Period. Try new sports Play paid, non-private contest (completed)
          for a sport other than cricket in such number as mentioned on your
          Party ticket. Get some common players with dream team in a match In
          any paid, non-private contest (completed), get as many players of
          Dream Team (DT) in your team that is added by you to participate in
          the public paid contest as specified in your Party ticket Pick Guru
          Teams to join paid contests Play any paid, non-private contest
          (completed) with a picked Guru Team in such number of matches
          specified in your Party ticket Play one paid contest each with every
          ICC Men’s CWC team Play at-least one paid, non-private contest with
          each of the World Cup team Play mega contest with multiple teams
          Play any Mega contest (completed) with as many teams as specified in
          your Party ticket Use Backups in your matches to play paid contests
          Add Backups to as many of your teams as specified in your Party
          ticket and join paid contests with each such team Play new cricket
          tours Play paid, non-private contests (complete) for as many cricket
          tours other than the ICC Men’s CWC as specified in your Party ticket
          Redeem a reward from the Reward Shop Redeem as many reward(s) worth
          200 DC from Reward shop as specified in your Party ticket *The term
          ”Completed” used in the above table refers to completion of the
          relevant WC match. In the event that a contest in which a user has
          added his team is cancelled or the match itself gets abandoned, the
          same will be considered as an invalid task for this Promotion.
          Prizes cannot be exchanged for cash or any other benefit. Prizes are
          non-transferable and cannot be returned. An iPhone 14(“ Reward”)
          will be rewarded to 4 users(chosen using a randomizer tool) who
          complete all tasks in one row of their Party ticket during the
          Promotion Period. In the event of a multiple rows completed, user
          will not be eligible to be rewarded additional iPhone 14(s). An
          iPhone 14(“ Reward”) will be rewarded to 6 other users(chosen using
          a randomizer tool) who complete all tasks of their Party ticket
          during the Promotion Period. If a user has already won an iPhone by
          completing a row of tasks, they’ll not be eligible for a new iPhone
          after completing the full Party ticket tasks. Reward cannot be
          exchanged for cash or any other benefit. Reward is non-transferable
          and cannot be returned. Dream11 shall not be liable for any failure
          to deliver the rewards to the address given by the user: Due to
          circumstances beyond its control, including but not limited to, acts
          of nature, force majeure events, restrictions imposed by any
          regulatory authority, technical failures, or any other unforeseen
          circumstances that make the delivery of rewards impractical or
          impossible; The address information provided by the user is
          inaccurate, incomplete, or outdated. The user fails to update their
          address information in a timely manner, resulting in delivery
          issues. The user's address is inaccessible due to legal or
          regulatory restrictions, security concerns, or any other
          circumstances preventing safe delivery. The user has not met the
          eligibility criteria or complied with the Terms required to receive
          the rewards. Out/Not-Out: ICC Men’s ODI Cricket World Cup The
          Out/Not-Out Promotion (“Promotion”) is open to select users of
          Dream11 who receive the in app communication on the Dream11 mobile
          application and participates in the Out/Not-Out contest. Under this
          Promotion, users who receive the communication and participate in
          the Out/Not-Out contest on the Dream11 app, will receive 3 questions
          to be answered. For each question, the user will have only one
          attempt to answer the question. Only one coupon will be given per
          user as long as the user gets at least one answer to the 3 questions
          right. Promotion criteria: From the 3 questions - If the user
          answers one question correctly, they would be not out and would be
          eligible to a discount coupon of 25% off upto Rs 20 ( “Discount
          Coupon”) . From the 3 questions - If the user answers 2 questions
          correctly, they would be not out and would be eligible to a discount
          coupon of 50% off up to Rs 25 ( “Discount Coupon”) . From the 3
          questions - If the user answers 3 questions correctly, they would be
          not out and would be eligible to a discount coupon of 75% off up to
          Rs 30 ( “Discount Coupon”) . From the 3 questions - If the user
          answers none of the questions correctly, the user would be OUT and
          would not be eligible to receive any discount coupon. Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Participation is only available
          to select registered users of Dream11 who receive the push/banner
          and static page communication on the Dream11 mobile application and
          are eligible to participate in the pay to play contests as per
          Dream11's terms and conditions (accessible here). By participating
          in this Promotion, you agree to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of Dream11 (accessible here). Coupon Details: The
          Promotion will be open for participation from IST 14:00:00 hours on
          November 5th, 2023 till IST 23:00:00 hours on November 5th, 2023
          ("Promotion Period"). User will only be given 1 discount coupon
          depending on the number of questions answered correctly as mentioned
          above. Users would receive a Discount Coupon code of either 25% off
          up to Rs 20, 50% off up to Rs 25, 75% off up to Rs 30 upon answering
          one, two or three questions correctly, respectively. Discount
          Coupons can be availed on multiple public paid contests of ICC Men’s
          ODI Cricket World Cup in which India is not playing till the cap of
          Rs. 20/Rs. 25/Rs. 30 (as applicable) is exhausted. Maximum discount
          a user can avail on a contest is Rs. 20/Rs. 25/Rs. 30 (as
          applicable) or 25%/50%/75% (as applicable) of the contest entry fee,
          whichever is lesser. Terms and Conditions- QUIZ on Leaderboard POC
          The Quiz on Leaderboard promotion (“Promotion”) is open to select
          users of Dream11 who receive external and in-app communication on
          the Dream11 mobile application and participate in the Promotion.
          Under this Promotion, users who receive the communication and
          participate in the Promotion on the Dream11 app, will receive
          certain number of multiple choice quiz questions to be answered. For
          each question, there will be 4 options with 1 correct answer. The
          user can select and submit only 1 answer. The Promotion will only be
          open to participation during a live match of the tournaments listed
          below. The access to participate in the Promotion will be via an
          entry point on the Leaderboard on Dream11 app. Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive the push, banner, App
          Inbox, In-app and static page communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). Tournaments
          Covered (“Match/Matches”) - ICC Cricket World Cup matches scheduled
          to take place between 9th November 2023 - 19th November 2023 5 India
          vs Australia T20 matches scheduled to take place between 23rd
          November 2023 - 3rd December 2023 Promotion criteria: There will be
          3 variants and eligible users can be exposed to any one of the
          following 3 variants - Variant Reward Eligibility 1 “Grand Prize”
          only - iPhone voucher worth Rs. 80,000 or Gold voucher worth Rs.
          10,000 (as applicable) 1 user with maximum correct answers out of
          all participating users during each Match will qualify to win either
          of these Grand Prizes, which will be decided by Dream11 at its sole
          discretion. 2 Grand Prize + Bonus DreamCoins Bonus Dreamcoin - 1 per
          user per correct answer Grand Prize - 1 user for the Match (maximum
          correct answers out of all participating users for a particular
          Match) 3 No Reward Eligible to only participate in the quiz. No
          rewards in any instance General Terms & Conditions for the Promotion
          The Promotion will be open for participation from IST 14:00:00 hours
          on November 09th, 2023 till IST 23:59:59 hours on December 3rd, 2023
          ("Promotion Period") The user with the maximum number of correct
          answers for a match will be selected as the winner of the Grand
          Prize. If there are multiple users tied for the most number of
          correct answers, a single winner will be chosen by a randomiser
          tool. The winner of the Grand Prize will be declared within the
          ‘Results’ page as displayed in the Promotion banner within the
          Dream11 App at the time of the winner declaration. The winners of
          the Grand Prize shall also receive an email communication confirming
          their winnings. Tanishq Gold voucher worth Rs.10,000 and Imagine
          (Apple Reseller) Voucher worth Rs.80,000 can be used by the user to
          only buy Gold from a Tanishq store and Apple iPhone from the Imagine
          store (Apple reseller) respectively. Winners shall receive an email
          communication confirming their winnings and the details of the
          Imagine Voucher within 2 working days after the end of the match
          Redemption of the Grand Prize voucher(s) will be subject to the
          terms and conditions of the issuers i.e Tanishq and Imagine (Apple
          Reseller). The Grand Prize vouchers will be valid till April 30th,
          2024 Subject to compliance with these Terms, the bonus Dream Coin
          will be credited to the user within 48 hours after the Match round
          is declared as ‘complete’. The validity of the Bonus DreamCoin will
          be 6 months from the date of the same being credited to the user's
          DreamCoin balance. The bonus DreamCoins given via this promotion
          will not be counted towards the user’s Champions Club progress. The
          bonus Dream Coins given under the Promotion will be subject to the
          terms and conditions accessible here. The Grand Prize of both IPhone
          voucher and Gold voucher is subject to TDS which will be borne by
          Dream11. In the event that the Match/contest gets cancelled or
          abandoned, this Promotion will be null and void for that
          Match/round. Dream11’s decision in selecting and declaring the
          winners of the Promotion, shall be final and binding on all the
          users participating in the Promotion. Google Pay Discount Coupon
          Promotion Offer: Rs. 300 Off This Google Pay Coupon Offer
          (“Promotion”) is open to all users of Google Pay who receive a
          Discount Coupon on the Google Pay mobile application. Under this
          Promotion, users who have this coupon and who install the Dream11
          app via the Google Pay coupon page shall be eligible to receive a
          90% discount on the contest entry amount with a maximum discount of
          Rs. 300 on paid public contests having a contest size of minimum of
          10,000 players and minimum Entry Fee of INR 25 (“Discount Coupon”).
          This Promotion is subject to the users’ full compliance with these
          Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who receive the coupon on
          the Google Pay mobile application and have never deposited and
          joined a pay-to-play contest on the Dream11 mobile application.
          Users must also be eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions and
          the terms and conditions of the Dream11 game (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 00:00:00 hours on Nov 10th, 2023 till IST 23:00:00 hours on
          Nov 19th, 2023 ("Promotion Period"). The Discount Coupon shall be
          valid for a period of 14 days from the date it was first redeemed by
          a user or until the end of the Promotion Period, whichever is
          earlier. Eligible Users must redeem the Discount Coupon on the
          Dream11 mobile application within the Promotion Period. The Discount
          Coupon will give the users a 90% discount on the contest entry
          amount of the first 2 paid public contests having a minimum of
          10,000 participants, joined during the Promotion Period with the
          maximum discount of up to Rs. 300. The maximum discount amount that
          can be availed by the user pursuant to this Promotion is Rs. 300.
          The Discount Coupon cannot be clubbed with any other offers, cash
          bonuses, or discount coupons available to the user. The Discount
          Coupon shall be available for redemption till Nov 19th 2023 only.
          This Promotion is only available to new users who install the
          Dream11 App from the "Redeem now" button on Google Pay and create an
          account with Dream11 by clicking on the button on the coupon page on
          their Google Pay app. This offer is not applicable to existing users
          of Dream11. Not valid for existing users of Dream11 & those who
          already have the app. In case of a match getting abandoned, the
          value of the coupon used will be added back as Discount Bonus to the
          user’s wallet. Dream11 reserves the right to cancel this Promotion
          anytime at its sole discretion. Referral Promotional Offer Terms and
          Conditions Referral Promotional Offer is only available for users
          who receive communication from Dream11 in their Dream11 app via a
          banner. Under this Promotion, users who receive the communication
          will be required to invite their friends to join a paid public
          contest from the list of friends shown on the offer page. Eligible
          users will receive a discount as per the number of friends that
          successfully join a paid contest. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility - Participation in this
          Promotion is available only to users who receive the relevant
          promotion communication from Dream11 on their Dream11 app. The users
          who invite their friends to join a paid public contest during the
          Promotion Period (defined below) from the list of friends shown on
          the offer page, will receive the discount. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 13:00:00 hours on
          Nov 10th, 2023, till IST 13:59:59 hours on Nov 19th, 2023
          ("Promotion Period"). For the user to be eligible, the friend must
          click on the link in the pre-filled whatsapp message shared by the
          user . Discount coupons won by the user will be for one-time use
          only and will be auto-applied for the next paid public contest. If
          any paid public contest is abandoned, such contest will not be
          considered complete and will not be eligible under this promotion.
          Dream11’s decision in selecting and declaring the 3 winners who
          shall win an iPhone shall be final and binding on all the users
          participating in the Promotion. Subject to your compliance with
          these Terms, the discount will be visible in the eligible users’ My
          Rewards section in the Rewards shop, accessible via the bottom
          navigation bar. The offer page will update at 9pm every day during
          the duration of the promotion. The iphone winners will be declared
          by Nov 22nd, and will receive communication over email/whatsapp.
          Winners shall receive a voucher for Rs. 80,000. Play with winnings
          This “Play with winnings” (“Promotion”) is only available for users
          who receive communication of the Promotion in their Dream11 app via
          a banner or push notification. Through this offer, users who receive
          the communication will be eligible to join a Public Paid Contest
          using their winning balance and win discounts. Join contests using
          your Winning balance as communicated and get a 25% off discount pass
          upon completion of the task. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility Participation in this Promotion is available
          to only select registered users who have received the relevant
          communication of the Promotion from Dream11 on their Dream11 app.
          and are eligible to participate in the pay to play contests as per
          Dream11's terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here) The Promotion will be open for
          participation from Indian Standard Time (IST) 00:00:00 hours on 11th
          November 2023 till IST 23:59:59 hours on 19th November 2023 The
          discount pass will be given to the eligible users only after they
          claim the discount from the offer page on or before 30th November
          2023. In the event a user joins a contest using only their
          Unutilised Amount Account, then such contest join shall not be
          considered for the purposes of this Promotion. The Discount pass
          once credited will have a validity of 30 Days and can be redeemed
          while joining only public paid contests on the Dream11 App. Discount
          pass is non-transferable and cannot be withdrawn. Pay-to-play
          deposit Offers Terms and Conditions This "Pay-to-play deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make a applicable qualifying deposit as displayed in the Add Cash
          page of the user’s Dream11 account (“Qualifying Deposit”). Users
          will be eligible to receive a Discount bonus as displayed in the Add
          Cash page Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11. By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 18:00:00 hours on Dec 12th, 2023 till
          IST 17:00:00 hours on Dec 19th, 2023 ("Promotion Period"). To
          participate in the Promotion, users must make at least one cash
          deposit of the amount(s) specified in the Add Cash page of the
          user’s Account . Users must deposit during the Promotion Period to
          be eligible to receive the applicable Discount bonus. Nodeposit made
          by the user to the Dream11 unutilised account before or after the
          Promotion Period will be considered to for the purpose of this
          Promotion.Please note that the Discount Bonus to be given to the
          user, will depend on the value of the Qualifying Deposit made by the
          user during the Promotion Period. The Discount Bonus shall be a
          percentage of the deposit amount as displayed on the Add Cash page
          of the user’s Account. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the respective Discount Bonus Amount shall be credited only
          once for the first Qualifying Deposit amount as communicated on the
          app. Subject to users’ compliance with these Terms, the Discount
          Bonus will be credited into users’ discount bonus Account within 48
          hours of deposit. Unutilized Discount Bonus will expire in 14 days
          from the date that it has been credited. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Once the Discount Bonus is utilized in
          part or whole, the user cannot claim any refund of the Qualifying
          Deposit. LeaderBoard Campaign This “LeaderBoard Campaign”
          ("Promotion") is only available for users who receive communication
          of the Promotion in their Dream11 app via a banner or push
          notification. Through this offer, users who receive the
          communication will be eligible to join a Public Paid Contest using
          their winning balance and win discounts Join contests using your
          Winning balance as communicated and get a discount pass upon
          completion of the task as mentioned in the LeaderBoard Promotion
          Period: 21st December 2023 (14:00) - 5th January 2024 (23:59) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on their Dream11 app. By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) Only paid public contests that are
          joined during the Promotion Period using winnings balance and winner
          declaration also concludes during the Promotion Period, shall be
          considered as eligible contests for the purposes of this Promotion.
          The discount pass will be given to the eligible users only after
          they claim the discount from the offer page between 6th January 2024
          and 10th January 2024. In the event a user joins a contest using
          only their Unutilised Amount Account, then such contest join shall
          not be considered for the purposes of this Promotion. The Discount
          pass once credited will have a validity of 90 Days and can be
          redeemed while joining only the public paid contest of any sport.
          Discount pass is non-transferable and cannot be withdrawn. Coupon
          Code Discount (Flipkart) - Jan 1 2024 - Dec 31st 2024 This Flipkart
          Coupon Offer (“Promotion”) is open to new users of Dream11 who
          receive a coupon pertaining to this offer through the Flipkart
          mobile application. Under this Promotion, users who have this coupon
          shall be eligible to receive a 50% Discount on paid public contests
          having a contest size of a minimum of 10,000 players. This Promotion
          is subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who receive the coupon on the Flipkart
          mobile application and have never deposited and joined a pay-to-play
          contest on the Dream11 mobile application. Users must also be
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:00:00 hours on
          Jan 1st, 2024 till IST 23:59:00 hours on Dec 31st, 2024 ("Promotion
          Period"). Users must redeem the Discount Coupon code on the Dream11
          mobile application within the Promotion Period. The Discount Coupon
          shall be valid for a period of 14 days from the date of 1st
          redemption. To participate in the Promotion, users must participate
          in public paid contests on the Dream11 app with a contest size of a
          minimum of 10,000 players across any sports with a minimum entry fee
          of Rs 25 (Eligible Contest). The discount coupon will give the users
          a 50% discount on the entry fee. The total discount that a user
          receives under this promotion is Rs. 300. The Discount Coupon cannot
          be clubbed with any other offers, discount bonus available with the
          user. this Discount Coupon cannot be clubbed with a Discount Bonus
          available to any user. The Discount Coupon will not be applicable on
          Private Contests. The Discount Coupon shall be available for
          redemption till Dec 31st 2024. The Discount Coupon can be used on
          maximum of eight (8) Eligible Contests. In the event that a contest
          in which a user has used this Discount Coupon gets canceled or the
          match itself gets abandoned, the discount amount used in that
          contest or match will be refunded to that user as Discount Bonus.
          Dream11 reserves the right to cancel this Promotion or modify the
          terms of this Promotion in its sole discretion at any time without
          any prior notice. All Discount Coupons given prior to the date of
          cancellation shall be valid for utilization as per these terms. Buy
          FanCode Merchandise with DreamCoins Terms and Conditions To buy
          FanCode shop items with DreamCoins directly for a selected
          collection, It is open to all users of Dream11 who wish to avail
          discount and burn such no of DreamCoins as are mentioned on the
          FanCode Page of this offer. Only the users who can see the offer on
          Reward Shop can access the FanCode Shop where they can buy items
          with DreamCoins. User should have sufficient DreamCoin balance to
          buy the item. On cancelling the orders from FanCode if duration of
          cancellation is not exceeded, DreamCoins will be refunded
          automatically. In case of return, it is subject to FanCode terms and
          conditions. No DreamCoins will be refunded. All purchases made on
          the FanCode Shop will be subject to the standard terms and
          conditions of FanCode Shop including its cancellation, return and
          delivery policy. Any complaints regarding the product purchased from
          FanCode Shop shall be resolved as per the terms and conditions of
          FanCode Shop. Special 5X DreamCoins Booster Offer Terms and
          Conditions This "Special 5X DreamCoins Booster Offer" ("Promotion")
          is open to only those users of Dream11 who can view the specific
          offer banner on their Dream11 app between January 16th to January
          22nd 2024. Under this Promotion, a user shall receive five times the
          DreamCoins earned for all paid contest joins they perform during the
          Promotion Period only for those matches which start and end between
          the Promotion Period, for which the users are eligible to earn
          DreamCoins. Please note that the user would not receive extra
          DreamCoins for any paid contests the users join if the matches do
          not start and end between January 16th to January 22nd 2024 as part
          of this Promotion. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and can view the specific offer banner in their
          Dream11 app. By participating in this Promotion, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions,
          the terms and conditions of the Dream11 game (accessible here) and
          the standard terms and conditions of DreamCoins (accessible here)
          The Promotion will be open for participation from Indian Standard
          Time ("IST") 00:00:01 hours on January 16th, 2024 till IST 23:59:59
          hours on January 22nd 2024 ("Promotion Period"). Under this
          Promotion, the 5X DreamCoins Booster will apply to all paid contest
          joins that a user can perform which earns them DreamCoins (defined
          here) under the stipulated Promotion Period only for matches that
          start and end during the Promotion Period. They will not receive 5X
          DreamCoins for any paid contest joins when the respective match of
          that contest does not start and end during the Promotion Period.
          Only performing this defined action will earn the user five times
          the DreamCoins, actions outside this list will not earn the user any
          DreamCoins. A user can receive a maximum of up to 2000 DreamCoins in
          addition to the usual naturally earned DreamCoins at the end of the
          Promotion Period. Ex: Users will receive 5x DreamCoins on all paid
          contest joins, comprising of 1x naturally earned DreamCoins and 4x
          as bonus DreamCoins provided by Dream11. Subject to a user’s
          compliance with these Terms, the DreamCoins will be credited into
          the user's DreamCoins balance by IST 23:59:59 hours the next day of
          match completion of the paid contest joined by the user. Users will
          not get DreamCoins (the usual naturally earned or bonus DreamCoins)
          for abandoned matches. The DreamCoins are valid as per the standard
          terms of the DreamCoins available here The additional bonus
          DreamCoins given via this promotion will not be counted towards the
          user’s Champions club progress. These are valid for 7 days from
          being credited in your account Bonus DreamCoins may be granted by
          Dream11 to its users at its sole discretion. Special DreamBox Terms
          and Conditions The offer is open to only those users of Dream11 who
          can view the specific offer banner on their Dream11 app between
          January 16th to January 22nd 2024. From 16-22nd January 2024, users
          joining any Paid Public contest will get guaranteed rewards via the
          Special DreamBox the next day by 6 pm. The DreamBox will expire 2
          days from the date when a user receives it i.e. the user will have 2
          days time to claim the reward through the DreamBox. Expiry of the
          Rewards received in a DreamBox is subject to terms and conditions of
          each reward. How to get a reward through DreamBox Go to the reward
          shop and click on the DreamBox fab icon to view all the collected
          DreamBoxes. Once inside, users need to select one of the four
          DreamBoxes, each of which will have a guaranteed reward inside it.
          Once the reward is disbursed, the user can’t claim the reward again
          from the same DreamBox. If the DreamBox is expired and the user
          hasn’t claimed the reward, the user won’t see the expired DreamBox
          anywhere and can no longer claim the reward. Where to view the
          rewards received through DreamBox DreamCoins will be added directly
          to your DreamCoins Balance Other rewards will be shown in the “My
          Rewards” section of Reward Shop. Rewards received through DreamBox
          Terms and Conditions: Bonus DreamCoins: Expiry of bonus DreamCoins
          will be 7 days from the date of credit. Bonus DreamCoins can be used
          towards redeeming rewards on the Rewards Shop only but will not
          count towards a user’s Champions Club Levels Progress. Check other
          DreamCoins terms and conditions here Imagine ₹80,000 Voucher Users
          who win an Imagine (Apple reseller) ₹80,000 Voucher via the Special
          DreamBox worth ₹1,14,285 (subject to deduction of 30% TDS) (“Imagine
          Voucher”) can use it on the Imagine (Apple reseller) website or
          application. The Imagine Vouchervalue received by the user post TDS
          deductions will be ₹80,000. Should the purchase value exceed the
          value of the Imagine Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. The Imagine Voucher coupon code will be available on
          “My Rewards” page of the user The ImagineVoucher cannot be exchanged
          for cash or any other benefit. The Imagine Voucher shall be valid
          till 10th January 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here. Cred
          Discount Coupon Promotion Offer: Total of Rs. 500 Off on first 3
          paid contests This Cred Coupon Offer (“Promotion”) is open to all
          users of Cred who receive a Discount Coupon on the Cred mobile
          application. Under this Promotion, new users who have this coupon
          and who install the Dream11 app via the Cred coupon page shall be
          eligible to receive a 90% discount on the contest entry amount with
          a maximum discount of upto Rs. 500 on paid public contests having a
          contest size of minimum of 10,000 players and minimum Entry Fee of
          INR 25 (“Discount Coupon”). This Promotion is subject to the users’
          full compliance with these Terms (defined below). Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered new users of Dream11 who
          receive the coupon on the Cred mobile application and have never
          deposited and joined a pay-to-play contest on the Dream11 mobile
          application. Users must also be eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 00:00:00 hours on Feb 1st, 2024 till
          IST 23:00:00 hours on Feb 29th, 2024 ("Promotion Period"). Users
          will be able to redeem the coupon within the Promotion Period only.
          The Discount Coupon shall be valid for a period of 14 days from the
          date it was first redeemed by a user. Eligible Users must redeem the
          Discount Coupon on the Dream11 mobile application within the
          Promotion Period. The Discount Coupon will give the users a 90%
          discount on the contest entry amount of the first 3 paid public
          contests having a minimum of 10,000 participants, joined during the
          Promotion Period with a maximum discount of up to Rs. 500. The
          maximum discount amount that can be availed by the user pursuant to
          this Promotion is Rs. 500. The Discount Coupon cannot be clubbed
          with any other offers, cash bonuses, or discount coupons available
          to the user. The Discount Coupon shall be available for redemption
          till Feb 29th 2024 only. This Promotion is only available to new
          users who install the Dream11 App from the "Redeem now" button on
          Cred and create an account with Dream11 by clicking on the button on
          the coupon page on their Cred app. This offer is not applicable to
          existing users of Dream11. Not valid for existing users of Dream11 &
          those who already have the app. In case of a match getting
          abandoned, the value of the coupon used will be added back as
          Discount Bonus to the user’s wallet. Dream11 reserves the right to
          cancel this Promotion or modify the terms of this Promotion in its
          sole discretion at any time without any prior notice. Republic Day
          Special DreamBox Terms and Conditions From 25-28th January 2024, on
          joining any Paid Public contest the user will get guaranteed rewards
          via the Republic Day Special DreamBox the next day by 6 pm. The
          DreamBox will expire 2 days from the date when a user receives it
          i.e. the user will have 2 days time to claim the reward through the
          DreamBox. Expiry of the Rewards received in a DreamBox is subject to
          terms and conditions of each reward. How to get a reward through
          DreamBox Go to the reward shop and click on the DreamBox fab icon to
          view all the collected DreamBoxes. Once inside, users need to select
          one of the four DreamBoxes, each of which will have a guaranteed
          reward inside it. Once the reward is disbursed, the user can’t claim
          the reward again from the same DreamBox. If the DreamBox is expired
          and the user hasn’t claimed the reward, the user won’t see the
          expired DreamBox anywhere and can no longer claim the reward. Where
          to view the rewards received through DreamBox DreamCoins will be
          added directly to your DreamCoins Balance Other rewards will be
          shown in the “My Rewards” section of Reward Shop. Rewards received
          through DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of
          Bonus DreamCoins will be 3 months from the date of credit. Bonus
          DreamCoins can be used towards redeeming rewards on the Rewards Shop
          only but will not count towards a user’s Champions Club Levels
          Progress. Check other DreamCoins terms and conditions here Imagine
          ₹80,000 Voucher Users who win an Imagine (Apple reseller) ₹80,000
          Voucher via the Republic Day Special DreamBox worth ₹1,14,285
          (subject to deduction of 30% TDS) can use it on the Imagine (Apple
          reseller) website or application. The actual voucher value received
          by the user post TDS deductions will be ₹80,000. Should the purchase
          value exceed the value of the Voucher, the differential amount of
          such purchase should be paid using the other modes of payment
          available on the website. Imagine (Apple reseller) ₹80,000 Voucher
          coupon code will be available on “My Rewards” page of the user
          Imagine (Apple reseller) ₹80,000 Voucher cannot be exchanged for
          cash or any other benefit. Imagine (Apple reseller) ₹80,000 Voucher
          shall be valid till 10th January 2025. Terms and conditions of the
          voucher shall apply. All purchases made on the Imagine (Apple
          reseller) website will be subject to the standard terms and
          conditions of the Imagine (Apple reseller) website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Imagine Gift Card shall be resolved as per
          the terms and conditions of Tresor Systems Pvt. Ltd. Winners may
          reach out to them as per the details provided in the Terms &
          Conditions outlined here FC Jersey Voucher Users who win an FC
          jersey Voucher via the Republic Day Special DreamBox worth ₹1427
          (subject to deduction of 30% TDS) can be used to purchase any items
          on FanCode website. The actual voucher value received by the user
          post TDS deductions will be ₹999. FC Jersey voucher coupon code will
          be available on “My Rewards” page of the user FC jersey voucher
          cannot be exchanged for cash or any other benefit. FC jersey voucher
          shall be valid till 30th April 2024. Terms and conditions of the FC
          jersey voucher shall apply. Should the purchase value exceed the
          value of the voucher, the differential amount of such purchase shall
          be paid by the user using the other modes of payment available on
          the website. All purchases made on the FanCode website will be
          subject to the standard terms and conditions of the FanCode website
          including its cancellation, return and delivery policy. SA20 League
          DreamBox Terms and Conditions On joining paid public contests for
          the SA20 League matches on 8th February 2024 and 10th February 2024,
          the eligible users will get guaranteed rewards via the SA20 League
          DreamBox on or before 11th February 2024 by 6 pm. Upon receipt of
          the DreamBox, a user must claim the reward within two (2) days from
          receipt (“Validity”); otherwise, the DreamBox will automatically
          expire. Expiry of the Rewards received in a DreamBox is subject to
          the terms and conditions of each reward. How to get a reward through
          DreamBox Go to the Reward Shop available on the Dream11 platform and
          click the DreamBox fab icon to view all the collected DreamBoxes.
          Once inside, a user needs to select one of the four DreamBoxes, each
          of which will have a guaranteed reward inside it. If a user receives
          a reward via the selected DreamBox, he/she will not be allowed to
          claim multiple rewards from the same DreamBox. If the DreamBox
          Validity expires, the user will no longer be eligible to claim the
          reward. A user won’t be able to see the expired DreamBox in the
          reward shop Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins balance All
          other rewards received via DreamBox will be shown in the “My
          Rewards” under the “Lifestyle and Entertainment” section of Reward
          Shop. Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Bonus DreamCoins shall expire within 3 months from the
          date of credit. Bonus DreamCoins can be used towards redeeming
          rewards on the Rewards Shop only but will not count towards a user’s
          Champions Club Levels Progress. Check other DreamCoins terms and
          conditions here Signed Official SA20 League Merchandise Ten (10)
          eligible winners will receive a Signed Official SA20 League
          Merchandise ("Reward") worth ₹2,714 (subject to a 30% TDS
          deduction). The actual value received will be ₹1,900. A user cannot
          exchange the Reward received against cash or any other item/
          benefit. The user who receives the Reward will be contacted by a
          Dream11 representative to seek the shipping address of the user.
          Dream11 will on a best effort basis try to deliver the Rewards to
          all the eligible users by 31st March 2024, once the address is
          confirmed by such user. Users must provide their address for
          delivery purposes within 24 hours of being contacted, otherwise,
          Dream11 reserves the right to cancel the Reward. These terms and
          conditions are subject to change at Dream11's discretion. By
          participating in the campaign, users agree to the terms and
          conditions mentioned above. Dream Coins Offer This “Dream Coins
          Offer” (“Promotion”) is only available for users who receive
          communication of the Promotion in their Dream11 app via an in-app or
          push notification. Through this offer, users who receive the
          communication will be eligible to win 500 Dream Coins if they join a
          Paid Public Contest within 24 hours of receiving such communication.
          Promotion Duration: Feb 13, 2024 (14:00:00) - Feb 29, 2024
          (23:59:00) Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on their Dream11 app. By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) and the standard terms and
          conditions of DreamCoins (accessible here). This offer is only valid
          if the user joins a Paid Public Contest within 24 hours of receiving
          the communication. This offer is valid only once per user & the
          Dream Coins will be credited within 48 hours of winner declaration.
          The Dream Coins once credited will have a validity of 14 Days and
          can be used to redeem rewards available in the Reward Shop. The
          bonus DreamCoins received in furtherance of this Promotion will not
          contribute to the Champions Club Level progress for the user.
          Dream11 Multiplier DreamCoins Booster Offer Terms and Conditions
          This "Dream11 Multiplier DreamCoins Booster Offer" ("Promotion") is
          open to only those users of Dream11 who view the specific reward on
          their Rewards page on the Dream11 app and who have redeemed the
          DreamCoin Booster from the reward shop for the required DreamCoins
          during the Promotion Period. Under this Promotion, a user shall
          receive four times the DreamCoins earned for all contests joined
          using winning balance during the Promotion Period for which they are
          eligible to earn DreamCoins. Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 public contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions, the terms and conditions of the
          Dream11 game (accessible here) and the standard terms and conditions
          of DreamCoins (accessible here) The Promotion will be open for
          participation from Indian Standard Time ("IST") 00:00:00 hours on
          February 24th 2024 till IST 23:59:59 hours on March 01st 2024
          ("Promotion Period"). Under this Promotion, the booster will apply
          to all contest joins done using winning balance which earns them
          DreamCoins (defined here) under the stipulated Promotion Period.
          Only performing these defined actions will earn the user four times
          the DreamCoins, actions outside this list will not earn the user any
          DreamCoins. The bonus DreamCoins will be given over and above the
          DreamCoins earned based on joining the contest. Ex: Users will
          receive 5x DreamCoins on all contest joins with winnings, comprising
          of 1x naturally earned DreamCoins and 4x as bonus DreamCoins
          provided by Dream11. Subject to a user’s compliance with these
          Terms, the DreamCoins will be credited into the user's DreamCoins
          balance by IST 23:59:59 hours on 2nd March, 2024. The additional
          bonus DreamCoins given via this promotion will not be counted
          towards the user’s Champions Club Level progress. The validity of
          the bonus DreamCoins will be 3 weeks from the date of the same being
          credited to the user's DreamCoin balance. Dream11 Payment Offer
          (Deposit 10 get 10) Terms and Conditions This "Dream11 Payment Offer
          (Deposit 10 get 10)" ("Promotion") is open to all the users of the
          Dream11 app. Under this Promotion, users who deposit exactly INR 10
          using Google Pay during the Promotion Period (defined below) will be
          eligible to receive a discount bonus amount of INR 10 ("Bonus
          Amount"), in accordance with and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here). By participating
          in this Promotion, user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions and the terms and
          conditions of the Dream11 game (accessible here) The Promotion will
          be open for participation from February 28th, 2024, IST 19:10:00
          hours to February 28th, 2024, IST 19:20:00 hours ("Promotion
          Period"). To participate in the Promotion, users must make a cash
          deposit of exactly INR 10 using Google Pay (“Qualifying Deposit”) to
          receive the Bonus Amount. Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period.
          Deposits made using Google pay of exactly INR 10 are applicable for
          this Promotion. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on March 1st, 2024. Offer can be availed 10 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Pay-to-play
          deposit Offers Terms and Conditions This "Pay-to-play deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make a applicable qualifying deposit as displayed in the ‘Add Cash’
          page of the user’s Dream11 account (“Qualifying Deposit”). Users
          will be eligible to receive a Discount bonus as displayed in the Add
          Cash page Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11. By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 12:00:00 hours on Mar 2nd, 2024 till
          IST 12:00:00 hours on Mar 9th, 2024 ("Promotion Period"). To
          participate in the Promotion, users must make at least one cash
          deposit of the amount(s) specified in the Add Cash page of the
          user’s Account . Users must deposit during the Promotion Period to
          be eligible to receive the applicable Discount Bonus. No deposit
          made by the user to the Dream11 unutilised account before or after
          the Promotion Period will be considered for the purpose of this
          Promotion. Please note that the Discount Bonus to be given to the
          user, will depend on the value of the Qualifying Deposit made by the
          user during the Promotion Period. The Discount Bonus shall be a
          percentage of the deposit amount as displayed on the Add Cash page
          of the user’s Account. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the respective Discount Bonus Amount shall be credited only
          once for the first Qualifying Deposit amount as communicated on the
          app. Subject to users’ compliance with these Terms, the Discount
          Bonus will be credited into users’ discount bonus Account within 48
          hours of deposit. Unutilized Discount Bonus will expire in 14 days
          from the date that it has been credited. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Once the Discount Bonus is utilized in
          part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Tata Women Premier League DreamBox Terms and Conditions
          From 2nd-4th March 2024, on joining WPL Paid Public contest the user
          will get guaranteed rewards via the Tata WPL DreamBox the next day
          by 12 PM. The DreamBox will expire 2 days from the date when a user
          receives it i.e. the user will have 2 days time to claim the reward
          through the DreamBox. Expiry of the Rewards received in a DreamBox
          is subject to terms and conditions of each reward. How to get a
          reward through DreamBox Go to the reward shop and click on the
          DreamBox fab icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Expiry of
          Bonus DreamCoins will be 7 days from the date of credit. Bonus
          DreamCoins can be used towards redeeming rewards on the Rewards Shop
          only but will not count towards a user’s Champions Club Levels
          Progress. Check other DreamCoins terms and conditions here Tata WPL
          Hospitality tickets Users who win TATA WPL Live match hospitality
          tickets via DreamBox given on 3rd, 4th and 5th March for TATA WPL
          Match taking place on March 9th, March 10th, and March 11th 2024
          resp. TDS applicable on the tickets shall be borne by Dream11. The
          TATA WPL live match hospitality tickets cannot be exchanged for cash
          or any other benefit. The TATA WPL live match hospitality tickets
          shall be valid for one of the match taking place on March 9th 2024
          match, March 10th 2024 and March 11th 2024 between Mumbai Indians-
          Women Vs Gujarat Giants - Women, Delhi Capitals - Women Vs Royal
          Challengers - Bangalore, Gujarat Giants - Women Vs UP warriorz
          respectively happening in Arun Jaitley Stadium, Delhi In the event
          of a winner not being a verified user on Dream11, the winner will be
          required to verify his/ her details within a period of two (2) days,
          from the date of winner declaration. In the event the winner fails
          to verify his/her details on Dream11, the prize for such winner
          shall be forfeited. This includes the following for one person: A
          single match ticket in the hospitality stands for one of the match
          taking place on March 9th 2024, March 10th 2024 and March 11th 2024
          between Mumbai Indians- Women Vs Gujarat Giants - Women, Delhi
          Capitals - Women Vs Royal Challengers - Bangalore, Gujarat Giants -
          Women Vs UP warriorz . These will include food & beverages Users
          shall be solely responsible to book and pay for the travel from a
          user’s home city to Delhi and all other expenses not included in the
          jackpot reward. This reward will always be subject to the following
          standard terms and conditions . No cancellation or return would be
          allowed for this reward. All complaints in relation to the
          redemption of the Match Tickets shall be resolved as per these terms
          and conditions. If a match gets canceled or abandoned without a
          single ball being bowled, then Dream11 shall not be held responsible
          in any manner and Dream11 shall not provide any alternate match
          tickets. Dream11 Payment Offer (Deposit 10 get 10) Terms and
          Conditions This "Dream11 Payment Offer (Deposit 10 get 10)"
          ("Promotion") is open to all the users of the Dream11 app. Under
          this Promotion, users who deposit exactly INR 10 using Google Pay
          during the Promotion Period (defined below) will be eligible to
          receive a discount bonus amount of INR 10 ("Bonus Amount"), in
          accordance with and subject to the users’ full compliance with these
          Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here). By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from March 8th, 2024, IST 19:05:00 hours to March 8th,
          2024, IST 19:20:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 10 using
          Google Pay (“Qualifying Deposit”) to receive the Bonus Amount.
          Please note that only the Qualifying Deposit made by a user during
          the Promotion Period shall be considered for determining whether a
          user is eligible to receive the Bonus Amount and not any deposits
          that the user makes into such user’s Dream11 account before or after
          the Promotion Period. Deposits made using Google pay of exactly INR
          10 are applicable for this Promotion. Subject to users’ compliance
          with these Terms, the Bonus Amount will be credited into users’
          Discount Bonus Account by IST 23:59:59 hours on March 10th, 2024.
          Offer can be availed 10 times per user post a successful transaction
          during the Promotion Period. Unutilized Bonus Amount will expire in
          14 days from the date that it has been credited. The Bonus Amount
          cannot be clubbed with any other coupon or offers available with the
          user and/ or the Dream11 platform. Once the Bonus Amount is utilized
          in part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Terms and Conditions This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to the users of
          the Dream11 app who receive the communication via notification on
          the Dream11 app. Under this Promotion, users who deposit exactly INR
          5 during the Promotion Period (defined below) will be eligible to
          receive a deposit bonus amount of INR 10 ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from March 9th, 2024, IST 19:05:00 hours to March 9th,
          2024, IST 19:20:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 5
          (“Qualifying Deposit”) to receive the Deposit Bonus Amount. Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Discount Bonus Account by IST
          23:59:59 hours on March 11th, 2024. Offer can be availed 15 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer Dep2Get10 Terms and Conditions This "Dream11
          Deposit Bonus Offer" ("Promotion") is open to the users of the
          Dream11 app who receive the communication via notification on the
          Dream11 app. Under this Promotion, users who deposit exactly INR 2
          during the Promotion Period (defined below) will be eligible to
          receive a deposit bonus amount of INR 10 ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from March 10th, 2024, IST 19:10:00 hours to March
          10th, 2024, IST 19:20:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 2
          (“Qualifying Deposit”) to receive the Deposit Bonus Amount. Please
          note that only the Qualifying Deposit made by a user during the
          Promotion Period shall be considered for determining whether a user
          is eligible to receive the Bonus Amount and not any deposits that
          the user makes into such user’s Dream11 account before or after the
          Promotion Period. All payment instruments are applicable for this
          Promotion. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Discount Bonus Account by IST
          23:59:59 hours on March 12th, 2024. Offer can be availed 15 times
          per user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream Sale :
          Mar 17 - Mar 24 This Dream Sale (“Sale”) is open to all users of
          Dream11 who participate in at least one Mega Contest with a prize
          pool of 40 Crore+ (“Contest”) of the first five matches of the
          Indian T20 League. Eligibility Criteria: This Sale is only available
          to registered users of Dream11 who are eligible to participate in
          the pay-to-play Dream11 contests as per Dream11’s terms and
          conditions (accessible here ). By participating in this Sale, you
          agree to comply with these Terms, and the terms and conditions of
          the Dream11 game (accessible here) The Sale will be open from Indian
          Standard Time ("IST") 16:00:00 hours on March 17th, 2024 till IST
          19:30:00 hours on March 24th, 2024 ("Sale Period"). Users who
          participate in at least one Mega Contest with a prize pool of 40
          Crore+ of the first 5 Indian T20 League matches, shall be considered
          eligible participants for the Sale. The user is provided with a
          discount of 50% off upto Rs.30 only on their first Contest joined in
          the Mega Contest with a prize pool of 40 Crore+ during the first
          five matches of the Indian T20 League. For avoidance of doubt, this
          discount is only applicable once per user during the Sale period. In
          the Mega Contest with a prize pool of 40 Crore+ of the first 5
          Indian T20 League matches, the top 11 ranks in the leaderboard will
          secure a prize of Rs.1 Crore or such other amount as displayed or
          seen on the platform for the relevant match. Winners shall receive
          an email communication confirming their winnings within 24 hours of
          the conclusion of the match. In the event the match is abandoned or
          canceled, this Sale shall be null and void for that particular match
          . In the event winners do not receive an email with a link they can
          reach out to the helpdesk within 7 days of winner declaration. In
          the event two or more users have the same team points at the end of
          each match in the Sale Period, then the following tiebreaker will be
          used. It is clarified that for the purpose of this Tiebreaker, the
          teams used to join the Mega contest only shall be considered at the
          end of the match to determine the winners and not teams created.
          Tiebreaker Rule - If there is a tie, the Prize will be distributed
          evenly among the declared winners falling within the same rank.
          Referral Discount - Dream Sale This "Referral DreamSale Discount"
          ("Promotion") is open to users of Dream11 app who receive the banner
          via in-app communication. Under this campaign users can refer their
          friends during the campaign duration to receive additional
          discounts. Only those friends that register and join at least one
          public cash contest would qualify as a referral under this campaign.
          Based on the number of referrals done by a user during the Promotion
          Period (defined below) , the user shall receive Discount Coupons
          subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Promotion Period - The Promotion will be open from Indian Standard
          Time ("IST") 00:16:00 hours from March 17th, 2024 till IST 23:59:00
          hours on March 24th, 2024 ("Promotion Period") Eligibility –
          Participation in the Promotion is only available to registered users
          of Dream11 who receive the banner in app communication and are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) By participating in
          this Promotion, you agree to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) Users who participate in the
          Promotion during the Promotion Period and refer new users to
          Dream11, shall be eligible to receive Discount Coupons, the value of
          which will be 75% OFF up to Rs.50 per friend that joins a paid
          public contest during the Promotion Period. Subject to your
          compliance with these Terms, the Discount Coupon will be credited to
          users’ account by IST 10:00:00 hours on a daily basis during the
          Promotion Period depending on the number of referrals completed. The
          Discount Coupon shall be valid for 5 Days from the date of credit.
          The Discount Coupon given under this Promotion is in addition to the
          existing referral rewards given to the users. Dream11 reserves the
          right to cancel this Promotion at any time in its sole discretion
          without assigning any reason thereto. The Discount Coupons will only
          be applicable for public contests with a league size greater than
          20,000 Dream11 Deposit Bonus Offer Dep2Get10 Terms and Conditions
          This "Dream11 Deposit Bonus Offer" ("Promotion") is open to the
          users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 2 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 10 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app banner communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from March 15th, 2024, IST 19:10:00 hours to March
          15th, 2024, IST 19:20:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 2
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on March 17th, 2024. Offer can be availed a
          maximum of 15 times per user post a successful transaction during
          the Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 50 Get 10 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 50 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 10 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 3rd, 2024, IST 17:00:00 hours to April 3rd,
          2024, IST 19:00:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 50
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on April 4th, 2024. Offer can be availed once per
          user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer Deposit 100 Get 20 Terms and Conditions This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to the users of
          the Dream11 app who receive the communication via notification on
          the Dream11 app. Under this Promotion, users who deposit exactly INR
          100 during the Promotion Period (defined below) will be eligible to
          receive a deposit bonus amount of INR 20 ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          communication from Dream11 By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          April 3rd, 2024, IST 17:00:00 hours to April 3rd, 2024, IST 19:00:00
          hours ("Promotion Period"). To participate in the Promotion, users
          must make a cash deposit of exactly INR 100 (“Qualifying Deposit”)
          to receive the Bonus Amount. Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period. Subject
          to users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Discount Bonus Account by IST 23:59:59 hours on
          April 4th, 2024. Offer can be availed once per user post a
          successful transaction during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus Offer
          Deposit 500 Get 50 Terms and Conditions This "Dream11 Deposit Bonus
          Offer" ("Promotion") is open to the users of the Dream11 app who
          receive the communication via notification on the Dream11 app. Under
          this Promotion, users who deposit exactly INR 500 during the
          Promotion Period (defined below) will be eligible to receive a
          deposit bonus amount of INR 50 ("Bonus Amount"), in accordance with,
          and subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from April 3rd, 2024,
          IST 17:00:00 hours to April 3rd, 2024, IST 19:00:00 hours
          ("Promotion Period"). To participate in the Promotion, users must
          make a cash deposit of exactly INR 500 (“Qualifying Deposit”) to
          receive the Bonus Amount. Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period. Subject
          to users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Discount Bonus Account by IST 23:59:59 hours on
          April 4th, 2024. Offer can be availed once per user post a
          successful transaction during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus Offer
          Deposit 1000 Get 100 Terms and Conditions This "Dream11 Deposit
          Bonus Offer" ("Promotion") is open to the users of the Dream11 app
          who receive the communication via notification on the Dream11 app.
          Under this Promotion, users who deposit exactly INR 1000 during the
          Promotion Period (defined below) will be eligible to receive a
          deposit bonus amount of INR 100 ("Bonus Amount"), in accordance
          with, and subject to the users’ full compliance with these Terms
          (defined below). Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app communication from
          Dream11 By participating in this Promotion, user agrees to comply
          with these Terms, the Standard Terms and Conditions for Promotions
          and the terms and conditions of the Dream11 game (accessible here)
          The Promotion will be open for participation from April 3rd, 2024,
          IST 17:00:00 hours to April 3rd, 2024, IST 19:00:00 hours
          ("Promotion Period"). To participate in the Promotion, users must
          make a cash deposit of exactly INR 1000 (“Qualifying Deposit”) to
          receive the Bonus Amount. Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 account before or after the Promotion Period. Subject
          to users’ compliance with these Terms, the Bonus Amount will be
          credited into users’ Discount Bonus Account by IST 23:59:59 hours on
          April 4th, 2024. Offer can be availed once per user post a
          successful transaction during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Dream11 Deposit Bonus Offer
          Deposit 50 Get 10 Terms and Conditions This "Dream11 Deposit Bonus
          Offer" ("Promotion") is open to the users of the Dream11 app who
          receive the communication via notification on the Dream11 app. Under
          this Promotion, users who deposit exactly INR 50 during the
          Promotion Period (defined below) will be eligible to receive a
          deposit bonus amount of INR 10 ("Bonus Amount"), in accordance with,
          and subject to the users’ full compliance with these Terms (defined
          below). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app communication from
          Dream11 By participating in this Promotion, the user agrees to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here The Promotion will be open for participation from
          April 3rd, 2024, IST 19:00:00 hours to April 3rd, 2024, IST 19:30:00
          hours ("Promotion Period"). To participate in the Promotion, users
          must make a cash deposit of exactly INR 50 (“Qualifying Deposit”) to
          receive the Bonus Amount. Please note that only the Qualifying
          Deposit made by a user during the Promotion Period shall be
          considered for determining whether a user is eligible to receive the
          Bonus Amount and not any deposits that the user makes into such
          user’s Dream11 Unutilized Account before or after the Promotion
          Period. Subject to users’ compliance with these Terms, the Bonus
          Amount will be credited into users’ Discount Bonus Account by IST
          23:59:59 hours on April 4th, 2024. Offer can be availed once per
          user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer Deposit 100 Get 20 Terms and Conditions This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to the users of
          the Dream11 app who receive the communication via notification on
          the Dream11 app. Under this Promotion, users who deposit exactly INR
          100 during the Promotion Period (defined below) will be eligible to
          receive a deposit bonus amount of INR 20 ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          communication from Dream11 By participating in this Promotion, the
          user agrees to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) The Promotion will be open for
          participation from April 3rd, 2024, IST 19:00:00 hours to April 3rd,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 100
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 4th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 500 Get 50 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 500 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 50 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 3rd, 2024, IST 19:00:00 hours to April 3rd,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 500
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 4th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 1000 Get 100 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 1000 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 100
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 3rd, 2024, IST 19:00:00 hours to April 3rd,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, the users must make a cash deposit of exactly INR 1000
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on April 4th, 2024. Offer can be availed once per
          user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Pay-to-play
          deposit Offers Terms and Conditions This "Pay-to-play deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make a applicable qualifying deposit as displayed in the ‘Add Cash’
          page of the user’s Dream11 account (“Qualifying Deposit”). Users
          will be eligible to receive a Discount bonus as displayed in the Add
          Cash page Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11. By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          Indian Standard Time ("IST") 16:00:00 hours on April 4th, 2024 till
          IST 18:00:00 hours on April 4th, 2024 ("Promotion Period"). To
          participate in the Promotion, users must make at least one cash
          deposit of the amount(s) specified in the Add Cash page of the
          user’s Account . Users must deposit during the Promotion Period to
          be eligible to receive the applicable Discount Bonus. No deposit
          made by the user to the Dream11 unutilised account before or after
          the Promotion Period will be considered for the purpose of this
          Promotion. Please note that the Discount Bonus to be given to the
          user, will depend on the value of the Qualifying Deposit made by the
          user during the Promotion Period. The Discount Bonus shall be a
          percentage of the deposit amount as displayed on the Add Cash page
          of the user’s Account. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the respective Discount Bonus Amount shall be credited only
          once for the first Qualifying Deposit amount as communicated on the
          app. Subject to users’ compliance with these Terms, the Discount
          Bonus will be credited into users’ discount bonus Account within 48
          hours of deposit. Unutilized Discount Bonus will expire in 14 days
          from the date that it has been credited. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Once the Discount Bonus is utilized in
          part or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 reserves the right to change/modify/or withdraw any
          of the Promotions and/or change these Standard Terms and/or the
          Promotion Terms without any prior notice of the same at its sole
          discretion. Dream11 Deposit Bonus Offer Deposit 50 Get 10 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 50 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 10 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 14:00:00 hours to April 4th,
          2024, IST 19:00:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 50
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 100 Get 20 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 100 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 20 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11. By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here). The Promotion will be open for
          participation from April 4th, 2024, IST 14:00:00 hours to April 4th,
          2024, IST 19:00:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 100
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 500 Get 50 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 500 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 50 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 14:00:00 hours to April 4th,
          2024, IST 19:00:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 500
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 1000 Get 100 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 1000 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 100
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 14:00:00 hours to April 4th,
          2024, IST 19:00:00 hours ("Promotion Period"). To participate in the
          Promotion, the users must make a cash deposit of exactly INR 1000
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on April 5th, 2024. Offer can be availed once per
          user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Dream11
          Deposit Bonus Offer Deposit 50 Get 10 Terms and Conditions This
          "Dream11 Deposit Bonus Offer" ("Promotion") is open to the users of
          the Dream11 app who receive the communication via notification on
          the Dream11 app. Under this Promotion, users who deposit exactly INR
          50 during the Promotion Period (defined below) will be eligible to
          receive a deposit bonus amount of INR 10 ("Bonus Amount"), in
          accordance with, and subject to the users’ full compliance with
          these Terms (defined below). Please note our terms and conditions
          ("Terms") in this respect, as they govern your participation in the
          Promotion: Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who are eligible to
          participate in the pay-to-play Dream11 contests as per Dream11’s
          terms and conditions (accessible here) and have received in-app
          communication from Dream11 By participating in this Promotion, the
          user agrees to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of the
          Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 19:00:00 hours to April 4th,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 50
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 100 Get 20 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 100 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 20 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11. By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here). The Promotion will be open for
          participation from April 4th, 2024, IST 19:00:00 hours to April 4th,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 100
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 500 Get 50 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 500 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 50 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 19:00:00 hours to April 4th,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 500
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilised Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 5th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 1000 Get 100 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 1000 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 100
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 4th, 2024, IST 19:00:00 hours to April 4th,
          2024, IST 19:30:00 hours ("Promotion Period"). To participate in the
          Promotion, the users must make a cash deposit of exactly INR 1000
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 account before or after the
          Promotion Period. Subject to users’ compliance with these Terms, the
          Bonus Amount will be credited into users’ Discount Bonus Account by
          IST 23:59:59 hours on April 5th, 2024. Offer can be availed once per
          user post a successful transaction during the Promotion Period.
          Unutilized Bonus Amount will expire in 14 days from the date that it
          has been credited. The Bonus Amount cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Once the Bonus Amount is utilized in part or whole, the
          user cannot claim any refund of the Qualifying Deposit. Pay-to-play
          deposit Offers Terms and Conditions This "Pay-to-play deposit
          Offers" ("Promotion") is open to all users of Dream11 app who see
          the banner via in-app communication. Under this Promotion, users who
          make a applicable qualifying deposit as displayed in the ‘Add Cash’
          page of the user’s Dream11 account (“Qualifying Deposit”). Users
          will be eligible to receive a Discount bonus as displayed in the Add
          Cash page Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests and have received in-app banner
          communication from Dream11. By participating in this Promotion, user
          agrees to comply with these Terms, the Standard Terms and Conditions
          for Promotions and the terms and conditions of the Dream11 game
          (accessible here) To participate in the Promotion, users must make
          at least one cash deposit of the amount(s) specified in the Add Cash
          page of the user’s Account . Users must deposit during the Promotion
          Period to be eligible to receive the applicable Discount Bonus. No
          deposit made by the user to the Dream11 unutilised account before or
          after the Promotion Period will be considered for the purpose of
          this Promotion. Please note that the Discount Bonus to be given to
          the user, will depend on the value of the Qualifying Deposit made by
          the user during the Promotion Period. The Discount Bonus shall be a
          percentage of the deposit amount as displayed on the Add Cash page
          of the user’s Account. There is no maximum limit on the number of
          Qualifying Deposits made by a user during the Promotion Period.
          However, the respective Discount Bonus Amount shall be credited only
          once for the first Qualifying Deposit amount as communicated on the
          app. Subject to users’ compliance with these Terms, the Discount
          Bonus will be credited into users’ discount bonus Account within 48
          hours of deposit. Unutilized Discount Bonus will expire in 14 days
          from the date that it has been credited. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Once the Discount Bonus is utilized in
          part or whole, the user cannot claim any refund of the Qualifying
          Deposit. The promotional period shall be as specified and
          communicated within the application. Dream11 Deposit Bonus Offer
          Deposit 1111 Get 555 Terms and Conditions This "Dream11 Deposit
          Bonus Offer" ("Promotion") is open to the users of the Dream11 app
          who receive the communication via notification on the Dream11 app.
          Under this Promotion, users who deposit exactly INR 1111 during the
          Promotion Period (defined below) will be eligible to receive a
          deposit bonus amount of INR 555 ("Bonus Amount"), in accordance
          with, and subject to the users’ full compliance with these Terms
          (defined below). Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and have received in-app communication from
          Dream11 By participating in this Promotion, the user agrees to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) The Promotion will be open for participation from
          April 17th, 2024, IST 15:00:00 hours to April 17th, 2024, IST
          17:00:00 hours ("Promotion Period"). To participate in the
          Promotion, users must make a cash deposit of exactly INR 1111
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 18th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 555 Get 250 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 555 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 250
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 17th, 2024, IST 15:00:00 hours to April
          17th, 2024, IST 17:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 555
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 18th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 333 Get 151 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 333 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 151
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 17th, 2024, IST 15:00:00 hours to April
          17th, 2024, IST 17:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 333
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 18th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 111 Get 55 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 111 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 55 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 17th, 2024, IST 15:00:00 hours to April
          17th, 2024, IST 17:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 111
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 18th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 1111 Get 555 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 1111 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 555
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 18th, 2024, IST 20:00:00 hours to April
          19th, 2024, IST 18:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 1111
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 20th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 555 Get 250 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 555 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 250
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 18th, 2024, IST 20:00:00 hours to April
          19th, 2024, IST 18:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 555
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 20th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 333 Get 151 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 333 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 151
          ("Bonus Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 18th, 2024, IST 20:00:00 hours to April
          19th, 2024, IST 18:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 333
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 20th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. Dream11 Deposit Bonus Offer Deposit 111 Get 55 Terms and
          Conditions This "Dream11 Deposit Bonus Offer" ("Promotion") is open
          to the users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 111 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 55 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from April 18th, 2024, IST 20:00:00 hours to April
          19th, 2024, IST 18:00:00 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 111
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account by IST 23:59:59 hours on April 20th, 2024. Offer can be
          availed once per user post a successful transaction during the
          Promotion Period. Unutilized Bonus Amount will expire in 14 days
          from the date that it has been credited. The Bonus Amount cannot be
          clubbed with any other coupon or offers available with the user and/
          or the Dream11 platform. Once the Bonus Amount is utilized in part
          or whole, the user cannot claim any refund of the Qualifying
          Deposit. April Discount Pass This “April Discount Pass”
          (“Promotion”) is only available for users who receive communication
          of the Promotion in their Dream11 app. Through this Promotion, users
          who receive the communication will be eligible to join a public paid
          contest and win discount passes as detailed below. Join public paid
          contests as communicated and get a 5% or 10% discount pass upon
          availing of the relevant offer. Promotion Period: 23rd April 2024
          (IST 22:00:00 hours) to 29th April 2024 (IST 19:30:00 hours) Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on the Dream11 app. By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions, and the terms and conditions of the Dream11 game
          (accessible here) The discount pass will be given to the eligible
          users only after they claim the discount from the withdrawals page
          on or before 29th April 2024 before 19:30:00 hours. The discount
          pass once credited will have a validity of 1 Hour and can be
          redeemed while joining only public paid contest. Discount pass is
          non-transferable and cannot be withdrawn. No-Withdrawal Bonus This
          “No-Withdrawal Bonus” (“Promotion”) is only available for users who
          receive communication of the Promotion in their Dream11 app. Through
          this Promotion, users who receive the communication will be eligible
          for a bonus amount equivalent to an amount as communicated on their
          Dream11 app (“Discount Bonus”) if they don't withdraw the winning
          balance from 25th April 2024 (00:00:00) to 01st May 2024 (23:59:59).
          Promotion Period: 25th April 2024 (IST 00:00:00 hours) to 01st May
          2024 (IST 23:59:59 hours) Upon availing the Promotion, the user will
          get the Discount Bonus amount credited to Dream11 Account by 3rd May
          2024 (23:59:59). Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the Promotion:
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here) The Discount Bonus as mentioned in
          the Promotion once credited will have a validity of 14 days and can
          be only be redeemed while joining public paid contests. The Discount
          Bonus is non-transferable and cannot be withdrawn. The Discount
          Bonus cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. This Promotion offer can
          be availed once per user during the Promotion Period. Dream Dhamaka
          Weekend : Apr 26 - Apr 28 This Dream Dhamaka (“Offer”) is open to
          all users of Dream11 who participate in at least one Mega Contest
          with a prize pool of 8 Crore+ of the 4 matches of Indian T20 League
          starting between April 27th and April 28th. Following are the
          eligible matches (hereinafter individually referred to as “Match”
          and collectively as “Matches”) DC vs MI (27th April, IST 15:30:00
          hours) LKN vs RR (27th April, IST 19:30:00 hours) GT vs RCB (28th
          April, IST 15:30:00 hours) CHE vs SRH (28th April, IST 19:30:00
          hours) Eligibility Criteria: This Offer is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here). By participating in this Offer, you agree to
          comply with these Terms, and the terms and conditions of the Dream11
          game (accessible here) The Offer will be open from Indian Standard
          Time ("IST") 19:30:00 hours on April 26th , 2024 till IST 19:30:00
          hours on April 28th, 2024 ("Offer Period"). Users who participate in
          at least one Mega Contest with a prize pool of 8 Crore+ in any of
          the Matches, shall be considered an eligible participant for the
          Offer. In the Mega Contest with a prize pool of 8 Crore+ in the
          Matches, there will be a total of at least 15 ranks across all the
          leaderboards put together that will secure a prize of Rs.1 Crore
          each or such other amount as displayed or seen on the platform for
          the relevant match (“Prize”). In the event two or more users have
          the same team points at the end of each Match in the Offer Period,
          then the following tiebreaker will be used. It is clarified that for
          the purpose of this Tiebreaker, the teams used to join the Mega
          Contest with a prize pool of 8 Crore+ only shall be considered at
          the end of the Match to determine the winners and not the teams
          created. Tiebreaker Rule - If there is a tie, the Prize will be
          distributed evenly among the declared winners falling within the
          same rank. Dream Dhamaka Weekend Audi : Apr 26 - Apr 28 This Dream
          Dhamaka (“Offer”) is open to all users of Dream11 who participate in
          at least one Mega Contest with a prize pool of 30 Crore+ of the
          following matches of Indian T20 League . (hereinafter individually
          referred to as “Match” and collectively as “Matches”) DC vs MI (27th
          April, IST 15:30:00 hour) GT vs RCB (28th April, IST 15:30:00 hour)
          Eligibility Criteria: This Offer is only available to registered
          users of Dream11 who are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here). By participating in this Offer, you agree to comply with
          these Terms, and the terms and conditions of the Dream11 game
          (accessible here) The Offer will be open from Indian Standard Time
          ("IST") 19:30:00 hours on April 26th , 2024 till IST 15:30:00 hours
          on April 28th, 2024 ("Offer Period") Users who participate in at
          least one Mega Contest with a prize pool of 30 Crore+ in any of the
          Matches, shall be considered an eligible participant for the Offer.
          The user who comes first or secures the first rank in any one of the
          Mega Contest with a prize pool of 30 Crore+ of the Matches shall win
          an Audi A4 Car (“Prize(s)”). Winners shall receive an email
          communication confirming their winnings within 24 hours of the
          conclusion of the match. Prizes will not be given for abandoned
          matches. The Prize is subject to TDS and shall be borne solely by
          the winners. In the event, a winner chooses to receive the cash
          equivalent of the Prize, the user must reach out to the helpdesk.
          The cash equivalent of the Prize shall be subject to a deduction of
          30% TDS deductions. The value of the Prize shall be of ex-showroom
          Delhi. The winner shall be solely liable for submission of all
          required documents at the time of receiving the Prize. The list of
          required documents shall be communicated to the winner by Dream11.
          The Prize if taken in the form of cash shall be transferred only to
          the registered bank account of the user (as seen on the Dream11 app)
          and not to any other account. In the event of a winner not being a
          verified user on Dream11, the winner will be required to verify
          his/her details within a period of fourteen (14) days, from the date
          of winner declaration. Failure by the winner to adhere to the above
          timelines and requirements, Dream11 shall select the immediate next
          qualifying rank as the winner. Dream11 is not responsible for any
          cost related to the Prize including but not limited to costs of
          maintenance once the Prize has been delivered to the winner. The
          delivery timeline of the Prize shall be subject to availability and
          registration of the nearest authorized dealership. In the event the
          winner is unable to produce the required valid documents, the Prize
          shall be forfeited. Upon delivery and acceptance of the Prize by the
          winner, Dream11 is discharged of all its liabilities under this
          Offer. In the event winners do not receive an email with a link they
          can reach out to the helpdesk within 7 days of winner declaration.
          In the event two or more users have the same team points at the end
          of each Match (DC vs MI or GT vs RCB) in the Offer Period, then the
          following tiebreaker will be used. It is clarified that for the
          purpose of this Tiebreaker, the teams used to join the Mega Contest
          with a prize pool of 30 Crore+ only shall be considered at the end
          of the Match to determine the winners and not the teams created.
          Tiebreaker Rule - If there is a tie, the team that joined the Mega
          Contest with a prize pool of 30 Crore+ first will be eligible to win
          the Prize. Dream11 Multiplier DreamCoins Booster Offer Terms and
          Conditions This "Dream11 Multiplier DreamCoins Booster Offer"
          ("Promotion") is open to only those users of Dream11 who view the
          specific reward on their Rewards page on the Dream11 app and who
          have redeemed the DreamCoin Booster from the reward shop for the
          required DreamCoins during the Promotion Period. Under this
          Promotion, a user shall receive four times the DreamCoins earned for
          all contests joined using winning balance during the Promotion
          Period for which they are eligible to earn DreamCoins. Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 public
          contests as per Dream11’s terms and conditions (accessible here) By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, the terms
          and conditions of the Dream11 game (accessible here) and the
          standard terms and conditions of DreamCoins (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 18:00:00 hours on April 25th 2024 till IST 23:59:59 hours on
          May 2nd 2024 ("Promotion Period"). Under this Promotion, the booster
          will apply to all contest joins done using winning balance which
          earns them DreamCoins (defined here) during the stipulated Promotion
          Period. Only performing these defined actions will earn the user
          four times the DreamCoins. Actions outside this list will not earn
          the user any DreamCoins. The bonus DreamCoins will be given over and
          above the DreamCoins earned based on joining the contest. Ex: Users
          will receive 5x DreamCoins on all contest joins with winnings,
          comprising of 1x naturally earned DreamCoins and 4x as bonus
          DreamCoins provided by Dream11. Subject to a user’s compliance with
          these Terms, the DreamCoins will be credited into the user's
          DreamCoins balance by IST 23:59:59 hours on 3rd May, 2024. The
          additional bonus DreamCoins given via this promotion will not be
          counted towards the user’s Champions Club Level progress. The
          validity of the bonus DreamCoins will be 3 weeks from the date of
          the same being credited to the user's DreamCoin balance. 3X
          DreamCoins Booster Offer Terms and Conditions This "3X DreamCoins
          Booster Offer" ("Promotion") is open to only those users of Dream11
          who can view the specific offer banner on their Dream11 app. Under
          this Promotion, a user shall receive three times the DreamCoins
          earned for all paid contest joins they perform for the Indian T20
          League tour during the Promotion Period, only for those matches
          which start during the Promotion Period and end before 3rd May 2024,
          for which the users are eligible to earn DreamCoins. Please note
          that the user would not receive extra DreamCoins for any paid
          contests the users join if the matches do not start during the
          Promotion Period and end before 3rd May 2024 as part of this
          Promotion. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and can view the specific offer banner in their
          Dream11 app. Promotion is open to only those users of Dream11 who
          can view the specific offer banner on their Dream11 app By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, the terms
          and conditions of the Dream11 game (accessible here) and the
          standard terms and conditions of DreamCoins (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 00:00:01 hours on April 26th, 2024 till IST 23:59:59 hours
          on May 2nd 2024 ("Promotion Period"). Under this Promotion, the 3X
          DreamCoins Booster will apply to all paid contest joins that a user
          can perform which earns them DreamCoins (defined here) under the
          stipulated Promotion Period only for all matches that start during
          the Promotion Period and end before 3rd May 2024. They will not
          receive 3X DreamCoins for any paid contest joins when the respective
          match of that contest does not start during the Promotion Period and
          end before 3rd May 2024. Users need to earn the natural DreamCoins
          during the promotion period. If users are not eligible for the
          naturally earned DreamCoins, they will also not be eligible for the
          Bonus DreamCoins. Only performing this defined action will earn the
          user three times the DreamCoins, actions outside this list will not
          earn the user any DreamCoins. A user can receive a maximum of up to
          2000 DreamCoins in addition to the usual naturally earned DreamCoins
          at the end of the Promotion Period. Ex: Users will receive 3X
          DreamCoins on all paid contest joins, comprising of 1x naturally
          earned DreamCoins and 2X as bonus DreamCoins provided by Dream11.
          Subject to a user’s compliance with these Terms, the DreamCoins will
          be credited into the user's DreamCoins balance by IST 12:00:00 hours
          on the next day, starting 27th April, 2024. Users will not get
          DreamCoins (the usual naturally earned or bonus DreamCoins) for
          abandoned matches. Users will not get DreamCoins (the bonus
          DreamCoins) for paid contest joins for tours not included in this
          offer. The DreamCoins are valid as per the standard terms of the
          DreamCoins available here The additional bonus DreamCoins given via
          this promotion will not be counted towards the user’s Champions club
          progress. These are valid for 7 days from being credited in your
          account Bonus DreamCoins may be granted by Dream11 to its users at
          its sole discretion. Indian T20 League DreamBox Terms and Conditions
          During 26 April - 2nd May 2024, on joining minimum any 2 Paid Public
          contests for the Indian T20 League the user will get guaranteed
          rewards via the DreamBox on the next day by 12 PM. The DreamBox will
          expire 2 days from the date when a user receives it i.e. the user
          will have 2 days time to claim the reward through the DreamBox.
          Expiry of the Rewards received in a DreamBox is subject to terms and
          conditions of each reward. How to get a reward through DreamBox Go
          to the reward shop and click on the DreamBox fab icon to view all
          the collected DreamBoxes. Once inside, users need to select one of
          the four DreamBoxes, each of which will have a guaranteed reward
          inside it. Once the reward is disbursed, the user can’t claim the
          reward again from the same DreamBox. If the DreamBox is expired and
          the user hasn’t claimed the reward, the user won’t see the expired
          DreamBox anywhere and can no longer claim the reward. Where to view
          the rewards received through DreamBox DreamCoins will be added
          directly to your DreamCoins Balance Other rewards will be shown in
          the “My Rewards” section of Reward Shop. Rewards received through
          DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of Bonus
          DreamCoins will be 7 days from the date of credit. Bonus DreamCoins
          can be used towards redeeming rewards on the Rewards Shop only but
          will not count towards a user’s Champions Club Levels Progress.
          Check other DreamCoins terms and conditions here Imagine ₹80,000
          Voucher Users who win an Imagine (Apple reseller) ₹80,000 Voucher
          via DreamBox worth ₹1,14,285 (subject to deduction of 30% TDS) can
          use it on the Imagine (Apple reseller) website or application. The
          actual voucher value received by the user post TDS deductions will
          be ₹80,000. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. Imagine
          (Apple reseller) ₹80,000 Voucher coupon code will be available on
          “My Rewards” page of the user Imagine (Apple reseller) ₹80,000
          Voucher cannot be exchanged for cash or any other benefit. Imagine
          (Apple reseller) ₹80,000 Voucher shall be valid till 31st March
          2025. Terms and conditions of the voucher shall apply. All purchases
          made on the Imagine (Apple reseller) website will be subject to the
          standard terms and conditions of the Imagine (Apple reseller)
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Imagine Gift Card
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here T20 Championship Missions
          Terms and Conditions T20 Championship missions In each zone, users
          get a new set of missions and get rewards on completion of every
          zone. Next zone unlocks only on completion of the previous zone,
          regardless of the user claiming the rewards from the previously
          completed zone. Users can participate in Missions from IST 14:00:00
          hours on 27th April 2024 till IST 23:59:59 hours on 26th May 2024
          (“Promotion Period”). Only matches starting and ending within the
          Promotion Period will be considered. Users must complete all
          missions in respective zones to be eligible for claiming the
          rewards. Only if users claim the benefits associated with the zone,
          will they be eligible for the rewards pertaining to it. If the user
          has completed all the missions in a zone but has not claimed the
          benefits, they shall not be eligible for the rewards. For “Cash
          Contest” missions, only public and private paid contests joined by a
          user shall be considered towards progress of the Mission Contest
          join will only be considered if the user is eligible for the
          naturally earned DreamCoins for it i.e. user would not be eligible
          in case of abandoned or refunded contests. Where do you find new T20
          Championship missions? Championship Missions are only available to
          users who can see and access the T20 Championship missions page via
          the offer banner on Reward Shop and are eligible to participate in
          the pay to play contests as per Dream11's terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible
          here). Rewards on completing the T20 Championship missions. On
          completing missions in each zone of T20 Championship missions, users
          get Bonus DreamCoins and/or such other rewards as may be
          communicated on the T20 Championship Missions page. The Bonus
          DreamCoins Reward is guaranteed for the users if the user has
          claimed the reward. The Bumper Reward: Users who complete Zone 1
          stand a chance to be among 100 winners of Zone 1 Bumper - 50% off up
          to ₹1,000 Discount Pass Users who complete Zone 2 stand a chance to
          be among 30 winners of Zone 2 Bumper - ₹5,000 Amazon Voucher. Users
          who complete Zone 3 stand a chance to be among 5 winners of Zone 3
          Bumper - ₹80,000 Imagine Voucher. Users who complete Zone 4 stand a
          chance to be the winner of Zone 4 Bumper - TATA Punch Car Users will
          be eligible for the opportunity to win the Bumper Rewards only if
          they claim the reward of the respective zone. The winners for all
          Bumper Rewards would be declared on the T20 Championship missions
          homepage by IST 12:00:00 hours on 27th May 2024 or 24 hours after
          the completion of the Indian T20 League (whichever is later).
          Dream11 representatives will contact the winners of the Bumper
          rewards, within 3 working days post declaration of the winners
          either via email or over call. If the user has completed the
          mission, the user will have to come to the T20 Championship missions
          homepage to claim the reward. All rewards earned on completion of a
          zone must be claimed by the user before completion of the relevant
          tournament. Bonus DreamCoins will be reflected directly in the
          user's DreamCoins Balance within 24 hours of claiming the same.
          Users get the Bonus DreamCoins in excess of naturally earned
          DreamCoins via the contest join actions. Bonus DreamCoins: Bonus
          DreamCoins expire on completion of 90 days from the date of credit.
          Bonus DreamCoins can be used towards redeeming rewards on the
          Rewards Shop. Bonus DreamCoins do not count towards a user’s
          Champions Club Levels Progress. Check other DreamCoins terms and
          conditions here Bonus DreamCoins: Bonus DreamCoins expire on
          completion of 90 days from the date of credit. Bonus DreamCoins can
          be used towards redeeming rewards on the Rewards Shop. Bonus
          DreamCoins do not count towards a user’s Champions Club Levels
          Progress. Check other DreamCoins terms and conditions here
          Miscellaneous Dream11 reserves the right to amend, modify, change
          the terms of Missions as it may deem fit in its sole discretion. Any
          such change made by Dream11 shall be notified to the users and shall
          be effective from a prospective date. The progress for users will be
          updated on the T20 Championship homepage everyday by IST 09:00:00
          hours. Any benefit, reward granted prior to such change shall be
          governed by the original terms and conditions applicable for such
          benefit or reward. Every zone shall have a reward, the details of
          which shall be reflected on the T20 Championship missions page
          against each such reward. All the prizes mentioned in these Terms
          are subject to TDS which shall be borne by Dream11 unless
          specifically stated otherwise. 50% off up to ₹1,000 Discount Pass
          The Discount Pass will allow the user to avail a 50% discount in any
          paid public contest on the Dream11 App across any sport, with an
          overall maximum avail amount limit of ₹1,000. Discount Pass expires
          at IST 23:59:59 hours IST on Aug 27th, 2024. Discount Pass is not
          applicable on Private contests. Discount Pass cannot be clubbed
          together with any other promotions that are running simultaneously
          on the Dream11 platform. Also, Discount Pass cannot be clubbed with
          a Discount Bonus available to any user. In the event that a contest
          in which a user has used this Discount Pass gets canceled or the
          match itself gets abandoned, the discount amount used in that
          contest or match will be refunded to that user as Discount Bonus in
          their Dream11 wallet. Amazon Voucher worth ₹5,000 Users who win an
          Amazon Voucher worth ₹5,000 (“Amazon Voucher”) can use it on the
          Amazon website or application. Should the purchase value exceed the
          value of the Voucher, the differential amount of such purchase
          should be paid using the other modes of payment available on the
          website. The details of the Amazon Voucher shall be sent to the
          registered email id(s) of the winner(s) within 3 working days from
          the date of winner declaration. The Amazon Voucher can be utilised
          on Amazon website or application. Amazon Voucher cannot be exchanged
          for cash or any other benefit. Amazon Voucher shall be valid till
          31st March 2025. Terms and conditions of the Amazon Voucher shall
          apply. All purchases made on the Amazon website will be subject to
          the standard terms and conditions of the Amazon website including
          its cancellation, return and delivery policy. All complaints in
          relation to the redemption of the Amazon Pay Gift Card shall be
          resolved as per the terms and conditions of Amazon as outlined here.
          Imagine Voucher worth ₹80,000 Users who win an Imagine (Apple
          reseller) Voucher worth ₹80,000 (“Apple Voucher”) via Missions can
          use it on the Imagine (Apple reseller) website or application.
          Should the purchase value exceed the value of the Voucher, the
          differential amount of such purchase should be paid using the other
          modes of payment available on the website. Apple Voucher coupon code
          will be available on “My Rewards” page of the user Apple Voucher
          cannot be exchanged for cash or any other benefit. Apple Voucher
          shall be valid till 31st March 2025. Terms and conditions of the
          Apple Voucher shall apply. All purchases made on the Imagine (Apple
          reseller) website will be subject to the standard terms and
          conditions of the Imagine (Apple reseller) website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Imagine Gift Card shall be resolved as per
          the terms and conditions of Tresor Systems Pvt. Ltd. Winners may
          reach out to them as per the details provided in the Terms &
          Conditions outlined here TATA Punch Car Voucher worth ₹8,00,000
          Users who win a TATA Voucher worth ₹8,00,000 via Missions (“TATA
          Voucher”)will be contacted by Dream11 and will be asked to provide
          KYC details (government issued ID, full name, full address). The
          winner will be given a list of Tata Dealership stores by Dream11
          based on the nearest dealerships to their location, and would be
          asked to choose a Tata Dealership store from which they want to
          purchase the Tata car. With this information, a TATA Voucher will be
          generated within 14 working days. Once this is done, the winner will
          be contacted by Dream11. The winner will have to go to the chosen
          Tata dealership store to collect and redeem the TATA Voucher for a
          Tata Punch car. The expiry of the TATA Voucher will be one year from
          the date of generation and communicated to the user by a Dream11
          representative. The TATA Voucher is intended for one time use only
          and cannot be used for repeat purchases by the user. Should the
          purchase value exceed the value of the Tata Voucher, the
          differential amount of such purchase should be paid by the winner
          only. This TATA Voucher will be generated by Qwikcilver & Dream11
          will only procure the TATA Voucher worth the amount on behalf of the
          user and have no further liability. Dream11 is not responsible for
          any costs of maintenance once the car has been handed over to the
          winner. The delivery timeline of the Tata Punch car shall be subject
          to availability and registration of the nearest authorized Tata
          dealership store and Dream11 shall not be held liable for any delay
          in the same. In the event, the winner chooses to receive the cash
          equivalent of the prize instead of the Tata Voucher, the user must
          reach out to the helpdesk. The cash equivalent of the Prize amount
          of 11.4 Lakh Rupees (amount prior to deduction of TDS) shall also be
          subject to a deduction of 30% TDS deductions. The actual cash
          equivalent that will be received by the winner will be ₹8,00,000
          post TDS deductions. The winner shall be solely liable for
          submission of all required documents at the time of receiving the
          Prize. The list of required documents required to process the cash
          equivalent pay out to the user shall be communicated to the winner
          by Dream11. The Prize if taken in the form of cash shall be
          transferred only to the registered bank account of the user and not
          to any other account. In the event of a winner not being a verified
          user on Dream11, the winner will be required to verify his/her
          details within a period of fourteen (14) days, from the date of
          winner declaration. In the event the winner is unable to produce the
          required valid documents, the TATA Voucher shall be forfeited. Upon
          delivery and acceptance of the TATA Voucher by the winner, Dream11
          is discharged of all its liabilities hereunder. Referral Offer - 3rd
          May This "Referral offer" ("Promotion") is open to users of Dream11
          app who receive the banner via in-app communication. Under this
          campaign users can refer their friends during the campaign duration
          to receive additional discounts. Only those friends that register
          and join at least one public cash contest would qualify as a
          referral under this campaign. Based on the number of referrals done
          by a user during the Promotion Period (defined below) , the user
          shall receive Discount Coupons subject to the users’ full compliance
          with these Terms (defined below). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open from Indian Standard Time ("IST") 14:00:00 hours from
          May 3rd, 2024 till IST 23:59:00 hours on May 5th, 2023 ("Promotion
          Period") Eligibility – Participation in the Promotion is only
          available to registered users of Dream11 who receive the banner in
          app communication and are eligible to participate in the pay-to-play
          Dream11 contests as per Dream11’s terms and conditions (accessible
          here) By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions and
          the terms and conditions of the Dream11 game (accessible here) Users
          who participate in the Promotion during the Promotion Period and
          refer new users to Dream11, shall be eligible to receive Discount
          Coupons, the value of which will be 100% OFF up to Rs.59 per friend
          that joins a paid public contest during the Promotion Period.
          Subject to your compliance with these Terms, the Discount Coupon
          will be credited to users’ account by IST 22:00:00 hours on a daily
          basis during the Promotion Period, depending on the number of
          referrals completed. The Discount Coupon shall be valid for 7 Days
          from the date of credit and is for one time use only The Discount
          Coupon given under this Promotion is in addition to the existing
          referral rewards given to the users. Dream11 reserves the right to
          cancel this Promotion at any time in its sole discretion without
          assigning any reason thereto. The Discount Coupon pass will only be
          applicable for Public Contests. Transfer Streaks Campaign - for
          Manager Mode - for the Indian Premier League 2024 This offer is open
          to users who have access to the Manager Mode product and see the
          Transfer Streak offer promotion story or in-app notification. The
          first 1 Lakh users who make transfers for any 3 consecutive matches
          from Monday 6h May 2024 to Sunday 12th May 2024 will be eligible to
          win Bonus DreamCoins. The first 1 Lakh users who qualify will be
          eligible to receive 100 Bonus DreamCoins each. If more than 1 lakh
          users qualify, those who have completed their streak after the first
          1 lakh users have already qualified will not receive the Bonus
          DreamCoins reward. In the event that any match itself is abandoned,
          this offer will be amended to include the next consecutive match.
          The eligible participants who are amongst the first 1 lakh users to
          have completed their Transfer Streak will receive the Bonus
          DreamCoins within 10 days from the end-date of the campaign. Bonus
          DreamCoins given will expire within 1 month from the date of credit.
          Bonus DreamCoins will not count towards a user’s Champion’s Club
          Levels progress. No-Withdrawal Bonus This “No-Withdrawal Bonus”
          (“Promotion”) is only available for users who receive communication
          of the Promotion in their Dream11 app. Through this Promotion, users
          who receive the communication will be eligible for a bonus amount
          equivalent to an amount as communicated on their Dream11 app
          (“Discount Bonus”) if they don't withdraw the winning balance from
          04th May, 2024 (00:00:00) to 13th May 2024 (23:59:59). Promotion
          Period: 04th May, 2024 (IST 00:00:00 hours) to 13th May 2024 (IST
          23:59:59 hours) Upon availing the Promotion, the user will get the
          Discount Bonus amount credited to Dream11 Account by 15th May 2024
          (23:59:59). Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here) The Discount Bonus as mentioned in
          the Promotion once credited will have a validity of 14 days and can
          only be redeemed while joining public paid contests. The Discount
          Bonus is non-transferable and cannot be withdrawn. The Discount
          Bonus cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. This Promotion offer can
          be availed once per user during the Promotion Period. Cricket
          Special DreamBox Terms and Conditions During 10 May - 16 May 2024,
          on joining a Paid Public contest for any Non- Indian T20 League the
          user will get guaranteed rewards via the DreamBox each day, credited
          after the contest join. User will get only one DreamBox in a day
          Users can get a maximum of 7 Dreambox if they join a Paid Public
          contest for any Non- Indian T20 League each of these 7 days (10 May
          - 16 May 2024). The DreamBox will expire 2 days from the date when a
          user receives it i.e. the user will have 2 days time to claim the
          reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285 (subject to
          deduction of 30% TDS) can use it on the Imagine (Apple reseller)
          website or application. The actual voucher value received by the
          user post TDS deductions will be ₹80,000. Should the purchase value
          exceed the value of the Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. Imagine (Apple reseller) ₹80,000 Voucher coupon code
          will be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 21st May 2024. Terms and conditions of the voucher shall apply.
          All purchases made on the Imagine (Apple reseller) website will be
          subject to the standard terms and conditions of the Imagine (Apple
          reseller) website including its cancellation, return and delivery
          policy. All complaints in relation to the redemption of the Imagine
          Gift Card shall be resolved as per the terms and conditions of
          Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here FC Jersey
          Voucher Users who win an FC jersey Voucher via the DreamBox worth
          ₹1427 (subject to deduction of 30% TDS) can use to purchase any
          items on FanCode website. The actual voucher value received by the
          user post TDS deductions will be ₹999. FC Jersey voucher coupon code
          will be available on “My Rewards” page of the user FC jersey voucher
          cannot be exchanged for cash or any other benefit. FC jersey voucher
          shall be valid till 30th June 2024. Terms and conditions of the FC
          jersey voucher shall apply. Should the purchase value exceed the
          value of the voucher, the differential amount of such purchase shall
          be paid by the user using the other modes of payment available on
          the website. All purchases made on the FanCode website will be
          subject to the standard terms and conditions of the FanCode website
          including its cancellation, return and delivery policy. Discount
          Dhamaka terms and Conditions The “Discount Dhamaka” ( “Promotion”)
          is open to select users of Dream11 who receive communication from
          the Dream11 application. Under this Promotion, users who receive the
          communication on the Dream11 app, will be required to achieve the
          total Entry Fee target (excluding Entry fee from private contests)
          given to them till the time communicated on the app during the
          Promotion Period. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the promotion:
          Participation is only available to select registered users of
          Dream11 who receive the In App communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). It
          is being amply clarified that the Entry Fee used to join private
          contests shall not be considered for this Promotion. The user will
          receive a flat Discount Coupon as communicated on the app upon
          achieving the target as mentioned in the communication. The amount
          and percentage of the Discount Coupon will be communicated to the
          user through an in-app communication. The users may receive either a
          15% or 25% discount coupon (“Discount Coupon”) as communicated on
          the app. Once the user achieves the target within the given
          timeframe and Promotion Period, they will receive the relevant
          Discount Coupon within three (3) hours of Entry fee target
          completion. This promotion will be valid from IST 00:00:00 hours on
          13th May 2024 till IST 23:59:59 hours on 19th May 2024 By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). In the event that
          the contest in which a user has joined a contest to complete the
          entry free targets as mentioned above gets canceled or the match
          itself gets abandoned, the amount used in that contest or match will
          not be considered for target entry fees completion. In the event
          that a contest in which a user has used the Discount Coupon gets
          canceled or the match itself gets abandoned, the discount amount
          used in that contest or match will be refunded to that user as
          Discount Bonus. Coupon Details: Discount Coupons will be given to
          the user within three (3) hours of Entry fee target completion.
          Discount Coupons are valid only on paid public contests and will be
          valid only for 7 days from the time they are given. Cricket Special
          DreamBox Terms and Conditions Cricket Special DreamBox Terms and
          Conditions During 27 May - 04 June 2024, on joining a Paid Public
          contest for any Cricket match, the user will get guaranteed rewards
          via the DreamBox each day, credited after the contest join. User
          will get only one DreamBox in a day Users can get a maximum of 9
          Dreambox if they join a Paid Public contest for any Cricket match
          each of these 9 days (27 May - 4 June 2024). The DreamBox will
          expire 2 days from the date when a user receives it i.e. the user
          will have 2 days time to claim the reward through the DreamBox.
          Expiry of the Rewards received in a DreamBox is subject to terms and
          conditions of each reward. How to get a reward through DreamBox Go
          to the reward shop and click on the DreamBox icon to view all the
          collected DreamBoxes. Once inside, users need to select one of the
          four DreamBoxes, each of which will have a guaranteed reward inside
          it. Once the reward is disbursed, the user can’t claim the reward
          again from the same DreamBox. If the DreamBox is expired and the
          user hasn’t claimed the reward, the user won’t see the expired
          DreamBox anywhere and can no longer claim the reward. Where to view
          the rewards received through DreamBox DreamCoins will be added
          directly to your DreamCoins Balance Other rewards will be shown in
          the “My Rewards” section of Reward Shop. Rewards received through
          DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of Bonus
          DreamCoins will be 7 days from the date of credit. Bonus DreamCoins
          can be used towards redeeming rewards on the Rewards Shop only but
          will not count towards a user’s Champions Club Levels Progress.
          Check other DreamCoins terms and conditions here Imagine ₹80,000
          Voucher Users who win an Imagine (Apple reseller) ₹80,000 Voucher
          via DreamBox worth ₹1,14,285 (subject to deduction of 30% TDS) can
          use it on the Imagine (Apple reseller) website or application. The
          actual voucher value received by the user post TDS deductions will
          be ₹80,000. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. Imagine
          (Apple reseller) ₹80,000 Voucher coupon code will be available on
          “My Rewards” page of the user Imagine (Apple reseller) ₹80,000
          Voucher cannot be exchanged for cash or any other benefit. Imagine
          (Apple reseller) ₹80,000 Voucher shall be valid till 24th May 2025.
          Terms and conditions of the voucher shall apply. All purchases made
          on the Imagine (Apple reseller) website will be subject to the
          standard terms and conditions of the Imagine (Apple reseller)
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Imagine Gift Card
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here FC Jersey Voucher Users who
          win an FC jersey Voucher via the DreamBox worth ₹1427 (subject to
          deduction of 30% TDS) can use to purchase any items on FanCode
          website. The actual voucher value received by the user post TDS
          deductions will be ₹999. FC Jersey voucher coupon code will be
          available on “My Rewards” page of the user FC jersey voucher cannot
          be exchanged for cash or any other benefit. FC jersey voucher shall
          be valid till 30th June 2024. Terms and conditions of the FC jersey
          voucher shall apply. Should the purchase value exceed the value of
          the voucher, the differential amount of such purchase shall be paid
          by the user using the other modes of payment available on the
          website. All purchases made on the FanCode website will be subject
          to the standard terms and conditions of the FanCode website
          including its cancellation, return and delivery policy. World Cup
          Mania Campaign Terms and Conditions This “World Cup Mania Campaign”
          is only available for users who receive communication of the
          Promotion in their Dream11 app. Through this Promotion, users who
          receive the communication will be eligible to get a guaranteed
          reward via the DreamBox each day and a bonus amount equivalent to an
          amount as communicated on their Dream11 app (“Discount Bonus”) if
          they don't withdraw the winning balance from 26th May, 2024
          (21:00:00) to 5th June 2024 (23:59:59). Promotion Period: 26th May,
          2024 (IST 21:00:00 hours) to 5th June 2024 (IST 23:59:59 hours) Upon
          availing the Promotion, the user will get the Discount Bonus amount
          credited to Dream11 Account by 7th June 2024 (23:59:59). Eligibility
          - Participation in this Promotion is available to only those users
          who have received the relevant communication of the Promotion from
          Dream11 on the Dream11 app. By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions, and the terms and conditions of the Dream11 game
          (accessible here). During the offer period (26 May - 05 June), if
          the user does not withdraw the winning balance the user will get
          guaranteed rewards via the DreamBox each day, credited the next day
          by 12 noon. Users will get only one DreamBox in a day. Users can get
          a maximum of 11 Dreambox if they do not withdraw their winnings
          during the Promotion Period days The DreamBox will expire in a day
          from the date when a user receives it i.e. the user will have a
          day's time to claim the reward through the DreamBox. Expiry of the
          Rewards received in a DreamBox is subject to terms and conditions of
          each reward. The Discount Bonus as mentioned in the Promotion once
          credited will have a validity of 14 days and can only be redeemed
          while joining public paid contests. The Discount Bonus is
          non-transferable and cannot be withdrawn. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. How to get a reward through DreamBox
          Go to the reward shop and click on the DreamBox icon to view all the
          collected DreamBoxes. Once inside, users need to select one of the
          four DreamBoxes, each of which will have a guaranteed reward inside
          it. Once the reward is disbursed, the user can’t claim the reward
          again from the same DreamBox. If the DreamBox is expired and the
          user hasn’t claimed the reward, the user won’t see the expired
          DreamBox anywhere and can no longer claim the reward. Where to view
          the rewards received through DreamBox DreamCoins will be added
          directly to your DreamCoins Balance Other rewards will be shown in
          the “My Rewards” section of Reward Shop. Rewards received through
          DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of Bonus
          DreamCoins will be 7 days from the date of credit. Bonus DreamCoins
          can be used towards redeeming rewards on the Rewards Shop only but
          will not count towards a user’s Champions Club Levels Progress.
          Check other DreamCoins terms and conditions here Imagine ₹80,000
          Voucher Users who win an Imagine (Apple reseller) ₹80,000 Voucher
          via DreamBox worth ₹1,14,285 (subject to deduction of 30% TDS) can
          use it on the Imagine (Apple reseller) website or application. The
          actual voucher value received by the user post TDS deductions will
          be ₹80,000. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. Imagine
          (Apple reseller) ₹80,000 Voucher coupon code will be available on
          “My Rewards” page of the user Imagine (Apple reseller) ₹80,000
          Voucher cannot be exchanged for cash or any other benefit. Imagine
          (Apple reseller) ₹80,000 Voucher shall be valid till 24th May 2025.
          Terms and conditions of the voucher shall apply. All purchases made
          on the Imagine (Apple reseller) website will be subject to the
          standard terms and conditions of the Imagine (Apple reseller)
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Imagine Gift Card
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here Manage&Win with Peeyush
          Contest - Terms and Conditions This "Manager Mode Peeyush’s Contest"
          ("Contest"), is open to all users of Dream11 who join a private
          contest shared by Peeyush (Peeyush Sharma, teamname:
          PhantastiPeeyush11) in their respective Dream11 broadcast group.
          This private contest will be free of entry to all users and only up
          to 20,000 members can join such a contest shared by Peeyush. Each
          user wishing to participate in such a contest will be allowed to
          enter with only one team (single entry only). The Top 3 users in
          this contest will be eligible to receive an Imagine(Apple) voucher
          worth Rs. 80,000 (total voucher value would be Rs. 1,14,286. The TDS
          of Rs. 34,286 will be borne by Dream11). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Contest: Eligibility – Participation in the
          Contest is available to all registered users of Dream11 and can be
          accessed through joining the contest shared by Peeyush on his
          Dream11 broadcast group. The Contest will be open for participation
          from Indian Standard Time ("IST") 00:00:00 hours on June 1st, 2024
          till IST 23:59:59 hours on 29th June, 2024 ("Contest Period"). Users
          can join the Contest with one team only (single entry). Entry with
          multiple teams will not be permitted for this Contest. The Contest
          will be open for joining throughout the Contest Period, unless it
          already has 20,000 members in which case no one else would be able
          to join this contest, since it has 20,000 spots only. There can be
          at most 20,000 participants in this contest, and up to 3 total
          winners of this Contest. In the case that 2 users have the exact
          same points, the user who created their team first will be awarded
          the higher rank. This Contest is only applicable to a single free
          private contest shared by Peeyush, on his broadcast group on June
          1st, 2024. The Contest would be a free private contest of up to
          20,000 members for the Manager Mode World Cup 2024 game. This
          Contest will be created by Peeyush from his Dream11 broadcast group.
          No further private contests shared by Peeyush would be considered
          for this Contest. Dream11’s decision in selecting, declaring the
          winner of the Contest, shall be final and binding on all the users
          participating in the Contest. Subject to users compliance with these
          Terms, the winners will be contacted for the vouchers within 10 days
          of the Contest period ending, i.e. by 23:59:59 hours on 9th July,
          2024. Terms and conditions of the Imagine(Apple) Voucher will apply.
          World Cup T20 Championship Missions Terms and Conditions World Cup
          T20 Championship missions In each zone, users get a new set of
          missions and get rewards on completion of every zone. Next zone
          unlocks only on completion of the previous zone, regardless of the
          user claiming the rewards from the previously completed zone. Users
          can participate in Missions from IST 14:00:00 hours on 2nd June 2024
          till IST 23:59:59 hours on 29th June 2024 (“Promotion Period”). Only
          matches starting and ending within the Promotion Period will be
          considered. Users must complete all missions in respective zones to
          be eligible for claiming the rewards. Only if users claim the
          benefits associated with the zone, will they be eligible for the
          rewards pertaining to it. If the user has completed all the missions
          in a zone but has not claimed the benefits, they shall not be
          eligible for the rewards. For “Cash Contest” missions, public and
          private paid contests joined by a user shall be considered towards
          progress of the Mission Contest join will only be considered if the
          user is eligible for the naturally earned DreamCoins for it i.e.
          user would not be eligible in case of abandoned or refunded
          contests. Where do you find new World Cup T20 Championship missions?
          World Cup T20 Championship Missions are only available to users who
          can see and access the World Cup T20 Championship missions page via
          the offer banner on Reward Shop and are eligible to participate in
          the pay to play contests as per Dream11's terms and conditions
          (accessible here). By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of Dream11 (accessible
          here). Rewards on completing the World Cup T20 Championship
          missions. On completing missions in each zone of World Cup T20
          Championship missions, users get Bonus DreamCoins and/or such other
          rewards as may be communicated on the World Cup T20 Championship
          Missions page. The Bonus DreamCoins Reward is guaranteed for the
          users if the user has claimed the reward. The Bumper Reward: Users
          who complete Zone 1 stand a chance to be among 100 winners of Zone 1
          Bumper - 50% off up to ₹1,000 Discount Pass Users who complete Zone
          2 stand a chance to be among 30 winners of Zone 2 Bumper - ₹5,000
          Amazon Voucher. Users who complete Zone 3 stand a chance to be among
          5 winners of Zone 3 Bumper - ₹80,000 Imagine Voucher. Users who
          complete Zone 4 stand a chance to be the winner of Zone 4 Bumper -
          TATA Punch Car Users will be eligible for the opportunity to win the
          Bumper Rewards only if they claim the reward of the respective zone.
          The winners for all Bumper Rewards would be declared on the World
          Cup T20 Championship missions homepage by IST 12:00:00 hours on 30th
          June 2024 or 24 hours after the completion of the ICC T20 World Cup
          (whichever is later). Dream11 representatives will contact the
          winners of the Bumper rewards, within 3 working days post
          declaration of the winners either via email or over call. If the
          user has completed the mission, the user will have to come to the
          World Cup T20 Championship missions homepage to claim the reward.
          All rewards earned on completion of a zone must be claimed by the
          user before completion of the relevant tournament. Bonus DreamCoins
          will be reflected directly in the user's DreamCoins Balance within
          24 hours of claiming the same. Users get the Bonus DreamCoins in
          excess of naturally earned DreamCoins via the contest join actions.
          Bonus DreamCoins: Bonus DreamCoins expire on completion of 90 days
          from the date of credit. Bonus DreamCoins can be used towards
          redeeming rewards on the Rewards Shop. Bonus DreamCoins do not count
          towards a user’s Champions Club Levels Progress. Check other
          DreamCoins terms and conditions here Miscellaneous Dream11 reserves
          the right to amend, modify, change the terms of Missions as it may
          deem fit in its sole discretion. Any such change made by Dream11
          shall be notified to the users and shall be effective from a
          prospective date. The progress for users will be updated on the
          World Cup T20 Championship homepage every 6 hours. Any benefit,
          reward granted prior to such change shall be governed by the
          original terms and conditions applicable for such benefit or reward.
          Every zone shall have a reward, the details of which shall be
          reflected on the World Cup T20 Championship missions page against
          each such reward. All the prizes mentioned in these Terms are
          subject to TDS which shall be borne by Dream11 unless specifically
          stated otherwise. 50% off up to ₹1,000 Discount Pass The Discount
          Pass will allow the user to avail a 50% discount in any paid public
          contest on the Dream11 App across any sport, with an overall maximum
          avail amount limit of ₹1,000. Discount Pass expires at IST 23:59:59
          hours IST on July 30th, 2024. Discount Pass is not applicable on
          Private contests. Discount Pass cannot be clubbed together with any
          other promotions that are running simultaneously on the Dream11
          platform. Also, Discount Pass cannot be clubbed with a Discount
          Bonus available to any user. In the event that a contest in which a
          user has used this Discount Pass gets canceled or the match itself
          gets abandoned, the discount amount used in that contest or match
          will be refunded to that user as Discount Bonus in their Dream11
          wallet. Amazon Voucher worth ₹5,000 Users who win an Amazon Voucher
          worth ₹5,000 (“Amazon Voucher”) can use it on the Amazon website or
          application. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. The
          details of the Amazon Voucher shall be sent to the registered email
          id(s) of the winner(s) within 3 working days from the date of winner
          declaration. The Amazon Voucher can be utilised on Amazon website or
          application. Amazon Voucher cannot be exchanged for cash or any
          other benefit. Amazon Voucher shall be valid till 1st June 2025.
          Terms and conditions of the Amazon Voucher shall apply. All
          purchases made on the Amazon website will be subject to the standard
          terms and conditions of the Amazon website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Amazon Pay Gift Card shall be resolved as
          per the terms and conditions of Amazon as outlined here. Imagine
          Voucher worth ₹80,000 Users who win an Imagine (Apple reseller)
          Voucher worth ₹80,000 (“Apple Voucher”) via Missions can use it on
          the Imagine (Apple reseller) website or application. Should the
          purchase value exceed the value of the Voucher, the differential
          amount of such purchase should be paid using the other modes of
          payment available on the website. Apple Voucher coupon code will be
          available on “My Rewards” page of the user Apple Voucher cannot be
          exchanged for cash or any other benefit. Apple Voucher shall be
          valid till 1st June 2025. Terms and conditions of the Apple Voucher
          shall apply. All purchases made on the Imagine (Apple reseller)
          website will be subject to the standard terms and conditions of the
          Imagine (Apple reseller) website including its cancellation, return
          and delivery policy. All complaints in relation to the redemption of
          the Imagine Gift Card shall be resolved as per the terms and
          conditions of Tresor Systems Pvt. Ltd. Winners may reach out to them
          as per the details provided in the Terms & Conditions outlined here
          TATA Punch Car Voucher worth ₹8,00,000 Users who win a TATA Voucher
          worth ₹8,00,000 via Missions (“TATA Voucher”)will be contacted by
          Dream11 and will be asked to provide KYC details (government issued
          ID, full name, full address). The winner will be given a list of
          Tata Dealership stores by Dream11 based on the nearest dealerships
          to their location, and would be asked to choose a Tata Dealership
          store from which they want to purchase the Tata car. With this
          information, a TATA Voucher will be generated within 14 working
          days. Once this is done, the winner will be contacted by Dream11.
          The winner will have to go to the chosen Tata dealership store to
          collect and redeem the TATA Voucher for a Tata Punch car. The expiry
          of the TATA Voucher will be one year from the date of generation and
          communicated to the user by a Dream11 representative. The TATA
          Voucher is intended for one time use only and cannot be used for
          repeat purchases by the user. Should the purchase value exceed the
          value of the Tata Voucher, the differential amount of such purchase
          should be paid by the winner only. This TATA Voucher will be
          generated by Qwikcilver & Dream11 will only procure the TATA Voucher
          worth the amount on behalf of the user and have no further
          liability. Dream11 is not responsible for any costs of maintenance
          once the car has been handed over to the winner. The delivery
          timeline of the Tata Punch car shall be subject to availability and
          registration of the nearest authorized Tata dealership store and
          Dream11 shall not be held liable for any delay in the same. In the
          event, the winner chooses to receive the cash equivalent of the
          prize instead of the Tata Voucher, the user must reach out to the
          helpdesk. The cash equivalent of the Prize amount of 11.4 Lakh
          Rupees (amount prior to deduction of TDS) shall also be subject to a
          deduction of 30% TDS deductions. The actual cash equivalent that
          will be received by the winner will be ₹8,00,000 post TDS
          deductions. The winner shall be solely liable for submission of all
          required documents at the time of receiving the Prize. The list of
          required documents required to process the cash equivalent pay out
          to the user shall be communicated to the winner by Dream11. Referral
          Offer - 8th June This "Referral offer" ("Promotion") is open to
          users of Dream11 app who receive the banner via in-app
          communication. Under this campaign users can refer their friends
          during the campaign duration to receive additional discounts. Only
          those friends that register and join at least one public cash
          contest would qualify as a referral under this campaign. Based on
          the number of referrals done by a user during the Promotion Period
          (defined below) , the user shall receive Discount Coupons subject to
          the users’ full compliance with these Terms (defined below). Please
          note our terms and conditions ("Terms") in this respect, as they
          govern your participation in the Promotion: Promotion Period - The
          Promotion will be open from Indian Standard Time ("IST") 14:00:00
          hours from June 8th, 2024 till IST 23:59:00 hours on June 10th, 2024
          ("Promotion Period") Eligibility – Participation in the Promotion is
          only available to registered users of Dream11 who receive the banner
          in app communication and are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) Users who participate in the Promotion during the
          Promotion Period and refer new users to Dream11, shall be eligible
          to receive Discount Coupons, the value of which will be 100% OFF up
          to Rs.59 per friend that joins a paid public contest during the
          Promotion Period. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by IST 22:00:00
          hours on a daily basis during the Promotion Period, depending on the
          number of referrals completed. The Discount Coupon shall be valid
          for 7 Days from the date of credit and is for one time use only The
          Discount Coupon given under this Promotion is in addition to the
          existing referral rewards given to the users. Dream11 reserves the
          right to cancel this Promotion at any time in its sole discretion
          without assigning any reason thereto. The Discount Coupon pass will
          only be applicable for Public Contests. iPhone Giveaway - EURO 2024
          This iPhone giveaway is open to all users of Dream11 who participate
          in the public Mega Contest with a prize pool of Rs. 25 lakhs and
          above for the EURO 2024 matches: GER vs SCO (15th June, 2024) ITA vs
          ALB (16th June, 2024) SER vs ENG (17th June, 2024) AUT vs FRA (18th
          June, 2024) POR vs CZR (19th June, 2024) All users who join the Mega
          Contest of the abovementioned matches starting from Indian Standard
          Time ("IST") 12:30:00 hours on June 14th , 2024 till IST 12:30:00
          hours on June 19th, 2024 (‘Promotion Period’) will be eligible to
          participate in this offer. The user who ranks 1st in the Mega
          Contest during the Promotion Period in any of the above-mentioned
          EURO 2024 matches will each receive an Imagine (Apple Reseller)
          Voucher worth Rs.80,000 (“Voucher”). The details of the Voucher
          shall be sent to the registered email id of the winner within 5
          working days from the date of the completion of the eligible match.
          Should the purchase value exceed the value of the Voucher, the
          differential amount of such purchase should be paid by the user
          using other modes of payment available on the website and Dream11
          shall not be responsible for the differential amount in any manner.
          The Voucher cannot be exchanged for cash or any other benefit. Terms
          and conditions of the Voucher shall apply. Only the 1st rank winner
          of the abovementioned Mega Contest(s) for the above mentioned EURO
          2024 matches with a prize pool of Rs.25 lakhs and above (“Contest”)
          will receive the Voucher and not any other contest within the match.
          If the spots in the Mega Contest for the above matches fill prior to
          the start of the match, no other contests that are available for
          that match will be eligible for this giveaway. The Voucher shall be
          valid till May 31st, 2025. In the event that any of the eligible
          match(es) are abandoned, this offer will be null and void for that
          match. The giveaway is subject to TDS which shall be borne by
          Dream11. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return, and
          delivery policy. All complaints in relation to the redemption of the
          Voucher shall be resolved as per the terms and conditions of Tresor
          Systems Pvt. Ltd. Users may reach out to them as per the details
          provided in the Terms & Conditions outlined here. In the event two
          or more users have the same team points at the end of any of the
          above-mentioned EURO 2024 match’s Mega Contest, then the following
          tiebreaker will be used. It is clarified that for the purpose of
          this Tiebreaker, the teams used to join the Mega contest only shall
          be considered at the end of match to determine the winners and not
          any other teams created. Tiebreaker Rule - The team that joined the
          contest first shall be considered the winner Referral Offer - 22nd
          June This "Referral offer" ("Promotion") is open to users of Dream11
          app who receive the banner via in-app communication. Under this
          campaign users can refer their friends during the campaign duration
          to receive additional discounts. Only those friends that register
          and join at least one public cash contest would qualify as a
          referral under this campaign. Based on the number of referrals done
          by a user during the Promotion Period (defined below), the user
          shall receive Discount Coupons subject to the users’ full compliance
          with these Terms (defined below). Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Promotion Period - The Promotion
          will be open from Indian Standard Time ("IST") 14:00:00 hours from
          June 22nd, 2024 till IST 23:59:00 hours on June 24th, 2024
          ("Promotion Period") Eligibility – Participation in the Promotion is
          only available to registered users of Dream11 who receive the banner
          in app communication and are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) Users who participate in the Promotion during the
          Promotion Period and refer new users to Dream11, shall be eligible
          to receive Discount Coupons, the value of which will be 100% OFF up
          to Rs.59 per friend that joins a paid public contest during the
          Promotion Period. Subject to your compliance with these Terms, the
          Discount Coupon will be credited to users’ account by IST 22:00:00
          hours on a daily basis during the Promotion Period, depending on the
          number of referrals completed. The Discount Coupon shall be valid
          for 7 Days from the date of credit and is for one time use only The
          Discount Coupon given under this Promotion is in addition to the
          existing referral rewards given to the users. Dream11 reserves the
          right to cancel this Promotion at any time in its sole discretion
          without assigning any reason thereto. The Discount Coupon pass will
          only be applicable for Public Contests. Euro Trip 2024 The Euro Trip
          Promotion ( “Promotion”) is open to select users of Dream11 who
          receive the banner and push notification communication on the
          Dream11 mobile application. Under this Promotion, users who receive
          the communication on the Dream11 app, will be eligible to take part
          in the Promotion. In order to take part in the Promotion, users will
          have to collect stamps/country flags by playing a paid public
          contest of matches of the respective country in the Euros 2024 tour.
          Users who collect all the stamps, will be eligible to win an Imagine
          (Apple Reseller) Voucher worth Rs 80,000. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Participation is only available to
          select registered users of Dream11 who receive the banner and push
          communication on the Dream11 mobile application and are eligible to
          participate in the pay-to-play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions, and the terms and conditions of Dream11
          (accessible here). The Promotion will be open for participation from
          IST 00:00:00 hours on June 26th, 2024 till IST 23:59:59 hours on
          July 9th, 2024 ("Promotion Period"). Any Discount Coupon availed as
          part of the Promotion cannot be clubbed with any other Discount
          Coupon received under this Promotion or other promotions running
          simultaneously on the Dream11 platform. Also, Discount Coupon(s)
          cannot be clubbed with a Discount Bonus available to any user. In
          the event that a paid public contest in which a user has used this
          Discount Coupon gets canceled or the match itself gets abandoned,
          the Discount Coupon amount used in that contest or match by the user
          will be refunded to that user as Discount Bonus. Users will have to
          collect stamps / country flags by playing a paid public contest of
          matches of the respective country in the Euros 2024 tour. Users
          unlock applicable discounts upon collecting multiple stamps /
          country flags. Below is the break up of the discounts that the users
          would receive upon completing the respective task - Number of
          Countries Played with in the Euros 2024 Tour Discount Coupon 4
          countries 25% OFF up to Rs.15 (whichever is lesser) 8 countries 40%
          OFF up to Rs.20 (whichever is lesser) 14 countries 50% OFF up to
          Rs.25 (whichever is lesser) Users who participate in contests for
          more than 14 different countries will be eligible to enter the
          iPhone giveaway. Among these eligible users, only one winner will be
          selected to receive the iPhone, provided they have collected all
          required stamps. Below are details of the promotion that will be
          given out to users who are eligible 25% OFF up to Rs.15 Discount
          Coupon will be valid on all paid public contests above Rs. 49 on the
          Dream11 platform. Discount Coupon is not applicable on private
          contests. The discount coupon will be valid on the Euros 2024 tour
          only. Maximum discount a user can avail of on a public paid contest
          is Rs. 15 or 25% of the contest entry fee, whichever is lesser.
          Subject to your compliance with these Terms, the Discount Coupon
          will be credited to the qualifying users’ Dream11 account within 24
          hours of completing the task. The Discount Coupon will be valid for
          7 days from the day of issue. 40% OFF up to Rs.20 Discount Coupon
          will be valid on all paid public contests above Rs. 49 on the
          Dream11 platform. Discount Coupon is not applicable on private
          contests. The discount coupon will be valid on the Euros 2024 tour
          only. Maximum discount a user can avail on a public paid contest is
          Rs. 20 or 40% of the contest entry fee, whichever is lesser. Subject
          to your compliance with these Terms, the Discount Coupon will be
          credited to the qualifying users’ Dream11 account within 24 hours of
          completing the task The Discount Coupon will be valid for 7 days
          from the day of issue. 50% OFF up to Rs.25 Discount Coupon will be
          valid on all paid public contests above Rs. 49 on the Dream11
          platform. Discount Coupon is not applicable on private contests. The
          discount coupon will be valid on the Euros 2024 tour only. Maximum
          discount a user can avail on a public paid contest is Rs. 25 or 50%
          of the contest entry fee, whichever is lesser. Subject to your
          compliance with these Terms, the Discount Coupon will be credited to
          the qualifying users’ Dream11 account within 24 hours of completing
          the task The Discount Coupon will be valid for 7 days from the day
          of issue. Illustration: Contest Entry Fee Discount Coupon Maximum
          Discount that can be availed in one contest of Rs.49. Rs.49 25% OFF
          up to Rs.15 Rs.13 Rs.49 40% OFF up to Rs.20 Rs.20 Rs.49 50% OFF up
          to Rs.25 Rs.25 Imagine Voucher worth ₹80,000 Users who win the
          Imagine (Apple reseller) Voucher worth ₹80,000 (“Voucher”) can use
          it on the Imagine (Apple reseller) website or application. Should
          the purchase value exceed the value of the Voucher, the differential
          amount of such purchase should be paid using the other modes of
          payment available on the website. The Voucher cannot be exchanged
          for cash or any other benefit. The Voucher shall be valid till 1st
          June 2025. Terms and conditions of the Voucher shall apply. All
          purchases made on the Imagine (Apple reseller) website will be
          subject to the standard terms and conditions of the Imagine (Apple
          reseller) website including its cancellation, return and delivery
          policy. All complaints in relation to the redemption of the Voucher
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here. Dream11 VISA Card Promo
          Offer Terms and Conditions This "Dream11 VISA Card Offer"
          ("Promotion") is open to users of the Dream11 app who receive the
          communication on the Dream11 app. Under this Promotion, users who
          perform a transaction of INR 250 or above using VISA Credit or Debit
          Card, during the Promotion Period (“defined below”) will be eligible
          to receive a discount bonus of INR 50 (“Discount Bonus”) Please note
          our terms and conditions ("Terms") in this respect, as they govern
          your participation in the Promotion: Eligibility – Participation in
          the Promotion is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received in-app banner communication from Dream11 By participating
          in this Promotion, the user agrees to comply with these Terms, the
          Standard Terms and Conditions for Promotions, and the terms and
          conditions of the Dream11 game (accessible here) The Promotion is
          only valid for transactions performed by the users using VISA card
          on Dream11 during the Promotion Period. The Promotion will be open
          for participation from Indian Standard Time ("IST") 00:00:00 hours
          on 26th June 2024 till IST 23:30:00 hours on 29th June 2024
          ("Promotion Period"). No other payment instruments (UPI, Wallet,
          Gift Card, etc.) are applicable for this Promotion In case the user
          does a transaction of less than INR 250, the user will not be
          eligible for the Discount Bonus. Subject to users’ compliance with
          these terms and conditions, the Discount Bonus will be disbursed
          after forty-eight (48) hours of successful VISA card transaction.
          Offer can be availed only once per VISA card during the offer
          period. Unutilized portion of the Discount Bonus will expire in 14
          days from the date that it has been credited The Bonus Amount cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Once the Discount Bonus is utilized in
          part or whole, the user cannot claim any refund of the deposit
          amount. Dream11 reserves the right to modify or discontinue the
          Promotion, or any part thereof, with or without notice, at its sole
          discretion. Dream11’s decision on any matters relating to this
          Promotion shall be final and binding 50% DreamCoins Back Terms and
          Conditions: This 50% DreamCoins Back (“Offer”) is open to all
          Dream11 users who can see this particular offer banner. The campaign
          will be live from 29th June, 2024 00:00 AM to 6th July, 2024 23:59
          PM. Users can use their DreamCoins balance to redeem from the list
          of Rewards shown. Users will receive 50% of the DreamCoins they
          spent on redeeming the eligible rewards as Bonus DreamCoins.
          DreamCoins will be disbursed at 12 PM every next day during the
          campaign period. Each user can redeem a particular reward only once
          during the campaign period. The DreamCoins received as a reward can
          be used according to the standard terms of Dream11. The rewarded
          Bonus DreamCoins will expire 7 days after they are credited to the
          user's account. Dream11 reserves the right to modify or terminate
          this campaign at any time without prior notice. Any changes to the
          campaign will be communicated through official Dream11 channels. By
          participating in this campaign, users agree to these terms and
          conditions as well as Dream11’s general terms of use. Dream11
          reserves the right to disqualify any user from participating in this
          campaign if found violating any terms of use or engaging in
          fraudulent activities. 3x DreamCoins Booster Offer Terms and
          Conditions This "3x DreamCoins Booster Offer" ("Promotion") is open
          to only those users of Dream11 who can view the specific offer on
          their Dream11 app. Under this Promotion, a user shall receive three
          times the DreamCoins earned for all paid contest joins they perform
          for any cricket match during the Promotion Period, only for those
          matches which start during the Promotion Period and end before 6th
          July 2024, for which the users are eligible to earn DreamCoins.
          Please note that the user would not receive extra DreamCoins for any
          paid contests the users join if the matches do not start during the
          Promotion Period and end before 6th July 2024 as part of this
          Promotion. Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Promotion:
          Eligibility – Participation in the Promotion is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and can view the specific offer banner in their
          Dream11 app. Promotion is open to only those users of Dream11 who
          can view the specific offer banner on their Dream11 app By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, the terms
          and conditions of the Dream11 game (accessible here) and the
          standard terms and conditions of DreamCoins (accessible here) The
          Promotion will be open for participation from Indian Standard Time
          ("IST") 00:00:01 hours on June 30th, 2024 till IST 23:59:59 hours on
          July 5th 2024 ("Promotion Period"). Under this Promotion, the 3x
          DreamCoins Booster will apply to all paid contest joins that a user
          can perform which earns them DreamCoins (defined here) under the
          stipulated Promotion Period only for all matches that start during
          the Promotion Period and end before 6th July 2024. They will not
          receive 3x DreamCoins for any paid contest joins when the respective
          match of that contest does not start during the Promotion Period and
          end before 6th July 2024. Users need to earn the natural DreamCoins
          during the promotion period. If users are not eligible for the
          naturally earned DreamCoins, they will also not be eligible for the
          Bonus DreamCoins. Only performing this defined action will earn the
          user three times the DreamCoins, actions outside this list will not
          earn the user any DreamCoins. A user can receive a maximum of up to
          10,000 DreamCoins in addition to the usual naturally earned
          DreamCoins at the end of the Promotion Period. Ex: Users will
          receive 3x DreamCoins on all paid contest joins, comprising of 1x
          naturally earned DreamCoins and 2x as bonus DreamCoins provided by
          Dream11. Subject to a user’s compliance with these Terms, the
          DreamCoins will be credited into the user's DreamCoins balance by
          IST 12:00:00 hours on the next day of contest join. Users will not
          get DreamCoins (the usual naturally earned or bonus DreamCoins) for
          abandoned matches. The DreamCoins are valid as per the standard
          terms of the DreamCoins available here The additional bonus
          DreamCoins given via this promotion will not be counted towards the
          user’s Champions club progress. These are valid for 7 days from
          being credited in your account Bonus DreamCoins may be granted by
          Dream11 to its users at its sole discretion. Bonus DreamBox Terms
          and Conditions During 30 June - 05 July 2024, on joining any Paid
          Public contest, the user will get guaranteed rewards via the
          DreamBox each day, credited after the contest join. User will get
          only one DreamBox in a day Users can get a maximum of 6 Dreambox if
          they join any Paid Public contest each of these 6 days (30 June - 05
          July 2024). The DreamBox will expire 2 days from the date when a
          user receives it i.e. the user will have 2 days time to claim the
          reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285 (subject to
          deduction of 30% TDS) can use it on the Imagine (Apple reseller)
          website or application. The actual voucher value received by the
          user post TDS deductions will be ₹80,000. Should the purchase value
          exceed the value of the Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. Imagine (Apple reseller) ₹80,000 Voucher coupon code
          will be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 27th June 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here FC Jersey
          Voucher Users who win an FC jersey Voucher via the DreamBox worth
          ₹1427 (subject to deduction of 30% TDS) can use to purchase any
          items on FanCode website. The actual voucher value received by the
          user post TDS deductions will be ₹999. FC Jersey voucher coupon code
          will be available on “My Rewards” page of the user FC jersey voucher
          cannot be exchanged for cash or any other benefit. FC jersey voucher
          shall be valid till 30th July 2024. Terms and conditions of the FC
          jersey voucher shall apply. Should the purchase value exceed the
          value of the voucher, the differential amount of such purchase shall
          be paid by the user using the other modes of payment available on
          the website. All purchases made on the FanCode website will be
          subject to the standard terms and conditions of the FanCode website
          including its cancellation, return and delivery policy. Discounts
          Mania Campaign Terms and Conditions This Discounts Mania Campaign”
          is only available for users who receive communication of the
          Promotion in their Dream11 app. Through this Promotion, users who
          receive the communication will be eligible to get a guaranteed
          reward via the DreamBox each day and a bonus amount equivalent to an
          amount as communicated on their Dream11 app (“Discount Bonus”) if
          they don't withdraw the winning balance from 29th June, 2024
          (21:00:00) to 6th July 2024 (23:59:59). Promotion Period: 29th June,
          2024 (IST 21:00:00 hours) to 6th July 2024 (IST 23:59:59 hours) Upon
          availing the Promotion, the user will get the Discount Bonus amount
          credited to Dream11 Account by 9th July 2024 (23:59:59).
          EligibilityHow to get a reward through DreamBox - Participation in
          this Promotion is available to only those users who have received
          the relevant communication of the Promotion from Dream11 on the
          Dream11 app. By participating in this Promotion, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions,
          and the terms and conditions of the Dream11 game (accessible here).
          During the offer period (29 June - 06 July 2024), if the user does
          not withdraw the winning balance the user will get guaranteed
          rewards via the DreamBox each day, credited the next day by 12 noon.
          Users will get only one DreamBox in a day. Users can get a maximum
          of 7 Dreambox if they do not withdraw their winnings during the
          Promotion Period days The DreamBox will expire in a day from the
          date when a user receives it i.e. the user will have a day's time to
          claim the reward through the DreamBox. Expiry of the Rewards
          received in a DreamBox is subject to terms and conditions of each
          reward. The Discount Bonus as mentioned in the Promotion once
          credited will have a validity of 14 days and can only be redeemed
          while joining public paid contests. The Discount Bonus is
          non-transferable and cannot be withdrawn. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. How to get a reward through DreamBox
          Go to the reward shop and click on the DreamBox icon to view all the
          collected DreamBoxes. Once inside, users need to select one of the
          four DreamBoxes, each of which will have a guaranteed reward inside
          it. Once the reward is disbursed, the user can’t claim the reward
          again from the same DreamBox. If the DreamBox is expired and the
          user hasn’t claimed the reward, the user won’t see the expired
          DreamBox anywhere and can no longer claim the reward. Where to view
          the rewards received through DreamBox DreamCoins will be added
          directly to your DreamCoins Balance Other rewards will be shown in
          the “My Rewards” section of Reward Shop. Rewards received through
          DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of Bonus
          DreamCoins will be 7 days from the date of credit. Bonus DreamCoins
          can be used towards redeeming rewards on the Rewards Shop only but
          will not count towards a user’s Champions Club Levels Progress.
          Check other DreamCoins terms and conditions here Imagine ₹80,000
          Voucher Users who win an Imagine (Apple reseller) ₹80,000 Voucher
          via DreamBox worth ₹1,14,285 (subject to deduction of 30% TDS) can
          use it on the Imagine (Apple reseller) website or application. The
          actual voucher value received by the user post TDS deductions will
          be ₹80,000. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. Imagine
          (Apple reseller) ₹80,000 Voucher coupon code will be available on
          “My Rewards” page of the user Imagine (Apple reseller) ₹80,000
          Voucher cannot be exchanged for cash or any other benefit. Imagine
          (Apple reseller) ₹80,000 Voucher shall be valid till 29th June,
          2025. Terms and conditions of the voucher shall apply. All purchases
          made on the Imagine (Apple reseller) website will be subject to the
          standard terms and conditions of the Imagine (Apple reseller)
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Imagine Gift Card
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here Team India and Rewards Offer
          - Terms and Conditions This "Team India and Rewards Offer"
          ("Offer"), is only open to users who receive communication about the
          offer via stories, banner on Reward Shop and App Inbox and who join
          Mega Contests for at least 3 India vs Zimbabwe Matches. The Offer is
          applicable for India vs Zimbabwe T20 Matches played starting 6th
          July. Users can earn rewards as per the below milestones as below:
          Join Mega Contests for any 3 matches and get 500 Bonus DreamCoins
          Join Mega Contests for any 4 matches and get 50% off up to Rs. 75
          Global Discount Join Mega Contests for any 5 matches and get 50% off
          up to Rs. 100 Global Discount Users will only be eligible for the
          rewards associated with the milestones if they have opted in for the
          offer by clicking on the ‘YES, I’M INTERESTED’ button on the offer
          detail page. The discount rewards will be global in nature and can
          be applied to any public contests. It is not applicable to Private
          Contests. The rewards will be disbursed to users by 15th July 20:00
          hours. Please note - if a user joins 5 matches, they will only get
          50% off up to Rs. 100 and not all 3 Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the Contest: Eligibility – Participation in the
          Offer is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and can view the
          specific offer banner in their Dream11 app. By participating in this
          Offer, you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions, the terms and conditions of the Dream11
          game (accessible here) and the standard terms and conditions of
          DreamCoins (accessible here) The Offer will be open for
          participation from Indian Standard Time ("IST") 00:00:01 hours on
          July 5th, 2024 till IST 23:59:59 hours on July 14th 2024 ("Offer
          Period"). Subject to a user’s compliance with these Terms, the Bonus
          DreamCoins or Discount will be credited into the user's Dream11
          account by IST 20:00:00 hours on 15th July 2024. The DreamCoins are
          valid as per the standard terms of the DreamCoins available here The
          additional bonus DreamCoins given via this offer will not be counted
          towards the user’s Champions club progress. These are valid for 7
          days from being credited in your account Bonus DreamCoins may be
          granted by Dream11 to its users at its sole discretion. Global
          Discounts will be valid till 15th August 2024. Get Unlimited
          Discounts This “Get Unlimited Discounts” (“Promotion”) is only
          available for users who receive communication of the Promotion in
          their Dream11 app via a banner or push notification. Through this
          offer, users who receive the communication will be eligible to join
          a Public Paid Contest using their winning balance and win discounts
          as detailed below. Promotion Period: 12th July 2024 (IST 21:00:00
          hours) to 23rd July 2024 (IST 23:59:59 hours) Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility - Participation in this
          Promotion is available to only those registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) and have
          received the relevant communication of the Promotion from Dream11 on
          their Dream11 app. By participating in this Promotion, you agree to
          comply with these Terms, the Standard Terms and Conditions for
          Promotions and the terms and conditions of the Dream11 game
          (accessible here) Users who use their winnings balance to join paid
          public contests and cross the specified threshold amount will
          qualify for the discount percentage as communicated on the winnings
          balance used above this threshold. Example - Use Winnings - ₹500 to
          unlock the discount during the Promotion Period and get discounts on
          subsequent contests joined by using your winnings. The discount will
          be given to the eligible users in the form of Discount Bonus only
          after they claim the discount from the offer page between 24th July,
          2024 to 31st July, 2024 (“Claim Period”). After claiming the
          discount, it will be credited within the next 72 hours. The Discount
          Bonus will be given to the eligible users only after they claim the
          discount from the offer page on or before the end of the Claim
          Period. Only winnings used for completed matches and non-refunded
          contests will be considered for this Promotion. In the event a user
          joins a contest using only their Unutilised Amount, then such
          contest join shall not be considered for the purposes of this
          Promotion. Discount Bonus is non-transferable and cannot be
          withdrawn. The Discount Bonus as mentioned in the Promotion once
          credited will have a validity of 14 days and can only be redeemed
          while joining public paid contests. Dream11 reserves the right to
          modify, suspend, or terminate the Promotion at any time without
          prior notice. Monsoon DreamBox Campaign Terms and Conditions This
          Monsoon DreamBox Campaign” is only available for users who receive
          communication of the Promotion in their Dream11 app. Through this
          Promotion, users who receive the communication will be eligible to
          get a guaranteed reward via the DreamBox each day. To be eligible to
          get a DreamBox everyday user must join any paid public Contest each
          day from 20th July, 2024 (00:00:00) to 26th July 2024 (23:59:59).
          Eligibility: How to get a reward through DreamBox - Participation in
          this Promotion is available to only those users who have received
          the relevant communication of the Promotion from Dream11 on the
          Dream11 app. By participating in this Promotion, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions,
          and the terms and conditions of the Dream11 game (accessible here).
          During the offer period (20 July - 26 July 2024), if the user joins
          any paid public Contest the user will get guaranteed rewards via the
          DreamBox each day. Users will get only one DreamBox in a day. Users
          can get a maximum of 7 Dreambox if they join any paid public Contest
          each day from 20th July, 2024 (00:00:00) to 26th July 2024
          (23:59:59). The DreamBox will expire in a day from the date when a
          user receives it i.e. the user will have a day's time to claim the
          reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285 (subject to
          deduction of 30% TDS) can use it on the Imagine (Apple reseller)
          website or application. The actual voucher value received by the
          user post TDS deductions will be ₹80,000. Should the purchase value
          exceed the value of the Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. Imagine (Apple reseller) ₹80,000 Voucher coupon code
          will be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 30th June, 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here Shuru with
          Guru terms and Conditions The “Shuru with Guru” (“Promotion”) is
          open to select users of Dream11 who receive communication from the
          Dream11 application. Under this Promotion, users who receive the
          communication on the Dream11 app must use a Guru team to join
          contest in the Sri Lanka vs India matches (Both T20I & ODI 3 match
          Series) to unlock this Promotion. Please note our terms and
          conditions ("Terms") in this respect, as they govern your
          participation in the promotion: Participation is only available to
          select registered users of Dream11 who receive the In App
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). It is being amply clarified that the
          Guru team used to join contests only shall be considered for this
          Promotion. The user will receive a Discount Coupon as communicated
          on the app upon joining the number of contests for the matches as
          mentioned in the communication on the Dream11 app. The amount and
          percentage of the Discount Coupon will be communicated to the user
          through an in-app communication. The users may receive either a 20%,
          40% or 60% discount coupon (“Discount Coupon”) on the app. The
          Discount Coupon will be given the next day at IST 12:00:00 hours
          after the completion of minimum 1 contest using a guru team. This
          Promotion will be valid from IST 19:00:00 hours on 27th July 2024
          till IST 14:30:30 hours on 7th August 2024. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). In the event that the contest in which a
          user has joined contest using a guru team gets canceled or the match
          itself gets abandoned, the team used in that contest or match will
          not be considered for the purposes of this Promotion. In the event
          that a contest in which a user has used the Discount Coupon gets
          canceled or the match itself gets abandoned, the discount amount
          used in that contest or match will be refunded to that user as
          Discount Bonus. Coupon Details: Discount Coupons will be given to
          the user next day at IST 12:00:00 hours after the completion of the
          contest using a guru team target completion. Discount Coupons are
          valid on all paid public contests and will be valid only for 36
          hours from the time they are given. Team India and Rewards Offer -
          Terms and Conditions This "Team India and Rewards Offer" ("Offer"),
          is only open to users who receive communication about the offer via
          stories, banner on Reward Shop and App Inbox and who join Mega
          Contests for at least 4 Sri Lanka vs India Matches. The Offer is
          applicable for Sri Lanka vs India T20I and ODI matches played
          starting 27th July. Users can earn rewards as per the below
          milestones as below: Join Mega Contests for any 4 matches and get
          500 Bonus DreamCoins Join Mega Contests for any 5 matches and get
          50% off up to Rs. 75 Global Discount Join Mega Contests for any 6
          matches and get 50% off up to Rs. 100 Global Discount Users will
          only be eligible for the rewards associated with the milestones if
          they have opted in for the offer by clicking on the ‘YES, I’M
          INTERESTED’ button on the offer detail page. The discount rewards
          will be global in nature and can be applied to any public contests.
          It is not applicable to Private Contests. The rewards will be
          disbursed to users by 8th August 20:00 hours. Please note - if a
          user joins 6 matches, they will only get 50% off up to Rs. 100 and
          not all 3 Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Contest:
          Eligibility – Participation in the Offer is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and can view the specific offer banner in their
          Dream11 app. By participating in this Offer, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions,
          the terms and conditions of the Dream11 game (accessible here) and
          the standard terms and conditions of DreamCoins (accessible here)
          The Offer will be open for participation from Indian Standard Time
          ("IST") 00:00:01 hours on July 27th, 2024 till IST 23:59:59 hours on
          August 7th 2024 ("Offer Period"). Subject to a user’s compliance
          with these Terms, the Bonus DreamCoins or Discount will be credited
          into the user's Dream11 account by IST 20:00:00 hours on 8th July
          2024. The DreamCoins are valid as per the standard terms of the
          DreamCoins available here The additional bonus DreamCoins given via
          this offer will not be counted towards the user’s Champions club
          progress. These are valid for 7 days from being credited in your
          account Bonus DreamCoins may be granted by Dream11 to its users at
          its sole discretion. Global Discounts will be valid till 8th
          September 2024. Dream11 SafetyShield Feature Terms and Conditions
          (1.1 | 29/07/24) This "Dream11 Safety Shield is available to all
          existing users of the Dream11 app, for whichever matches it has been
          enabled. This feature is only available and enabled on some matches
          and contests on the platform, and not all. The feature is only
          applicable to the largest prize pool ‘Mega Contest’ for a match, and
          not subsequent ones that open up once the first one fills A user may
          choose to ‘Shield’ their team Captain and/or Vice Captain for a
          contest by paying Rs 4 for each of these shields. If they do this,
          they will receive Rs 20 in the form of Discount Bonus if both the
          following conditions are met - The user’s selected Captain or Vice
          Captain for that shielded team used to join the Mega Contest scores
          less than 22 (11 base FPS x2) and 16.5 (11 base FPS x1.5) points
          respectively and ; User is not in the winning zone for the match.
          Eligibility – Participation is only available to registered users of
          Dream11 who are eligible to participate in the pay-to-play Dream11
          contests as per Dream11’s terms and conditions (accessible here) The
          entry amount to purchase the Shield can come from the users deposit
          balance and winning balance and not Discount Bonus balance. If the
          user has insufficient deposit/winning balance, then they will have
          to add more in order to opt for the Safety Shield. The Discount
          Bonus will be credited into the user's Discount Bonus Account within
          two hours from the end time of the match when the contest winnings
          are disbursed (only if the above conditions are satisfied). The
          amount of Discount Bonus received per team is not capped, so if a
          user has opted for both C and VC shield and both the players score
          less than the required points as mentioned above, and the user does
          not come in the winning zone, then Discount Bonus of Rs 40 (20x2)
          will be given the user In case the user switches their team that was
          shielded, or edits the team (including changing C/VC) - the shield
          is inherited by the new team / new player i.e it is not lost If the
          user has utilized back-ups and their Shielded captain or vice
          captain changes at the time of round lock - the newly selected
          Captain/vice captain will now be shielded Discount Bonus expires
          fourteen (14) days from the date of credit. In case the match is
          abandoned, we will return the Rupee amount that was deducted to opt
          for this feature The Hundred Men's promotion campaign The “The
          Hundred Men's promotion campaign” (“Promotion”) is open to select
          users of Dream11 who receive communication from the Dream11
          application. Under this Promotion, users who receive the
          communication on the Dream11 app must join a paid public contest in
          the Hundred (“Contest”) weekly to unlock this Promotion. The weeks
          are defined as: Week 1: July 29, 2024 - Aug 4, 2024 Week 2: Aug 5,
          2024 - Aug 11, 2024 Week 3: Aug 12, 2024 - Aug 18, 2024 Users need
          to participate in a Contest in week 1, 2 and 3 to be eligible to win
          an iPhone. Users participating in a Contest in week 1 and week 2
          will be eligible to get a discount of 50% up to Rs 50 (“Discount
          Coupon”). Dream11's decision in the declaration of winners will be
          final and binding. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the promotion:
          Participation is only available to select registered users of
          Dream11 who receive the In App communication on the Dream11 mobile
          application and are eligible to participate in the pay to play
          contests as per Dream11's terms and conditions (accessible here). By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions and the
          terms and conditions of Dream11 (accessible here). In the event that
          any of the eligible match(es) forming part of the Contest are
          abandoned, this offer will be null and void for that match and the
          user will have to participate in another Contest within the relevant
          week to continue under this Promotion. Discount Coupon Details: The
          Discount Coupon will be credited to My Rewards the day after the
          user has participated in at least one Contest in week 2. Discount
          Coupons are valid on all paid public contests and will be valid only
          for 72 hours from the time they are given. The Discount Coupon
          cannot be clubbed with any other offers, cash bonus available with
          the user. In the event that a contest in which a user has used the
          Discount Coupon gets canceled or the match itself gets abandoned,
          the discount amount used in that contest or match will be refunded
          to that user as Discount Bonus. Imagine Rs 80,000 Voucher details:
          All users who participate in a Contest in all three weeks stand a
          chance to win an Imagine (Apple Reseller) Voucher worth Rs 80,000
          (post TDS value) through which the winner can purchase items on the
          Imagine (Apple reseller) website and at Imagine (Apple reseller)
          Stores. The Imagine Voucher is subject to TDS which shall be borne
          by Dream11. The winners of the Jackpot will be announced by IST
          23:00:00 hours on 20th July 2024. The winners will receive the
          Voucher details on their registered email id within three (3)
          working days after the winners have been announced. The Voucher
          cannot be exchanged for cash or any other benefit. The Voucher shall
          be valid till 20th August 2025. Terms and conditions of the Voucher
          shall apply. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase shall be paid
          using the other modes of payment available on the website and the
          responsibility to pay any such differential amount shall be solely
          that of the winner/user. In the event of the winner not being a
          verified user on Dream11, that winner will be required to verify
          his/ her details within a period of fourteen (14) days, from the
          date of winner declaration. In the event the winner fails to verify
          his/her details on Dream11, the Jackpot prize for such winner shall
          be forfeited. All purchases made on the Imagine (Apple reseller)
          website will be subject to the standard terms and conditions of the
          Imagine (Apple reseller) website including its cancellation, return
          and delivery policy. All complaints in relation to the redemption of
          the Imagine Gift Card shall be resolved as per the terms and
          conditions of Tresor Systems Pvt. Ltd. Winner may reach out to them
          as per the details provided in the Terms & Conditions outlined here
          Dream11 Deposit Bonus Offer Deposit 100 Get 25 Terms and Conditions
          This "Dream11 Deposit Bonus Offer" ("Promotion") is open to the
          users of the Dream11 app who receive the communication via
          notification on the Dream11 app. Under this Promotion, users who
          deposit exactly INR 100 during the Promotion Period (defined below)
          will be eligible to receive a deposit bonus amount of INR 25 ("Bonus
          Amount"), in accordance with, and subject to the users’ full
          compliance with these Terms (defined below). Please note our terms
          and conditions ("Terms") in this respect, as they govern your
          participation in the Promotion: Eligibility – Participation in the
          Promotion is only available to registered users of Dream11 who are
          eligible to participate in the pay-to-play Dream11 contests as per
          Dream11’s terms and conditions (accessible here) and have received
          in-app communication from Dream11 By participating in this
          Promotion, the user agrees to comply with these Terms, the Standard
          Terms and Conditions for Promotions and the terms and conditions of
          the Dream11 game (accessible here) The Promotion will be open for
          participation from August 2nd, 2024, IST 18:00:00 hours to August
          30th, 2024, IST 23:59:59 hours ("Promotion Period"). To participate
          in the Promotion, users must make a cash deposit of exactly INR 100
          (“Qualifying Deposit”) to receive the Bonus Amount. Please note that
          only the Qualifying Deposit made by a user during the Promotion
          Period shall be considered for determining whether a user is
          eligible to receive the Bonus Amount and not any deposits that the
          user makes into such user’s Dream11 Unutilized Account before or
          after the Promotion Period. Subject to users’ compliance with these
          Terms, the Bonus Amount will be credited into users’ Discount Bonus
          Account within 48 hours. Offer can be availed once per user post a
          successful transaction during the Promotion Period. Unutilized Bonus
          Amount will expire in 14 days from the date that it has been
          credited. The Bonus Amount cannot be clubbed with any other coupon
          or offers available with the user and/ or the Dream11 platform. Once
          the Bonus Amount is utilized in part or whole, the user cannot claim
          any refund of the Qualifying Deposit. Independence Day DreamBox
          Campaign Terms and Conditions This Independence Day DreamBox
          Campaign” is only available for users who receive communication of
          the Promotion in their Dream11 app. Through this Promotion, users
          who receive the communication will be eligible to get a guaranteed
          reward via the DreamBox each day. To be eligible to get a DreamBox
          everyday user must join any paid public Contest each day from 13th
          August, 2024 (00:00:00) to 15th August 2024 (23:59:59). Eligibility:
          How to get a reward through DreamBox - Participation in this
          Promotion is available to only those users who have received the
          relevant communication of the Promotion from Dream11 on the Dream11
          app. By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions, and
          the terms and conditions of the Dream11 game (accessible here).
          During the offer period (13 August - 15 August 2024), if the user
          joins any paid public Contest the user will get guaranteed rewards
          via the DreamBox each day. Users will get only one DreamBox in a
          day. Users can get a maximum of 3 Dreambox if they join any paid
          public Contest each day from 13th August, 2024 (00:00:00) to 15th
          August 2024 (23:59:59). The DreamBox will expire in a day from the
          date when a user receives it i.e. the user will have a day's time to
          claim the reward through the DreamBox. Expiry of the Rewards
          received in a DreamBox is subject to terms and conditions of each
          reward. How to get a reward through DreamBox Go to the reward shop
          and click on the DreamBox icon to view all the collected DreamBoxes.
          Once inside, users need to select one of the four DreamBoxes, each
          of which will have a guaranteed reward inside it. Once the reward is
          disbursed, the user can’t claim the reward again from the same
          DreamBox. If the DreamBox is expired and the user hasn’t claimed the
          reward, the user won’t see the expired DreamBox anywhere and can no
          longer claim the reward. Where to view the rewards received through
          DreamBox DreamCoins will be added directly to your DreamCoins
          Balance Other rewards will be shown in the “My Rewards” section of
          Reward Shop. Rewards received through DreamBox Terms and Conditions:
          Bonus DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the
          date of credit. Bonus DreamCoins can be used towards redeeming
          rewards on the Rewards Shop only but will not count towards a user’s
          Champions Club Levels Progress. Check other DreamCoins terms and
          conditions here Imagine ₹80,000 Voucher Users who win an Imagine
          (Apple reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285
          (subject to deduction of 30% TDS) can use it on the Imagine (Apple
          reseller) website or application. The actual voucher value received
          by the user post TDS deductions will be ₹80,000. Should the purchase
          value exceed the value of the Voucher, the differential amount of
          such purchase should be paid using the other modes of payment
          available on the website. Imagine (Apple reseller) ₹80,000 Voucher
          coupon code will be available on “My Rewards” page of the user
          Imagine (Apple reseller) ₹80,000 Voucher cannot be exchanged for
          cash or any other benefit. Imagine (Apple reseller) ₹80,000 Voucher
          shall be valid till 31st July, 2025. Terms and conditions of the
          voucher shall apply. All purchases made on the Imagine (Apple
          reseller) website will be subject to the standard terms and
          conditions of the Imagine (Apple reseller) website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Imagine Gift Card shall be resolved as per
          the terms and conditions of Tresor Systems Pvt. Ltd. Winners may
          reach out to them as per the details provided in the Terms &
          Conditions outlined here Discounts Mania Campaign Terms and
          Conditions This “Discounts Mania Campaign” (“Promotion”) is only
          available for users who receive communication of the Promotion in
          their Dream11 app. Through this Promotion, users who receive the
          communication will be eligible to get a bonus amount equivalent to
          an amount as communicated on their Dream11 app (“Discount Bonus”) if
          the user chooses to continue playing instead of withdrawing their
          winnings between the time period specified in the communication
          shared with the user on their Dream11 app. Promotion Period: As per
          app communication received by the relevant user Eligibility -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on the Dream11 app. By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions, and the terms and conditions of the Dream11 game
          (accessible here). During the offer period (as communicated on the
          your Dream11 app), if the user chooses to continue playing instead
          of withdrawing their winnings, the user will get a bonus amount
          equivalent to an amount as communicated on their Dream11 app
          (“Discount Bonus”). The Discount Bonus as mentioned in the Promotion
          once credited will have a validity of 14 days and can only be
          redeemed while joining public paid contests. The Discount Bonus is
          non-transferable and cannot be withdrawn. The Discount Bonus cannot
          be clubbed with any other coupon or offers available with the user
          and/ or the Dream11 platform. Dream11 reserves the right to modify
          or discontinue the Promotion, or any part thereof, with or without
          notice, at its sole discretion. Dream11’s decision on any matters
          relating to this Promotion shall be final and binding. Once the user
          clicks on the ‘GET IT’ button, the user cannot claim any refund of
          the deducted amount. Football DreamBox Campaign Terms and Conditions
          This Football DreamBox Campaign” is only available for users who
          receive communication of the Promotion in their Dream11 app. Through
          this Promotion, users who receive the communication will be eligible
          to get a guaranteed reward via the DreamBox each day. To be eligible
          to get a DreamBox everyday user must join any paid public Contest of
          the Premier League or La Liga Tour each day from 31st August, 2024
          (00:00:00) to 2nd September 2024 (01:59:59). Eligibility: How to get
          a reward through DreamBox - Participation in this Promotion is
          available to only those users who have received the relevant
          communication of the Promotion from Dream11 on the Dream11 app. By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, and the
          terms and conditions of the Dream11 game (accessible here). During
          the offer period (31 August - 2 September 2024), if the user joins
          any paid public Contest the user will get guaranteed rewards via the
          DreamBox each day. Users will get only one DreamBox in a day. Users
          can get a maximum of 3 Dreambox if they join any paid public Contest
          each day from 31st August, 2024 (00:00:00) to 2nd September 2024
          (01:59:59). The DreamBox will expire in a day from the date when a
          user receives it i.e. the user will have a day's time to claim the
          reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Amazon ₹10,000 Voucher Users who win an Amazon ₹10,000 Voucher
          via the Football DreamBox worth ₹14285 (subject to deduction of 30%
          TDS) can be used Amazon website or application. The actual voucher
          value received by the user post TDS deductions will be ₹10,000.
          Should the purchase value exceed the value of the Voucher, the
          differential amount of such purchase should be paid using the other
          modes of payment available on the website. Amazon ₹10,000 Voucher
          coupon code will be available on “My Rewards” page of the user
          Amazon ₹10,000 Voucher cannot be exchanged for cash or any other
          benefit. Amazon ₹10,000 Voucher shall be valid till 2nd August 2025.
          Terms and conditions of the voucher shall apply. All purchases made
          on the Amazon website will be subject to the standard terms and
          conditions of the Amazon website including its cancellation, return
          and delivery policy. All complaints in relation to the redemption of
          the Amazon Pay Voucher/Gift Card shall be resolved as per the terms
          and conditions of Amazon as outlined here Ultimate Kick-Off Offer -
          Terms and Conditions This "Ultimate Kick-Off" ("Offer"), is only
          open to users who receive communication about the offer via stories,
          banner on Reward Shop and App Inbox and who join any Public Contests
          for at least 3 Premier League or La Liga matches. The Offer is
          applicable for Premier League or La Liga matches starting 31st
          August. Users can earn rewards as per the below milestones as below:
          Join any public contest for any 3 matches and get 1000 Bonus
          DreamCoins Join any public contest for any 6 matches and get 100%
          off up to Rs. 75 Global Discount Join any public contest for any 9
          matches and get 100% off up to Rs. 100 Global Discount Join any
          public contest for all matches and get Amazon voucher worth 10,000
          Users will only be eligible for the rewards associated with the
          milestones if they have opted in for the offer by clicking on the
          ‘YES, I’M INTERESTED’ button on the offer detail page. The discount
          rewards will be global in nature and can be applied to any public
          contests. It is not applicable to Private Contests. The rewards will
          be disbursed to users by 4th September 20:00 hours. Please note - if
          a user joins 5 matches, they will only get 100% off up to Rs. 100
          and not all 3 Please note our terms and conditions ("Terms") in this
          respect, as they govern your participation in the Contest:
          Eligibility – Participation in the Offer is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here) and can view the specific offer banner in their
          Dream11 app. By participating in this Offer, you agree to comply
          with these Terms, the Standard Terms and Conditions for Promotions,
          the terms and conditions of the Dream11 game (accessible here) and
          the standard terms and conditions of DreamCoins (accessible here)
          The Offer will be open for participation from Indian Standard Time
          ("IST") 00:00:01 hours on August 31st, 2024 till IST 23:59:59 hours
          on September 2nd 2024 ("Offer Period"). Subject to a user’s
          compliance with these Terms, the Bonus DreamCoins or Discount will
          be credited into the user's Dream11 account by IST 20:00:00 hours on
          September 4th 2024. The DreamCoins are valid as per the standard
          terms of the DreamCoins available here The additional bonus
          DreamCoins given via this offer will not be counted towards the
          user’s Champions club progress. These are valid for 7 days from
          being credited in your account Bonus DreamCoins may be granted by
          Dream11 to its users at its sole discretion. Global Discounts will
          be valid till 28th September 2024. Ultimate Kick-Off Offer 2 - Terms
          and Conditions This "Ultimate Kick-Off" ("Offer"), is only open to
          users who receive communication about the offer via stories, banner
          on Reward Shop and App Inbox and who join any Public Contests for at
          least 3 Premier League or La Liga matches. The Offer is applicable
          for Premier League or La Liga matches starting 31st August. Users
          can earn rewards as per the below milestones as below: Join any
          public contest for any 3 matches and get 1000 Bonus DreamCoins Join
          any public contest for any 6 matches and get 100% off up to Rs. 75
          Global Discount Join any public contest for any 9 matches and get
          100% off up to Rs. 100 Global Discount Join any public contest for
          all matches and get Amazon voucher worth 10,000 Users will only be
          eligible for the rewards associated with the milestones if they have
          opted in for the offer by clicking on the ‘YES, I’M INTERESTED’
          button on the offer detail page. The discount rewards will be global
          in nature and can be applied to any public contests. It is not
          applicable to Private Contests. The rewards will be disbursed to
          users by 4th September 20:00 hours. Please note - if a user joins 5
          matches, they will only get 100% off up to Rs. 100 and not all 3
          Please note our terms and conditions ("Terms") in this respect, as
          they govern your participation in the Contest: Eligibility –
          Participation in the Offer is only available to registered users of
          Dream11 who are eligible to participate in the pay-to-play Dream11
          contests as per Dream11’s terms and conditions (accessible here) and
          can view the specific offer banner in their Dream11 app. By
          participating in this Offer, you agree to comply with these Terms,
          the Standard Terms and Conditions for Promotions, the terms and
          conditions of the Dream11 game (accessible here) and the standard
          terms and conditions of DreamCoins (accessible here) The Offer will
          be open for participation from Indian Standard Time ("IST") 00:00:01
          hours on August 31st, 2024 till IST 23:59:59 hours on September 2nd
          2024 ("Offer Period"). Subject to a user’s compliance with these
          Terms, the Bonus DreamCoins or Discount will be credited into the
          user's Dream11 account by IST 20:00:00 hours on 4th September 2024.
          The DreamCoins are valid as per the standard terms of the DreamCoins
          available here The additional bonus DreamCoins given via this offer
          will not be counted towards the user’s Champions club progress.
          These are valid for 7 days from being credited in your account Bonus
          DreamCoins may be granted by Dream11 to its users at its sole
          discretion. Global Discounts will be valid till 4th October 2024.
          Dream11 SafetyShield Feature Terms and Conditions (1.2 | 13/09/24)
          This "Dream11 Safety Shield is available to all existing users of
          the Dream11 app, for whichever matches it has been enabled. This
          feature is only available and enabled on some matches and contests
          on the platform, and not all. The feature is only applicable to the
          largest prize pool ‘Mega Contest’ for a match, and not subsequent
          ones that open up once the first one fills A user may choose to
          ‘Shield’ their team Captain and/or Vice Captain for a contest by
          paying Rs 3 for each of these shields. If they do this, they will
          receive Rs 15 in the form of Discount Bonus if both the following
          conditions are met - The user’s selected Captain or Vice Captain for
          that shielded team used to join the Mega Contest scores less than 22
          (11 base FPS x2) and 16.5 (11 base FPS x1.5) points respectively and
          ; User is not in the winning zone for the match. Eligibility –
          Participation is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) The entry
          amount to purchase the Shield can come from the users deposit
          balance and winning balance and not Discount Bonus balance. If the
          user has insufficient deposit/winning balance, then they will have
          to add more in order to opt for the Safety Shield. The Discount
          Bonus will be credited into the user's Discount Bonus Account within
          two hours from the end time of the match when the contest winnings
          are disbursed (only if the above conditions are satisfied). The
          amount of Discount Bonus received per team is not capped, so if a
          user has opted for both C and VC shield and both the players score
          less than the required points as mentioned above, and the user does
          not come in the winning zone, then Discount Bonus of Rs 30 (15x2)
          will be given the user In case the user switches their team that was
          shielded, or edits the team (including changing C/VC) - the shield
          is inherited by the new team / new player i.e it is not lost If the
          user has utilized back-ups and their Shielded captain or vice
          captain changes at the time of round lock - the newly selected
          Captain/vice captain will now be shielded Discount Bonus expires
          fourteen (14) days from the date of credit. In case the match is
          abandoned, we will return the Rupee amount that was deducted to opt
          for this feature The Daily DreamBox Mania Campaign Terms and
          Conditions This "The Daily DreamBox Mania Campaign” is only
          available for users who receive communication of the Promotion in
          their Dream11 app. Through this Promotion, users who receive the
          communication will be eligible to get a guaranteed reward via the
          DreamBox each day. To be eligible to get a DreamBox everyday user
          must join any paid public Contest each day from 19th September, 2024
          (00:00:00) to 22nd September,2024 (23:59:59) for the respective
          Afghanistan vs South Africa & England vs Australia ODI matches on
          each day . Eligibility: How to get a reward through DreamBox -
          Participation in this Promotion is available to only those users who
          have received the relevant communication of the Promotion from
          Dream11 on the Dream11 app. By participating in this Promotion, you
          agree to comply with these Terms, the Standard Terms and Conditions
          for Promotions, and the terms and conditions of the Dream11 game
          (accessible here). During the offer period (19 September- 22
          September 2024), if the user joins any paid public Contest of the
          matches mentioned above the user will get guaranteed rewards via the
          DreamBox each day. Users will get only one DreamBox in a day. Users
          can get a maximum of 4 Dreambox if they join any paid public Contest
          each day from 19th September, 2024 (00:00:00) to 22nd September,2024
          (23:59:59). The DreamBox will expire in a day from the date when a
          user receives it i.e. the user will have a two day's time to claim
          the reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285 (subject to
          deduction of 30% TDS) can use it on the Imagine (Apple reseller)
          website or application. The actual voucher value received by the
          user post TDS deductions will be ₹80,000. Should the purchase value
          exceed the value of the Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. Imagine (Apple reseller) ₹80,000 Voucher coupon code
          will be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 17th September, 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here Dream11 ECC
          T10 Promotion The “Dream11 ECC T10 promotion campaign” (“Promotion”)
          is open to select users of Dream11 who receive communication from
          the Dream11 application. Under this Promotion, users who receive the
          communication on the Dream11 app must join a paid public contest in
          the Dream11 ECC T10 (“Contest”) weekly to unlock this Promotion. The
          weeks are defined as: Week 1: September 23, 2024 - September 29,
          2024 Week 2: September 30, 2024 - October 6, 2024 Week 3: October 7,
          2024 - October 13, 2024 Week 4: October 14, 2024 - October 20, 2024
          Users will be eligible to win any of the following rewards subject
          to fulfillment of the conditions stated below: Eligible Contests
          Rewards Participation in at least one paid Contest in weeks 1, 2, 3
          and 4 An Imagine (Apple Reseller) Voucher worth Rs. 80,000/-
          (post-TDS value) (“Voucher”) Participation in at least one paid
          Contest in weeks 1, 2, and 3 Discount of 50% up to Rs 30 (“Discount
          Coupon 1”) Participation in at least one paid Contest in weeks 1 and
          2 Discount of 50% up to Rs 25 (“Discount Coupon 2”) Participation in
          at least one paid Contest in week 1 100 DreamCoins Please note our
          terms and conditions ("Terms") in this respect, as they govern your
          participation in the promotion: Participation is only available to
          select registered users of Dream11 who receive the In App
          communication on the Dream11 mobile application and are eligible to
          participate in the pay to play contests as per Dream11's terms and
          conditions (accessible here). By participating in this Promotion,
          you agree to comply with these Terms, the Standard Terms and
          Conditions for Promotions and the terms and conditions of Dream11
          (accessible here ). In the event that any of the eligible match(es)
          forming part of the Contest are abandoned, this offer will be null
          and void for that match and the user will have to participate in
          another Contest within the relevant week to continue under this
          Promotion. Dream11's decision in the declaration of winners will be
          final and binding. Discount Coupon Details: The Discount Coupon 1
          will be credited to My Rewards the day after the user has
          participated in at least one Contest in weeks 1 and 2. The Discount
          Coupon 2 will be credited to My Rewards the day after the user has
          participated in at least one Contest in weeks 1, 2 and 3. Discount
          Coupons are valid on all paid public contests and will be valid only
          for 72 hours from the time they are credited. The Discount Coupon
          cannot be clubbed with any other offers, cash bonus available with
          the user. In the event that a contest in which a user has used the
          Discount Coupon gets canceled or the match itself gets abandoned,
          the discount amount used in that contest or match will be refunded
          to that user as Discount Bonus. Imagine Rs 80,000 Voucher details:
          All users who participate in a Contest in weeks 1, 2, 3 and 4 stand
          a chance to win the Voucher through which the winner can purchase
          items on the Imagine (Apple reseller) website and at Imagine (Apple
          reseller) Stores. The Voucher is subject to TDS which shall be borne
          by Dream11. The winner of the Voucher will be announced by IST
          23:00:00 hours on 22nd October, 2024. The winners will receive the
          Voucher details on their registered email id within three (3)
          working days after the winners have been announced. The Voucher
          cannot be exchanged for cash or any other benefit. The Voucher shall
          be valid till 22 October, 2025. Terms and conditions of the Voucher
          shall apply. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase shall be paid
          using the other modes of payment available on the website and the
          responsibility to pay any such differential amount shall be solely
          that of the winner/user. In the event of the winner not being a
          verified user on Dream11, that winner will be required to verify
          his/ her details within a period of fourteen (14) days, from the
          date of winner declaration. In the event the winner fails to verify
          his/her details on Dream11, the Voucher for such winner shall be
          forfeited. All purchases made on the Imagine (Apple reseller)
          website will be subject to the standard terms and conditions of the
          Imagine (Apple reseller) website including its cancellation, return
          and delivery policy. All complaints in relation to the redemption of
          the Imagine Gift Card shall be resolved as per the terms and
          conditions of Tresor Systems Pvt. Ltd. Winner may reach out to them
          as per the details provided in the Terms & Conditions outlined here
          DreamCoins Details: DreamCoins will be credited in the user’s
          Dream11 account the day after the user has participated in at least
          one paid Contest in week 1. The validity of these DreamCoins will be
          7 days. Dream Ticket Promotion This Dream Ticket Promotion
          (“Promotion”)is available to all users of Dream11 who participate in
          at least one public paid contest (“Contest”) of any Women’s cricket
          match on or after September 1st, 2024 till October 4th, 2024, 7:30
          PM (“Eligibility Criteria”) This Dream Ticket is only available to
          registered users of Dream11 who are eligible to participate in the
          pay-to-play Dream11 contests as per Dream11’s terms and conditions
          (accessible here). By participating in this Dream Ticket Promotion,
          you agree to comply with these Terms, and the terms and conditions
          of the Dream11 game (accessible here) The Dream Ticket Promotion
          will conclude on IST 19:30:00 hours on October 4th, 2024 ("Dream
          Ticket Period"). A total of 50 winners will be declared using a
          randomizer. Of the 50 winners, 3 winners will be declared on
          September 30th 2024. The users who get a Dream Ticket are shall
          receive a discount coupon of 100% off upto INR 3,00,000/-, which is
          only applicable to use in the match between Indian Women and
          Pakistan Women in the Women’s World Cup 2024 to be held on 6th
          October 2024, at 15:30 hours. This discount coupon is only
          applicable for paid public contests applicable for the
          aforementioned match only. Once the total discount under this
          Promotion is used by the user, the user shall not be eligible for
          any other free contests. Winners shall receive an email
          communication confirming their winnings within 24 hours of the
          conclusion of the match between India Women and New Zealand Women in
          the Women’s World Cup 2024, this match will be held on 4th October
          2024, at 19:30 hours. The winners shall receive only 1 Dream Ticket
          under this Promotion. In the event the match is abandoned or
          canceled, the contest entry refunds for such a match shall be given
          as a discount bonus to the user. The standard terms and conditions
          of discount bonus shall apply. In the event winners do not receive
          an email with a link they can reach out to the helpdesk within 1
          days of winner declaration. Poora Fan DreamBox Campaign Terms and
          Conditions This "Poora Fan DreamBox Campaign” is only available for
          users who receive communication of the Promotion in their Dream11
          app. Through this Promotion, users who receive the communication
          will be eligible to get a guaranteed reward via the DreamBox. To be
          eligible to get a DreamBox user must join any paid public Contest of
          India Women Vs Pakistan Women WWC Match and India Men Vs Bangladesh
          Men on 6th October 2024. Eligibility: How to get a reward through
          DreamBox - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here). During the offer period (3rd October
          - 6th October 2024), if the user joins any paid public Contest of
          the matches mentioned above the user will get guaranteed rewards via
          the DreamBox. Users will get only one DreamBox. Users can get a
          maximum of 1 Dreambox if they join any paid public Contest of the
          above mentioned matches from 3rd October, 2024 (00:00:00) to 6th
          October,2024 (23:59:59). The DreamBox will expire in two days from
          the date when a user receives it i.e. the user will have a two day's
          time to claim the reward through the DreamBox. Expiry of the Rewards
          received in a DreamBox is subject to terms and conditions of each
          reward. How to get a reward through DreamBox Go to the reward shop
          and click on the DreamBox icon to view all the collected DreamBoxes.
          Once inside, users need to select one of the four DreamBoxes, each
          of which will have a guaranteed reward inside it. Once the reward is
          disbursed, the user can’t claim the reward again from the same
          DreamBox. If the DreamBox is expired and the user hasn’t claimed the
          reward, the user won’t see the expired DreamBox anywhere and can no
          longer claim the reward. Where to view the rewards received through
          DreamBox DreamCoins will be added directly to your DreamCoins
          Balance Other rewards will be shown in the “My Rewards” section of
          Reward Shop. Rewards received through DreamBox Terms and Conditions:
          Bonus DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the
          date of credit. Bonus DreamCoins can be used towards redeeming
          rewards on the Rewards Shop only and will not count towards a user’s
          Champions Club Levels Progress. Check other DreamCoins terms and
          conditions here Imagine ₹80,000 Voucher Users who win an Imagine
          (Apple reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285
          (subject to deduction of 30% TDS) can use it on the Imagine (Apple
          reseller) website or application. The actual voucher value received
          by the user post TDS deductions will be ₹80,000. Should the purchase
          value exceed the value of the Voucher, the differential amount of
          such purchase should be paid using the other modes of payment
          available on the website. Imagine (Apple reseller) ₹80,000 Voucher
          coupon code will be available on “My Rewards” page of the user
          Imagine (Apple reseller) ₹80,000 Voucher cannot be exchanged for
          cash or any other benefit. Imagine (Apple reseller) ₹80,000 Voucher
          shall be valid till 30th September, 2025. Terms and conditions of
          the voucher shall apply. All purchases made on the Imagine (Apple
          reseller) website will be subject to the standard terms and
          conditions of the Imagine (Apple reseller) website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Imagine Gift Card shall be resolved as per
          the terms and conditions of Tresor Systems Pvt. Ltd. Winners may
          reach out to them as per the details provided in the Terms &
          Conditions outlined here Poora Fan Bano Quiz: Grand Prize- Women's
          World Cup Final Match Experience The Poora Fan Bano Quiz
          ("Promotion") is open to all Dream11 users who receive communication
          (external or in-app) on the Dream11 mobile application and choose to
          participate. Users who engage in the Promotion will be presented
          with a series of multiple-choice quiz questions within the Dream11
          app. Each question will offer four possible answers, with only one
          correct answer. Participants can select and submit only one answer
          per question. The Promotion will be open for participation from
          10:00:00 AM IST on October 6th, 2024, until 23:59:59 PM IST on
          October 6th, 2024 (“Promotion Period”). Access to participate will
          be available via an entry point on the Stories tab of the Dream11
          app. Please note our terms and conditions ("Terms") in this respect,
          as they govern your participation in the Promotion: Participation is
          only available to select registered users of Dream11 who are
          eligible to participate in the pay to play contests as per Dream11's
          terms and conditions (accessible here). By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions and the terms and conditions of
          Dream11 (accessible here). Prize Details: Reward Eligibility Grand
          Prize: Women's World Cup Final Match Experience 5 users with the
          highest number of correct answers during the Promotion Period, as
          determined by the randomiser tool, provided they meet all
          eligibility requirements. Promotion Terms and Conditions: Five (5)
          users with the highest number of correct answers will be selected as
          the winners of the Grand Prize using a randomiser tool, subject to
          meeting all criteria (such as holding a valid passport, visa, etc.)
          as set/communicated by Dream11 ("Winners"). Winners will be
          contacted via phone by the Dream11 team between 7th October 2024 and
          9th October, 2024. Winners must confirm acceptance of the Grand
          Prize during the phone call and provide all necessary documents
          requested by Dream11 to validate their eligibility. Failure to do so
          within the specified timeline will result in forfeiture of the Grand
          Prize. Upon acceptance and confirmation of the relevant eligibility
          criteria by Dream11, the winners’ contact details will be shared
          with the relevant third party service providers for the purposes of
          providing the relevant services to the user in relation to the Grand
          Prize. The Grand Prize includes: Air transport (return Economy Class
          flight tickets for each winner from the winner’s residence city in
          India or the nearest airport from the user’s residence city in India
          to Dubai), Two-night stay at a 4-Star/5-Star hotel, 1 match ticket
          to the Women’s World Cup Final scheduled to take place at Dubai
          International Cricket Stadium on 20th October 2024, and hotel
          transfers to and from the stadium. Dubai Tourist Visa expenses
          (subject to the winner submitting the necessary documents on time)
          Travel to and from the airport to the hotel Travel to and from the
          hotel to the stadium All meals for the duration of the stay The
          Grand Prize DOES NOT INCLUDE: Personal expenses Entrance fee for any
          parks, concerts, centers not included in the Package. Excludes all
          expenses that are not specifically mentioned in the Package
          inclusion. The Grand Prize is subject to Tax Deducted at Source
          (TDS), which will be borne by Dream11. If a Winner fails to confirm
          their acceptance, refuses the prize, or is deemed ineligible at any
          time, they will forfeit the Grand Prize without reimbursement for
          any expenses incurred. If a Winner forfeits the prize or fails to
          meet eligibility requirements, another user with the highest number
          of correct answers will be selected via the randomiser tool until
          five (5) Winners are chosen. This process will continue until 9th
          October 2024. Dream11 is not responsible for any expenses not
          explicitly covered in these Terms. Should a user's Dubai visa
          application be declined/rejected, fail to obtain a valid passport,
          or fail to board the relevant flight, resulting in their inability
          to travel to Dubai, neither Dream11 nor third party service provider
          shall be held responsible or be liable for providing an alternative
          arrangement in such circumstances. Dream11 will not be responsible
          for any expenses incurred if the Match is abandoned, cancelled, or
          rescheduled for any reason. Dream11 will not provide additional
          tickets or arrangements for any rescheduled match. In addition to
          Dream11’s terms, Winners are also bound by the terms and conditions
          of third-party service providers, including hotels, stadiums, travel
          agencies, and others involved in delivering the Grand Prize. It is
          the responsibility of the user to review and comply with these
          third-party terms, which govern reservations, cancellations, refund
          policies, and more. Dream11 assumes no responsibility for the acts,
          omissions, errors, or negligence of any third-party service
          provider. Dream11 reserves the right to cancel or modify the
          Promotion at its sole discretion at any time. Poora Fan DreamBox
          Campaign Terms and Conditions This "Poora Fan DreamBox Campaign” is
          only available for users who receive communication of the Promotion
          in their Dream11 app. Through this Promotion, users who receive the
          communication will be eligible to get a guaranteed reward via the
          DreamBox. To be eligible to get a DreamBox user must join any paid
          public Contest of India Women Vs Sri Lanka Women WWC Match and India
          Men Vs Bangladesh Men on 9th October 2024. Eligibility: How to get a
          reward through DreamBox - Participation in this Promotion is
          available to only those users who have received the relevant
          communication of the Promotion from Dream11 on the Dream11 app. By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, and the
          terms and conditions of the Dream11 game (accessible here ). During
          the offer period (7th October - 9th October 2024), if the user joins
          any paid public Contest of the matches mentioned above the user will
          get guaranteed rewards via the DreamBox. Users will get only one
          DreamBox. Users can get a maximum of 1 Dreambox if they join any
          paid public Contest of the above mentioned matches from 7th October,
          2024 (00:00:00) to 9th October,2024 (23:59:59). The DreamBox will
          expire in two days from the date when a user receives it i.e. the
          user will have a two day's time to claim the reward through the
          DreamBox. Expiry of the Rewards received in a DreamBox is subject to
          terms and conditions of each reward. How to get a reward through
          DreamBox Go to the reward shop and click on the DreamBox icon to
          view all the collected DreamBoxes. Once inside, users need to select
          one of the four DreamBoxes, each of which will have a guaranteed
          reward inside it. Once the reward is disbursed, the user can’t claim
          the reward again from the same DreamBox. If the DreamBox is expired
          and the user hasn’t claimed the reward, the user won’t see the
          expired DreamBox anywhere and can no longer claim the reward. Where
          to view the rewards received through DreamBox DreamCoins will be
          added directly to your DreamCoins Balance Other rewards will be
          shown in the “My Rewards” section of Reward Shop. Rewards received
          through DreamBox Terms and Conditions: Bonus DreamCoins: Expiry of
          Bonus DreamCoins will be 7 days from the date of credit. Bonus
          DreamCoins can be used towards redeeming rewards on the Rewards Shop
          only and will not count towards a user’s Champions Club Levels
          Progress. Check other DreamCoins terms and conditions here Imagine
          ₹80,000 Voucher Users who win an Imagine (Apple reseller) ₹80,000
          Voucher via DreamBox worth ₹1,14,285 (subject to deduction of 30%
          TDS) can use it on the Imagine (Apple reseller) website or
          application. The actual voucher value received by the user post TDS
          deductions will be ₹80,000. Should the purchase value exceed the
          value of the Voucher, the differential amount of such purchase
          should be paid using the other modes of payment available on the
          website. Imagine (Apple reseller) ₹80,000 Voucher coupon code will
          be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 30th September, 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here Women's
          World Cup T20 Championship Missions Terms and Conditions Women's
          World Cup T20 Championship missions In each zone, users get a new
          set of missions and get rewards on completion of every zone. Next
          zone unlocks only on completion of the previous zone, regardless of
          the user claiming the rewards from the previously completed zone.
          Users can participate in Missions from IST 15:30:00 hours on 3rd
          October 2024 till IST 23:59:59 hours on 20th October 2024
          (“Promotion Period”). Only matches starting and ending within the
          Promotion Period will be considered. Users must complete all
          missions in respective zones to be eligible for claiming the
          rewards. Only if users claim the benefits associated with the zone,
          will they be eligible for the rewards pertaining to it. If the user
          has completed all the missions in a zone but has not claimed the
          benefits, they shall not be eligible for the rewards. For “Cash
          Contest” missions, public and private paid contests joined by a user
          shall be considered towards progress of the Mission Contest join
          will only be considered if the user is eligible for the naturally
          earned DreamCoins for it i.e. user would not be eligible in case of
          abandoned or refunded contests. Where do you find new Women's World
          Cup T20 Championship missions? Women's World Cup T20 Championship
          Missions are only available to users who can see and access the
          Women's World Cup T20 Championship missions page via the offer
          banner on Reward Shop and are eligible to participate in the pay to
          play contests as per Dream11's terms and conditions (accessible
          here). By participating in this Promotion, you agree to comply with
          these Terms, the Standard Terms and Conditions for Promotions and
          the terms and conditions of Dream11 (accessible here). Rewards on
          completing the Women's World Cup T20 Championship missions. On
          completing missions in each zone of Women's World Cup T20
          Championship missions, users get Bonus DreamCoins and/or such other
          rewards as may be communicated on the Women's World Cup T20
          Championship Missions page. The Bonus DreamCoins Reward is
          guaranteed for the users if the user has claimed the reward. The
          Bumper Reward: Users will be eligible for the opportunity to win the
          Bumper Rewards only if they claim the reward of the respective zone.
          The winners for all Bumper Rewards would be declared for the Women's
          World Cup T20 Championship missions homepage by 23:59:59 IST hours
          on 24th October 2024 .Dream11 representatives will contact the
          winners of the Bumper rewards, within 3 working days post
          declaration of the winners either via email or over call. If the
          user has completed the mission, the user will have to come to the
          Women's World Cup T20 Championship missions homepage to claim the
          reward. All rewards earned on completion of a zone must be claimed
          by the user before completion of the relevant tournament. Bonus
          DreamCoins will be reflected directly in the user's DreamCoins
          Balance within 24 hours of claiming the same. Users get the Bonus
          DreamCoins in excess of naturally earned DreamCoins via the contest
          join actions. Bonus DreamCoins: Bonus DreamCoins expire on
          completion of 90 days from the date of credit. Bonus DreamCoins can
          be used towards redeeming rewards on the Rewards Shop. Bonus
          DreamCoins do not count towards a user’s Champions Club Levels
          Progress. Check other DreamCoins terms and conditions here
          Miscellaneous Dream11 reserves the right to amend, modify, change
          the terms of Missions as it may deem fit in its sole discretion. Any
          such change made by Dream11 shall be notified to the users and shall
          be effective from a prospective date. The progress for users will be
          updated on the Women's World Cup T20 Championship homepage every 6
          hours. Any benefit, reward granted prior to such change shall be
          governed by the original terms and conditions applicable for such
          benefit or reward. Every zone shall have a reward, the details of
          which shall be reflected on the Women's World Cup T20 Championship
          missions page against each such reward. All the prizes mentioned in
          these Terms are subject to TDS which shall be borne by Dream11
          unless specifically stated otherwise. 100% off up to ₹1,000 Discount
          Pass The Discount Pass will allow the user to avail a 100% discount
          in any paid public contest on the Dream11 App across any sport, with
          an overall maximum avail amount limit of ₹1,000. Discount Pass
          expires at IST 23:59:59 hours IST on November 20th, 2024. Discount
          Pass is not applicable on Private contests. Discount Pass cannot be
          clubbed together with any other promotions that are running
          simultaneously on the Dream11 platform. Also, Discount Pass cannot
          be clubbed with a Discount Bonus available to any user. In the event
          that a contest in which a user has used this Discount Pass gets
          canceled or the match itself gets abandoned, the discount amount
          used in that contest or match will be refunded to that user as
          Discount Bonus in their Dream11 wallet. Amazon Voucher worth ₹5,000
          Users who win an Amazon Voucher worth ₹5,000 (“Amazon Voucher”) can
          use it on the Amazon website or application. Should the purchase
          value exceed the value of the Voucher, the differential amount of
          such purchase should be paid using the other modes of payment
          available on the website. The details of the Amazon Voucher shall be
          sent to the registered email id(s) of the winner(s) within 3 working
          days from the date of winner declaration. The Amazon Voucher can be
          utilised on Amazon website or application. Amazon Voucher cannot be
          exchanged for cash or any other benefit. Amazon Voucher shall be
          valid till 1st October 2025. Terms and conditions of the Amazon
          Voucher shall apply. All purchases made on the Amazon website will
          be subject to the standard terms and conditions of the Amazon
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Amazon Pay Gift Card
          shall be resolved as per the terms and conditions of Amazon as
          outlined here. Imagine Voucher worth ₹80,000 Users who win an
          Imagine (Apple reseller) Voucher worth ₹80,000 (“Apple Voucher”) via
          Missions can use it on the Imagine (Apple reseller) website or
          application. Should the purchase value exceed the value of the
          Voucher, the differential amount of such purchase should be paid
          using the other modes of payment available on the website. Apple
          Voucher coupon code will be available on “My Rewards” page of the
          user Apple Voucher cannot be exchanged for cash or any other
          benefit. Apple Voucher shall be valid till 30th September 2025.
          Terms and conditions of the Apple Voucher shall apply. All purchases
          made on the Imagine (Apple reseller) website will be subject to the
          standard terms and conditions of the Imagine (Apple reseller)
          website including its cancellation, return and delivery policy. All
          complaints in relation to the redemption of the Imagine Gift Card
          shall be resolved as per the terms and conditions of Tresor Systems
          Pvt. Ltd. Winners may reach out to them as per the details provided
          in the Terms & Conditions outlined here Yezdi Bikes Voucher worth
          ₹2,50,000 Users who win a Yezdi Bikes Voucher worth ₹2,50,000 via
          Missions (“Yezdi Bikes Voucher”)will be contacted by Dream11 and
          will be asked to provide KYC details (government issued ID, full
          name, full address). The winner will be given a list of Yezdi Bikes
          Dealership stores by Dream11 based on the nearest dealerships to
          their location, and would be asked to choose a Yezdi Bikes
          Dealership store from which they want to purchase the Yezdi Bikes
          car. With this information, a Yezdi Bikes Voucher will be generated
          within 14 working days. Once this is done, the winner will be
          contacted by Dream11. The winner will have to go to the chosen Yezdi
          Bikes dealership store to collect and redeem the Yezdi Bikes Voucher
          for a Yezdi Bikes. The expiry of the Yezdi Bikes Voucher will be one
          year from the date of generation and communicated to the user by a
          Dream11 representative. The Yezdi Bikes Voucher is intended for one
          time use only and cannot be used for repeat purchases by the user.
          Should the purchase value exceed the value of the Yezdi Bikes
          Voucher, the differential amount of such purchase should be paid by
          the winner only. This Yezdi Bikes Voucher will be generated by
          Qwikcilver & Dream11 will only procure the Yezdi Bikes Voucher worth
          the amount on behalf of the user and have no further liability.
          Dream11 is not responsible for any costs of maintenance once the car
          has been handed over to the winner. The delivery timeline of the
          Yezdi Bikes shall be subject to availability and registration of the
          nearest authorized Yezdi Bikes dealership store and Dream11 shall
          not be held liable for any delay in the same. The winner shall be
          solely liable for submission of all required documents at the time
          of receiving the Prize. The list of required documents required to
          process the cash equivalent pay out to the user shall be
          communicated to the winner by Dream11. In the event of a winner not
          being a verified user on Dream11, the winner will be required to
          verify his/her details within a period of fourteen (14) days, from
          the date of winner declaration. In the event the winner is unable to
          produce the required valid documents, the Yezdi Bikes Voucher shall
          be forfeited. Upon delivery and acceptance of the Yezdi Bikes
          Voucher by the winner, Dream11 is discharged of all its liabilities
          hereunder. Registration, Insurance for 1st year and other costs to
          take the bike on-road will be borne by Yezdi Discount Mania Campaign
          Terms and Conditions This “Discount Mania Campaign ” is only
          available for users who receive communication of the Promotion in
          their Dream11 app. Through this Promotion, users who receive the
          communication will be eligible to get a guaranteed reward via the
          DreamBox each day and a bonus amount equivalent to an amount as
          communicated on their Dream11 app (“Discount Bonus”) if they choose
          to continue playing instead of withdrawing their winnings balance
          from 20th October, 2024 (00:00:00) to 27th October 2024 (IST
          23:59:59 hours). Promotion Period: 20th October, 2024 (IST 00:00:00
          hours) to 27th October 2024 (IST 23:59:59 hours). Upon availing the
          Promotion, the user will get the Discount Bonus amount credited to
          Dream11 Account by 30th October, 2024 (IST 23:59:59 hours).
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here). During the Promotion Period (20 - 27
          October 2024), if users choose to continue playing instead of
          withdrawing their winnings balance they will get a guaranteed reward
          via the DreamBox each day, credited the next day by IST 12:00:00
          hours. Users will get only one DreamBox in a day. Users can get a
          maximum of 8 Dreambox if they choose to continue playing instead of
          withdrawing their winnings during the Promotion Period days. The
          DreamBox will expire in a day from the date when a user receives it
          i.e. the user will have a day's time to claim the reward through the
          DreamBox. Expiry of the Rewards received in a DreamBox is subject to
          terms and conditions of each reward. The Discount Bonus as mentioned
          in the Promotion once credited will have a validity of 14 days and
          can only be redeemed while joining public paid contests. The
          Discount Bonus is non-transferable and cannot be withdrawn. The
          Discount Bonus cannot be clubbed with any other coupon or offers
          available with the user and/ or the Dream11 platform. How to get a
          reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher (“Apple Voucher”) can use it on the
          Imagine (Apple reseller) website or application. The Apple
          Vouchervalue is the final value post TDS deductions. Should the
          purchase value exceed the value of the Apple Voucher, the
          differential amount of such purchase should be paid using the other
          modes of payment available on the website. Apple Voucher coupon code
          will be available on “My Rewards” page of the user AppleVoucher
          cannot be exchanged for cash or any other benefit. Apple Voucher
          shall be valid till 18th October 2025. Terms and conditions of the
          voucher shall apply. All purchases made on the Imagine (Apple
          reseller) website will be subject to the standard terms and
          conditions of the Imagine (Apple reseller) website including its
          cancellation, return and delivery policy. All complaints in relation
          to the redemption of the Imagine Gift Card shall be resolved as per
          the terms and conditions of Tresor Systems Pvt. Ltd. Winners may
          reach out to them as per the details provided in the Terms &
          Conditions outlined here Dream Diwali DreamBox Campaign Terms and
          Conditions This Dream Diwali DreamBox Campaign” is only available
          for users who receive communication of the Promotion in their
          Dream11 app. Through this Promotion, users who receive the
          communication will be eligible to get a guaranteed reward via the
          DreamBox each day. To be eligible to get a DreamBox everyday user
          must join any paid public Contest each day from 29th October, 2024
          (00:00:00) to 4th November 2024 (23:59:59). Eligibility: How to get
          a reward through DreamBox - Participation in this Promotion is
          available to only those users who have received the relevant
          communication of the Promotion from Dream11 on the Dream11 app. By
          participating in this Promotion, you agree to comply with these
          Terms, the Standard Terms and Conditions for Promotions, and the
          terms and conditions of the Dream11 game (accessible here). During
          the offer period (29 October - 4 November 2024), if the user joins
          any paid public Contest the user will get guaranteed rewards via the
          DreamBox each day. Users will get only one DreamBox in a day. Users
          can get a maximum of 7 Dreambox if they join any paid public Contest
          each day from 29th October, 2024 (00:00:00) to 4th November 2024
          (23:59:59). The DreamBox will expire in a day from the date when a
          user receives it i.e. the user will have a day's time to claim the
          reward through the DreamBox. Expiry of the Rewards received in a
          DreamBox is subject to terms and conditions of each reward. How to
          get a reward through DreamBox Go to the reward shop and click on the
          DreamBox icon to view all the collected DreamBoxes. Once inside,
          users need to select one of the four DreamBoxes, each of which will
          have a guaranteed reward inside it. Once the reward is disbursed,
          the user can’t claim the reward again from the same DreamBox. If the
          DreamBox is expired and the user hasn’t claimed the reward, the user
          won’t see the expired DreamBox anywhere and can no longer claim the
          reward. Where to view the rewards received through DreamBox
          DreamCoins will be added directly to your DreamCoins Balance Other
          rewards will be shown in the “My Rewards” section of Reward Shop.
          Rewards received through DreamBox Terms and Conditions: Bonus
          DreamCoins: Expiry of Bonus DreamCoins will be 7 days from the date
          of credit. Bonus DreamCoins can be used towards redeeming rewards on
          the Rewards Shop only but will not count towards a user’s Champions
          Club Levels Progress. Check other DreamCoins terms and conditions
          here Imagine ₹80,000 Voucher Users who win an Imagine (Apple
          reseller) ₹80,000 Voucher via DreamBox worth ₹1,14,285 (subject to
          deduction of 30% TDS) can use it on the Imagine (Apple reseller)
          website or application. The actual voucher value received by the
          user post TDS deductions will be ₹80,000. Should the purchase value
          exceed the value of the Voucher, the differential amount of such
          purchase should be paid using the other modes of payment available
          on the website. Imagine (Apple reseller) ₹80,000 Voucher coupon code
          will be available on “My Rewards” page of the user Imagine (Apple
          reseller) ₹80,000 Voucher cannot be exchanged for cash or any other
          benefit. Imagine (Apple reseller) ₹80,000 Voucher shall be valid
          till 30th September, 2025. Terms and conditions of the voucher shall
          apply. All purchases made on the Imagine (Apple reseller) website
          will be subject to the standard terms and conditions of the Imagine
          (Apple reseller) website including its cancellation, return and
          delivery policy. All complaints in relation to the redemption of the
          Imagine Gift Card shall be resolved as per the terms and conditions
          of Tresor Systems Pvt. Ltd. Winners may reach out to them as per the
          details provided in the Terms & Conditions outlined here Play Big,
          Win Big Terms and Conditions This “Play Big, Win Big Campaign"
          (“Promotion”) is only available for users who receive communication
          of the Promotion in their Dream11 app. Through this Promotion, users
          who receive the communication will be eligible to get Discount Bonus
          equivalent to an amount as communicated on their Dream11 app
          (“Discount Bonus”) if they achieve the total Entry Fee target
          (excluding Entry fee from private contests) during the Promotion
          Period. If the user achieves the target within the offer period,
          they would receive the promised Discount Bonus within 48 hrs of
          Promotion completion. Please note our terms and conditions ("Terms")
          in this respect, as they govern your participation in the promotion:
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here). During the Promotion Period only the
          user who achieves the target as communicated on their Dream11 app
          will be eligible to get the Discount Bonus. These promotions are
          valid from IST 00:00:00 hours on 30th October, 2024 to IST 00:00:00
          hours on 5th November, 2024 (“Promotion Period”). The Discount Bonus
          as mentioned in the Promotion will be credited before IST 23:59:59
          hours on 7th November, 2024 will have a validity of 14 days and can
          only be redeemed while joining public paid contests. The Discount
          Bonus is non-transferable and cannot be withdrawn. The Discount
          Bonus cannot be clubbed with any other coupon or offers available
          with the user and/ or the Dream11 platform. Dream11 reserves the
          right to modify or discontinue the Promotion, or any part thereof,
          with or without notice, at its sole discretion. Dream11’s decision
          on any matters relating to this Promotion shall be final and
          binding. Play Big, Win Big Terms and Conditions This “Play Big, Win
          Big Campaign" (“Promotion”) is only available for users who receive
          communication of the Promotion in their Dream11 app. Through this
          Promotion, users who receive the communication will be eligible to
          get a Discount Bonus equivalent to an amount as communicated on
          their Dream11 app (“Discount Bonus”) if they achieve the total Entry
          Fee target (excluding Entry fee from private contests) during the
          Promotion Period. Please note our terms and conditions ("Terms") in
          this respect, as they govern your participation in the promotion:
          Eligibility - Participation in this Promotion is available to only
          those users who have received the relevant communication of the
          Promotion from Dream11 on the Dream11 app. By participating in this
          Promotion, you agree to comply with these Terms, the Standard Terms
          and Conditions for Promotions, and the terms and conditions of the
          Dream11 game (accessible here). During the Promotion Period, only
          the user who achieves the target as communicated on their Dream11
          app will be eligible to get the Discount Bonus. The promotion is
          valid from IST 00:00:00 hours on 20th November 2024 to IST 23:59:59
          hours on 26th November 2024 (“Promotion Period”). The Discount Bonus
          as mentioned in the Promotion will be credited before IST 23:59:59
          hours on 28th November, 2024 and the Discount Bonus will have a
          validity of 14 days and can only be redeemed while joining public
          paid contests. The Discount Bonus is non-transferable and cannot be
          withdrawn. The Discount Bonus cannot be clubbed with any other
          coupon or offers available with the user and/ or the Dream11
          platform. Dream11 reserves the right to modify or discontinue the
          Promotion, or any part thereof, with or without notice, at its sole
          discretion. Dream11’s decision on any matters relating to this
          Promotion shall be final and binding. ‘Super Fan Pass’ | Terms and
          Conditions Users may participate in a Contest(s) by paying the
          pre-designated amount as provided on the relevant Contest page. The
          ‘pre-designated amount’ means and includes pre-determined platform
          fee for accessing Dream11 services and pre-determined participant’s
          contribution towards prize money pool. Platform Fee shall be charged
          to the User prior to a User's joining of such Contest which will be
          debited from the User’s account balance. The Participant with the
          highest aggregate points at the end of the pre-determined match
          shall be eligible to win a pre-designated prize which is disbursed
          out of prize money pool, as stated on the relevant Contest(s) page.
          Additionally, upon landing on the contest page, wherever applicable,
          Users will have an option to opt in for Superfan Pass which will
          enable the Users to access value added services such as live
          streaming of the game, exclusive match statistics, match facts, and
          pitch insights. This is in an effort to improve the User’s game
          experience and aid in decision making for selection of teams by
          availing a Superfan Pass. Such option to opt in on payment of
          additional platform fees will be made available along with the
          contest entry cards. User will be eligible to opt for Superfan Pass
          only on joining contests on Dream11 platform. The Superfan Pass is
          only available for users once the user has joined contests with
          entry fee above the Superfan Pass amount. Once the user joins the
          contest with Superfan Pass, user is provided with a prompt to go to
          the video player page and start watching the stream when available
          (availability basis stream start from event broadcaster). User has
          access to in depth analysis and stats on the video player or via
          stream to improve the skill set of the user and get better insights
          for decision making. Upon the start of the match users may choose to
          avail the option of SuperFan Pass available on the leaderboard. Post
          completion of the match, Dream11 will announce winners of a contest
          based on the ranking of teams used to participate in the contest by
          user. User shall receive net winnings in winnings account subsequent
          to deduction of platform fees as applicable including additional
          platform fees towards SuperFan Pass. All winners will be credited
          with winning net off platform fees including additional platform
          fees towards Superfan Pass in the winnings account which is
          determined upfront but computed subsequently depending on the
          ranking of the user in the contest. To clarify, additional platform
          fees towards the SuperFan Pass will only be deducted if a user’s
          total winnings from all public contests in the subject match is
          greater than the additional platform fees towards the Superfan pass.
          Dream11 SafetyShield Feature Terms and Conditions (1.1 | 20/12/24)
          This "Dream11 Safety Shield is available to all existing users of
          the Dream11 app, for whichever matches it has been enabled. This
          feature is only available and enabled on some matches and contests
          on the platform, and not all. The feature is only applicable to the
          largest prize pool ‘Mega Contest’ for a match, and not subsequent
          ones that open up once the first one fills user may choose to
          ‘Shield’ their team Captain and/or Vice Captain for a contest by
          paying Rs 5 for each of these shields. If they do this, they will
          receive Rs 15 in the form of Discount Bonus if both the following
          conditions are met - The user’s selected Captain or Vice Captain for
          that shielded team used to join the Mega Contest scores less than 22
          (11 base FPS x2) and 16.5 (11 base FPS x1.5) points respectively and
          ; User is not in the winning zone for the match. Eligibility –
          Participation is only available to registered users of Dream11 who
          are eligible to participate in the pay-to-play Dream11 contests as
          per Dream11’s terms and conditions (accessible here) The entry
          amount to purchase the Shield can come from the users deposit
          balance and winning balance and not Discount Bonus balance. If the
          user has insufficient deposit/winning balance, then they will have
          to add more in order to opt for the Safety Shield. The Discount
          Bonus will be credited into the user's Discount Bonus Account within
          two hours from the end time of the match when the contest winnings
          are disbursed (only if the above conditions are satisfied). The
          amount of Discount Bonus received per team is not capped, so if a
          user has opted for both C and VC shield and both the players score
          less than the required points as mentioned above, and the user does
          not come in the winning zone, then Discount Bonus of Rs 30 (15x2)
          will be given the user In case the user switches their team that was
          shielded, or edits the team (including changing C/VC) - the shield
          is inherited by the new team / new player i.e it is not lost If the
          user has utilized back-ups and their Shielded captain or vice
          captain changes at the time of round lock - the newly selected
          Captain/vice captain will now be shielded Discount Bonus expires
          fourteen (14) days from the date of credit. In case the match is
          abandoned, we will return the Rupee amount that was deducted to opt
          for this feature Dream11 reserves the right to amend, modify, change
          the terms of the Safety Shield feature as it may deem fit in its
          sole discretion. Any such change made by Dream11 shall be notified
          to the users and shall be effective from a prospective date.
          
          TERMS OF USE This website, located at https://dreamsports.co.in/
          (“Website”) is owned and operated by the Sporta Technologies Private
          Limited (the “Company”). Through the Website, the Company provides
          users with information regarding the Company and its various brands,
          initiatives and offerings such as Dream11, FanCode, DreamX,
          DreamSetGo, DreamPay, and Dream Sports Foundation — the
          philanthropic arm of the Company. The terms ‘Company’ or ‘us’ or
          ‘we’ refer to the owners of this Website. The term ‘you’ refers to
          the user or viewer of our Website. By browsing, viewing, using the
          Website provided therein you consent to and agree to comply with
          these terms and conditions of use (“Terms” or “Terms of Use”), which
          together with our Privacy Policy govern the Company’s relationship
          with you in relation to this Website. If you disagree with any part
          of these Terms, please discontinue using our Website immediately.
          The Company reserves the right to change or modify the Website, the
          contents thereof and these Terms of Use at any time without any
          prior intimation to you. All modifications will be posted on the
          Website and will become effective immediately upon such posting to
          the Website. Please review these Terms of Use regularly to remain
          informed of any change. ACCESSING, BROWSING OR OTHERWISE USING THE
          WEBSITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN
          THESE TERMS, AS MODIFIED FROM TIME TO TIME. THEREFORE, PLEASE READ
          THESE TERMS CAREFULLY BEFORE PROCEEDING. Website Content All
          materials, including all documents, illustrations, statements,
          photographs, products, images, artwork, designs, text, graphics,
          logos, button icons, images, audio and video clips and software
          (collectively, “Content”) are protected by copyrights, trademarks
          and other intellectual property rights that are owned by the Company
          or by other parties that have licensed their material to the
          Company. Except for the limited license granted to you herein, all
          Content on the Website is solely for your personal, non-commercial
          use. You may not copy Content from this Website, or otherwise
          modify, obscure or delete any copyright or other propriety notices
          on this Website. No right, title or interest in any materials or
          software, if applicable, on this Website is transferred to you from
          your use of this Website. You may not reproduce, publish, transmit,
          distribute, display, modify, create derivative works from, sell or
          participate in any sale of, or exploit in any way, in whole or in
          part, any of the Content, this Website, or any related material
          without the prior written authorization of the Company. Contributed
          Content This Website may contain views/ideas/opinions expressed by
          independent authors/contributors/posters on various topics. Such
          views/ideas/opinions are solely those of the
          authors/contributors/posters and not endorsed in any manner by the
          Company. The Company shall not be responsible for or liable in any
          manner whatsoever for the views/ideas/opinions expressed by such
          authors/contributors/posters in any section of this Website.
          Further, the accuracy of such statements has not been verified by
          the Company and the Company provides no warranties of the same. If
          you seek to rely on any representation of information contained on
          this Website, any such reliance shall be at your own risk. User
          Restrictions Your use of our Website is solely for your personal
          informational purposes and non-commercial use. In addition to the
          aforementioned restrictions on the usage of Content, Users shall
          not: Decompile, reverse engineer, or disassemble the contents of the
          Website, or tamper or remove any copyright, trademark registration,
          or other proprietary notices from the contents of the Website. Use
          our Website for commercial purposes of any kind; Advertise or sell
          or otherwise (whether or not for profit) solicit others (including,
          without limitation, solicitations for contributions or donations) in
          relation to the Company’s or any of the Company’s brand’s domain
          names; Aggregate or otherwise use our Content in a manner that could
          reasonably serve as a substitute for access to the Website; Use the
          Website in any way that is unlawful, fraudulent or dishonest, or
          harms the Company or any other person or entity, as determined by
          the Company; Access or use the Website in any manner that could
          damage, disable, overburden or impair the Website or any of the
          Website’s servers or the networks connected to any of the servers on
          which the Website is hosted; Intentionally or unintentionally
          interfere with or disrupt the services or violate any applicable
          laws related to the access to or use of the Website, violate any
          requirements, procedures, policies or regulations of networks
          connected to the Website, or engage in any activity prohibited by
          these Terms; Disrupt or interfere with the security of, or otherwise
          cause harm to the Website, Content, systems resources, or gain
          unauthorized access to the servers or networks connected to or
          accessible through the Website or any affiliated or linked sites;
          and Use deep-links, page-scrape, robot, spider or other automatic
          device, program, algorithm or methodology, or any similar or
          equivalent manual process, to increase traffic to the Website, to
          access, acquire, copy or monitor any portion of the Website, or in
          any way reproduce or circumvent the exclusionary protocols,
          navigational structure or presentation of the Website, or any
          content, to obtain or attempt to obtain any content, documents or
          information through any means not specifically made available
          through the Website. Warranty and Liability Disclaimer This Website
          and the Content herein is provided by the Company in good faith on
          an “as is”, “as available” basis. The Company does not guarantee the
          accuracy, timeliness, completeness, performance or fitness for a
          particular purpose of this Website. Further, the Company makes no
          representations or warranties of any kind, express or implied, as to
          the operation of this Website, the information, the Content or
          materials herein or hereon. To the extent as permitted under
          applicable law, the Company disclaims all responsibility (whether
          direct, indirect, consequential or otherwise) with respect to the
          accuracy or otherwise of the information, Content or materials
          arising from the use of this Website. Without prejudice to the
          foregoing paragraph, the Company does not warrant that: This Website
          will be constantly available, or available at all; or The
          information on this Website is complete, true, accurate or not
          misleading; or The quality of any information, or other material
          that you obtain through the Website will meet your expectations. The
          Company, to the fullest extent permitted by law, disclaims all
          warranties, whether express or implied, including the warranty of
          fitness for particular purpose and non-infringement. The Company
          makes no warranties about the accuracy, reliability, completeness,
          or timeliness of any content, software, text, graphics and links.
          The Company does not warrant that this Website; information, Content
          or materials included on or otherwise made available to you through
          this Website; the servers hosting the Website; or electronic
          communication sent from the Company are free of viruses or other
          harmful components. The Company utilises safeguards and industry
          best practices to protect the Website from hackers, sniffers and
          malicious modification tools, however, it does not warrant that the
          Website shall be completely safe from such attacks at any period of
          time. Nothing on this Website constitutes, or is meant to
          constitute, advice of any kind. Third Party Links Please note that
          this Website may contain links to affiliated websites or links
          provided by third parties. Any website or information accessed by or
          as a result of following such links is at your sole risk and the
          content viewed thereof is not endorsed in any manner by the Company,
          unless expressly stated in that website’s terms. The Company shall
          not be responsible for or liable in any manner whatsoever for the
          content provided through such third party links. Further, the
          accuracy of the content provided through third party links has not
          been verified by the Company and we provide no warranties with
          respect to the same. Any information collected on websites visited
          through such links is subject to the privacy policies of those
          websites. Accuracy of Information The information presented on this
          Website has been compiled by the Company from various sources
          including from external sources. No representation is made or
          warranty given as to the completeness or accuracy of such
          information. This Website may contain typographical errors and
          incomplete or out of date information. The Company reserves the
          right to make changes to the Content and information on this Website
          or update such information at any time without notice, but the
          Company makes no commitment to corrector update this information. If
          you seek to rely on any representation of information contained on
          this Website, any such reliance shall be at your own risk.
          Indemnification You represent, warrant and covenant (a) that you
          will fulfil your obligations hereunder these Terms in good faith;
          (b) that no materials of any kind provided by you will (i) violate,
          plagiarize, or infringe upon the rights of any third party,
          including copyright, trademark, privacy or other personal or
          proprietary rights; or (ii) contain defamatory or otherwise unlawful
          material. You hereby indemnify, defend and hold harmless the Company
          and all of the Company’s officers, directors, owners, agents,
          information providers, affiliates, licensors, licensees and third
          party service providers (collectively, the “Indemnified Parties“)
          from and against any and all liability and costs, including, without
          limitation, reasonable legal fees, incurred by the Indemnified
          Parties in connection with any claim arising out of any breach by
          you of these Terms or the foregoing representations, warranties and
          covenants. You shall cooperate as reasonably required in the defense
          of any such claim. The Company reserves the right, at its own
          expense, to assume the exclusive defense and control of any matter
          subject to indemnification by you. Termination The Company may
          immediately, without notice, deny you access to this Website if, in
          the Company’s opinion, you fail to comply with any or all of the
          Terms set forth herein. Infringement of Copyright In good faith, if
          you have reason to believe that any work copyrighted by you has been
          reproduced, embedded, or linked without any authorization on this
          Website, in a manner that constitutes an infringement of your
          copyright under Indian law, please compile the following information
          and email to: brand@dream11.com A clear identification of the
          copyrighted work allegedly infringed; A clear identification of the
          allegedly infringing material on the Website (with specific URL
          reference); Your contact details: name, address, e-mail address and
          phone number; A statement that you believe, in good faith, that the
          use of the copyrighted material allegedly infringed on the Website
          is not authorized by your agent or the law; A statement that the
          information provided in the notice is accurate, and under penalty of
          perjury, that the signatory is authorized to act on behalf of the
          owner of an exclusive copyright right that is allegedly infringed;
          Your signature or a signature of your authorized agent. Applicable
          Law This Website, including the Content and information contained
          herein, shall be governed by the laws of the Republic of India and
          the courts of Mumbai, India shall retain exclusive jurisdiction to
          entertain any proceedings in relation to any disputes arising out of
          the same. As such, the laws of India shall govern any disclaimers,
          legal notices, privacy policies of this Website, and any
          transactions completed using this Website. Information Gathered and
          Tracked Information submitted through any questionnaire forms on the
          Website is stored in a database. Specifically, we may store the
          name, name of the organisation, e-mail address, and certain
          demographic information (address, occupation, phone no. etc.) of the
          users of our Website who wish to interact with the Company or seek
          information on its activities. Such information will be primarily
          used to facilitate interaction between users of the Website and the
          Company. We may also use such information to send out occasional
          promotional materials including alerts on latest events organised by
          the Company or its affiliated brands. We have clearly marked which
          information is optional and which is mandatory. We guarantee that
          personal information about users will be used strictly in accordance
          with our Privacy Policy. Assignment You may not assign your rights
          and obligations under these Terms without the prior written consent
          of the Company. The Company may assign its rights and obligations to
          a third party upon issuing written notification via an announcement
          on the Website.
          */}



          </p>
        </div>
      </div>
      <FooterEl />
    </>
  );
};

export default TermsAndConditions;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Main/Main";
import "./style.scss";
import TermsAndConditions from './componets/Policy/TermsAndConditions'
import Policy from './componets/Policy/Policy'
import Refund from './componets/Policy/Refund'
import Scroll from "./componets/Scroll";

function App() {
  return (
    <BrowserRouter>
    <Scroll />
      <Routes>
        <Route path="/">
          <Route index element={<Main />} />
          <Route path="/Termsandconditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyPolicy" element={<Policy />} />
          <Route path="/RefundPolicy" element={<Refund />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import facebook1 from "../../Images/facebook1.webp";
import instagram from "../../Images/instagram.webp";
import linkedin from "../../Images/linkedin1.webp";
import telegram from "../../Images/telegramlogo.webp";
import twiter from "../../Images/twitter1.webp";
import youtube from "../../Images/youtube1.webp";
import whatsapp from "../../Images/whatsapp.png";
const FooterEl = () => {

  return (
    <div className="footerContainer">
      <div className="subContainer">
        <text className="footerAppname">Chat&money</text>
        <text className="footerAppname-com">chatandmoney.com / C&M</text>
      </div>
      <div className="linkIcons">
        <a
          href="https://www.facebook.com/profile.php?id=61561833271265"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebook1}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://whatsapp.com/channel/0029VacXbB06hENyBuQik21v"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsapp}
            style={{
              borderRadius: "90px",
              backgroundColor: "#fff",
              padding: "4px",
            }}
            alt="Logo"
            width={25}
            height={25}
          />
        </a>
        <a
          href="    https://www.youtube.com/@ChatandMoney"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={youtube}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://www.instagram.com/chatandmoney?igsh=OXVmMmVyNjZremI0&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagram}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
      </div>
      <div className="termsAndContainer">
        <Link to="/Termsandconditions">
          <div className="linkContainer">
            <p className="linkContainerText">Terms & Conditions</p>
          </div>
        </Link>
        <Link to="/PrivacyPolicy">
          <div className="linkContainer">
            <p className="linkContainerText">Privacy Policy</p>
          </div>
        </Link>
        <Link to="/RefundPolicy">
          <div className="linkContainer">
            <p className="linkContainerText">Refund Policy</p>
          </div>
        </Link>
      </div>
      <div className="textContainer">
        <p style={{ color: "#fff", marginTop: "10px" }}>
          Regd Ofiice Add : 40/45 'OR' 556 SHIV SHAKTI NAGAR
        </p>
        <p style={{ color: "#fff", marginTop: "0px" }}>
          MEERUT, 250002 (UTTAR PRADESH) INDIA
        </p>
        <p style={{ color: "#fff", marginTop: "0px" }}>
          Design & Developed by 🤍
        </p>
      </div>
      <div className="footerBase">
        <p className="footerBaseText">
          "This game may be habit-forming or financially risky. Play
          responsibly."
        </p>
      </div>
    </div>
  );
};

export default FooterEl;

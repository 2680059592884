import React from "react";
import Header from "../header/Header";
import FooterEl from "../Footer/FooterEl";
const Policy = () => {
  return (
    <>
      <Header />

      <div className="headLine">
        <p className="textHeadline">User Privacy Policy</p>
        <div className="content">
          <p className="text">
            <strong>Last Updated: 12 January, 2025</strong>
            <br></br>
            <br></br>
            <strong>
              INTRODUCTION - WHO WE ARE AND WHAT WE DO TO PROTECT OUR USER DATA
              AND INFORMATION WITH US ?
            </strong>
          </p>
        </div>
      </div>
      <FooterEl />

    </>
  );
};

export default Policy;
